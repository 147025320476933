import { Button } from '@nextui-org/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const ConfirmedAccount = () => {
  const navigator = useNavigate();
  const user = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.user);

  const navigate = (route) => {
    navigator(route);
  };

  const handleClick = (e) => {
    e.preventDefault();

    navigate('/login');
  };

  useEffect(() => {
    if (user.currentUser && user.currentUser?.email_verified_at && !error) {
      navigate('/dashboard');
      // reload page
      window.location.reload();
    }
  }, [user]);

  return (
    <div className="h-full bg-white">
      <div className="flex h-full min-h-screen justify-center">
        <div
          className="mx-auto flex w-full flex-col items-start bg-bottom bg-no-repeat md:w-1/2"
          style={{
            backgroundImage: 'url("/images/home-light.svg")',
            backgroundSize: '100% auto',
          }}
        >
          <div className="w-full items-center text-primary md:flex md:pr-4">
            <div className="w-1/3 px-4">
              <img
                className="py-8 md:h-44 md:py-0"
                src="/images/logo-large.svg"
                alt=""
              />
            </div>
          </div>
          <div className="w-full max-w-2xl px-4 md:px-16">
            <div className="text-start">
              <h2 className="mb-8 text-xl font-bold text-black">
                Adresse E-mail vérifiée
              </h2>
              <div className="space-y-2 text-lg dark:text-gray-300">
                <p className=" ">
                  Vous avez vérifié avec succès votre adresse e-mail. Veuillez
                  continuer avec la dernière étape pour accéder à votre compte.
                </p>
              </div>
            </div>
            <div className="mt-8 text-lg">
              <form onSubmit={handleClick}>
                <div>
                  <Button
                    type="submit"
                    css={{
                      borderRadius: '0px', // radii.xs
                      border: '1px solid',
                      borderColor: '#DC3838',
                      color: 'white',
                      backgroundColor: '#DC3838',
                    }}
                    size="lg"
                    fullWidth="true"
                  >
                    SUIVANT
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="hidden w-1/2 bg-cover lg:flex"
          style={{
            backgroundImage: 'url("/images/cover.png")',
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmedAccount;
