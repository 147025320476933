import { userRequest } from '../../api';
import {
  auditFailure,
  auditStart,
  auditSuccess,
} from '../reducers/auditReducer';
import { expired, loginReset } from '../reducers/userReducer';

export const getAudits = (page, pageSize) => async (dispatch) => {
  dispatch(auditStart());
  try {
    const res = await userRequest.get(
      `inspecteur/auditaffecter?page=${page}&per_page=${pageSize}`
    );
    dispatch(auditSuccess(res.data));
  } catch (err) {
    dispatch(auditFailure(err.response.data));
    if (err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(auditFailure(err.response.data));
    }
  }
};

export const getAuditNegociation = (page, pageSize) => async (dispatch) => {
  dispatch(auditStart());
  try {
    const res = await userRequest.get(
      `entreprise/negociation/index?page=${page}&per_page=${pageSize}`
    );
    dispatch(auditSuccess(res.data));
  } catch (err) {
    dispatch(auditFailure(err.response.data));
    if (err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(auditFailure(err.response.data));
    }
  }
};

export const getMeasurersAudits = (page, pageSize) => async (dispatch) => {
  dispatch(auditStart());
  try {
    const res = await userRequest.get(
      `entreprisemesureur/index?page=${page}&per_page=${pageSize}`
    );
    dispatch(auditSuccess(res.data));
  } catch (err) {
    dispatch(auditFailure(err.response.data));
    if (err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(auditFailure(err.response.data));
    }
  }
};

export const getMeasurerAudits = (page, pageSize) => async (dispatch) => {
  dispatch(auditStart());
  try {
    const res = await userRequest.get(
      `mesureur/listeaudit?page=${page}&per_page=${pageSize}`
    );
    dispatch(auditSuccess(res.data));
  } catch (err) {
    dispatch(auditFailure(err.response.data));
    if (err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(auditFailure(err.response.data));
    }
  }
};

export const getEnterpriseInspectorsAudits =
  (page, pageSize) => async (dispatch) => {
    dispatch(auditStart());
    try {
      const res = await userRequest.get(
        `entreprise/attribuerauditinspecteur?page=${page}&per_page=${pageSize}`
      );
      dispatch(auditSuccess(res.data));
    } catch (err) {
      dispatch(auditFailure(err.response.data));
      if (err.response.status === 403) {
        dispatch(expired());
        dispatch(loginReset());
      } else {
        dispatch(auditFailure(err.response.data));
      }
    }
  };

export const getEnterpriseAudits = (page) => async (dispatch) => {
  dispatch(auditStart());
  try {
    const res = await userRequest.get(`entreprise/demandeaudit?page=${page}`);
    dispatch(auditSuccess(res.data));
  } catch (err) {
    dispatch(auditFailure(err.response.data));
    if (err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(auditFailure(err.response.data));
    }
  }
};

export const getEnterpriseInspectorAudits = (id, page) => async (dispatch) => {
  dispatch(auditStart());
  try {
    const res = await userRequest.get(
      `entreprise/inspecteur/${id}/auditaffecter?page=${page}`
    );
    dispatch(auditSuccess(res.data));
  } catch (err) {
    dispatch(auditFailure(err.response.data));
    if (err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(auditFailure(err.response.data));
    }
  }
};

export const getAdminInspectorAudits =
  (id, userId, page) => async (dispatch) => {
    dispatch(auditStart());
    try {
      const res = await userRequest.get(
        `admin/entreprise/${id}/inspecteur/${userId}?page=${page}`
      );
      dispatch(auditSuccess(res.data));
    } catch (err) {
      dispatch(auditFailure(err.response.data));
      if (err.response.status === 403) {
        dispatch(expired());
        dispatch(loginReset());
      } else {
        dispatch(auditFailure(err.response.data));
      }
    }
  };

export const getAdminMeasurerAudits =
  (id, userId, page) => async (dispatch) => {
    dispatch(auditStart());
    try {
      const res = await userRequest.get(
        `admin/entreprise/${id}/mesureur/${userId}?page=${page}`
      );
      dispatch(auditSuccess(res.data));
    } catch (err) {
      dispatch(auditFailure(err.response.data));
      if (err.response.status === 403) {
        dispatch(expired());
        dispatch(loginReset());
      } else {
        dispatch(auditFailure(err.response.data));
      }
    }
  };

export const getAudit = async (id) => {
  return userRequest.get(`inspecteur/auditaffecter/${id}`);
};

export const getMesurerAudit = async (id) => {
  return userRequest.get(`mesureur/show/${id}`);
};

export const getEnterpriseAudit = async (id) => {
  return userRequest.get(`entreprise/demandeaudit/${id}`);
};
// export const getEnterpriseAuditStatisque = async (page) => {
//   return userRequest.get(`entreprise/audits/demandes-count?page=${page}`);
// };
// export const getEnterprisePeriodAudit = async (page, start_date, end_date) => {
//   return userRequest.get(
//     `entreprise/audits/demandes-count?page=${page}&start_date=${start_date}&end_date=${end_date}`
//   );
// };

export const getEnterpriseInspectorAudit = async (id) => {
  return userRequest.get(`entreprise/attribuerauditinspecteur/${id}`);
};

export const getEnterpriseMeasurerAudit = async (id) => {
  return userRequest.get(`entreprisemesureur/show/${id}`);
};

export const assignAuditToInspector = async (userId, id, comment) => {
  return userRequest.post(`entreprise/attribuerauditinspecteur`, {
    inspecteur_id: userId,
    demande_audit_id: id,
    commentaire: comment,
  });
};

export const affectAuditToMesurer = async (userId, id, comment) => {
  return userRequest.post(`entreprisemesureur/store`, {
    mesureur_id: userId,
    demande_audit_id: id,
    commentaire: comment,
  });
};

export const validateInspectorAudit = async (id, comment, status) => {
  return userRequest.post(`entreprise/updatestatus/${id}/inspecteur`, {
    demande_audit_id: id,
    commentaire: comment,
    status,
  });
};

export const validateAudit = async (id, comment, status) => {
  return userRequest.post(`entreprisemesureur/updatestatus/${id}`, {
    demande_audit_id: id,
    commentaire: comment,
    status,
  });
};

export const updateAuditStatus = async (id, status) => {
  return userRequest.post(`entreprise/updatestatus/${id}`, {
    status,
  });
};

export const removeAuditToInspector = async (id) => {
  return userRequest.post(`entreprise/attribuerauditinspecteur/${id}`);
};

export const rejectAuditToMeasurer = async (id) => {
  return userRequest.post(`entreprisemesureur/destroy/${id}`);
};

export const rejectMeasurerAudit = async (id) => {
  return userRequest.post(`mesureur/destroy/${id}`);
};

export const rejectInspectorAudit = async (id) => {
  return userRequest.post(`inspecteur/removeauditaffecter/${id}`);
};

export const update = async (user) => {
  return userRequest.put('inspecteur/auditaffecter', user);
};

export const getPricings = async (page) => {
  return userRequest.get(`entreprise/tarificationaudit?page=${page}`);
};
export const getEnterpriseAuditStatisque = async (page) => {
  return userRequest.get(`entreprise/audits/demandes-count?page=${page}`);
};
export const getInspectorAuditStatisque = async (page) => {
  return userRequest.get(`inspecteur/audits/demandes-count?page=${page}`);
};
export const getMeasurerAuditStatisque = async (page) => {
  return userRequest.get(`mesureur/audits/demandes-count?page=${page}`);
};
export const getAdminAuditStatisque = async (page) => {
  return userRequest.get(`admin/audits/demandes-count?page=${page}`);
};
export const getEnterprisePeriodAudit = async (page, start_date, end_date) => {
  return userRequest.get(
    `entreprise/audits/demandes-count?page=${page}&start_date=${start_date}&end_date=${end_date}`
  );
};

export const getPricing = async (id) => {
  return userRequest.get(`entreprise/tarificationaudit/${id}`);
};

export const addPricing = async (pricing) => {
  return userRequest.post('entreprise/tarificationaudit', pricing);
};

export const editPricing = async (pricing) => {
  return userRequest.put(`entreprise/tarificationaudit`, pricing);
};

export const getAuditsTypes = async () => {
  return userRequest.get(`entreprise/typeaudit`);
};
export const getAuditNegociations = async () => {
  return userRequest.get(`entreprise/negociation/index`);
};

export const getEnterprisePeriodicAudits = async (data, page, pageSize) => {
  return userRequest.post(
    `entreprise/demandeaudit/filterbyperiode?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getMeasurePeriodicAudits = async (data, page, pageSize) => {
  return userRequest.post(
    `mesureur/auditaffecterfilterbyperiode?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getPeriodicAudits = async (data, page, pageSize) => {
  return userRequest.post(
    `inspecteur/auditaffecterfilterbyperiode?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getAuditsStatus = async (data, page, pageSize) => {
  return userRequest.post(
    `inspecteur/auditaffecterfilterbystatus?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getMeasurerAuditsStatus = async (data, page, pageSize) => {
  return userRequest.post(
    `mesureur/demandeauditbystatus?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getEnterpriseInspectorPeriodicAudits = async (
  data,
  page,
  pageSize
) => {
  return userRequest.post(
    `/entreprise/inspecteurdmdfilterbyperiode?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getEnterpriseMeasurerPeriodicAudits = async (
  data,
  page,
  pageSize
) => {
  return userRequest.post(
    `/entreprisemesureur/dmdfilterbyperiode?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getEnterpriseMeasurersStatus = async (data, page, pageSize) => {
  return userRequest.post(
    `entreprisemesureur/dmdbystatus?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getEnterpriseAuditsStatus = async (data, page, pageSize) => {
  return userRequest.post(
    `entreprise/demandeaudit/filterbystatus?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getEnterpriseInspecteursStatus = async (data, page, pageSize) => {
  return userRequest.post(
    `entreprise/attribuerauditinspecteur?page=${page}&per_page=${pageSize}`,
    data
  );
};
