import { combineReducers } from 'redux';

import appReducer from './appReducer';
import auditReducer from './auditReducer';
import customerReducer from './customerReducer';
import enterpriseReducer from './enterpriseReducer';
import inspectorReducer from './inspectorReducer';
import measurerReducer from './measurerReducer';
import userReducer from './userReducer';

export default combineReducers({
  app: appReducer,
  user: userReducer,
  audit: auditReducer,
  customer: customerReducer,
  inspector: inspectorReducer,
  measurer: measurerReducer,
  enterprise: enterpriseReducer,
});
