/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-useless-escape */
/* eslint-disable no-nested-ternary */
import { Button, Checkbox, Input, Loading, Text } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import IconBuilding from '../components/icons/iconBuilding';
import IconHidden from '../components/icons/IconHidden';
import IconLeft from '../components/icons/IconLeft';
import IconMail from '../components/icons/IconMail';
import IconPassword from '../components/icons/IconPassword';
import IconVisible from '../components/icons/IconVisible';
import IconPhone from '../components/icons/PhoneIcon';
import Map from '../components/Map';
import { register } from '../redux/api/user';
import {
  confirmEmail,
  loginReset,
  loginStart,
} from '../redux/reducers/userReducer';

const SignUp = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const account_type = new URLSearchParams(window.location.search).get(
    'profile'
  );
  const user = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.user);
  const [inputs, setInputs] = useState({});
  const [helper, setHelper] = useState({
    lastname: {
      text: '',
      color: '',
    },
    firstname: {
      text: '',
      color: '',
    },
    email: {
      text: '',
      color: '',
    },
    phone: {
      text: '',
      color: '',
    },
    city: {
      text: '',
      color: '',
    },
    address: {
      text: '',
      color: '',
    },
    zipcode: {
      text: '',
      color: '',
    },
    password: {
      text: '',
      color: '',
    },
    newpassword: {
      text: '',
      color: '',
    },
    type: {
      text: '',
      color: '',
    },
    terms: {
      text: '',
      color: '',
    },
  });

  const resetHelpers = (e) => {
    if (e.target.name === 'lastname')
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'firstname')
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'email')
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'phone')
      setHelper((prev) => {
        return {
          ...prev,
          phone: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'zipcode')
      setHelper((prev) => {
        return {
          ...prev,
          zipcode: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'address')
      setHelper((prev) => {
        return {
          ...prev,
          address: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'password')
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'newpassword')
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: '',
            color: '',
          },
        };
      });

    if (e.target.name === 'type')
      setHelper((prev) => {
        return {
          ...prev,
          type: {
            text: '',
            color: '',
          },
        };
      });

    if (e.target.name === 'terms')
      setHelper((prev) => {
        return {
          ...prev,
          terms: {
            text: '',
            color: '',
          },
        };
      });
  };

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  const navigate = (route) => {
    navigator(route);
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  const handleCheckboxChange = (e) => {
    setHelper((prev) => {
      return {
        ...prev,
        terms: {
          text: '',
          color: '',
        },
      };
    });
    setInputs((prev) => {
      return { ...prev, terms: e };
    });
  };

  useEffect(() => {
    dispatch(loginReset());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(loginReset());
    };
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleClick = (e) => {
    dispatch(loginReset());
    e.preventDefault()
    console.log("event :>>", e.nativeEvent);

    if (inputs.lastname)
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.lastname)
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: 'Veuillez renseigner votre nom',
            color: 'error',
          },
        };
      });

    if (inputs.firstname)
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.firstname)
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: 'Veuillez renseigner votre prénom',
            color: 'error',
          },
        };
      });

    if (inputs.phone)
      setHelper((prev) => {
        return {
          ...prev,
          phone: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.phone)
      setHelper((prev) => {
        return {
          ...prev,
          phone: {
            text: 'Veuillez renseigner votre téléphone',
            color: 'error',
          },
        };
      });

    if (inputs.city)
      setHelper((prev) => {
        return {
          ...prev,
          city: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.city)
      setHelper((prev) => {
        return {
          ...prev,
          city: {
            text: 'Veuillez renseigner votre ville',
            color: 'error',
          },
        };
      });

    if (inputs.zipcode)
      setHelper((prev) => {
        return {
          ...prev,
          zipcode: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.zipcode)
      setHelper((prev) => {
        return {
          ...prev,
          zipcode: {
            text: 'Veuillez renseigner votre code postal',
            color: 'error',
          },
        };
      });

    if (inputs?.location)
      setHelper((prev) => {
        return {
          ...prev,
          address: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.location)
      setHelper((prev) => {
        return {
          ...prev,
          address: {
            text: 'Veuillez renseigner votre adresse',
            color: 'error',
          },
        };
      });

    if (inputs.email)
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.email)
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: 'Email requis',
            color: 'error',
          },
        };
      });

    if (inputs.email && !validateEmail(inputs.email))
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: 'Email non valide',
            color: 'error',
          },
        };
      });

    if (inputs.password)
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.password)
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: 'Mot de passe requis',
            color: 'error',
          },
        };
      });

    if (inputs.password && inputs.password.length < 5)
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: 'Le mot de passe doit contenir au mois 5 caractères',
            color: 'error',
          },
        };
      });

    if (inputs.newpassword)
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.newpassword)
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: 'Confirmer le mot de passe',
            color: 'error',
          },
        };
      });

    if (inputs.type)
      setHelper((prev) => {
        return {
          ...prev,
          type: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.type)
      setHelper((prev) => {
        return {
          ...prev,
          type: {
            text: 'Choississez un type de compte',
            color: 'error',
          },
        };
      });

    if (inputs.terms)
      setHelper((prev) => {
        return {
          ...prev,
          terms: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.terms)
      setHelper((prev) => {
        return {
          ...prev,
          terms: {
            text: 'Veuillez accepter les termes et conditions',
            color: 'error',
          },
        };
      });

    if (
      inputs.password &&
      inputs.newpassword &&
      inputs.password !== inputs.newpassword
    )
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: 'Les mots de passe ne correspondent pas',
            color: 'error',
          },
        };
      });

    if (
      !inputs.lastname ||
      !inputs.firstname ||
      !inputs.phone ||
      !inputs.city ||
      !inputs.zipcode ||
      !inputs.location ||
      !inputs.email ||
      (account_type === 'company' && !inputs.type) ||
      !inputs.password ||
      !inputs.newpassword ||
      inputs.password.length < 5 ||
      inputs.password !== inputs.newpassword ||
      !validateEmail(inputs.email) ||
      !inputs.terms
    ) {
      return;
    }

    setLoading(true);
    dispatch(loginReset());
    dispatch(loginStart());
    setMessage('');

    const newUser = {
      email: inputs.email,
      password: inputs.password,
      prenom: inputs.firstname,
      nom: inputs.lastname,
      telephone: inputs.phone,
      lat: inputs.location.lat,
      long: inputs.location.lng,
      code_postal: inputs.zipcode,
      ville_residence: inputs.city,
      type_users:
        account_type === 'company'
          ? 'entreprise'
          : account_type === 'user'
          ? 'mesureur'
          : 'inspecteur',
      type_entreprise: account_type === 'company' ? inputs.type : '',
    };

    console.log('newUser: ', newUser);

    if (newUser.type_users === 'mesureur') delete newUser.type_entreprise;
    if (newUser.type_users === 'inspecteur') delete newUser.type_entreprise;
    register(newUser)
      .then(() => {
        setLoading(false);
        dispatch(confirmEmail({ email: inputs.email, account_type }));
        navigate('/confirm');
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.data.message === 'The given data was invalid.')
          setMessage("L'adresse email a été déjà utilisée");
        else setMessage('Une erreur est survenue');
      });
  };

  useEffect(() => {
    if (error === 'Unauthorized')
      setMessage("Nom d'utilisateur ou mot de passe incorrect");
    else setMessage('');
  }, [error]);

  useEffect(() => {
    if (
      user.currentUser &&
      user.currentUser?.email_verified_at &&
      !error &&
      !loading
    ) {
      navigate('/dashboard');
      // reload page
      window.location.reload();
    }
  }, [user]);

  const back = () => {
    navigate('/');
  };

  return (
    <div className="h-full bg-white">
      <div className="flex h-full min-h-screen justify-center">
        <div
          className="mx-auto flex w-full flex-col items-center bg-bottom bg-no-repeat lg:w-1/2"
          style={{
            backgroundImage: 'url("/images/home-light.svg")',
            backgroundSize: '100% auto',
          }}
        >
          <div className="flex w-full items-center text-primary md:pr-4">
            <div className="w-1/3 px-4">
              <img
                className="py-8 md:h-44 md:py-0"
                src="/images/logo-large.svg"
                alt=""
              />
            </div>
          </div>
          <div className="w-full px-4 md:px-16">
            <div className="mt-4 flex w-full max-w-3xl flex-col items-start justify-center space-y-8 md:mb-[4.5rem]">
              <form
                onSubmit={(e) => handleClick(e)}
                className="w-full space-y-6 lg:w-auto"
              >
                <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <Input
                      name="lastname"
                      underlined
                      fullWidth="true"
                      label="Nom"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      color={helper.lastname.color}
                      helperColor={helper.lastname.color}
                      helperText={helper.lastname.text}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      name="firstname"
                      underlined
                      fullWidth="true"
                      label="Prénom(s)"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      color={helper.firstname.color}
                      helperColor={helper.firstname.color}
                      helperText={helper.firstname.text}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <Input
                      contentLeft={<IconMail />}
                      name="email"
                      type="email"
                      underlined
                      fullWidth="true"
                      label="Email"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      color={helper.email.color}
                      helperColor={helper.email.color}
                      helperText={helper.email.text}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      contentLeft={<IconPhone />}
                      name="phone"
                      underlined
                      fullWidth="true"
                      label="Téléphone"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      color={helper.phone.color}
                      helperColor={helper.phone.color}
                      helperText={helper.phone.text}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <Input
                      contentLeft={<IconBuilding />}
                      name="city"
                      underlined
                      fullWidth="true"
                      label="Ville"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      color={helper.city.color}
                      helperColor={helper.city.color}
                      helperText={helper.city.text}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      name="zipcode"
                      underlined
                      fullWidth="true"
                      label="Code postal"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      color={helper.zipcode.color}
                      helperColor={helper.zipcode.color}
                      helperText={helper.zipcode.text}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <Map
                      input={
                        <Input
                          name="address"
                          underlined
                          fullWidth="true"
                          label="Adresse"
                          placeholder="Rue, numéro"
                          className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                          color={helper.address.color}
                          helperColor={helper.address.color}
                          helperText={helper.address.text}
                        />
                      }
                      onClose={(place) => {
                        handleChange({
                          target: { name: 'location', value: place },
                        });
                      }}
                    />
                  </div>
                  {account_type === 'company' && (
                    <div className="w-full">
                      <div className="group w-full border-[0.75rem] border-x-0 border-b-2 border-t-0 border-[#dfe3e6] bg-white bg-transparent">
                        <label className="mb-[0.375rem] block pl-1 text-[0.875rem] leading-6 text-black">
                          Type de compte
                        </label>
                        <select
                          className="inline-flex w-full flex-1 appearance-none border-0 bg-white bg-transparent text-[0.875rem] text-black"
                          name="type"
                          onChange={(e) =>
                            handleChange({
                              target: {
                                name: e.target.name,
                                value: e.target.value,
                              },
                            })
                          }
                        >
                          <option
                            value=""
                            className="hover:bg-primary hover:text-white"
                          >
                            Sélectionner
                          </option>
                          <option
                            value="Immobilier"
                            className="hover:bg-primary hover:text-white"
                          >
                            Immobilier
                          </option>
                          <option
                            value="Énergétique"
                            className="hover:bg-primary hover:text-white"
                          >
                            Énergétique
                          </option>
                        </select>
                      </div>
                      <p
                        className={`ml-2.5 mb-4 text-[0.625rem] ${
                          helper.type.color === 'error'
                            ? 'text-[#F31260]'
                            : 'text-initial'
                        }`}
                      >
                        {helper.type.text}
                      </p>
                    </div>
                  )}
                </div>
                <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <Input.Password
                      contentLeft={<IconPassword />}
                      name="password"
                      underlined
                      fullWidth="true"
                      width=""
                      label="Mot de passe"
                      visibleIcon={<IconHidden />}
                      hiddenIcon={<IconVisible />}
                      color={helper.password.color}
                      helperColor={helper.password.color}
                      helperText={helper.password.text}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <Input.Password
                      name="newpassword"
                      underlined
                      fullWidth="true"
                      width=""
                      label="Confirmer votre mot de passe"
                      visibleIcon={<IconHidden />}
                      hiddenIcon={<IconVisible />}
                      color={helper.newpassword.color}
                      helperColor={helper.newpassword.color}
                      helperText={helper.newpassword.text}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="mt-2 max-w-2xl text-start">
                  <Checkbox
                    name="terms"
                    className="register-checkbox"
                    onChange={handleCheckboxChange}
                  >
                    <Text className="text-base">
                      En vous inscrivant vous acceptez nos{' '}
                      <a href="/conditions" className="text-primary">
                        Termes
                      </a>{' '}
                      et{' '}
                      <a href="/privacy" className="text-primary">
                        Politique de confidentialité
                      </a>
                    </Text>
                  </Checkbox>
                  <p
                    className={`mb-4 text-sm ${
                      helper.terms.color === 'error'
                        ? 'text-[#F31260]'
                        : 'text-initial'
                    }`}
                  >
                    {helper.terms.text}
                  </p>
                </div>

                {message && (
                  <div className="w-full text-center text-sm text-red-500">
                    {message}
                  </div>
                )}
                <div className="mt-4 flex w-full flex-col-reverse space-y-4 space-y-reverse md:flex-row md:space-x-4">
                  <Button
                    onClick={back}
                    css={{
                      borderRadius: '0px', // radii.xs
                      border: '1px solid',
                      color: '#DC3838',
                      borderColor: '#DC3838',
                      width: '100%',
                    }}
                    bordered
                    size="lg"
                  >
                    <IconLeft />
                    <span>RETOUR</span>
                  </Button>
                  <Button
                    type="submit"
                    onClick={handleClick}
                    css={{
                      borderRadius: '0px', // radii.xs
                      border: '1px solid',
                      borderColor: '#DC3838',
                      color: 'white',
                      backgroundColor: '#DC3838',
                      width: '100%',
                    }}
                    size="lg"
                  >
                    {loading && (
                      <Loading
                        style={{
                          marginRight: '1rem',
                        }}
                        color="currentColor"
                        size="sm"
                      />
                    )}
                    S&apos;INSCRIRE
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="hidden w-1/2 bg-cover lg:flex"
          style={{
            backgroundImage: 'url("/images/cover.png")',
          }}
        />
      </div>
    </div>
  );
};

export default SignUp;
