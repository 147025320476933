/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import { Button } from '@nextui-org/react';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import IconLeft from '../components/icons/IconLeft';

const SignUpChoice = () => {
  const navigator = useNavigate();
  const [selected, setSelected] = useState('');

  const navigate = (route) => {
    navigator(route);
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (selected) navigate(`/register?profile=${selected}`);
  };

  const back = () => {
    navigate('/');
  };

  return (
    <div className="h-full bg-white">
      <div className="flex h-full min-h-screen justify-center">
        <div
          className="mx-auto flex w-full flex-col items-center bg-bottom bg-no-repeat md:w-1/2"
          style={{
            backgroundImage: 'url("/images/home-light.svg")',
            backgroundSize: '100% auto',
          }}
        >
          <div className="flex w-full items-center text-primary md:pr-4">
            <div className="w-1/3 px-4">
              <img
                className="py-8 md:h-44 md:py-0"
                src="/images/logo-large.svg"
                alt=""
              />
            </div>
          </div>
          <div className="w-full px-4 md:px-14">
            <div className="text-start">
              <h2 className="px-4 text-start text-2xl font-bold text-primary md:text-4xl">
                Bienvenue !
              </h2>

              <div className="mt-8 space-y-2 px-4 text-xl text-gray-500 md:text-2xl">
                <p className=" ">
                  Diags Audit, la solution pour vos audits{' '}
                  <br className="hidden md:block" />
                  énergétiques en toute <br className="hidden md:block" />
                  facilité et en toute sécurité.
                </p>
              </div>

              <div className="flex h-full w-full flex-col items-start space-y-8 px-4">
                <div className="flex items-start">
                  <h2 className="mt-8 text-2xl text-primary">
                    Choisissez le type de compte
                  </h2>
                </div>
                <form onSubmit={handleClick}>
                  <div
                    type="button"
                    className="max-w-md-entrprise-choice mt-8 flex w-full flex-row-reverse items-center justify-between space-x-1"
                  >
                    <button
                      type="button"
                      className={`ml-4 flex w-auto cursor-pointer items-center justify-center border-2 border-transparent py-2 px-2 duration-100 hover:border-2 hover:border-primary
                  ${
                    selected === 'inspector'
                      ? '!border-primary'
                      : 'border-transparent'
                  }
                    `}
                      onClick={() => {
                        setSelected('inspector');
                      }}
                    >
                      <img
                        className="img-signup mt-2"
                        src="../images/inspector_logo.svg"
                        alt=""
                        width="150px"
                        height="150px"
                      />
                    </button>
                    <button
                      type="button"
                      className={`ml-4 flex w-auto cursor-pointer items-center justify-center border-2 border-transparent py-2 px-2 duration-100 hover:border-2 hover:border-primary
                  ${
                    selected === 'user'
                      ? '!border-primary'
                      : 'border-transparent'
                  }
                    `}
                      onClick={() => {
                        setSelected('user');
                      }}
                    >
                      <img
                        className="img-signup mt-2"
                        src="../images/user.svg"
                        alt=""
                      />
                    </button>
                    <button
                      type="button"
                      className={`flex w-auto cursor-pointer items-center justify-center border-2 border-transparent py-2 px-2 duration-100 hover:border-2 hover:border-primary
                    ${
                      selected === 'company'
                        ? '!border-primary'
                        : 'border-transparent'
                    }
                      `}
                      onClick={() => {
                        setSelected('company');
                      }}
                    >
                      <img
                        className="img-signup mt-2"
                        src="../images/company.svg"
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="mt-4 flex w-full flex-col-reverse space-y-4 space-y-reverse md:w-auto md:flex-row md:space-x-4">
                    <Button
                      onClick={back}
                      css={{
                        borderRadius: '0px', // radii.xs
                        border: '1px solid',
                        color: '#DC3838',
                        borderColor: '#DC3838',
                      }}
                      bordered
                      size="lg"
                    >
                      <IconLeft />
                      <span>RETOUR</span>
                    </Button>
                    <Button
                      type="submit"
                      onClick={handleClick}
                      css={{
                        borderRadius: '0px', // radii.xs
                        border: '1px solid',
                        borderColor: '#DC3838',
                        color: 'white',
                        backgroundColor: '#DC3838',
                      }}
                      size="lg"
                    >
                      CONTINUER
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div
          className="hidden w-1/2 bg-cover lg:flex"
          style={{
            backgroundImage: 'url("/images/cover.png")',
          }}
        />
      </div>
    </div>
  );
};

export default SignUpChoice;
