import { createTheme, ThemeProvider } from '@mui/material';
import { DataGrid, frFR, GridActionsCellItem } from '@mui/x-data-grid';
import {
  Button,
  Dropdown,
  Grid,
  Input,
  Modal,
  Text,
  useModal,
} from '@nextui-org/react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import IconDownload from '../components/icons/IconDownload';
import {
  getEnterprisePeriodicTransactionsHistory,
  getEnterpriseTransactionsHistory,
  getPeriodicTransactionsHistory,
  getTransactionsHistory,
} from '../redux/api/app';
import { expired, loginReset } from '../redux/reducers/userReducer';
import { renderDate } from '../utils/common';

const TransactionsHistory = () => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const { setVisible, bindings } = useModal();
  const [selected, setSelected] = useState(new Set(['Filtrer par période']));
  const { isEnterprise } = useSelector((state) => state.user);
  const [transaction, setTransaction] = useState(null);
  const [transactions, setTransactions] = useState([]);
  const [meta, setMeta] = useState({});
  const [mode, setMode] = useState('server');
  const [loading, setLoading] = useState(false);
  const [filters] = useState([
    'Tous',
    'Il y a une semaine',
    'Il y a deux semaines',
    'Il y a un mois',
    'Il y a six mois',
    'Définir une autre période',
  ]);

  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(15);
  const [rowCountState, setRowCountState] = useState(0);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const getTransactions = () => {
    setLoading(true);
    if (isEnterprise) {
      getEnterpriseTransactionsHistory(page + 1, pageSize)
        .then((res) => {
          setLoading(false);
          setTransactions(
            res.data?.data?.map((t) => {
              return {
                id: t.id,
                description: t.action,
                amount: t.montant,
                payment_method: t.moyen_paiement,
                client_email: t.email,
                client_number: t.telephone,
                client_lastName: t.nom,
                client_firstName: t.prenom,
                created_at: t.created_at,
                updated_at: t.updated_at,
              };
            })
          );
          setMeta(res.data.meta);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          }
        });
    } else {
      getTransactionsHistory(page + 1, pageSize)
        .then((res) => {
          setLoading(false);
          setTransactions(
            res.data?.data?.map((t) => {
              return {
                id: t.id,
                description: t.action,
                amount: t.montant,
                payment_method: t.moyen_paiement,
                client_email: t.email,
                client_number: t.telephone,
                client_lastName: t.nom,
                client_firstName: t.prenom,
                created_at: t.created_at,
                updated_at: t.updated_at,
              };
            })
          );
          setMeta(res.data.meta);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          }
        });
    }
  };

  const getPeriodicTransaction = (period) => {
    setLoading(true);

    if (isEnterprise) {
      getEnterprisePeriodicTransactionsHistory(
        {
          param: period,
        },
        page + 1,
        pageSize
      )
        .then((res) => {
          setLoading(false);
          if (page === 0)
            setTransactions(
              res.data?.data?.map((t) => {
                return {
                  id: t.id,
                  description: t.action,
                  amount: t.montant,
                  payment_method: t.moyen_paiement,
                  client_email: t.email,
                  client_number: t.telephone,
                  client_lastName: t.nom,
                  client_firstName: t.prenom,
                  created_at: t.created_at,
                  updated_at: t.updated_at,
                };
              })
            );
          else setPage(0);
          setRowCountState(res.data?.data?.length);
          setMode('client');
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            // setMessage('Impossible de récupérer les transactions');
          }
        });
    } else {
      getPeriodicTransactionsHistory(
        {
          param: period,
        },
        page + 1,
        pageSize
      )
        .then((res) => {
          setLoading(false);
          if (page === 0)
            setTransactions(
              res.data?.data?.map((t) => {
                return {
                  id: t.id,
                  description: t.action,
                  amount: t.montant,
                  payment_method: t.moyen_paiement,
                  client_email: t.email,
                  client_number: t.telephone,
                  client_lastName: t.nom,
                  client_firstName: t.prenom,
                  created_at: t.created_at,
                  updated_at: t.updated_at,
                };
              })
            );
          else setPage(0);
          setRowCountState(res.data?.data?.length);
          setMode('client');
        })
        .catch((err) => {
          setLoading(false);
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            // setMessage('Impossible de récupérer les transactions');
          }
        });
    }
  };

  const getCustomPeriodicTransaction = () => {
    setLoading(true);

    getPeriodicTransactionsHistory(
      {
        param: 'custom',
        debut: startDate,
        fin: endDate,
      },
      page + 1,
      pageSize
    )
      .then((res) => {
        setLoading(false);
        setPage(0);
        setTransactions(
          res.data?.data?.map((t) => {
            return {
              id: t.id,
              description: t.audit,
              amount: t.montant,
              audit_number: t.action,
              payment_method: t.moyen_paiement,
              client_email: t.email,
              client_number: t.telephone,
              client_lastName: t.nom,
              client_firstName: t.prenom,
              created_at: t.created_at,
              updated_at: t.updated_at,
            };
          })
        );
        setRowCountState(res.data?.data?.length);
        setMode('client');
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.status === 401 || err.response?.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        }
      });
  };

  useEffect(() => {
    getTransactions();
  }, []);

  useEffect(() => {
    const filterVal = selected.entries().next().value[0].toLowerCase();

    if (filterVal === 'tous') {
      setPage(0);
      getTransactions(page + 1, pageSize);
    } else if (filterVal === 'il y a une semaine') {
      getPeriodicTransaction('1week');
    } else if (filterVal === 'il y a deux semaines') {
      getPeriodicTransaction('2weeks');
    } else if (filterVal === 'il y a un mois') {
      getPeriodicTransaction('1month');
    } else if (filterVal === 'il y a six mois') {
      getPeriodicTransaction('6month');
    }

    if (filterVal !== 'définir une autre période') {
      setStartDate(null);
      setEndDate(null);
    }
  }, [selected]);

  useEffect(() => {
    setLoading(true);
    getTransactions(page + 1, pageSize);
  }, [page]);

  const navigate = (route) => {
    navigator(route);
  };

  const handleFilter = (e) => {
    e.preventDefault();

    if (startDate && endDate) getCustomPeriodicTransaction();
  };

  const viewTransaction = (transact) => {
    setVisible(true);
    setTransaction(transact);
  };

  const closeHandler = () => {
    navigate('/enterprises');
  };
  const downloadHistory = (history) => {
    const HISTORY_DATE = new Date(history.updated_at);
    const addZero = (date) => {
      return date.toString().length === 1 ? `0${date}` : date;
    };
    const historyDetails = {
      content: [
        {
          image: 'logo',
          width: '150',
          margin: [-25, -50, 0, -25],
        },
        {
          text: 'DIAGS AUDIT',
          style: 'header',
          margin: [0, 0, 0, 5],
        },
        {
          text: 'Marque de KEEP SAFE',
          style: 'header',
        },
        {
          columns: [
            [
              {
                text: '2 rue Diderot',
                margin: [0, 10, 0, 3],
              },
              {
                text: '94190 Villeneuve Saint Georges',
                margin: [0, 3],
              },
              {
                text: 'Email : diagaudit4@gmail.com',
                margin: [0, 3],
              },
              {
                text: 'Siret : 819 275 389 00022',
                margin: [0, 3],
              },
              {
                text: 'TVA : FR21819275389',
                margin: [0, 3],
              },
              {
                text: 'Code APE : 7490B',
                margin: [0, 3],
              },
            ],
            [
              {
                text: 'Client',
                margin: [0, 10, 0, 3],
                style: 'header',
              },
              {
                text: `${history.client_lastName} ${history.client_firstName}`,
                margin: [0, 3],
              },
              {
                text: `${history.client_email}`,
                margin: [0, 3],
              },
              {
                text: `${history.client_number}`,
                margin: [0, 3],
              },
            ],
          ],
          margin: [0, 0, 0, 30],
        },
        {
          text: [
            { text: 'Date ', bold: true },
            `${addZero(HISTORY_DATE.getDate())}/${addZero(
              HISTORY_DATE.getMonth() + 1
            )}/${HISTORY_DATE.getFullYear()}`,
          ],
          margin: [0, 5],
        },
        {
          text: [
            { text: 'Numéro de facture ', bold: true },
            `: ${history.audit_number}`,
          ],
          margin: [0, 0, 0, 5],
        },
        {
          layout: 'lightHorizontalLines', // optional
          margin: [0, 0, 0, 10],
          table: {
            headerRows: 1,
            widths: ['*', 'auto', 'auto', 'auto'],
            body: [
              [
                { text: 'Description', bold: true, margin: [5, 10] },
                { text: '', bold: true, margin: [-100, 5] },
                { text: '', bold: true, margin: [-1, 5] },
                { text: 'Total HT', bold: true, margin: [-20, 10] },
              ],
              [
                { text: history.description, margin: [5, 10] },
                { text: '', margin: [5, 10] },
                { text: '', margin: [5, 10] },
                { text: `${history.amount} €`, margin: [-20, 10] },
              ],
              [
                '',
                '',
                { text: 'Montant Total HT', margin: [-200, 10] },
                { text: `${history.amount} €`, margin: [-20, 10] },
              ],
              [
                '',
                '',
                { text: 'Total Net HT', margin: [-200, 10] },
                { text: `${history.amount} €`, margin: [-20, 10] },
              ],
              [
                '',
                '',
                { text: 'Total TVA (20%)', margin: [-200, 10] },
                {
                  text: `${String(Number(history.amount) * 0.2)}`,
                  margin: [-5, 10],
                },
              ],
              [
                '',
                '',
                { text: 'Montant Total TTC ', bold: true, margin: [-200, 10] },
                {
                  text: `${
                    Number(history.amount) + Number(history.amount) * 0.2
                  } €`,
                  margin: [-20, 10],
                },
              ],
            ],
          },
        },
        {
          text: 'La loi n°92/1442 du 31 décembre 1992 nous fait l’obligation de vous indiquer que le non-respect des conditions de paiement entraine des intérêts de retard suivant modalités et taux défini par la loi. Une indemnité forfaitaire de 40€ sera due pour frais de recouvrement en cas de retard de paiement.',
          style: 'small',
        },
        {
          text: 'Mode de règlement : ',
          style: 'small',
          margin: [0, 30, 0, 3],
        },
        {
          text: 'Conditions de règlement :',
          style: 'small',
        },
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
        },
        small: {
          fontSize: 8,
        },
      },
      images: {
        logo: `${window.location.origin}/images/logo.png`,
      },
      defaultStyle: {
        fontSize: 11,
      },
    };
    pdfMake.createPdf(historyDetails).open();
    pdfMake.createPdf(historyDetails).download(history?.id);
  };
  const columns = [
    {
      field: 'created_at',
      headerName: 'Date',
      minWidth: 100,
      renderCell: (params) => {
        return <div>{renderDate(params.row.created_at)}</div>;
      },
    },
    {
      field: 'description',
      headerName: 'Description',
      minWidth: 250,
      flex: 2,
    },
    {
      field: 'amount',
      headerName: 'Montant',
      width: 180,
      flex: 1,
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<IconDownload />}
            label="Envoyer un message"
            onClick={() => {
              downloadHistory(params.row);
              // navigate(`/chats/enterprise/${params.row.userId}/new`);
            }}
          />,
        ];
      },
    },
  ];

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#dc3838' },
      },
    },
    frFR
  );

  const selectedValue = React.useMemo(
    () => Array.from(selected).join(', ').replaceAll('_', ' '),
    [selected]
  );

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.pagination?.total !== undefined
        ? meta?.pagination?.total
        : prevRowCountState
    );
  }, [meta?.pagination?.total, setRowCountState]);

  return (
    <>
      <div className="w-full">
        <div className="mx-auto w-full px-3 md:px-8">
          <div className="py-8">
            <div>
              <h2 className="block text-xl font-medium text-black md:hidden">
                Historique des transactions
              </h2>
              <div className="mx-auto mt-2 max-w-xs items-end justify-end md:flex md:max-w-none md:space-x-4">
                <form
                  className="items-end justify-end md:flex md:space-x-4"
                  onSubmit={handleFilter}
                >
                  {selected.entries().next().value[0].toLowerCase() ===
                    'définir une autre période' && (
                    <Grid>
                      <Input
                        onChange={(e) => setStartDate(e.target.value)}
                        width="186px"
                        label="Du"
                        type="date"
                      />
                    </Grid>
                  )}
                  {selected.entries().next().value[0].toLowerCase() ===
                    'définir une autre période' && (
                    <Grid>
                      <Input
                        onChange={(e) => setEndDate(e.target.value)}
                        width="186px"
                        label="Au"
                        type="date"
                      />
                    </Grid>
                  )}
                  {selected.entries().next().value[0].toLowerCase() ===
                    'définir une autre période' && (
                    <div className="my-4 mr-4 md:my-0">
                      <Button
                        type="submit"
                        css={{
                          border: '1px solid',
                          borderColor: '#DC3838',
                          color: 'white',
                          backgroundColor: '#DC3838',
                        }}
                        auto
                      >
                        Filtrer
                      </Button>
                    </div>
                  )}
                </form>

                <Dropdown placement="bottom-right">
                  <Dropdown.Button
                    flat
                    css={{
                      color: 'white',
                      backgroundColor: '#DC3838',
                    }}
                  >
                    {selectedValue}
                  </Dropdown.Button>
                  <Dropdown.Menu
                    aria-label="Single selection actions"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={selected}
                    onSelectionChange={setSelected}
                  >
                    {filters?.map((city) => (
                      <Dropdown.Item
                        key={city}
                        css={{
                          '&:hover': {
                            fontWeight: 500,
                          },
                          fontSize: '14px',
                        }}
                        className="first-letter:capitalize"
                      >
                        {city}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="mt-8 w-full"
                style={{
                  height: `${108 + 10 * 52}px`,
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    rows={transactions}
                    disableSelectionOnClick
                    columns={columns}
                    getRowId={(row) => row.id}
                    columnBuffer={15}
                    checkboxSelection
                    rowCount={rowCountState}
                    loading={loading}
                    rowsPerPageOptions={[15]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode={mode}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                    onRowClick={(e) => viewTransaction(e.row)}
                  />
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        closeButton
        scroll
        width="600px"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        onClose={closeHandler}
        {...bindings}
      >
        <Modal.Header justify="start">
          <Text h2 b id="modal-title" size={18}>
            Transaction
          </Text>
        </Modal.Header>
        <Modal.Body>
          <div className="flex flex-col">{transaction?.description}</div>
        </Modal.Body>
        <Modal.Footer
          justify="start"
          className="block w-full items-center justify-end"
        >
          <Button
            css={{
              border: '1px solid',
              borderColor: '#DC3838',
              color: 'white',
              backgroundColor: '#DC3838',
            }}
            auto
            onClick={closeHandler}
          >
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TransactionsHistory;
