import { Button, Loading } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { confirm, resend } from '../redux/api/user';
import { loginReset } from '../redux/reducers/userReducer';

const ConfirmAccount = () => {
  const navigator = useNavigate();
  const user = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [message, setMessage] = useState('');
  const [code, setCode] = useState('');

  useEffect(() => {
    return () => {
      dispatch(loginReset());
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();

    dispatch(loginReset());
    setMessage('');
    if (user?.currentUser?.email) {
      setIsSending(true);
      resend(user?.currentUser?.email)
        .then(() => {
          setIsSending(false);
          setMessage(
            'Une nouvelle confirmation a été envoyée à votre adresse email'
          );
        })
        .catch(() => {
          setIsSending(false);
        });
    }
  };

  const handleChange = () => {
    setMessage('');
    if (code) {
      setLoading(true);
      confirm(code)
        .then(() => {
          setLoading(false);
          navigator('/confirmed');
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  const navigate = (route) => {
    navigator(route);
  };

  useEffect(() => {
    handleChange();
  }, [code]);

  useEffect(() => {
    if (
      user.currentUser &&
      user.currentUser?.email_verified_at &&
      !error &&
      !loading
    ) {
      navigate('/dashboard');
      // reload page
      window.location.reload();
    }
  }, [user]);

  useEffect(() => {
    dispatch(loginReset());
    if (location.pathname.split('/')[2]) {
      setCode(location.pathname.split('/')[2]);
    }
  }, []);

  return (
    <div className="h-full bg-white">
      <div className="flex h-full min-h-screen justify-center">
        <div
          className="mx-auto flex w-full flex-col items-start bg-bottom bg-no-repeat md:w-1/2"
          style={{
            backgroundImage: 'url("/images/home-light.svg")',
            backgroundSize: '100% auto',
          }}
        >
          <div className="w-full items-center text-primary md:flex md:pr-4">
            <div className="w-1/3 px-4">
              <img
                className="py-8 md:h-44 md:py-0"
                src="/images/logo-large.svg"
                alt=""
              />
            </div>
          </div>
          <div className="w-full max-w-2xl px-4 md:px-16">
            <div className="text-start">
              <h2 className="mb-8 text-xl font-bold text-black">
                Mail de confirmation
              </h2>
              <div className="space-y-2 text-lg dark:text-gray-300">
                <p className=" ">
                  Veuillez vérifier votre boîte mail et confirmer le mail que
                  nous venons de vous envoyer au {user?.currentUser?.email}
                </p>
              </div>
            </div>
            <div className="mt-8 text-lg">
              <p className="">Vous n&apos;avez pas reçu le lien ?</p>
              <form onSubmit={handleClick}>
                <Button
                  type="submit"
                  onClick={handleClick}
                  css={{
                    color: '#DC3838',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    justifyContent: 'start',
                  }}
                  light
                  auto
                  animated={false}
                >
                  Renvoyer
                </Button>
              </form>
              <p className="text-gray-500">
                {(isSending || loading) && (
                  <Loading
                    style={{
                      marginRight: '1rem',
                    }}
                    color="currentColor"
                    size="sm"
                  />
                )}
              </p>

              <p className="mt-4 text-gray-500">
                {message && (
                  <div className="text-left">
                    <p className="text-primary">{message}</p>
                  </div>
                )}
              </p>
            </div>
          </div>
        </div>
        <div
          className="hidden w-1/2 bg-cover lg:flex"
          style={{
            backgroundImage: 'url("/images/cover.png")',
          }}
        />
      </div>
    </div>
  );
};

export default ConfirmAccount;
