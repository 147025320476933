export const GET_USER = 'GET_USER';
export const USER_ERROR = 'USER_ERROR';
export const REQUEST = 'REQUEST';
export const GET_PRODUCT = 'GET_PRODUCT';
export const INTERNAL = 'INTERNAL';
export const UNAUTHORIZED = 'UNAUTHORIZED';
export const INACTIVE = 'INACTIVE';
export const SIGN_OUT = 'SIGN_OUT';

export const AUDIT_TYPES = [
  'Diag termites',
  'Diag Gaz',
  'Diag Amiante',
  'Diag DPE',
  'Diag Electrique',
  'Diag Plomb',
  'Loi Carez',
  'Audit energétique Résidentiel',
  'Audit energétique Tertiaire',
  "Contrôle d'étanchéité clim / Froid règlementaire",
  'Maintenance conduit de cheminée',
];
