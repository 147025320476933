import axios from 'axios';

import { BASE_URL, publicRequest, userRequest } from '../../api';
import {
  loginFailure,
  loginStart,
  loginSuccess,
} from '../reducers/userReducer';

export const login = (user) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.post('auth/login', user);
    if (res.data.role[0] !== 'client') {
      if (res.data.role[0] === 'entreprise') {
        axios
          .get(`${BASE_URL}entreprise/verifyprofil`, {
            headers: {
              Authorization: `Bearer ${res.data.access_token}`,
            },
          })
          .then((p) => {
            if (p.data.message)
              res.data = { ...res.data, missing_d: p.data.message };
            dispatch(loginSuccess(res.data));
          });
      } else {
        dispatch(loginSuccess(res.data));
      }
    } else {
      dispatch(loginFailure({ error: 'Unauthorized' }));
    }
  } catch (err) {
    dispatch(loginFailure(err.response.data));
  }
};

export const confirmAccount = (token) => async (dispatch) => {
  dispatch(loginStart());
  try {
    const res = await publicRequest.get('auth/confirmregister', token);
    dispatch(loginSuccess(res.data));
  } catch (err) {
    dispatch(loginFailure(err.response.data));
  }
};

export const getAllUsers = async (page) => {
  return userRequest.get(`admin/user/user?page=${page}`);
};

export const register = async (user) => {
  return publicRequest.post('auth/register', user);
};

export const request = async (email) => {
  return publicRequest.post('auth/resetpassword', email);
};

export const reset = async (data) => {
  return publicRequest.put('auth/updatepassword', data);
};

export const confirm = async (code) => {
  return publicRequest.get(`auth/confirmregister/${code}`);
};

export const resend = async (email) => {
  return publicRequest.post(`auth/resendcode`, { email });
};

export const deleteUser = async (id) => {
  return userRequest.delete(`admin/user/user/${id}`);
};

export const updateUser = async (user) => {
  return userRequest.put('inspecteur/updateinspecteur', user);
};

export const updateAdmin = async (user) => {
  return userRequest.put('admin/updateprofil', user);
};

export const get = async () => {
  return userRequest.post('auth/me');
};
