/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-useless-escape */
import { createTheme, ThemeProvider } from '@mui/material';
import { DataGrid, frFR, GridActionsCellItem } from '@mui/x-data-grid';
import {
  Button,
  Dropdown,
  Grid,
  Input,
  Loading,
  Modal,
  Spacer,
  Text,
  useModal,
} from '@nextui-org/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import IconBuilding from '../components/icons/iconBuilding';
import IconHidden from '../components/icons/IconHidden';
import IconMail from '../components/icons/IconMail';
import IconPassword from '../components/icons/IconPassword';
import IconSearch from '../components/icons/IconSearch';
import IconVisible from '../components/icons/IconVisible';
import IconPhone from '../components/icons/PhoneIcon';
import Map from '../components/Map';
import Popover from '../components/Popover';
import {
  addEnterprise,
  getAdminEnterprise,
  getAdminEnterpriseAudits,
  getEnterprisesByType,
} from '../redux/api/enterprise';
import { updateState } from '../redux/reducers/appReducer';
import { auditSuccess } from '../redux/reducers/auditReducer';
import { resetList } from '../redux/reducers/enterpriseReducer';
import { expired, loginReset } from '../redux/reducers/userReducer';

const Enterprises = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const location = useLocation();
  const { visible, setVisible, bindings } = useModal();
  const action = location.pathname.split('/')[2];
  const [selected, setSelected] = useState(new Set(['Filtrer']));
  const [selectedType, setSelectedType] = useState(new Set(['Énergétique']));
  const [filters] = useState(['Tous', 'Par Ville']);
  const [typeFilters] = useState(['Énergétique', 'Immobilier']);
  const [showDeletePopUp, setShowDeletePopUp] = useState(false);
  const [entrepriseToDelete, setEntrepriseToDelete] = useState(0);
  const [actionName, setActionName] = useState('');
  const [focus, setFocus] = useState(false);

  const { isLoading, meta } = useSelector((state) => state.enterprise);
  const list = useSelector((state) => state.enterprise.list);

  const [title, setTitle] = useState([]);
  const [description, setDescription] = useState([]);
  const [enterprises, setEnterprises] = useState([]);
  const [completeList, setCompleteList] = useState([]);
  const [mode, setMode] = useState('server');
  const [inputs, setInputs] = useState({});
  const [helper, setHelper] = useState({
    lastname: {
      text: '',
      color: '',
    },
    firstname: {
      text: '',
      color: '',
    },
    email: {
      text: '',
      color: '',
    },
    phone: {
      text: '',
      color: '',
    },
    city: {
      text: '',
      color: '',
    },
    address: {
      text: '',
      color: '',
    },
    zipcode: {
      text: '',
      color: '',
    },
    password: {
      text: '',
      color: '',
    },
    newpassword: {
      text: '',
      color: '',
    },
    type: {
      text: '',
      color: '',
    },
  });

  const resetHelpers = (e) => {
    if (e.target.name === 'lastname')
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'firstname')
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'email')
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'phone')
      setHelper((prev) => {
        return {
          ...prev,
          phone: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'zipcode')
      setHelper((prev) => {
        return {
          ...prev,
          zipcode: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'address')
      setHelper((prev) => {
        return {
          ...prev,
          address: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'password')
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'newpassword')
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'typenewpassword')
      setHelper((prev) => {
        return {
          ...prev,
          typenewpassword: {
            text: '',
            color: '',
          },
        };
      });
  };

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(15);
  const [rowCountState, setRowCountState] = useState(0);

  const selectedValue = useMemo(
    () => Array.from(selected).join(', ').replaceAll('_', ' '),
    [selected]
  );

  const selectedTypeValue = useMemo(
    () => Array.from(selectedType).join(', ').replaceAll('_', ' '),
    [selectedType]
  );

  function closeNotif() {
    setTimeout(() => {
      setMessage({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 300);
    }, 100);
  }

  const showNotif = (text, type) => {
    setTimeout(() => {
      setNotification({
        text,
        type,
      });
      setTimeout(() => {
        setShowNotification(true);
      }, 100);
    }, 500);

    setTimeout(() => {
      setNotification({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 6000);
    }, 5000);
  };

  useEffect(() => {
    return () => {
      dispatch(resetList());
    };
  }, []);

  const get = () => {
    const filterVal = selectedType.entries().next().value[0];

    dispatch(
      getEnterprisesByType(
        {
          type_entreprise: filterVal,
        },
        page + 1,
        pageSize
      )
    );
  };

  useEffect(() => {
    get();
  }, [page]);

  useEffect(() => {
    if (action === 'add') setVisible(true);
  }, [action]);

  useEffect(() => {
    setRowCountState(meta?.pagination?.total);

    setCompleteList(
      list.map((enterprise) => ({
        id: enterprise.id,
        name: enterprise.nom_entreprise,
        email: enterprise.users?.email,
        city: enterprise.users?.ville_residence,
        inspectorCount: enterprise.nombre_inspecteur,
        measurerCount: enterprise.nombre_mesureur,
        isActive: enterprise.users?.available,
        type: enterprise.type_entreprise,
        telephone: enterprise.users.telephone,
        userId: enterprise.users_id,
      }))
    );
  }, [list]);

  useEffect(() => {
    setEnterprises(completeList);
  }, [completeList]);

  const navigate = (route) => {
    navigator(route);
  };

  const closeHandler = () => {
    setFocus(false);
    setVisible(false);
    navigate('/enterprises');
  };

  useEffect(() => {
    if (!visible && action === 'add') {
      navigate('/enterprises');
    }
  }, [visible]);

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (inputs.lastname)
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.lastname)
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: 'Veuillez renseigner votre nom',
            color: 'error',
          },
        };
      });

    if (inputs.firstname)
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.firstname)
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: 'Veuillez renseigner votre prénom',
            color: 'error',
          },
        };
      });

    if (inputs.phone)
      setHelper((prev) => {
        return {
          ...prev,
          phone: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.phone)
      setHelper((prev) => {
        return {
          ...prev,
          phone: {
            text: 'Veuillez renseigner votre téléphone',
            color: 'error',
          },
        };
      });

    if (inputs.city)
      setHelper((prev) => {
        return {
          ...prev,
          city: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.city)
      setHelper((prev) => {
        return {
          ...prev,
          city: {
            text: 'Veuillez renseigner votre ville',
            color: 'error',
          },
        };
      });

    if (inputs.zipcode)
      setHelper((prev) => {
        return {
          ...prev,
          zipcode: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.zipcode)
      setHelper((prev) => {
        return {
          ...prev,
          zipcode: {
            text: 'Veuillez renseigner votre code postal',
            color: 'error',
          },
        };
      });

    if (inputs?.location)
      setHelper((prev) => {
        return {
          ...prev,
          address: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.location)
      setHelper((prev) => {
        return {
          ...prev,
          address: {
            text: 'Veuillez renseigner votre adresse',
            color: 'error',
          },
        };
      });

    if (inputs.type)
      setHelper((prev) => {
        return {
          ...prev,
          type: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.type)
      setHelper((prev) => {
        return {
          ...prev,
          type: {
            text: "Veuillez renseigner le type d'entreprise",
            color: 'error',
          },
        };
      });

    if (inputs.email)
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.email)
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: 'Email requis',
            color: 'error',
          },
        };
      });

    if (inputs.email && !validateEmail(inputs.email))
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: 'Email non valide',
            color: 'error',
          },
        };
      });

    if (inputs.password)
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.password && inputs.newpassword)
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: 'Nouveau Mot de passe requis',
            color: 'error',
          },
        };
      });

    if (inputs.password && inputs.password.length < 5)
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: 'Le nouveau mot de passe doit contenir au mois 5 caractères',
            color: 'error',
          },
        };
      });

    if (inputs.newpassword || !inputs.password)
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: '',
            color: '',
          },
        };
      });

    if (inputs.password && !inputs.newpassword)
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: 'Confirmer le nouveau mot de passe',
            color: 'error',
          },
        };
      });

    if (
      inputs.password &&
      inputs.newpassword &&
      inputs.password !== inputs.newpassword
    )
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: 'Les mots de passe ne correspondent pas',
            color: 'error',
          },
        };
      });

    if (
      !inputs.lastname ||
      !inputs.firstname ||
      !inputs.phone ||
      !inputs.city ||
      !inputs.zipcode ||
      !inputs.location ||
      !inputs.type ||
      !inputs.email ||
      (!inputs.password && inputs.newpassword) ||
      (inputs.password && !inputs.newpassword) ||
      (inputs.password && inputs.password.length < 5) ||
      (inputs.password &&
        inputs.newpassword &&
        inputs.password !== inputs.newpassword) ||
      !validateEmail(inputs.email)
    ) {
      return;
    }

    setLoading(true);
    setMessage(null);

    const data = {
      email: inputs.email,
      prenom: inputs.firstname,
      nom: inputs.lastname,
      telephone: inputs.phone,
      lat: inputs.location.lat,
      long: inputs.location.lng,
      code_postal: inputs.zipcode,
      ville_residence: inputs.city,
      type_users: 'entreprise',
      password: inputs.password,
      type_entreprise: inputs.type,
    };

    addEnterprise(data)
      .then(() => {
        setLoading(false);
        showNotif('Le compte entreprise a été crée', 'success');

        setTimeout(() => {
          setMode('server');
          setPage(0);
          closeHandler();
        }, 500);
        // smooth scroll to message
        const messageEl = document.getElementById('message');
        messageEl.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      })
      .catch((err) => {
        setLoading(false);
        if (err.response?.data?.message === 'The given data was invalid.')
          showNotif("L'adresse email a été déjà utilisée", 'error');
        else if (err.response.status === 401) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          showNotif('Une erreur est survenue', 'error');
        }
      });
  };

  useEffect(() => {
    const filterVal = selected.entries().next().value[0].toLowerCase();

    if (filterVal === 'tous') {
      setMode('server');
      setPage(0);
    }
  }, [selected]);

  useEffect(() => {
    const filterVal = selectedType.entries().next().value[0].toLowerCase();

    if (filterVal === 'tous') {
      if (page > 0) setPage(0);
      else get();
    } else get();
  }, [selectedType]);

  const search = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filterVal = selected.entries().next().value[0].toLowerCase();

    if (searchTerm === '') {
      setEnterprises(completeList);
    } else {
      setEnterprises(
        completeList.filter((item) => {
          return filterVal.toLowerCase() === 'par ville'
            ? item.name?.toLowerCase().includes(searchTerm) ||
                item.email?.toLowerCase().includes(searchTerm) ||
                item.city?.toLowerCase().includes(searchTerm)
            : item.name?.toLowerCase().includes(searchTerm) ||
                item.email?.toLowerCase().includes(searchTerm);
        })
      );
    }
  };

  const displayPopover = () => {
    setShowDeletePopUp(!showDeletePopUp);
    const position = document.querySelector(
      '.MuiDataGrid-menu.MuiPopperUnstyled-root'
    );
    const popUpContainer = document.getElementById('popup_delete');
    if (position !== null) {
      popUpContainer.style.position = position.style.position;
      popUpContainer.style.inset = position.style.inset;
      popUpContainer.style.margin = position.style.margin;
      popUpContainer.style.transform = position.style.transform;
    }
  };
  const columns = [
    {
      field: 'name',
      headerName: "Nom de l'entreprise",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">{params.row.name}</span>
        );
      },
    },
    {
      field: 'email',
      headerName: 'E-mail',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">{params.row.email}</span>
        );
      },
    },
    {
      field: 'phone',
      headerName: 'Téléphone',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">{params.row.telephone}</span>
        );
      },
    },
    {
      field: 'city',
      headerName: 'Ville',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">{params.row.city}</span>
        );
      },
    },
    {
      field: 'type',
      headerName: 'Type de compte',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">{params.row.type}</span>
        );
      },
    },
    {
      field: 'inspectorCount',
      headerName: 'Nombre d’inspecteurs',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">
            {params.row.inspectorCount}
          </span>
        );
      },
    },
    {
      field: 'inspectorCountOne',
      headerName: 'Nombre d’inspecteurs niveau 1',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">
            {params.row.inspectorCount}
          </span>
        );
      },
    },
    {
      field: 'mesureurCount',
      headerName: 'Nombre de mesureurs',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">
            {params.row.measurerCount}
          </span>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 200,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={undefined}
            label="Ecrire à l’entreprise"
            showInMenu
            onClick={() => {
              getAdminEnterprise(params.row.id).then((res) => {
                dispatch(updateState({ enterprise: res.data.data }));
                navigate(`/chats/enterprise/${params.row.userId}/new`);
              });
            }}
          />,
          <GridActionsCellItem
            icon={undefined}
            label="Voir les inspecteurs"
            showInMenu
            onClick={() => {
              getAdminEnterprise(params.row.id).then((res) => {
                dispatch(updateState({ enterprise: res.data.data }));
                navigate(`/enterprise/${params.row.id}/inspectors`);
              });
            }}
          />,
          <GridActionsCellItem
            icon={undefined}
            label="Voir les mesureurs"
            showInMenu
            onClick={() => {
              getAdminEnterprise(params.row.id).then((res) => {
                dispatch(updateState({ enterprise: res.data.data }));
                navigate(`/enterprise/${params.row.id}/measurers`);
              });
            }}
          />,
          <GridActionsCellItem
            icon={undefined}
            label="Voir les clients"
            showInMenu
            onClick={() => {
              getAdminEnterprise(params.row.id).then((res) => {
                dispatch(updateState({ enterprise: res.data.data }));
                navigate(`/customers/enterprise/${params.row.id}`);
              });
            }}
          />,
          <GridActionsCellItem
            icon={undefined}
            label="Voir les demandes"
            showInMenu
            onClick={() => {
              getAdminEnterpriseAudits(params.row.id).then((res) => {
                dispatch(auditSuccess(res.data.data));
                navigate(`/enterprise/${params.row.id}/audits`);
              });
            }}
          />,
          params.row.isActive ? (
            <GridActionsCellItem
              icon={undefined}
              label="Bloquer cette entreprise"
              showInMenu
              onClick={() => {
                setEntrepriseToDelete(params.row.id);
                setActionName('restrictThisEnterprise');
                setTitle('Bloquer');
                setDescription(
                  "Êtes-vous sûr de vouloir bloquer cette entreprise ? Une fois bloqué, l'entreprise ne pourra plus se connecter à l'application."
                );
                displayPopover();
              }}
            />
          ) : (
            <GridActionsCellItem
              icon={undefined}
              label="Débloquer cette entreprise"
              showInMenu
              onClick={() => {
                setEntrepriseToDelete(params.row.id);
                setActionName('unlockThisEnterprise');
                setTitle('Débloquer');
                setDescription(
                  "Êtes-vous sûr de vouloir débloquer cette entreprise ? Une fois bloqué, l'entreprise pourra de nouveau se connecter à l'application."
                );
                displayPopover();
              }}
            />
          ),
          // <GridActionsCellItem
          //   icon={<IconMessage />}
          //   label="Envoyer un message"
          //   onClick={() => {
          //     navigate(`/chats/enterprise/${params.row.userId}/new`);
          //   }}
          // />,
        ];
      },
    },
  ];

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.pagination?.total !== undefined
        ? meta?.pagination?.total
        : prevRowCountState
    );
  }, [meta?.pagination?.total, setRowCountState]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#dc3838' },
      },
    },
    frFR
  );
  return (
    <>
      <div className="w-full">
        <div className="mx-auto w-full px-3 md:px-8">
          <div className="py-8">
            <div>
              <h2 className="block text-xl font-medium text-black md:hidden">
                Liste des entreprises
              </h2>
              <div className="mt-2 flex items-center justify-end space-x-4">
                <Grid>
                  <Input
                    onChange={search}
                    labelRight={<IconSearch />}
                    placeholder="Rechercher"
                    bordered
                    type="search"
                  />
                </Grid>
                <Dropdown placement="bottom-right">
                  <Dropdown.Button
                    flat
                    css={{
                      tt: 'capitalize',
                      color: 'white',
                      backgroundColor: '#DC3838',
                    }}
                  >
                    {selectedTypeValue}
                  </Dropdown.Button>
                  <Dropdown.Menu
                    aria-label="Single selection actions"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={selectedType.keys()}
                    onSelectionChange={setSelectedType}
                  >
                    {typeFilters?.map((item) => (
                      <Dropdown.Item
                        key={item}
                        css={{
                          '&:hover': {
                            fontWeight: 500,
                          },
                          fontSize: '14px',
                        }}
                        className="first-letter:uppercase"
                      >
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown placement="bottom-right">
                  <Dropdown.Button
                    flat
                    css={{
                      tt: 'capitalize',
                      color: 'white',
                      backgroundColor: '#DC3838',
                    }}
                  >
                    {selectedValue}
                  </Dropdown.Button>
                  <Dropdown.Menu
                    aria-label="Single selection actions"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={selected.keys()}
                    onSelectionChange={setSelected}
                  >
                    {filters?.map((item) => (
                      <Dropdown.Item
                        key={item}
                        css={{
                          '&:hover': {
                            fontWeight: 500,
                          },
                          fontSize: '14px',
                        }}
                        className="first-letter:uppercase"
                      >
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="mt-8 w-full"
                style={{
                  height: `${108 + 10 * 52.05}px`,
                  width: '100%',
                  overflowX: 'auto',
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    rows={enterprises}
                    disableSelectionOnClick
                    columns={columns}
                    getRowId={(row) => row.id}
                    columnBuffer={15}
                    checkboxSelection
                    rowCount={rowCountState}
                    loading={isLoading && !loading}
                    rowsPerPageOptions={[15]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode={mode}
                  />
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="popup_delete">
        {showDeletePopUp && (
          <Popover
            title={title}
            description={description}
            actionName={actionName}
            id={entrepriseToDelete}
            show={showDeletePopUp}
            refresh={get}
            dispatch={dispatch}
            displayPopover={displayPopover}
            displayNotif={showNotif}
          />
        )}
      </div>
      <Modal
        closeButton
        scroll
        width="600px"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        onClose={closeHandler}
        {...bindings}
      >
        <form onSubmit={handleClick}>
          <Modal.Header justify="start">
            <Text h2 b id="modal-title" size={18}>
              Ajouter une entreprise
            </Text>
          </Modal.Header>
          <Modal.Body>
            <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
              <div className="w-full">
                <Input
                  name="lastname"
                  bordered
                  fullWidth="true"
                  label="Nom"
                  className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                  value={inputs.lastname}
                  color={helper.lastname.color}
                  helperColor={helper.lastname.color}
                  helperText={helper.lastname.text}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Input
                  name="firstname"
                  bordered
                  fullWidth="true"
                  label="Prénom(s)"
                  className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                  value={inputs.firstname}
                  color={helper.firstname.color}
                  helperColor={helper.firstname.color}
                  helperText={helper.firstname.text}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
              <div className="w-full">
                <Input
                  contentLeft={<IconMail />}
                  name="email"
                  type="email"
                  bordered
                  fullWidth="true"
                  label="Email"
                  className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                  value={inputs.email}
                  color={helper.email.color}
                  helperColor={helper.email.color}
                  helperText={helper.email.text}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Input
                  contentLeft={<IconPhone />}
                  name="phone"
                  bordered
                  fullWidth="true"
                  label="Téléphone"
                  className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                  value={inputs.phone}
                  color={helper.phone.color}
                  helperColor={helper.phone.color}
                  helperText={helper.phone.text}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
              <div className="w-full">
                <Input
                  contentLeft={<IconBuilding />}
                  name="city"
                  bordered
                  fullWidth="true"
                  label="Ville"
                  className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                  value={inputs.city}
                  color={helper.city.color}
                  helperColor={helper.city.color}
                  helperText={helper.city.text}
                  onChange={handleChange}
                />
              </div>
              <div className="w-full">
                <Input
                  name="zipcode"
                  bordered
                  fullWidth="true"
                  label="Code postal"
                  className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                  value={inputs.zipcode}
                  color={helper.zipcode.color}
                  helperColor={helper.zipcode.color}
                  helperText={helper.zipcode.text}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
              <div className="w-full">
                <div className="group w-full bg-white bg-transparent">
                  <label className="mb-[0.375rem] block pl-1 text-[0.875rem] leading-6 text-black">
                    Type de compte
                  </label>
                  <div
                    className="rounded-[0.75rem]"
                    style={{
                      boxShadow:
                        helper.type.color === 'error' && focus
                          ? '0 0 0 2px #F31260'
                          : '0 0 0 2px #DFE3E6',
                    }}
                    onFocus={() => {
                      setFocus(true);
                    }}
                  >
                    <select
                      className="inline-flex w-full flex-1 appearance-none border-0 bg-white bg-transparent text-[0.875rem] text-black"
                      name="type"
                      onChange={(e) =>
                        handleChange({
                          target: {
                            name: e.target.name,
                            value: e.target.value,
                          },
                        })
                      }
                    >
                      <option
                        value=""
                        className="hover:bg-primary hover:text-white"
                      >
                        Sélectionner
                      </option>
                      <option
                        value="Immobilier"
                        className="hover:bg-primary hover:text-white"
                      >
                        Immobilier
                      </option>
                      <option
                        value="Énergétique"
                        className="hover:bg-primary hover:text-white"
                      >
                        Énergétique
                      </option>
                    </select>
                  </div>
                </div>
                <p
                  className={`mb-4 text-[0.625rem] ${
                    helper.type.color === 'error'
                      ? 'text-red-500'
                      : 'text-initial'
                  }`}
                >
                  {helper.type.text}
                </p>
              </div>
              <div className="w-full">
                <Map
                  input={
                    <Input
                      name="address"
                      bordered
                      fullWidth="true"
                      label="Adresse"
                      placeholder="Rue, numéro"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      color={helper.address.color}
                      helperColor={helper.address.color}
                      helperText={helper.address.text}
                    />
                  }
                  onClose={(place) => {
                    handleChange({
                      target: { name: 'location', value: place },
                    });
                  }}
                />
              </div>
            </div>
            <div className="w-full">
              <Input.Password
                contentLeft={<IconPassword />}
                name="password"
                bordered
                fullWidth="true"
                width=""
                label="Nouveau mot de passe"
                visibleIcon={<IconHidden />}
                hiddenIcon={<IconVisible />}
                color={helper.password.color}
                helperColor={helper.password.color}
                helperText={helper.password.text}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <Input.Password
                contentLeft={<IconPassword />}
                name="newpassword"
                bordered
                fullWidth="true"
                width=""
                label="Confirmer le nouveau votre mot de passe"
                visibleIcon={<IconHidden />}
                hiddenIcon={<IconVisible />}
                color={helper.newpassword.color}
                helperColor={helper.newpassword.color}
                helperText={helper.newpassword.text}
                onChange={handleChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer
            justify="start"
            className="!block w-full justify-between md:!flex"
          >
            <div
              id="message"
              className={`mt-4 w-full flex-1 text-left text-sm md:mb-0 md:mt-2 ${
                message?.type === 'success' ? 'text-green-500' : 'text-red-500'
              } `}
            >
              {message?.text}
            </div>
            <div className="m-auto flex justify-center md:justify-start">
              <Button
                css={{
                  border: '1px solid',
                  borderColor: '#DC3838',
                  color: 'white',
                  backgroundColor: '#DC3838',
                }}
                auto
                onClick={closeHandler}
              >
                Annuler
              </Button>
              <Spacer x={0.5} />
              <Button type="submit" color="success" auto>
                {loading && (
                  <Loading
                    style={{
                      marginRight: '1rem',
                    }}
                    color="currentColor"
                    size="sm"
                  />
                )}
                Ajouter
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {notification?.text && (
        <div
          className={`fixed bottom-4 right-4 z-[99999] flex rounded px-3 py-2.5 text-sm text-white transition-all delay-200 duration-300
        ${
          showNotification
            ? 'translate-y-0 opacity-100'
            : 'translate-y-2 opacity-0'
        } ${notification.type === 'error' ? 'bg-red-600' : 'bg-green-600'}`}
        >
          <span>{notification.text}</span>
          <svg
            onClick={() => {
              closeNotif();
            }}
            className="ml-2 h-5 w-5 cursor-pointer"
            width="24"
            height="24"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default Enterprises;
