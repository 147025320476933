/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { INTERNAL } from '../constants';

const customerSlice = createSlice({
  name: 'customer',
  initialState: {
    list: [],
    total: 0,
    error: null,
  },
  reducers: {
    customerStart: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    customerSuccess: (state, action) => {
      state.isLoading = false;
      if (action.payload.data) state.list = action.payload.data;
      else state.list = action.payload;
    },
    customerFailure: (state) => {
      state.isLoading = false;
      state.error = INTERNAL;
    },
    customerReset: (state) => {
      state.list = [];
      state.isLoading = false;
      state.error = null;
    },
    orderInspector: (state, action) => {
      if (action.payload === 'asc') {
        state.list = state.list.sort(
          (a, b) => new Date(a.create_at) - new Date(b.create_at)
        );
      } else {
        state.list = state.list.sort(
          (a, b) => new Date(b.create_at) - new Date(a.create_at)
        );
      }
    },
    resetList: (state) => {
      state.list = [];
    },
    reset: (state) => {
      state.list = [];
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const {
  customerStart,
  customerSuccess,
  customerReset,
  customerFailure,
  orderInspector,
  resetList,
  reset,
} = customerSlice.actions;
export default customerSlice.reducer;
