import { createTheme, ThemeProvider } from '@mui/material';
import { DataGrid, frFR, GridActionsCellItem } from '@mui/x-data-grid';
import {
  Button,
  Grid,
  Input,
  Loading,
  Modal,
  Spacer,
  Switch,
  Text,
  useModal,
} from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import IconOff from '../components/icons/IconOff';
import IconOn from '../components/icons/IconOn';
import IconSearch from '../components/icons/IconSearch';
import {
  addPricing,
  editPricing,
  getAuditsTypes,
  getEnterpriseAudits,
  getPricings,
} from '../redux/api/audit';
import { get } from '../redux/api/user';
import { expired, failure, loginReset } from '../redux/reducers/userReducer';
import useOutsideClick from '../utils/useOutsideClick';

const Pricing = () => {
  const dispatch = useDispatch();
  const { setVisible, bindings } = useModal();
  const [enterprise, setEnterprise] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [focus, setFocus] = useState(false);
  const [pricings, setPricings] = useState([]);
  const [completeList, setCompleteList] = useState([]);
  const [pricing, setPricing] = useState(null);
  const [types, setTypes] = useState([]);
  const [list, setList] = useState([]);
  const [inputs, setInputs] = useState({
    name: 'isActive',
    value: false,
  });
  const [helper, setHelper] = useState({
    audit: {
      text: '',
      color: '',
    },
    min_amount: {
      text: '',
      color: '',
    },
    max_amount: {
      text: '',
      color: '',
    },
    percent: {
      text: '',
      color: '',
    },
    measurer_percent: {
      text: '',
      color: '',
    },
  });

  const handleClickOutside = () => {
    setOpen(false);
    setFocus(false);
  };

  const ref = useOutsideClick(handleClickOutside);

  const resetHelpers = (e) => {
    if (e.target?.name === 'audit')
      setHelper((prev) => {
        return {
          ...prev,
          audit: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target?.name === 'montant_min')
      setHelper((prev) => {
        return {
          ...prev,
          min_amount: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target?.name === 'montant_max')
      setHelper((prev) => {
        return {
          ...prev,
          max_amount: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target?.name === 'percent')
      setHelper((prev) => {
        return {
          ...prev,
          percent: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target?.name === 'measurer_percent')
      setHelper((prev) => {
        return {
          ...prev,
          measurer_percent: {
            text: '',
            color: '',
          },
        };
      });
  };

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);

  function closeNotif() {
    setTimeout(() => {
      setMessage({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 50000);
    }, 50000);
  }

  function showNotif(text, type) {
    setTimeout(() => {
      setNotification({
        text,
        type,
      });
      setTimeout(() => {
        setShowNotification(true);
      }, 100);
    }, 500);

    setTimeout(() => {
      setNotification({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 6000);
    }, 5000);
  }

  useEffect(() => {
    dispatch(getEnterpriseAudits(1, 10));
  }, [dispatch]);

  useEffect(() => {
    setList(types);
  }, [types]);

  const getAllPricing = () => {
    setIsLoading(true);
    getPricings(1, 10)
      .then((res) => {
        setIsLoading(false);
        setPricings(res.data.data);

        setCompleteList(
          res.data.data.map((p) => ({
            id: p.id,
            name: p.nom_audit,
            min_amount: p.montant_min,
            max_amount: p.montant_max,
            percent: p.percent_inspecteur,
            measurer_percent: p.percent_mesureur,
            isActive: p.is_active === 1,
          }))
        );
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        }
      });
  };

  useEffect(() => {
    get().then(
      (res) => {
        setEnterprise(res.data?.entreprise?.original?.data);
      },
      (err) => {
        if (err.response.status === 401) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          dispatch(failure(err.response.data));
        }
      }
    );

    getAllPricing();
  }, []);

  const getAuditTypes = () => {
    getAuditsTypes()
      .then((res) => {
        setTypes(
          res.data.data.map((p) => ({
            id: p.id,
            name: p.libelle,
          }))
        );
      })
      .catch((err) => {
        if (err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        }
      });
  };

  useEffect(() => {
    setPricings(completeList);
  }, [completeList]);

  const closeHandler = () => {
    setVisible(false);
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  const handleCheckboxChange = (e) => {
    setInputs((prev) => {
      return { ...prev, isActive: e.target.checked };
    });
  };

  useEffect(() => {
    if (pricing) {
      Object.keys(pricing).forEach((key) => {
        if (key === 'name') {
          const p = pricings.find(
            (a) => a.name?.toLowerCase() === pricing[key]?.toLowerCase()
          );

          setInputs((prev) => {
            return { ...prev, audit: p?.name };
          });
        } else if (key !== 'id') {
          setInputs((prev) => {
            return { ...prev, [key]: pricing[key] };
          });
        }
      });
    } else {
      setInputs({
        name: 'isActive',
        value: false,
      });
    }
  }, [pricing]);

  const handleClick = (e) => {
    e.preventDefault();

    if (inputs.audit)
      setHelper((prev) => {
        return {
          ...prev,
          audit: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.audit)
      setHelper((prev) => {
        return {
          ...prev,
          audit: {
            text: 'Veuillez sélectionner un audit',
            color: 'error',
          },
        };
      });

    if (inputs.min_amount)
      setHelper((prev) => {
        return {
          ...prev,
          min_amount: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.min_amount)
      setHelper((prev) => {
        return {
          ...prev,
          min_amount: {
            text: 'Veuillez renseigner le montant minimun',
            color: 'error',
          },
        };
      });
    if (inputs.max_amount)
      setHelper((prev) => {
        return {
          ...prev,
          max_amount: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.max_amount)
      setHelper((prev) => {
        return {
          ...prev,
          max_amount: {
            text: 'Veuillez renseigner le montant maximun',
            color: 'error',
          },
        };
      });

    if (inputs.percent)
      setHelper((prev) => {
        return {
          ...prev,
          percent: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.percent)
      setHelper((prev) => {
        return {
          ...prev,
          percent: {
            text: 'Veuillez renseigner le pourcentage',
            color: 'error',
          },
        };
      });

    if (inputs.measurer_percent)
      setHelper((prev) => {
        return {
          ...prev,
          measurer_percent: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.measurer_percent)
      setHelper((prev) => {
        return {
          ...prev,
          measurer_percent: {
            text: 'Veuillez renseigner le pourcentage',
            color: 'error',
          },
        };
      });

    if (
      !inputs.audit ||
      !inputs.max_amount ||
      !inputs.min_amount ||
      !inputs.percent ||
      !inputs.measurer_percent
    ) {
      return;
    }

    setLoading(true);
    setMessage(null);

    const audit = types.find(
      (a) => a.name?.toLowerCase() === inputs.audit?.toLowerCase()
    );

    if (!pricing) {
      const newPricing = {
        audit_id: audit?.id,
        entreprise_id: enterprise?.id,
        montant_min: inputs.min_amount,
        montant_max: inputs.max_amount,
        percent_inspecteur: inputs.percent,
        percent_mesureur: inputs.measurer_percent,
      };
      addPricing(newPricing)
        .then(() => {
          setLoading(false);
          showNotif('La tarification a été ajoutée', 'success');
          setVisible(false);

          getAllPricing();
          // smooth scroll to message
          const messageEl = document.getElementById('message');
          messageEl.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 401) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            showNotif('Une erreur est survenue', 'error');
          }
        });
    } else {
      const data = {
        id: pricing.id,
        audit_id: pricing?.audit_id,
        montant_min: inputs.min_amount,
        montant_max: inputs.max_amount,
        percent_inspecteur: inputs.percent,
        percent_mesureur: inputs.measurer_percent,
        is_active: inputs.isActive,
      };

      editPricing(data)
        .then(() => {
          setLoading(false);
          showNotif('La tarification a été modifiée', 'success');
          setVisible(false);

          getAllPricing();
          // smooth scroll to message
          const messageEl = document.getElementById('message');
          messageEl.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
          });
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.status === 401) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            showNotif('Une erreur est survenue', 'error');
          }
        });
    }
  };

  const search = (e) => {
    const searchTerm = e.target.value?.toLowerCase();

    if (searchTerm === '') {
      setPricings(completeList);
    } else {
      setPricings(
        completeList.filter((item) => {
          return item.name?.toLowerCase().includes(searchTerm);
        })
      );
    }
  };

  const searchAuditType = (e) => {
    const searchTerm = e.target.value?.toLowerCase();

    if (searchTerm === '') {
      setList(types);
    } else {
      setList(
        types.filter((item) => {
          return item.name?.toLowerCase().includes(searchTerm);
        })
      );
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: "Nom de l'audit",
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">{params.row.name}</span>
        );
      },
    },
    {
      field: 'montant_min',
      headerName: 'Montant minimum',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return <span className="font-semibold">{params.row.min_amount} €</span>;
      },
    },
    {
      field: 'montant_max',
      headerName: 'Montant maximum',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return <span className="font-semibold">{params.row.max_amount} €</span>;
      },
    },
    {
      field: 'percent',
      headerName: 'Pourcentage inspecteur',
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return <span className="font-bold">{params.row.percent} %</span>;
      },
    },
    {
      field: 'measurer_percent',
      headerName: 'Pourcentage mesureur',
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="font-bold">{params.row.measurer_percent} %</span>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={undefined}
            label="Modifier"
            showInMenu
            onClick={() => {
              setPricing(params.row);
              setVisible(true);
            }}
          />,
        ];
      },
    },
  ];

  const selectAuditType = (audit) => {
    handleChange({ target: { name: 'audit', value: audit?.name } });
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#dc3838' },
      },
    },
    frFR
  );

  return (
    <>
      <div className="w-full">
        <div className="mx-auto w-full px-3 md:px-8">
          <div className="py-8">
            <div>
              <div className="mt-2 flex items-center justify-between space-x-4">
                <Button
                  css={{
                    border: '1px solid',
                    borderColor: '#DC3838',
                    color: 'white',
                    backgroundColor: '#DC3838',
                  }}
                  auto
                  onClick={() => {
                    setVisible(true);
                    setPricing(null);
                    resetHelpers({
                      target: {
                        name: 'audit',
                      },
                    });
                    resetHelpers({
                      target: {
                        name: 'min_amount',
                      },
                    });
                    resetHelpers({
                      target: {
                        name: 'max_amount',
                      },
                    });
                    resetHelpers({
                      target: {
                        name: 'percent',
                      },
                    });
                    resetHelpers({
                      target: {
                        name: 'measurer_percent',
                      },
                    });
                    setInputs({
                      name: 'isActive',
                      value: false,
                    });
                    getAuditTypes();
                  }}
                >
                  Ajouter
                </Button>
                <Grid>
                  <Input
                    onChange={search}
                    labelRight={<IconSearch />}
                    placeholder="Rechercher"
                    bordered
                    type="search"
                  />
                </Grid>
              </div>
              <div
                className="mt-8 w-full"
                style={{
                  height: `${108 + 10 * 52.05}px`,
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    rows={pricings}
                    disableSelectionOnClick
                    columns={columns}
                    getRowId={(row) => row.id}
                    columnBuffer={15}
                    checkboxSelection
                    loading={isLoading && !loading}
                    rowsPerPageOptions={[15]}
                    paginationMode="client"
                  />
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        closeButton
        scroll
        width="600px"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        onClose={closeHandler}
        {...bindings}
      >
        <form onSubmit={handleClick}>
          <Modal.Header justify="space-between">
            <Text h2 b id="modal-title" size={18}>
              {pricing ? 'Modifier' : 'Ajouter une tarification'}
            </Text>
            {pricing && (
              <Switch
                checked={pricing?.isActive}
                size="xl"
                color="success"
                className="mr-2"
                onChange={handleCheckboxChange}
                iconOn={<IconOn />}
                iconOff={<IconOff />}
              />
            )}
          </Modal.Header>
          <Modal.Body className="overflow-auto">
            <div ref={ref} className="relative w-full">
              <div
                className={`search my-2 flex overflow-hidden rounded-[0.75rem] bg-white ${
                  pricing && 'bg-gray-300 bg-opacity-50'
                }`}
                style={{
                  boxShadow:
                    helper.audit.color === 'error' && focus
                      ? '0 0 0 2px #F31260'
                      : '0 0 0 2px #DFE3E6',
                }}
                onFocus={() => {
                  setFocus(true);
                }}
              >
                <input
                  name="audit"
                  placeholder="Sélectionner un type d'audit"
                  value={inputs?.audit}
                  className={`w-full appearance-none py-2.5 px-2.5 text-gray-800 outline-none
                ${pricing && 'text-gray-400'}`}
                  disabled={pricing}
                  onChange={(e) => {
                    handleChange(e);
                    setOpen(true);
                    searchAuditType(e);
                  }}
                  onFocus={() => {
                    setOpen(true);
                  }}
                />
                <div className="flex w-14 items-center border-l border-gray-200 py-1 pl-3 pr-1 text-gray-300">
                  <button
                    onClick={() => setOpen(!open)}
                    type="button"
                    className="h-6 w-6 cursor-pointer text-gray-600 outline-none focus:outline-none"
                  >
                    <svg
                      width="24"
                      height="24"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 9L12 15L18 9"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </button>
                </div>
              </div>
              {open && !pricing && (
                <div className="max-h-select absolute top-14 left-0 z-40 w-full overflow-y-auto rounded bg-white shadow">
                  {list.map((audit_type) => (
                    <button
                      type="button"
                      className="group flex w-full cursor-pointer flex-col"
                      onClick={() => {
                        selectAuditType(audit_type);
                        setOpen(false);
                      }}
                    >
                      <div className="relative flex w-full items-center border-x-2 border-transparent p-2 pl-2 hover:border-red-100 hover:bg-primary hover:text-white">
                        <div className="flex w-full items-center">
                          <div className="mx-2 -mt-1 flex-1 text-left">
                            <span>{audit_type?.name}</span>
                          </div>
                          {inputs.audit?.toLowerCase() ===
                            audit_type.name?.toLowerCase() && (
                            <div className="pr-1.5">
                              <svg
                                width="24"
                                height="24"
                                strokeWidth="1.5"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                                className={`group-hover:text-white
                                          ${
                                            inputs.audit?.toLowerCase() ===
                                            audit_type.name?.toLowerCase()
                                              ? 'text-primary'
                                              : 'text-white'
                                          }
                                        `}
                              >
                                <path
                                  d="M5 13L9 17L19 7"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                    </button>
                  ))}
                </div>
              )}
              {helper.audit.color === 'error' && (
                <p className="-mt-[0.25rem] ml-[0.75rem] text-[0.625rem] text-[#F31260]">
                  {helper.audit.text}
                </p>
              )}
            </div>
            <div className="w-full">
              <Input
                name="min_amount"
                bordered
                fullWidth="true"
                label="Montant minimun"
                className="my-2 ring-0 focus:border-none focus:outline-none focus:ring-0"
                value={inputs.min_amount}
                color={helper.min_amount.color}
                helperColor={helper.min_amount.color}
                helperText={helper.min_amount.text}
                onChange={handleChange}
              />
              <Input
                name="max_amount"
                bordered
                fullWidth="true"
                label="Montant maximun"
                className="my-2 ring-0 focus:border-none focus:outline-none focus:ring-0"
                value={inputs.max_amount}
                color={helper.max_amount.color}
                helperColor={helper.max_amount.color}
                helperText={helper.max_amount.text}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <Input
                name="percent"
                bordered
                fullWidth="true"
                label="Pourcentage Inspecteur"
                className="my-2 ring-0 focus:border-none focus:outline-none focus:ring-0"
                value={inputs.percent}
                color={helper.percent.color}
                helperColor={helper.percent.color}
                helperText={helper.percent.text}
                onChange={handleChange}
              />
            </div>
            <div className="w-full">
              <Input
                name="measurer_percent"
                bordered
                fullWidth="true"
                label="Pourcentage Mesureur"
                className="my-2 ring-0 focus:border-none focus:outline-none focus:ring-0"
                value={inputs.measurer_percent}
                color={helper.measurer_percent.color}
                helperColor={helper.measurer_percent.color}
                helperText={helper.measurer_percent.text}
                onChange={handleChange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer
            justify="start"
            className="!block w-full justify-between md:!flex"
          >
            <div
              id="message"
              className={`mt-4 w-full flex-1 text-left text-sm md:mb-0 md:mt-2 ${
                message?.type === 'success' ? 'text-green-500' : 'text-red-500'
              } `}
            >
              {message?.text}
            </div>
            <div className="m-auto flex justify-center md:justify-start">
              <Button
                css={{
                  border: '1px solid',
                  borderColor: '#DC3838',
                  color: 'white',
                  backgroundColor: '#DC3838',
                }}
                auto
                onClick={closeHandler}
              >
                Annuler
              </Button>
              <Spacer x={0.5} />
              <Button type="submit" color="success" auto>
                {loading && (
                  <Loading
                    style={{
                      marginRight: '1rem',
                    }}
                    color="currentColor"
                    size="sm"
                  />
                )}
                {pricing ? 'Modifier' : 'Ajouter'}
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {notification?.text && (
        <div
          className={`fixed bottom-4 right-4 z-[99999] flex rounded px-3 py-2.5 text-sm text-white transition-all delay-200 duration-300
        ${
          showNotification
            ? 'translate-y-0 opacity-100'
            : 'translate-y-2 opacity-0'
        } ${notification.type === 'error' ? 'bg-red-600' : 'bg-green-600'}`}
        >
          <span>{notification.text}</span>
          <svg
            onClick={() => {
              closeNotif();
            }}
            className="ml-2 h-5 w-5 cursor-pointer"
            width="24"
            height="24"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default Pricing;
