import { Button } from '@nextui-org/react';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Page404 = () => {
  const navigator = useNavigate();
  const user = useSelector((state) => state.user);

  const navigate = () => {
    if (user?.currentUser) {
      navigator('/dashboard');
    } else {
      navigator('/');
    }
  };

  return (
    <div className="py-16 md:py-64">
      <div className="flex flex-col items-center">
        <div className="text-7xl font-bold text-primary">404</div>

        <div className="mt-10 text-3xl font-bold md:text-xl">
          Page non trouvée
        </div>

        <div className="mt-8 text-sm font-medium text-gray-400 md:text-xl lg:text-2xl">
          <Button
            css={{
              borderRadius: '0px', // radii.xs
              border: '1px solid',
              borderColor: '#DC3838',
              color: 'white',
              backgroundColor: '#DC3838',
            }}
            size="xl"
            onClick={navigate}
          >
            {user?.currentUser
              ? "Revenir sur l'application"
              : 'Revenir sur le site'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Page404;
