import { Text } from '@nextui-org/react';
import React from 'react';

const Privacy = () => {
  return (
    <div className="prose m-auto my-16 max-w-7xl">
      <Text className="prose-headings:text-lg" h1>
        Politique de confidentialité
      </Text>

      <Text className="prose-body:text-lg" p>
        La politique de confidentialité ici.
      </Text>
    </div>
  );
};

export default Privacy;
