import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import AccountCrediting from './pages/AccountCrediting';
import Agenda from './pages/Agenda';
import Audit from './pages/Audit';
import Audits from './pages/Audits';
import Chat from './pages/Chat';
import Conditions from './pages/Conditions';
import ConfirmAccount from './pages/ConfirmAccount';
import ConfirmedAccount from './pages/ConfirmedAccount';
import Customers from './pages/Customers';
import Dashboard from './pages/Dashboard';
import Enterprises from './pages/Enterprises';
import ForgotPassword from './pages/ForgotPassword';
import Index from './pages/Index';
import Inspectors from './pages/Inspectors';
import Login from './pages/Login';
import Measurers from './pages/Measurers';
import NoMatch from './pages/NoMatch';
import Paiements from './pages/Paiements';
import Pricing from './pages/Pricing';
import Privacy from './pages/Privacy';
import Report from './pages/Report';
import ResetPassword from './pages/ResetPassword';
import Settings from './pages/Settings';
import SignUp from './pages/SignUp';
import SignUpChoice from './pages/SignUpChoice';
import Statistiques from './pages/Statistiques';
import TransactionsHistory from './pages/TransactionsHistory';

const App = () => {
  return (
    <>
      <BrowserRouter>
        <Routes onUpdate={() => window.scrollTo(0, 0)}>
          <Route path="/login" element={<Login />} />
          <Route path="/choose-profile" element={<SignUpChoice />} />
          <Route path="/register" element={<SignUp />} />
          <Route path="/confirm" element={<ConfirmAccount />} />
          <Route path="/confirm/:code" element={<ConfirmAccount />} />
          <Route path="/confirmed" element={<ConfirmedAccount />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password/:token" element={<ResetPassword />} />

          <Route path="/" element={<Index />}>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/customers" element={<Customers />} />
            <Route path="/customers/enterprise/:id" element={<Customers />} />
            <Route path="/customers/inspector/:id" element={<Customers />} />
            <Route path="/enterprises" element={<Enterprises />} />
            <Route path="/enterprise/:id/inspectors" element={<Inspectors />} />
            <Route
              path="/enterprise/:id/inspector/:userId/audits"
              element={<Audits />}
            />
            <Route path="/enterprise/:id/measurers" element={<Measurers />} />
            <Route
              path="/enterprise/:id/measurer/:userId/audits"
              element={<Audits />}
            />
            <Route path="/audit/:id" element={<Audit />} />
            <Route path="/inspector/audit/:id" element={<Audit />} />
            <Route path="/measurer/audit/:id" element={<Audit />} />
            <Route path="/audits" element={<Audits />} />
            <Route path="enterprise/:id/audits/" element={<Audits />} />
            <Route path="/audit/:id/edit" element={<Report />} />
            <Route path="/inspector/:id/audits" element={<Audits />} />
            <Route path="/measurer/:id/audits" element={<Audits />} />
            <Route path="/enterprises/add" element={<Enterprises />} />
            <Route path="/inspectors" element={<Inspectors />} />
            <Route path="/inspectors/add" element={<Inspectors />} />
            <Route path="/measurers" element={<Measurers />} />
            <Route path="/payments" element={<Paiements />} />
            <Route path="/chats/:type/:id/new" element={<Chat />} />
            <Route path="/chats" element={<Chat />} />
            <Route path="/account/crediting" element={<AccountCrediting />} />
            <Route path="/meeting" element={<Agenda />} />
            <Route
              path="/history/transactions"
              element={<TransactionsHistory />}
            />
            <Route path="/statistiques" element={<Statistiques />} />
            <Route path="/pricing" element={<Pricing />} />
          </Route>

          <Route path="/privacy" element={<Privacy />} />
          <Route path="/conditions" element={<Conditions />} />
          <Route path="*" element={<NoMatch />} />
        </Routes>
      </BrowserRouter>
      {/* <MenuTabs /> */}
    </>
  );
};

export default App;
