/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const appSlice = createSlice({
  name: 'app',
  initialState: {
    isMenuOpen: false,
    isSidebarOpen: false,
    stats: {},
    currentState: {},
    loading: false,
  },
  reducers: {
    openMenu: (state, action) => {
      state.isMenuOpen = action.payload;
    },
    openSidebar: (state, action) => {
      state.isSidebarOpen = action.payload;
    },
    appStart: (state) => {
      state.loading = true;
    },
    appSuccess: (state, action) => {
      state.loading = false;
      let validateAudit;
      if (action.payload.auditvalidee !== undefined) {
        validateAudit = action.payload?.auditvalidee;
      } else {
        validateAudit = action.payload?.auditvalide;
      }
      state.stats = {
        enterprises: action.payload?.entreprise,
        inspectors: action.payload?.inspecteur,
        measurer: action.payload?.mesureur,
        customers: action.payload?.client,
        cities: action.payload?.ville,
        achievedAudits: action.payload?.auditrealisee,
        pendingAudits: action.payload?.auditenattente,
        inProgressAudits: action.payload?.auditencours,
        annulledAudits: action.payload?.auditannulee,
        affectedAudits: action.payload?.auditaffectee,
        rejectedAudits: action.payload?.auditrejete,
        validatedAudits: validateAudit,
        submittedAudits: action.payload?.auditsoumise,
        processingAudits: action.payload?.auditencours,
        processingValidationAudits: action.payload?.auditencoursvalidation,
        processingAchievementAudits: action.payload?.auditencoursrealisation,
        terminatedAudits: action.payload?.audittermine,
      };
    },
    appFailure: (state) => {
      state.loading = false;
    },
    updateState: (state, action) => {
      state.currentState = action.payload;
    },
    resetState: (state) => {
      state.currentState = {};
    },
  },
});

export const {
  openMenu,
  openSidebar,
  appStart,
  appSuccess,
  appFailure,
  updateState,
  resetState,
} = appSlice.actions;
export default appSlice.reducer;
