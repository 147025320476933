/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-return */
import { DataGrid, frFR } from '@mui/x-data-grid';

import { Button, Dropdown, Grid, Input } from '@nextui-org/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import IconSearch from '../components/icons/IconSearch';
import {
  getAdminCommission,
  getHistory,
  getHistoryMeasurer,
  getPeriodicHistory,
  getPeriodicMeasurer,
} from '../redux/api/inspector';
import { expired, loginReset } from '../redux/reducers/userReducer';
import { renderDate } from '../utils/common';

const filters = [
  'Tous',
  'Il y a une semaine',
  'Il y a deux semaines',
  'Il y a un mois',
  'Il y a six mois',
  'Définir une autre période',
];

const Paiements = () => {
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector((state) => state.audit);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [completeList, setCompleteList] = useState([]);
  const [totalCommission, setTotalCommission] = useState(0);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const { isMesurer, isAdmin } = useSelector((state) => state.user);

  const [selected, setSelected] = useState(new Set(['Filtrer par période']));
  const selectedValue = useMemo(
    () => Array.from(selected).join(', ').replaceAll('_', ' '),
    [selected]
  );

  const [mode, setMode] = useState('server');
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(15);
  const [rowCountState, setRowCountState] = useState(0);

  const onSuccess = (res) => {
    const data = res?.data;
    setTotalCommission(data?.total_commission);

    setLoading(false);
    const historyPayments = data.data.map((payment) => ({
      id:
        payment?.id ||
        payment?.demande_audit?.original?.data?.audit_id ||
        payment?.demande_audit?.audit_id,
      name:
        payment?.action ||
        payment?.demande_audit?.original?.data?.name_audit ||
        payment?.demande_audit?.name_audit,
      date:
        renderDate(payment?.created_at) ||
        renderDate(payment?.demande_audit?.original?.data?.created_at) ||
        renderDate(payment?.demande_audit?.created_at),
      montant: isAdmin ? `${payment.commission} €` : `${payment?.montant} €`,
      montant_min: `${payment?.montant_min} €`,
      montant_max: `${payment?.montant_max} €`,
      prix_propose: `${payment?.montant} €`,
      numero_demand:
        payment?.demande_audit?.original?.data?.num_dmd ||
        payment?.demande_audit?.num_dmd,
      // status: 'en attente',
    }));
    setCompleteList(historyPayments);
    setList(historyPayments);
  };

  const onError = (err) => {
    setLoading(false);
    if (err.response?.status === 401 || err.response?.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    }
  };

  const getCustomPeriodicPayments = () => {
    setLoading(true);

    if (isMesurer) {
      getPeriodicMeasurer(
        {
          param: 'custom',
          debut: startDate,
          fin: endDate,
        },
        page + 1,
        pageSize
      )
        .then((res) => {
          setLoading(false);
          setPage(0);
          onSuccess(res);
          setRowCountState(res.data?.data?.length);
          setMode('client');
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          }
          setLoading(false);
        });
    } else if (isAdmin) {
      return;
    } else {
      getPeriodicHistory(
        {
          param: 'custom',
          debut: startDate,
          fin: endDate,
        },
        page + 1,
        pageSize
      )
        .then((res) => {
          setLoading(false);
          setPage(0);
          onSuccess(res);
          setRowCountState(res.data?.data?.length);
          setMode('client');
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          }
          setLoading(false);
        });
    }
  };

  const handleFilter = (e) => {
    e.preventDefault();

    if (startDate && endDate) getCustomPeriodicPayments();
  };

  const getPayments = () => {
    setLoading(true);
    if (isMesurer) {
      getHistoryMeasurer(page + 1, pageSize)
        .then((res) => {
          onSuccess(res);
        })
        .catch((err) => {
          onError(err);
        });
    } else if (isAdmin) {
      getAdminCommission(page + 1, pageSize)
        .then((res) => {
          onSuccess(res);
        })
        .catch((err) => {
          onError(err);
        });
    } else {
      getHistory(page + 1, pageSize)
        .then((res) => {
          onSuccess(res);
        })
        .catch((err) => {
          onError(err);
        });
    }
  };

  useEffect(() => {
    getPayments();
  }, [page]);

  const columns = [
    {
      field: 'date',
      headerName: 'Date',
      minWidth: 250,
    },
    {
      field: 'numero_demand',
      hide: !isAdmin,
      headerName: 'Numero de la demande',
      minWidth: 250,
      flex: 2,
      renderCell: (params) => {
        return (
          <div className="flex items-center space-x-2">
            <span> {params.row?.numero_demand}</span>
          </div>
        );
      },
    },
    {
      field: 'name',
      headerName: 'Nom de l’audit',
      minWidth: 250,
      flex: 2,
      renderCell: (params) => {
        return (
          <div className="flex items-center space-x-2">
            <span> {params.row?.name}</span>
          </div>
        );
      },
    },
    {
      field: 'prix_propose',
      headerName: 'Prix négocié',
      minWidth: 250,
    },
    {
      field: 'montant_min',
      hide: !isAdmin,
      headerName: 'Montant minimun',
      minWidth: 250,
    },
    {
      field: 'montant_max',
      hide: !isAdmin,
      headerName: 'Montant maximun',
      minWidth: 250,
    },
    {
      field: 'montant',
      headerName: isAdmin ? 'Commission' : 'Montant',
      minWidth: 250,
    },
    // {
    //   field: 'payer',
    //   headerName: 'Nom du débiteur',
    //   width: 250,
    //   flex: 2,
    // },
    // {
    //   field: 'accountNumber',
    //   headerName: 'Numéro de compte',
    //   width: 220,
    // },
    // {
    //   field: 'status',
    //   headerName: 'Etat',
    //   width: 210,
    //   renderCell: (params) => {
    //     return (
    //       <div className="flex w-10/12 items-center justify-between">
    //         <span
    //           className={`p-1 px-1.5 text-sm text-white first-letter:uppercase
    //         ${
    //           params.row?.status?.toLowerCase() === 'en attente' && 'bg-red-600'
    //         }
    //         ${
    //           params.row?.status?.toLowerCase() === 'finished' && 'bg-green-600'
    //         }
    //         `}
    //         >
    //           {' '}
    //           {params.row?.status}
    //         </span>
    //         <button type="button" className="ml-4">
    //           <span
    //             className={`flex items-center rounded-sm bg-primary bg-opacity-[35%] p-1 text-sm text-red-600
    //           ${params.row?.status?.toLowerCase() !== 'finished' && 'hidden'}
    //       `}
    //           >
    //             <svg
    //               width="24"
    //               height="24"
    //               strokeWidth="1.5"
    //               viewBox="0 0 24 24"
    //               fill="none"
    //               xmlns="http://www.w3.org/2000/svg"
    //             >
    //               <path
    //                 d="M6 20L18 20"
    //                 stroke="currentColor"
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //               />
    //               <path
    //                 d="M12 4V16M12 16L15.5 12.5M12 16L8.5 12.5"
    //                 stroke="currentColor"
    //                 strokeLinecap="round"
    //                 strokeLinejoin="round"
    //               />
    //             </svg>
    //             Télécharger
    //           </span>
    //         </button>
    //       </div>
    //     );
    //   },
    // },
  ];

  const getPeriodicPayments = (period) => {
    setLoading(true);
    if (isMesurer) {
      getPeriodicMeasurer(
        {
          param: period,
        },
        page + 1,
        pageSize
      )
        .then((res) => {
          onSuccess(res);
        })
        .catch((err) => {
          onError(err);
        });
    } else if (isAdmin) {
      return;
    } else {
      getPeriodicHistory(
        {
          param: period,
        },
        page + 1,
        pageSize
      )
        .then((res) => {
          onSuccess(res);
        })
        .catch((err) => {
          onError(err);
        });
    }
  };

  useEffect(() => {
    const filterVal = selected.entries().next().value[0].toLowerCase();

    if (filterVal !== 'filtrer par période') {
      switch (filterVal) {
        case 'tous':
          setMode('server');
          if (page === 0) getPayments();
          else setPage(0);
          break;
        case 'il y a une semaine':
          getPeriodicPayments('1week');
          break;
        case 'il y a deux semaines':
          getPeriodicPayments('2weeks');
          break;
        case 'il y a un mois':
          getPeriodicPayments('1month');
          break;
        case 'il y a six mois':
          getPeriodicPayments('6month');
          break;
        default:
          setStartDate(null);
          setEndDate(null);
          break;
      }
    }
  }, [selected]);

  const search = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm === '') {
      setList(completeList);
    } else {
      setList(
        completeList.filter((item) => {
          return (
            item.name?.toLowerCase().includes(searchTerm) ||
            item.date?.toLowerCase().includes(searchTerm) ||
            item.montant?.toLowerCase().includes(searchTerm)
          );
        })
      );
    }
  };

  return (
    <>
      {isLoading && !loading && (
        <>
          <div className="fixed inset-0 z-[99] h-full min-h-full overflow-hidden bg-black bg-opacity-10" />
          <div className="absolute top-[50%] left-[48%] z-[991] h-[100px] w-[100px] animate-spin rounded-full border-[10px] border-t-[10px] border-secondary border-t-primary" />
        </>
      )}
      <div className="w-full">
        <div className="mx-auto w-full px-8">
          <div className="py-8">
            <h2 className="text-xl font-medium text-black">
              Liste de paiements des audits
            </h2>
            {isAdmin && <span>{`Solde: ${totalCommission} €`}</span>}
            <div className="mx-auto mt-2 max-w-xs items-end justify-end sm:flex sm:max-w-full sm:space-x-4">
              <div className="mb-5 xl:mb-0">
                <Input
                  css={{ width: '100%' }}
                  onChange={search}
                  labelRight={<IconSearch />}
                  placeholder="Rechercher"
                  bordered
                  type="search"
                />
              </div>
              <form
                className="items-end justify-end md:flex md:space-x-4"
                onSubmit={handleFilter}
              >
                {selected.entries().next().value[0].toLowerCase() ===
                  'définir une autre période' && (
                  <Grid>
                    <Input
                      onChange={(e) => setStartDate(e.target.value)}
                      width="186px"
                      label="Du"
                      type="date"
                    />
                  </Grid>
                )}
                {selected.entries().next().value[0].toLowerCase() ===
                  'définir une autre période' && (
                  <Grid>
                    <Input
                      onChange={(e) => setEndDate(e.target.value)}
                      width="186px"
                      label="Au"
                      type="date"
                    />
                  </Grid>
                )}
                {selected.entries().next().value[0].toLowerCase() ===
                  'définir une autre période' && (
                  <div className="mr-4">
                    <Button
                      type="submit"
                      css={{
                        border: '1px solid',
                        borderColor: '#DC3838',
                        color: 'white',
                        backgroundColor: '#DC3838',
                      }}
                      auto
                    >
                      Filtrer
                    </Button>
                  </div>
                )}
              </form>
              <div className="mb-5 xl:mb-0">
                <Dropdown placement="bottom-right">
                  <Dropdown.Button
                    flat
                    css={{
                      color: 'white',
                      backgroundColor: '#DC3838',
                      width: '100%',
                    }}
                  >
                    {selectedValue}
                  </Dropdown.Button>
                  <Dropdown.Menu
                    aria-label="Single selection actions"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={selected.keys()}
                    onSelectionChange={setSelected}
                  >
                    {filters?.map((p) => (
                      <Dropdown.Item
                        key={p}
                        css={{
                          '&:hover': {
                            fontWeight: 500,
                          },
                          fontSize: '14px',
                        }}
                        className="first-letter:capitalize"
                      >
                        {p}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div
              className="mt-8 w-full"
              style={{
                height: `${108 + 8 * 52.05}px`,
              }}
            >
              <DataGrid
                localeText={frFR.props.MuiDataGrid.localeText}
                rows={list}
                disableSelectionOnClick
                columns={columns}
                getRowId={(row) => row.id}
                columnBuffer={15}
                checkboxSelection
                mode={mode}
                rowsPerPageOptions={[15]}
                pagination
                page={page}
                pageSize={pageSize}
                paginationMode={mode}
                onPageChange={(newPage) => setPage(newPage)}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Paiements;
