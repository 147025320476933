/* eslint-disable react/react-in-jsx-scope */
import { User } from '@nextui-org/react';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { dispatchAside } from '../redux/api/app';
import { UNAUTHORIZED } from '../redux/constants';
import { loginReset } from '../redux/reducers/userReducer';

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const app = useSelector((state) => state.app);
  const { isSidebarOpen } = useSelector((state) => state.app);
  const { isAdmin, isEnterprise, isMesurer, currentUser, error } = useSelector(
    (state) => state.user
  );
  const [title, setTitle] = useState('');
  const [avatar, setAvatar] = useState('');
  const [profile, setProfile] = useState('');

  const closeSidebar = () => {
    dispatch(dispatchAside(!isSidebarOpen));
  };

  const login = () => {
    if (
      !currentUser?.access_token ||
      String(error).toLowerCase() === String(UNAUTHORIZED).toLowerCase()
    ) {
      dispatch(loginReset());
      setTimeout(() => {
        window.location.href = '/login';
      }, 50);
    }
  };

  useEffect(() => {
    login();
  }, [dispatch, currentUser, error, UNAUTHORIZED]);

  const renderTitle = () => {
    switch (path) {
      case '/':
        setTitle('Accueil');
        break;
      case '/enterprises':
        setTitle('Liste des entreprises');
        break;

      case '/enterprises/add':
        setTitle('Ajouter une entreprise');
        break;

      case '/reports':
        if (!isEnterprise && !isAdmin) {
          setTitle('Mes rapports');
          break;
        }
        setTitle('Liste des rapports');
        break;

      case '/inspectors':
        setTitle('Liste des inspecteurs');
        break;

      case '/measurers':
        setTitle('Liste des mesureurs');
        break;

      case '/inspectors/add':
        setTitle('Ajouter un inspecteur');
        break;

      case '/history/transactions':
        setTitle('Historique des transactions');
        break;

      case '/customers':
        setTitle('Liste des clients');
        break;

      case '/statistiques':
        setTitle('Statistiques');
        break;

      case '/audits':
        if (!isEnterprise && !isAdmin) {
          setTitle('Mes audits');
          break;
        }
        setTitle('Liste des audits');
        break;

      case '/client/audit':
        setTitle('Nouveau Rapport');
        break;

      case '/account/crediting':
        setTitle('Paiements Audits');
        break;

      case '/meeting':
        setTitle('Agenda');
        break;

      case '/payments':
        setTitle('Mes Paiements');
        break;

      case '/pricing':
        setTitle('Tarifications');
        break;

      case '/chats':
        setTitle('Messagerie');
        break;

      case '/settings':
        setTitle('Paramètres');
        break;

      default:
        if (path.startsWith('/chats/')) {
          setTitle(`Messagerie
            ${app.currentState?.enterprise?.nom_entreprise || ''}`);
          break;
        }
        if (path.startsWith('/client/audit')) {
          setTitle("Demande d'audit");
          break;
        }
        if (path.startsWith('/inspector/report')) {
          setTitle('Rapport');
          break;
        }
        if (path.startsWith('/enterprise/') && path.endsWith('/inspectors')) {
          setTitle(`Liste des inspecteurs de l'entreprise
            ${app.currentState?.enterprise?.nom_entreprise || ''}`);
          break;
        }
        if (
          path.startsWith('/enterprise/') ||
          (path.startsWith('/inspector') && path.endsWith('/audits')) ||
          (path.startsWith('/measurer') && path.endsWith('/audits'))
        ) {
          const currentUsr = path?.split('/')[1];
          const id = path?.split('/')[2];
          const userId = path?.split('/')[4];
          if (
            (id && userId && id !== 'audit') ||
            (id && currentUsr === 'inspector')
          ) {
            setTitle(`Liste des audits de l'inspecteur`);
          } else if (
            (id && userId && id !== 'audit') ||
            (id && currentUsr === 'measurer')
          ) {
            setTitle(`Liste des audits du mesureur`);
          }
          break;
        }
        if (path.startsWith('/audit/')) setTitle('');
        setTitle('Tableau de bord');
        break;
    }
  };

  const renderProfile = () => {
    if (isAdmin) {
      setProfile('Espace Admin');
    } else if (isEnterprise) {
      setProfile(
        app?.currentState?.enterprise?.nom_entreprise || 'Espace Entreprise'
      );
    } else if (isMesurer) {
      setProfile('Espace Mesureur');
    } else {
      setProfile('Espace Inspecteur');
    }
  };

  const renderAvatar = () => {
    if (isAdmin) {
      setAvatar('Admin'.substring(0, 1));
    }
    if (isEnterprise) {
      setAvatar(
        (
          app?.currentState?.enterprise?.nom_entreprise || 'Entreprise'
        ).substring(0, 1)
      );
    } else if (isMesurer) {
      setAvatar((app?.currentState?.mesurer?.nom || 'Mesurer').substring(0, 1));
    } else {
      setAvatar(
        (app?.currentState?.inspector?.nom || 'Inspecteur').substring(0, 1)
      );
    }
  };

  useEffect(() => {
    dispatch(dispatchAside(false));
    login();
  }, []);

  useEffect(() => {
    renderProfile();
    renderAvatar();
    renderTitle();
  }, [path]);

  return (
    <header className="shadow-bottom bg-white py-4">
      <div className="flex h-full w-full items-center justify-between px-6 text-primary">
        <button
          type="button"
          className="focus:shadow-outline-green mr-5 -ml-1 rounded-md p-1 focus:outline-none lg:hidden"
          aria-label="Menu"
          onClick={closeSidebar}
        >
          <svg
            width="24"
            height="24"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 5H21"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 12H21"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M3 19H21"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <div className="flex w-full items-center justify-between py-4">
          <div className="mr-auto hidden md:block">
            <h2 className="max-w-xl text-3xl text-red-600">{title}</h2>
          </div>
          <div className="ml-auto flex w-auto items-center justify-between">
            <h2 className="hidden text-lg text-gray-600 md:block">{profile}</h2>
            <Link to="/settings">
              <User
                text={avatar}
                className="avatar"
                css={{
                  fontSize: '1.5rem !important',
                }}
              />
            </Link>

            {/* <img className="w-6" src="../images/notification.svg" alt="" /> */}
          </div>
        </div>
      </div>
    </header>
  );
};

export default Navbar;
