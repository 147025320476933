import { useMapboxAutofill, useSearchSession } from '@mapbox/search-js-react';
import { Loading } from '@nextui-org/react';
import PropTypes from 'prop-types';
import React, { cloneElement, useEffect, useRef, useState } from 'react';

import { reverseLocation } from '../redux/api/app';
import useOutsideClick from '../utils/useOutsideClick';

const Map = ({ input, onOpen, onClose }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [parsed, setParsed] = useState({});
  const autofill = useMapboxAutofill({
    accessToken: process.env.REACT_APP_MAPBOX_MAPS_API_KEY,
    language: 'fr',
    country: 'FR',
  });
  const sessionToken = useSearchSession();
  const [error, setError] = useState(null);
  const handleClickOutside = () => {
    setOpen(false);
  };

  const refInput = useRef();

  const ref = useOutsideClick(handleClickOutside);

  useEffect(() => {
    if (onOpen && !loaded) {
      const location = onOpen;
      reverseLocation(location?.lng, location?.lat).then((response) => {
        refInput.current.value = response.data.features[0].place_name;
        setLoaded(true);
      });
    }
  }, [onOpen]);

  const getPlacePredictions = (value) => {
    autofill
      .suggest(value, {
        sessionToken,
      })
      .then((response) => {
        setLoading(false);
        setSuggestions(response.suggestions);
      })
      .catch(() => {
        setLoading(false);
        setError({
          message:
            'Une erreur est survenue, veuillez réessayer ou recharger la page.',
        });
      });
  };

  const onSelect = (suggestion) => {
    autofill.retrieve(suggestion, { sessionToken }).then((response) => {
      const { features } = response;
      setLoading(false);
      refInput.current.value = features[0].properties.place_name;
      onClose({
        lat: features[0].geometry.coordinates[1],
        lng: features[0].geometry.coordinates[0],
      });
    });
  };

  return (
    <div ref={ref} className="relative">
      {cloneElement(input, {
        ref: refInput,
        onClose,
        contentRight: loading ? (
          <button type="button" className="cursor-default text-current">
            <Loading color="currentColor" size="sm" />
          </button>
        ) : (
          false
        ),
        onChange: (evt) => {
          setOpen(true);
          setLoading(true);
          getPlacePredictions(evt.target.value);
        },
      })}
      {suggestions.length > 0 && open && (
        <ul className="absolute left-0 right-0 z-[999] mt-1 max-h-36 overflow-hidden overflow-y-auto rounded-lg border-t-4 border-gray-300 bg-white shadow-md">
          {suggestions.map((prediction) => (
            <li
              key={prediction.id}
              className="group transform cursor-pointer text-sm text-gray-800 transition-all duration-200 hover:bg-gray-100"
            >
              <button
                type="button"
                className="p-2 text-left group-hover:font-medium"
                onClick={() => {
                  setOpen(false);
                  setLoading(true);
                  onSelect(prediction);
                }}
              >
                {prediction.place_name}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

Map.propTypes = {
  input: PropTypes.element.isRequired,
  onOpen: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
};

export default Map;
