import { Button, Input, Loading } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import IconPassword from '../components/icons/IconPassword';
import { reset as resetPass } from '../redux/api/user';
import { loginReset, reset } from '../redux/reducers/userReducer';

const ResetPassword = () => {
  const navigator = useNavigate();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const user = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const location = useLocation();
  const token = location.pathname.split('/')[2];
  const [passhelper, setPassHelper] = useState({
    text: '',
    color: '',
  });
  const [confirmPasshelper, setConfirmPasshelper] = useState({
    text: '',
    color: '',
  });
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState({
    type: '',
    text: '',
  });

  const navigate = (route) => {
    navigator(route);
  };

  const auth = () => {
    if (
      (user.currentUser && user.currentUser?.available && !error) ||
      (user.currentUser && user?.isAdmin)
    ) {
      navigate('/dashboard');
      // reload page
      window.location.reload();
    }
  };

  useEffect(() => {
    dispatch(loginReset());
    auth();
  }, []);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    if (password === '') {
      setPassHelper({
        text: 'Mot de passe requis',
        color: 'error',
      });
    }

    if (password && password.length < 5)
      setPassHelper({
        text: 'Le mot de passe doit contenir au mois 5 caractères',
        color: 'error',
      });

    if (!confirmPassword) {
      setConfirmPasshelper({
        text: 'Confirmation du mot de passe requis',
        color: 'error',
      });
    }

    if (password && confirmPassword && password !== confirmPassword)
      setConfirmPasshelper({
        text: 'Les mots de passe ne correspondent pas',
        color: 'error',
      });

    if (password === '' || confirmPassword === '') {
      return;
    }
    dispatch(loginReset());
    if (password && confirmPassword && password === confirmPassword) {
      setLoading(true);
      resetPass({
        token,
        password,
      })
        .then(() => {
          setLoading(false);
          setMessage({
            type: 'success',
            text: 'Réinitialisation réussi !',
          });

          setTimeout(() => {
            navigate('/login');
          }, 2000);
        })
        .catch(() => {
          setLoading(false);
          setMessage({
            type: 'error',
            text: 'Lien expiré ou non valide !',
          });
        });
    }
  };

  useEffect(() => {
    if (error === 'Unauthorized') setMessage('Email ou mot de passe incorrect');
    else setMessage('');
  }, [error]);

  useEffect(() => {
    setPassHelper({
      text: '',
      color: 'default',
    });
  }, [password]);

  useEffect(() => {
    setConfirmPasshelper({
      text: '',
      color: '',
    });
  }, [confirmPassword]);

  useEffect(() => {
    auth();
  }, [user]);

  return (
    <div className="bg-white">
      <div className="flex min-h-screen justify-center">
        <div
          className="mx-auto flex w-full flex-col items-center bg-bottom bg-no-repeat md:w-1/2"
          style={{
            backgroundImage: 'url("/images/home-light.svg")',
            backgroundSize: '100% auto',
          }}
        >
          <div className="w-full items-center text-primary md:flex md:pr-4">
            <div className="w-1/3 px-4">
              <img
                className="py-8 md:h-44 md:py-0"
                src="/images/logo-large.svg"
                alt=""
              />
            </div>
          </div>
          <div className="w-full px-4 md:px-16">
            <div className="text-start">
              <div className="space-y-2 text-2xl text-gray-500">
                <p className=" ">Réinitialiser mon mot de passe</p>
              </div>
            </div>
            <form onSubmit={handleClick}>
              <div className="mt-4 flex h-full w-full max-w-md flex-col items-start justify-center space-y-8">
                <Input.Password
                  contentLeft={<IconPassword />}
                  underlined
                  fullWidth="true"
                  label="Mot de passe"
                  className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                  color={passhelper.color}
                  helperColor={passhelper.color}
                  helperText={passhelper.text}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <Input.Password
                  contentLeft={<IconPassword />}
                  underlined
                  fullWidth="true"
                  width=""
                  label="Confirmer le Mot de passe"
                  visibleIcon={
                    <svg
                      width="24"
                      height="24"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.5 8C7.5 14.5 16.5 14.5 19.5 8"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.8162 11.3175L19.5 15"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 12.875V16.5"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.18383 11.3175L4.5 15"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  color={confirmPasshelper.color}
                  helperColor={confirmPasshelper.color}
                  helperText={confirmPasshelper.text}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <p className="mt-4 text-gray-500">
                  {message && (
                    <div
                      className={`w-full text-left text-sm
              ${
                message?.type === 'error' ? 'text-red-500' : 'text-green-500'
              } `}
                    >
                      {message.text}
                    </div>
                  )}
                </p>
                <div className="w-full">
                  <Button
                    type="submit"
                    css={{
                      borderRadius: '0px', // radii.xs
                      border: '1px solid',
                      borderColor: '#DC3838',
                      color: 'white',
                      backgroundColor: '#DC3838',
                      width: '100%',
                    }}
                    size="xl"
                  >
                    {loading && (
                      <Loading
                        style={{
                          marginRight: '1rem',
                        }}
                        color="currentColor"
                        size="sm"
                      />
                    )}
                    VALIDER
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          className="hidden w-1/2 bg-cover lg:flex"
          style={{
            backgroundImage: 'url("/images/cover.png")',
          }}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
