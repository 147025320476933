import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  getEnterprisesStats,
  getInspectorsStats,
  getMesurersStats,
  getStats,
} from '../redux/api/app';
import Audits from './Audits';

const Dashboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const { isLoading } = useSelector((state) => state.audit);
  const { isAdmin, isEnterprise, isMesurer, currentUser } = useSelector(
    (state) => state.user
  );
  const { stats } = useSelector((state) => state.app);

  useEffect(() => {
    if (currentUser && isAdmin) {
      dispatch(getStats());
    } else if (currentUser && isEnterprise) {
      dispatch(getEnterprisesStats());
    } else if (currentUser && isMesurer) {
      dispatch(getMesurersStats());
    } else if (
      currentUser &&
      currentUser?.role[0] === 'inspecteur' &&
      !isAdmin &&
      !isEnterprise &&
      !isMesurer &&
      !isLoading
    ) {
      dispatch(getInspectorsStats());
    }
  }, []);

  return (
    <>
      {!isAdmin && !isEnterprise && (
        <div className="w-full">
          <div className="mx-auto w-full px-8">
            <div className="m-auto flex max-w-full justify-center md:w-full lg:max-w-full">
              <div className="w-1/2 border-r-2 border-primary py-4 text-center sm:min-w-0 sm:border-b-0 md:w-1/3 lg:w-1/4 lg:border-r-4">
                <h2 className="text-sm lg:text-lg">
                  Demandes <br /> d&apos;audit réalisées
                </h2>
                <div className="mt-4 text-xl font-semibold lg:text-4xl">
                  {stats?.achievedAudits}
                </div>
              </div>
              <div className="w-1/2 border-r-2 border-primary py-4 text-center sm:min-w-0 sm:border-b-0 md:w-1/3 lg:w-1/4 lg:border-r-4">
                <h2 className="text-sm lg:text-lg">
                  Demandes <br /> d&apos;audit affectées
                </h2>
                <div className="mt-4 text-xl font-semibold lg:text-4xl">
                  {stats?.affectedAudits}
                </div>
              </div>
              <div className="w-1/2 border-r-2 border-primary py-4 text-center sm:min-w-0 sm:border-b-0 md:w-1/3 lg:w-1/4 lg:border-r-4">
                <h2 className="text-sm lg:text-lg">
                  Demandes <br /> d&apos;audit validées
                </h2>
                <div className="mt-4 text-xl font-semibold lg:text-4xl">
                  {stats?.validatedAudits}
                </div>
              </div>
              <div className="w-1/2 py-4 text-center sm:min-w-0 md:w-1/3 lg:w-1/4">
                <h2 className="text-sm lg:text-lg">
                  Demandes <br /> d&apos;audit rejetées
                </h2>
                <div className="mt-4 text-xl font-semibold lg:text-4xl">
                  {stats?.rejectedAudits}
                </div>
              </div>
            </div>
            <div>{path === '/dashboard' && <Audits />}</div>
          </div>
        </div>
      )}

      {isEnterprise && (
        <div className="mx-auto w-full max-w-7xl px-3 py-20">
          <div className="mx-auto w-full md:m-0">
            <div className="grid grid-cols-1 gap-0 md:grid-cols-3 md:gap-5 xl:gap-10">
              {/* Inspectors */}
              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 50C21.1 50 3.33333 54.4667 3.33333 63.3333V70H56.6667V63.3333C56.6667 54.4667 38.9 50 30 50Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M73.6667 22.8C73.7 22.4333 73.7333 22.0667 73.7333 21.6667C73.7333 21.2667 73.7 20.9 73.6333 20.5333L76.1 18.6C76.3333 18.4333 76.3667 18.1 76.2333 17.8667L73.9 13.8333C73.7667 13.5667 73.4333 13.5 73.2 13.5667L70.3333 14.7333C69.7333 14.2667 69.0667 13.9 68.3667 13.6L67.9333 10.5C67.8667 10.2 67.6333 10 67.3333 10H62.6667C62.3667 10 62.1333 10.2 62.1 10.5L61.6667 13.6C60.9667 13.9 60.3 14.3 59.7 14.7333L56.8 13.5667C56.5333 13.4667 56.2333 13.5667 56.1 13.8333L53.7667 17.8667C53.6333 18.1333 53.6667 18.4333 53.9 18.6L56.3667 20.5333C56.3 20.9 56.2667 21.3 56.2667 21.6667C56.2667 22.0333 56.3 22.4333 56.3667 22.8L53.9 24.7333C53.6667 24.9 53.6333 25.2333 53.7667 25.4667L56.1 29.5C56.2333 29.7667 56.5667 29.8333 56.8 29.7667L59.7 28.6C60.3 29.0667 60.9667 29.4333 61.6667 29.7333L62.1 32.8333C62.1333 33.1333 62.3667 33.3333 62.6667 33.3333H67.3333C67.6333 33.3333 67.8667 33.1333 67.9 32.8333L68.3333 29.7333C69.0333 29.4333 69.7 29.0333 70.3 28.6L73.2 29.7667C73.4667 29.8667 73.7667 29.7667 73.9 29.5L76.2333 25.4667C76.3667 25.2 76.3333 24.9 76.1 24.7333L73.6667 22.8ZM65 25.8333C62.7 25.8333 60.8333 23.9667 60.8333 21.6667C60.8333 19.3667 62.7 17.5 65 17.5C67.3 17.5 69.1667 19.3667 69.1667 21.6667C69.1667 23.9667 67.3 25.8333 65 25.8333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M66.4 38.9333L64.7333 36.0333C64.6333 35.8333 64.4 35.7667 64.2333 35.8333L62.1667 36.6667C61.7333 36.3333 61.2667 36.0667 60.7667 35.8667L60.4667 33.6667C60.4 33.4667 60.2 33.3333 60 33.3333H56.6667C56.4667 33.3333 56.3 33.4667 56.2667 33.7L55.9667 35.9C55.4667 36.1 55 36.4 54.5667 36.7L52.5 35.8667C52.3 35.8 52.1 35.8667 52 36.0667L50.3333 38.9667C50.2333 39.1667 50.2667 39.3667 50.4333 39.5L52.2 40.8667C52.1667 41.1333 52.1333 41.4 52.1333 41.6667C52.1333 41.9333 52.1667 42.2333 52.2 42.4667L50.4333 43.8333C50.2667 43.9667 50.2333 44.2 50.3333 44.3667L52 47.2667C52.1 47.4667 52.3333 47.5333 52.5 47.4667L54.5667 46.6333C55 46.9667 55.4667 47.2333 55.9667 47.4333L56.2667 49.6333C56.3 49.8667 56.4667 50 56.6667 50H60C60.2 50 60.4 49.8667 60.4 49.6333L60.7 47.4333C61.2 47.2333 61.6667 46.9333 62.1 46.6333L64.1667 47.4667C64.3667 47.5333 64.5667 47.4667 64.6667 47.2667L66.3333 44.3667C66.4333 44.1667 66.4 43.9667 66.2333 43.8333L64.5 42.4667C64.5333 42.2 64.5667 41.9333 64.5667 41.6667C64.5667 41.4 64.5333 41.1 64.5 40.8667L66.2667 39.5C66.4333 39.3667 66.4667 39.1333 66.4 38.9333ZM58.3333 44.4333C56.8 44.4333 55.5667 43.1667 55.5667 41.6667C55.5667 40.1333 56.8333 38.9 58.3333 38.9C59.8333 38.9 61.1 40.1667 61.1 41.6667C61.1 43.2 59.8667 44.4333 58.3333 44.4333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M15.8 30H44.2333C45.1333 30 45.8667 29.2667 45.8667 28.3667V28.3C45.8667 27.4 45.1333 26.6667 44.2333 26.6667H43.3333C43.3333 21.7333 40.6333 17.5 36.6667 15.1667V18.3333C36.6667 19.2667 35.9333 20 35 20C34.0667 20 33.3333 19.2667 33.3333 18.3333V13.8C32.2667 13.5333 31.1667 13.3333 30 13.3333C28.8333 13.3333 27.7333 13.5333 26.6667 13.8V18.3333C26.6667 19.2667 25.9333 20 25 20C24.0667 20 23.3333 19.2667 23.3333 18.3333V15.1667C19.3667 17.5 16.6667 21.7333 16.6667 26.6667H15.8C14.9 26.6667 14.1667 27.4 14.1667 28.3V28.4C14.1667 29.2667 14.9 30 15.8 30Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M30 43.3333C36.2 43.3333 41.3667 39.0667 42.8667 33.3333H17.1333C18.6333 39.0667 23.8 43.3333 30 43.3333Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">{stats?.inspectors}</p>
                  <p className="text-2xl">Inspecteurs</p>
                </div>
              </div>

              {/* Measurer */}
              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 50C21.1 50 3.33333 54.4667 3.33333 63.3333V70H56.6667V63.3333C56.6667 54.4667 38.9 50 30 50Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M73.6667 22.8C73.7 22.4333 73.7333 22.0667 73.7333 21.6667C73.7333 21.2667 73.7 20.9 73.6333 20.5333L76.1 18.6C76.3333 18.4333 76.3667 18.1 76.2333 17.8667L73.9 13.8333C73.7667 13.5667 73.4333 13.5 73.2 13.5667L70.3333 14.7333C69.7333 14.2667 69.0667 13.9 68.3667 13.6L67.9333 10.5C67.8667 10.2 67.6333 10 67.3333 10H62.6667C62.3667 10 62.1333 10.2 62.1 10.5L61.6667 13.6C60.9667 13.9 60.3 14.3 59.7 14.7333L56.8 13.5667C56.5333 13.4667 56.2333 13.5667 56.1 13.8333L53.7667 17.8667C53.6333 18.1333 53.6667 18.4333 53.9 18.6L56.3667 20.5333C56.3 20.9 56.2667 21.3 56.2667 21.6667C56.2667 22.0333 56.3 22.4333 56.3667 22.8L53.9 24.7333C53.6667 24.9 53.6333 25.2333 53.7667 25.4667L56.1 29.5C56.2333 29.7667 56.5667 29.8333 56.8 29.7667L59.7 28.6C60.3 29.0667 60.9667 29.4333 61.6667 29.7333L62.1 32.8333C62.1333 33.1333 62.3667 33.3333 62.6667 33.3333H67.3333C67.6333 33.3333 67.8667 33.1333 67.9 32.8333L68.3333 29.7333C69.0333 29.4333 69.7 29.0333 70.3 28.6L73.2 29.7667C73.4667 29.8667 73.7667 29.7667 73.9 29.5L76.2333 25.4667C76.3667 25.2 76.3333 24.9 76.1 24.7333L73.6667 22.8ZM65 25.8333C62.7 25.8333 60.8333 23.9667 60.8333 21.6667C60.8333 19.3667 62.7 17.5 65 17.5C67.3 17.5 69.1667 19.3667 69.1667 21.6667C69.1667 23.9667 67.3 25.8333 65 25.8333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M66.4 38.9333L64.7333 36.0333C64.6333 35.8333 64.4 35.7667 64.2333 35.8333L62.1667 36.6667C61.7333 36.3333 61.2667 36.0667 60.7667 35.8667L60.4667 33.6667C60.4 33.4667 60.2 33.3333 60 33.3333H56.6667C56.4667 33.3333 56.3 33.4667 56.2667 33.7L55.9667 35.9C55.4667 36.1 55 36.4 54.5667 36.7L52.5 35.8667C52.3 35.8 52.1 35.8667 52 36.0667L50.3333 38.9667C50.2333 39.1667 50.2667 39.3667 50.4333 39.5L52.2 40.8667C52.1667 41.1333 52.1333 41.4 52.1333 41.6667C52.1333 41.9333 52.1667 42.2333 52.2 42.4667L50.4333 43.8333C50.2667 43.9667 50.2333 44.2 50.3333 44.3667L52 47.2667C52.1 47.4667 52.3333 47.5333 52.5 47.4667L54.5667 46.6333C55 46.9667 55.4667 47.2333 55.9667 47.4333L56.2667 49.6333C56.3 49.8667 56.4667 50 56.6667 50H60C60.2 50 60.4 49.8667 60.4 49.6333L60.7 47.4333C61.2 47.2333 61.6667 46.9333 62.1 46.6333L64.1667 47.4667C64.3667 47.5333 64.5667 47.4667 64.6667 47.2667L66.3333 44.3667C66.4333 44.1667 66.4 43.9667 66.2333 43.8333L64.5 42.4667C64.5333 42.2 64.5667 41.9333 64.5667 41.6667C64.5667 41.4 64.5333 41.1 64.5 40.8667L66.2667 39.5C66.4333 39.3667 66.4667 39.1333 66.4 38.9333ZM58.3333 44.4333C56.8 44.4333 55.5667 43.1667 55.5667 41.6667C55.5667 40.1333 56.8333 38.9 58.3333 38.9C59.8333 38.9 61.1 40.1667 61.1 41.6667C61.1 43.2 59.8667 44.4333 58.3333 44.4333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M15.8 30H44.2333C45.1333 30 45.8667 29.2667 45.8667 28.3667V28.3C45.8667 27.4 45.1333 26.6667 44.2333 26.6667H43.3333C43.3333 21.7333 40.6333 17.5 36.6667 15.1667V18.3333C36.6667 19.2667 35.9333 20 35 20C34.0667 20 33.3333 19.2667 33.3333 18.3333V13.8C32.2667 13.5333 31.1667 13.3333 30 13.3333C28.8333 13.3333 27.7333 13.5333 26.6667 13.8V18.3333C26.6667 19.2667 25.9333 20 25 20C24.0667 20 23.3333 19.2667 23.3333 18.3333V15.1667C19.3667 17.5 16.6667 21.7333 16.6667 26.6667H15.8C14.9 26.6667 14.1667 27.4 14.1667 28.3V28.4C14.1667 29.2667 14.9 30 15.8 30Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M30 43.3333C36.2 43.3333 41.3667 39.0667 42.8667 33.3333H17.1333C18.6333 39.0667 23.8 43.3333 30 43.3333Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">{stats?.measurer}</p>
                  <p className="text-2xl">Mesureurs</p>
                </div>
              </div>

              {/* Processing Audits */}

              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M40 23.3333V10H6.66666V70H73.3333V23.3333H40ZM20 63.3333H13.3333V56.6667H20V63.3333ZM20 50H13.3333V43.3333H20V50ZM20 36.6667H13.3333V30H20V36.6667ZM20 23.3333H13.3333V16.6667H20V23.3333ZM33.3333 63.3333H26.6667V56.6667H33.3333V63.3333ZM33.3333 50H26.6667V43.3333H33.3333V50ZM33.3333 36.6667H26.6667V30H33.3333V36.6667ZM33.3333 23.3333H26.6667V16.6667H33.3333V23.3333ZM66.6667 63.3333H40V56.6667H46.6667V50H40V43.3333H46.6667V36.6667H40V30H66.6667V63.3333ZM60 36.6667H53.3333V43.3333H60V36.6667ZM60 50H53.3333V56.6667H60V50Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.processingAudits}
                  </p>
                  <p className="text-2xl">
                    {' '}
                    Demandes <br /> d&apos;audit en cours
                  </p>
                </div>
              </div>

              {/* Submitted Audits */}

              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 50C21.1 50 3.33333 54.4667 3.33333 63.3333V70H56.6667V63.3333C56.6667 54.4667 38.9 50 30 50Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M73.6667 22.8C73.7 22.4333 73.7333 22.0667 73.7333 21.6667C73.7333 21.2667 73.7 20.9 73.6333 20.5333L76.1 18.6C76.3333 18.4333 76.3667 18.1 76.2333 17.8667L73.9 13.8333C73.7667 13.5667 73.4333 13.5 73.2 13.5667L70.3333 14.7333C69.7333 14.2667 69.0667 13.9 68.3667 13.6L67.9333 10.5C67.8667 10.2 67.6333 10 67.3333 10H62.6667C62.3667 10 62.1333 10.2 62.1 10.5L61.6667 13.6C60.9667 13.9 60.3 14.3 59.7 14.7333L56.8 13.5667C56.5333 13.4667 56.2333 13.5667 56.1 13.8333L53.7667 17.8667C53.6333 18.1333 53.6667 18.4333 53.9 18.6L56.3667 20.5333C56.3 20.9 56.2667 21.3 56.2667 21.6667C56.2667 22.0333 56.3 22.4333 56.3667 22.8L53.9 24.7333C53.6667 24.9 53.6333 25.2333 53.7667 25.4667L56.1 29.5C56.2333 29.7667 56.5667 29.8333 56.8 29.7667L59.7 28.6C60.3 29.0667 60.9667 29.4333 61.6667 29.7333L62.1 32.8333C62.1333 33.1333 62.3667 33.3333 62.6667 33.3333H67.3333C67.6333 33.3333 67.8667 33.1333 67.9 32.8333L68.3333 29.7333C69.0333 29.4333 69.7 29.0333 70.3 28.6L73.2 29.7667C73.4667 29.8667 73.7667 29.7667 73.9 29.5L76.2333 25.4667C76.3667 25.2 76.3333 24.9 76.1 24.7333L73.6667 22.8ZM65 25.8333C62.7 25.8333 60.8333 23.9667 60.8333 21.6667C60.8333 19.3667 62.7 17.5 65 17.5C67.3 17.5 69.1667 19.3667 69.1667 21.6667C69.1667 23.9667 67.3 25.8333 65 25.8333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M66.4 38.9333L64.7333 36.0333C64.6333 35.8333 64.4 35.7667 64.2333 35.8333L62.1667 36.6667C61.7333 36.3333 61.2667 36.0667 60.7667 35.8667L60.4667 33.6667C60.4 33.4667 60.2 33.3333 60 33.3333H56.6667C56.4667 33.3333 56.3 33.4667 56.2667 33.7L55.9667 35.9C55.4667 36.1 55 36.4 54.5667 36.7L52.5 35.8667C52.3 35.8 52.1 35.8667 52 36.0667L50.3333 38.9667C50.2333 39.1667 50.2667 39.3667 50.4333 39.5L52.2 40.8667C52.1667 41.1333 52.1333 41.4 52.1333 41.6667C52.1333 41.9333 52.1667 42.2333 52.2 42.4667L50.4333 43.8333C50.2667 43.9667 50.2333 44.2 50.3333 44.3667L52 47.2667C52.1 47.4667 52.3333 47.5333 52.5 47.4667L54.5667 46.6333C55 46.9667 55.4667 47.2333 55.9667 47.4333L56.2667 49.6333C56.3 49.8667 56.4667 50 56.6667 50H60C60.2 50 60.4 49.8667 60.4 49.6333L60.7 47.4333C61.2 47.2333 61.6667 46.9333 62.1 46.6333L64.1667 47.4667C64.3667 47.5333 64.5667 47.4667 64.6667 47.2667L66.3333 44.3667C66.4333 44.1667 66.4 43.9667 66.2333 43.8333L64.5 42.4667C64.5333 42.2 64.5667 41.9333 64.5667 41.6667C64.5667 41.4 64.5333 41.1 64.5 40.8667L66.2667 39.5C66.4333 39.3667 66.4667 39.1333 66.4 38.9333ZM58.3333 44.4333C56.8 44.4333 55.5667 43.1667 55.5667 41.6667C55.5667 40.1333 56.8333 38.9 58.3333 38.9C59.8333 38.9 61.1 40.1667 61.1 41.6667C61.1 43.2 59.8667 44.4333 58.3333 44.4333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M15.8 30H44.2333C45.1333 30 45.8667 29.2667 45.8667 28.3667V28.3C45.8667 27.4 45.1333 26.6667 44.2333 26.6667H43.3333C43.3333 21.7333 40.6333 17.5 36.6667 15.1667V18.3333C36.6667 19.2667 35.9333 20 35 20C34.0667 20 33.3333 19.2667 33.3333 18.3333V13.8C32.2667 13.5333 31.1667 13.3333 30 13.3333C28.8333 13.3333 27.7333 13.5333 26.6667 13.8V18.3333C26.6667 19.2667 25.9333 20 25 20C24.0667 20 23.3333 19.2667 23.3333 18.3333V15.1667C19.3667 17.5 16.6667 21.7333 16.6667 26.6667H15.8C14.9 26.6667 14.1667 27.4 14.1667 28.3V28.4C14.1667 29.2667 14.9 30 15.8 30Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M30 43.3333C36.2 43.3333 41.3667 39.0667 42.8667 33.3333H17.1333C18.6333 39.0667 23.8 43.3333 30 43.3333Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.submittedAudits}
                  </p>
                  <p className="text-2xl">
                    {' '}
                    Demandes <br /> d&apos;audit soumises
                  </p>
                </div>
              </div>

              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M40 42.5C45.4333 42.5 50.2333 43.8 54.1333 45.5C57.7333 47.1 60 50.7 60 54.6V60H20V54.6333C20 50.7 22.2667 47.1 25.8667 45.5333C29.7667 43.8 34.5667 42.5 40 42.5ZM13.3333 43.3333C17 43.3333 20 40.3333 20 36.6667C20 33 17 30 13.3333 30C9.66667 30 6.66667 33 6.66667 36.6667C6.66667 40.3333 9.66667 43.3333 13.3333 43.3333ZM17.1 47C15.8667 46.8 14.6333 46.6667 13.3333 46.6667C10.0333 46.6667 6.9 47.3667 4.06667 48.6C1.6 49.6667 0 52.0667 0 54.7667V60H15V54.6333C15 51.8667 15.7667 49.2667 17.1 47ZM66.6667 43.3333C70.3333 43.3333 73.3333 40.3333 73.3333 36.6667C73.3333 33 70.3333 30 66.6667 30C63 30 60 33 60 36.6667C60 40.3333 63 43.3333 66.6667 43.3333ZM80 54.7667C80 52.0667 78.4 49.6667 75.9333 48.6C73.1 47.3667 69.9667 46.6667 66.6667 46.6667C65.3667 46.6667 64.1333 46.8 62.9 47C64.2333 49.2667 65 51.8667 65 54.6333V60H80V54.7667ZM40 20C45.5333 20 50 24.4667 50 30C50 35.5333 45.5333 40 40 40C34.4667 40 30 35.5333 30 30C30 24.4667 34.4667 20 40 20Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">{stats?.pendingAudits}</p>
                  <p className="text-2xl">
                    {' '}
                    Demandes <br /> d&apos;audit en attente
                  </p>
                </div>
              </div>

              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M50 36.6667V16.6667L40 6.66667L30 16.6667V23.3333H10V70H70V36.6667H50ZM23.3333 63.3333H16.6667V56.6667H23.3333V63.3333ZM23.3333 50H16.6667V43.3333H23.3333V50ZM23.3333 36.6667H16.6667V30H23.3333V36.6667ZM43.3333 63.3333H36.6667V56.6667H43.3333V63.3333ZM43.3333 50H36.6667V43.3333H43.3333V50ZM43.3333 36.6667H36.6667V30H43.3333V36.6667ZM43.3333 23.3333H36.6667V16.6667H43.3333V23.3333ZM63.3333 63.3333H56.6667V56.6667H63.3333V63.3333ZM63.3333 50H56.6667V43.3333H63.3333V50Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.achievedAudits}
                  </p>
                  <p className="text-2xl">
                    {' '}
                    Demandes <br /> d&apos;audit réalisées
                  </p>
                </div>
              </div>

              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto pr-1.5"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 13.3333V17.9333C27.2333 16.8333 24.2667 16.2667 21.3 16.2667C15.3333 16.2667 9.36667 18.5333 4.8 23.1L15.9 34.2H19.6V37.9C22.4667 40.7667 26.2 42.2667 29.9667 42.4333V50H20V60C20 63.6667 23 66.6667 26.6667 66.6667H60C65.5333 66.6667 70 62.2 70 56.6667V13.3333H30ZM26.3 34.7V27.5333H18.7L15.2333 24.0667C17.1333 23.3333 19.2 22.9333 21.3 22.9333C25.7667 22.9333 29.9333 24.6667 33.1 27.8L37.8 32.5L37.1333 33.1667C35.4333 34.8667 33.1667 35.8333 30.7333 35.8333C29.1667 35.8333 27.6333 35.4333 26.3 34.7ZM63.3333 56.6667C63.3333 58.5 61.8333 60 60 60C58.1667 60 56.6667 58.5 56.6667 56.6667V50H36.6667V41.3667C38.5667 40.6 40.3333 39.4667 41.8667 37.9333L42.5333 37.2667L51.9667 46.6667H56.6667V41.9667L36.6667 22.0667V20H63.3333V56.6667Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.processingValidationAudits}
                  </p>
                  <p className="text-2xl">
                    Demandes <br /> d&apos;audit en cours de validation
                  </p>
                </div>
              </div>

              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto pr-1.5"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 13.3333V17.9333C27.2333 16.8333 24.2667 16.2667 21.3 16.2667C15.3333 16.2667 9.36667 18.5333 4.8 23.1L15.9 34.2H19.6V37.9C22.4667 40.7667 26.2 42.2667 29.9667 42.4333V50H20V60C20 63.6667 23 66.6667 26.6667 66.6667H60C65.5333 66.6667 70 62.2 70 56.6667V13.3333H30ZM26.3 34.7V27.5333H18.7L15.2333 24.0667C17.1333 23.3333 19.2 22.9333 21.3 22.9333C25.7667 22.9333 29.9333 24.6667 33.1 27.8L37.8 32.5L37.1333 33.1667C35.4333 34.8667 33.1667 35.8333 30.7333 35.8333C29.1667 35.8333 27.6333 35.4333 26.3 34.7ZM63.3333 56.6667C63.3333 58.5 61.8333 60 60 60C58.1667 60 56.6667 58.5 56.6667 56.6667V50H36.6667V41.3667C38.5667 40.6 40.3333 39.4667 41.8667 37.9333L42.5333 37.2667L51.9667 46.6667H56.6667V41.9667L36.6667 22.0667V20H63.3333V56.6667Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.validatedAudits}
                  </p>
                  <p className="text-2xl">
                    Demandes <br /> d&apos;audit validées
                  </p>
                </div>
              </div>

              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto pr-1.5"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 13.3333V17.9333C27.2333 16.8333 24.2667 16.2667 21.3 16.2667C15.3333 16.2667 9.36667 18.5333 4.8 23.1L15.9 34.2H19.6V37.9C22.4667 40.7667 26.2 42.2667 29.9667 42.4333V50H20V60C20 63.6667 23 66.6667 26.6667 66.6667H60C65.5333 66.6667 70 62.2 70 56.6667V13.3333H30ZM26.3 34.7V27.5333H18.7L15.2333 24.0667C17.1333 23.3333 19.2 22.9333 21.3 22.9333C25.7667 22.9333 29.9333 24.6667 33.1 27.8L37.8 32.5L37.1333 33.1667C35.4333 34.8667 33.1667 35.8333 30.7333 35.8333C29.1667 35.8333 27.6333 35.4333 26.3 34.7ZM63.3333 56.6667C63.3333 58.5 61.8333 60 60 60C58.1667 60 56.6667 58.5 56.6667 56.6667V50H36.6667V41.3667C38.5667 40.6 40.3333 39.4667 41.8667 37.9333L42.5333 37.2667L51.9667 46.6667H56.6667V41.9667L36.6667 22.0667V20H63.3333V56.6667Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.terminatedAudits}
                  </p>
                  <p className="text-2xl">
                    Demandes <br /> d&apos;audit terminées
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {isAdmin && (
        <div className="mx-auto w-full max-w-7xl px-3 py-20">
          <div className="mx-auto w-full md:m-0">
            <div className="grid grid-cols-1 gap-0 md:grid-cols-3 md:gap-5 xl:gap-10">
              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M40 23.3333V10H6.66666V70H73.3333V23.3333H40ZM20 63.3333H13.3333V56.6667H20V63.3333ZM20 50H13.3333V43.3333H20V50ZM20 36.6667H13.3333V30H20V36.6667ZM20 23.3333H13.3333V16.6667H20V23.3333ZM33.3333 63.3333H26.6667V56.6667H33.3333V63.3333ZM33.3333 50H26.6667V43.3333H33.3333V50ZM33.3333 36.6667H26.6667V30H33.3333V36.6667ZM33.3333 23.3333H26.6667V16.6667H33.3333V23.3333ZM66.6667 63.3333H40V56.6667H46.6667V50H40V43.3333H46.6667V36.6667H40V30H66.6667V63.3333ZM60 36.6667H53.3333V43.3333H60V36.6667ZM60 50H53.3333V56.6667H60V50Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">{stats?.enterprises}</p>
                  <p className="text-2xl">Entreprises</p>
                </div>
              </div>
              {/* Inspectors */}
              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 50C21.1 50 3.33333 54.4667 3.33333 63.3333V70H56.6667V63.3333C56.6667 54.4667 38.9 50 30 50Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M73.6667 22.8C73.7 22.4333 73.7333 22.0667 73.7333 21.6667C73.7333 21.2667 73.7 20.9 73.6333 20.5333L76.1 18.6C76.3333 18.4333 76.3667 18.1 76.2333 17.8667L73.9 13.8333C73.7667 13.5667 73.4333 13.5 73.2 13.5667L70.3333 14.7333C69.7333 14.2667 69.0667 13.9 68.3667 13.6L67.9333 10.5C67.8667 10.2 67.6333 10 67.3333 10H62.6667C62.3667 10 62.1333 10.2 62.1 10.5L61.6667 13.6C60.9667 13.9 60.3 14.3 59.7 14.7333L56.8 13.5667C56.5333 13.4667 56.2333 13.5667 56.1 13.8333L53.7667 17.8667C53.6333 18.1333 53.6667 18.4333 53.9 18.6L56.3667 20.5333C56.3 20.9 56.2667 21.3 56.2667 21.6667C56.2667 22.0333 56.3 22.4333 56.3667 22.8L53.9 24.7333C53.6667 24.9 53.6333 25.2333 53.7667 25.4667L56.1 29.5C56.2333 29.7667 56.5667 29.8333 56.8 29.7667L59.7 28.6C60.3 29.0667 60.9667 29.4333 61.6667 29.7333L62.1 32.8333C62.1333 33.1333 62.3667 33.3333 62.6667 33.3333H67.3333C67.6333 33.3333 67.8667 33.1333 67.9 32.8333L68.3333 29.7333C69.0333 29.4333 69.7 29.0333 70.3 28.6L73.2 29.7667C73.4667 29.8667 73.7667 29.7667 73.9 29.5L76.2333 25.4667C76.3667 25.2 76.3333 24.9 76.1 24.7333L73.6667 22.8ZM65 25.8333C62.7 25.8333 60.8333 23.9667 60.8333 21.6667C60.8333 19.3667 62.7 17.5 65 17.5C67.3 17.5 69.1667 19.3667 69.1667 21.6667C69.1667 23.9667 67.3 25.8333 65 25.8333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M66.4 38.9333L64.7333 36.0333C64.6333 35.8333 64.4 35.7667 64.2333 35.8333L62.1667 36.6667C61.7333 36.3333 61.2667 36.0667 60.7667 35.8667L60.4667 33.6667C60.4 33.4667 60.2 33.3333 60 33.3333H56.6667C56.4667 33.3333 56.3 33.4667 56.2667 33.7L55.9667 35.9C55.4667 36.1 55 36.4 54.5667 36.7L52.5 35.8667C52.3 35.8 52.1 35.8667 52 36.0667L50.3333 38.9667C50.2333 39.1667 50.2667 39.3667 50.4333 39.5L52.2 40.8667C52.1667 41.1333 52.1333 41.4 52.1333 41.6667C52.1333 41.9333 52.1667 42.2333 52.2 42.4667L50.4333 43.8333C50.2667 43.9667 50.2333 44.2 50.3333 44.3667L52 47.2667C52.1 47.4667 52.3333 47.5333 52.5 47.4667L54.5667 46.6333C55 46.9667 55.4667 47.2333 55.9667 47.4333L56.2667 49.6333C56.3 49.8667 56.4667 50 56.6667 50H60C60.2 50 60.4 49.8667 60.4 49.6333L60.7 47.4333C61.2 47.2333 61.6667 46.9333 62.1 46.6333L64.1667 47.4667C64.3667 47.5333 64.5667 47.4667 64.6667 47.2667L66.3333 44.3667C66.4333 44.1667 66.4 43.9667 66.2333 43.8333L64.5 42.4667C64.5333 42.2 64.5667 41.9333 64.5667 41.6667C64.5667 41.4 64.5333 41.1 64.5 40.8667L66.2667 39.5C66.4333 39.3667 66.4667 39.1333 66.4 38.9333ZM58.3333 44.4333C56.8 44.4333 55.5667 43.1667 55.5667 41.6667C55.5667 40.1333 56.8333 38.9 58.3333 38.9C59.8333 38.9 61.1 40.1667 61.1 41.6667C61.1 43.2 59.8667 44.4333 58.3333 44.4333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M15.8 30H44.2333C45.1333 30 45.8667 29.2667 45.8667 28.3667V28.3C45.8667 27.4 45.1333 26.6667 44.2333 26.6667H43.3333C43.3333 21.7333 40.6333 17.5 36.6667 15.1667V18.3333C36.6667 19.2667 35.9333 20 35 20C34.0667 20 33.3333 19.2667 33.3333 18.3333V13.8C32.2667 13.5333 31.1667 13.3333 30 13.3333C28.8333 13.3333 27.7333 13.5333 26.6667 13.8V18.3333C26.6667 19.2667 25.9333 20 25 20C24.0667 20 23.3333 19.2667 23.3333 18.3333V15.1667C19.3667 17.5 16.6667 21.7333 16.6667 26.6667H15.8C14.9 26.6667 14.1667 27.4 14.1667 28.3V28.4C14.1667 29.2667 14.9 30 15.8 30Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M30 43.3333C36.2 43.3333 41.3667 39.0667 42.8667 33.3333H17.1333C18.6333 39.0667 23.8 43.3333 30 43.3333Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">{stats?.inspectors}</p>
                  <p className="text-2xl">Inspecteurs</p>
                </div>
              </div>

              {/* Measurer */}
              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 50C21.1 50 3.33333 54.4667 3.33333 63.3333V70H56.6667V63.3333C56.6667 54.4667 38.9 50 30 50Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M73.6667 22.8C73.7 22.4333 73.7333 22.0667 73.7333 21.6667C73.7333 21.2667 73.7 20.9 73.6333 20.5333L76.1 18.6C76.3333 18.4333 76.3667 18.1 76.2333 17.8667L73.9 13.8333C73.7667 13.5667 73.4333 13.5 73.2 13.5667L70.3333 14.7333C69.7333 14.2667 69.0667 13.9 68.3667 13.6L67.9333 10.5C67.8667 10.2 67.6333 10 67.3333 10H62.6667C62.3667 10 62.1333 10.2 62.1 10.5L61.6667 13.6C60.9667 13.9 60.3 14.3 59.7 14.7333L56.8 13.5667C56.5333 13.4667 56.2333 13.5667 56.1 13.8333L53.7667 17.8667C53.6333 18.1333 53.6667 18.4333 53.9 18.6L56.3667 20.5333C56.3 20.9 56.2667 21.3 56.2667 21.6667C56.2667 22.0333 56.3 22.4333 56.3667 22.8L53.9 24.7333C53.6667 24.9 53.6333 25.2333 53.7667 25.4667L56.1 29.5C56.2333 29.7667 56.5667 29.8333 56.8 29.7667L59.7 28.6C60.3 29.0667 60.9667 29.4333 61.6667 29.7333L62.1 32.8333C62.1333 33.1333 62.3667 33.3333 62.6667 33.3333H67.3333C67.6333 33.3333 67.8667 33.1333 67.9 32.8333L68.3333 29.7333C69.0333 29.4333 69.7 29.0333 70.3 28.6L73.2 29.7667C73.4667 29.8667 73.7667 29.7667 73.9 29.5L76.2333 25.4667C76.3667 25.2 76.3333 24.9 76.1 24.7333L73.6667 22.8ZM65 25.8333C62.7 25.8333 60.8333 23.9667 60.8333 21.6667C60.8333 19.3667 62.7 17.5 65 17.5C67.3 17.5 69.1667 19.3667 69.1667 21.6667C69.1667 23.9667 67.3 25.8333 65 25.8333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M66.4 38.9333L64.7333 36.0333C64.6333 35.8333 64.4 35.7667 64.2333 35.8333L62.1667 36.6667C61.7333 36.3333 61.2667 36.0667 60.7667 35.8667L60.4667 33.6667C60.4 33.4667 60.2 33.3333 60 33.3333H56.6667C56.4667 33.3333 56.3 33.4667 56.2667 33.7L55.9667 35.9C55.4667 36.1 55 36.4 54.5667 36.7L52.5 35.8667C52.3 35.8 52.1 35.8667 52 36.0667L50.3333 38.9667C50.2333 39.1667 50.2667 39.3667 50.4333 39.5L52.2 40.8667C52.1667 41.1333 52.1333 41.4 52.1333 41.6667C52.1333 41.9333 52.1667 42.2333 52.2 42.4667L50.4333 43.8333C50.2667 43.9667 50.2333 44.2 50.3333 44.3667L52 47.2667C52.1 47.4667 52.3333 47.5333 52.5 47.4667L54.5667 46.6333C55 46.9667 55.4667 47.2333 55.9667 47.4333L56.2667 49.6333C56.3 49.8667 56.4667 50 56.6667 50H60C60.2 50 60.4 49.8667 60.4 49.6333L60.7 47.4333C61.2 47.2333 61.6667 46.9333 62.1 46.6333L64.1667 47.4667C64.3667 47.5333 64.5667 47.4667 64.6667 47.2667L66.3333 44.3667C66.4333 44.1667 66.4 43.9667 66.2333 43.8333L64.5 42.4667C64.5333 42.2 64.5667 41.9333 64.5667 41.6667C64.5667 41.4 64.5333 41.1 64.5 40.8667L66.2667 39.5C66.4333 39.3667 66.4667 39.1333 66.4 38.9333ZM58.3333 44.4333C56.8 44.4333 55.5667 43.1667 55.5667 41.6667C55.5667 40.1333 56.8333 38.9 58.3333 38.9C59.8333 38.9 61.1 40.1667 61.1 41.6667C61.1 43.2 59.8667 44.4333 58.3333 44.4333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M15.8 30H44.2333C45.1333 30 45.8667 29.2667 45.8667 28.3667V28.3C45.8667 27.4 45.1333 26.6667 44.2333 26.6667H43.3333C43.3333 21.7333 40.6333 17.5 36.6667 15.1667V18.3333C36.6667 19.2667 35.9333 20 35 20C34.0667 20 33.3333 19.2667 33.3333 18.3333V13.8C32.2667 13.5333 31.1667 13.3333 30 13.3333C28.8333 13.3333 27.7333 13.5333 26.6667 13.8V18.3333C26.6667 19.2667 25.9333 20 25 20C24.0667 20 23.3333 19.2667 23.3333 18.3333V15.1667C19.3667 17.5 16.6667 21.7333 16.6667 26.6667H15.8C14.9 26.6667 14.1667 27.4 14.1667 28.3V28.4C14.1667 29.2667 14.9 30 15.8 30Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M30 43.3333C36.2 43.3333 41.3667 39.0667 42.8667 33.3333H17.1333C18.6333 39.0667 23.8 43.3333 30 43.3333Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">{stats?.measurer}</p>
                  <p className="text-2xl">Mesureurs</p>
                </div>
              </div>

              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M40 42.5C45.4333 42.5 50.2333 43.8 54.1333 45.5C57.7333 47.1 60 50.7 60 54.6V60H20V54.6333C20 50.7 22.2667 47.1 25.8667 45.5333C29.7667 43.8 34.5667 42.5 40 42.5ZM13.3333 43.3333C17 43.3333 20 40.3333 20 36.6667C20 33 17 30 13.3333 30C9.66667 30 6.66667 33 6.66667 36.6667C6.66667 40.3333 9.66667 43.3333 13.3333 43.3333ZM17.1 47C15.8667 46.8 14.6333 46.6667 13.3333 46.6667C10.0333 46.6667 6.9 47.3667 4.06667 48.6C1.6 49.6667 0 52.0667 0 54.7667V60H15V54.6333C15 51.8667 15.7667 49.2667 17.1 47ZM66.6667 43.3333C70.3333 43.3333 73.3333 40.3333 73.3333 36.6667C73.3333 33 70.3333 30 66.6667 30C63 30 60 33 60 36.6667C60 40.3333 63 43.3333 66.6667 43.3333ZM80 54.7667C80 52.0667 78.4 49.6667 75.9333 48.6C73.1 47.3667 69.9667 46.6667 66.6667 46.6667C65.3667 46.6667 64.1333 46.8 62.9 47C64.2333 49.2667 65 51.8667 65 54.6333V60H80V54.7667ZM40 20C45.5333 20 50 24.4667 50 30C50 35.5333 45.5333 40 40 40C34.4667 40 30 35.5333 30 30C30 24.4667 34.4667 20 40 20Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">{stats?.customers}</p>
                  <p className="text-2xl">Clients</p>
                </div>
              </div>

              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M50 36.6667V16.6667L40 6.66667L30 16.6667V23.3333H10V70H70V36.6667H50ZM23.3333 63.3333H16.6667V56.6667H23.3333V63.3333ZM23.3333 50H16.6667V43.3333H23.3333V50ZM23.3333 36.6667H16.6667V30H23.3333V36.6667ZM43.3333 63.3333H36.6667V56.6667H43.3333V63.3333ZM43.3333 50H36.6667V43.3333H43.3333V50ZM43.3333 36.6667H36.6667V30H43.3333V36.6667ZM43.3333 23.3333H36.6667V16.6667H43.3333V23.3333ZM63.3333 63.3333H56.6667V56.6667H63.3333V63.3333ZM63.3333 50H56.6667V43.3333H63.3333V50Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">{stats?.cities}</p>
                  <p className="text-2xl">Villes</p>
                </div>
              </div>

              {/* Audit DONE  */}
              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto pr-1.5"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 13.3333V17.9333C27.2333 16.8333 24.2667 16.2667 21.3 16.2667C15.3333 16.2667 9.36667 18.5333 4.8 23.1L15.9 34.2H19.6V37.9C22.4667 40.7667 26.2 42.2667 29.9667 42.4333V50H20V60C20 63.6667 23 66.6667 26.6667 66.6667H60C65.5333 66.6667 70 62.2 70 56.6667V13.3333H30ZM26.3 34.7V27.5333H18.7L15.2333 24.0667C17.1333 23.3333 19.2 22.9333 21.3 22.9333C25.7667 22.9333 29.9333 24.6667 33.1 27.8L37.8 32.5L37.1333 33.1667C35.4333 34.8667 33.1667 35.8333 30.7333 35.8333C29.1667 35.8333 27.6333 35.4333 26.3 34.7ZM63.3333 56.6667C63.3333 58.5 61.8333 60 60 60C58.1667 60 56.6667 58.5 56.6667 56.6667V50H36.6667V41.3667C38.5667 40.6 40.3333 39.4667 41.8667 37.9333L42.5333 37.2667L51.9667 46.6667H56.6667V41.9667L36.6667 22.0667V20H63.3333V56.6667Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.achievedAudits}
                  </p>
                  <p className="text-2xl">
                    Demandes <br /> d&apos;audit réalisées
                  </p>
                </div>
              </div>

              {/* Submitted Audits */}

              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 50C21.1 50 3.33333 54.4667 3.33333 63.3333V70H56.6667V63.3333C56.6667 54.4667 38.9 50 30 50Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M73.6667 22.8C73.7 22.4333 73.7333 22.0667 73.7333 21.6667C73.7333 21.2667 73.7 20.9 73.6333 20.5333L76.1 18.6C76.3333 18.4333 76.3667 18.1 76.2333 17.8667L73.9 13.8333C73.7667 13.5667 73.4333 13.5 73.2 13.5667L70.3333 14.7333C69.7333 14.2667 69.0667 13.9 68.3667 13.6L67.9333 10.5C67.8667 10.2 67.6333 10 67.3333 10H62.6667C62.3667 10 62.1333 10.2 62.1 10.5L61.6667 13.6C60.9667 13.9 60.3 14.3 59.7 14.7333L56.8 13.5667C56.5333 13.4667 56.2333 13.5667 56.1 13.8333L53.7667 17.8667C53.6333 18.1333 53.6667 18.4333 53.9 18.6L56.3667 20.5333C56.3 20.9 56.2667 21.3 56.2667 21.6667C56.2667 22.0333 56.3 22.4333 56.3667 22.8L53.9 24.7333C53.6667 24.9 53.6333 25.2333 53.7667 25.4667L56.1 29.5C56.2333 29.7667 56.5667 29.8333 56.8 29.7667L59.7 28.6C60.3 29.0667 60.9667 29.4333 61.6667 29.7333L62.1 32.8333C62.1333 33.1333 62.3667 33.3333 62.6667 33.3333H67.3333C67.6333 33.3333 67.8667 33.1333 67.9 32.8333L68.3333 29.7333C69.0333 29.4333 69.7 29.0333 70.3 28.6L73.2 29.7667C73.4667 29.8667 73.7667 29.7667 73.9 29.5L76.2333 25.4667C76.3667 25.2 76.3333 24.9 76.1 24.7333L73.6667 22.8ZM65 25.8333C62.7 25.8333 60.8333 23.9667 60.8333 21.6667C60.8333 19.3667 62.7 17.5 65 17.5C67.3 17.5 69.1667 19.3667 69.1667 21.6667C69.1667 23.9667 67.3 25.8333 65 25.8333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M66.4 38.9333L64.7333 36.0333C64.6333 35.8333 64.4 35.7667 64.2333 35.8333L62.1667 36.6667C61.7333 36.3333 61.2667 36.0667 60.7667 35.8667L60.4667 33.6667C60.4 33.4667 60.2 33.3333 60 33.3333H56.6667C56.4667 33.3333 56.3 33.4667 56.2667 33.7L55.9667 35.9C55.4667 36.1 55 36.4 54.5667 36.7L52.5 35.8667C52.3 35.8 52.1 35.8667 52 36.0667L50.3333 38.9667C50.2333 39.1667 50.2667 39.3667 50.4333 39.5L52.2 40.8667C52.1667 41.1333 52.1333 41.4 52.1333 41.6667C52.1333 41.9333 52.1667 42.2333 52.2 42.4667L50.4333 43.8333C50.2667 43.9667 50.2333 44.2 50.3333 44.3667L52 47.2667C52.1 47.4667 52.3333 47.5333 52.5 47.4667L54.5667 46.6333C55 46.9667 55.4667 47.2333 55.9667 47.4333L56.2667 49.6333C56.3 49.8667 56.4667 50 56.6667 50H60C60.2 50 60.4 49.8667 60.4 49.6333L60.7 47.4333C61.2 47.2333 61.6667 46.9333 62.1 46.6333L64.1667 47.4667C64.3667 47.5333 64.5667 47.4667 64.6667 47.2667L66.3333 44.3667C66.4333 44.1667 66.4 43.9667 66.2333 43.8333L64.5 42.4667C64.5333 42.2 64.5667 41.9333 64.5667 41.6667C64.5667 41.4 64.5333 41.1 64.5 40.8667L66.2667 39.5C66.4333 39.3667 66.4667 39.1333 66.4 38.9333ZM58.3333 44.4333C56.8 44.4333 55.5667 43.1667 55.5667 41.6667C55.5667 40.1333 56.8333 38.9 58.3333 38.9C59.8333 38.9 61.1 40.1667 61.1 41.6667C61.1 43.2 59.8667 44.4333 58.3333 44.4333Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M15.8 30H44.2333C45.1333 30 45.8667 29.2667 45.8667 28.3667V28.3C45.8667 27.4 45.1333 26.6667 44.2333 26.6667H43.3333C43.3333 21.7333 40.6333 17.5 36.6667 15.1667V18.3333C36.6667 19.2667 35.9333 20 35 20C34.0667 20 33.3333 19.2667 33.3333 18.3333V13.8C32.2667 13.5333 31.1667 13.3333 30 13.3333C28.8333 13.3333 27.7333 13.5333 26.6667 13.8V18.3333C26.6667 19.2667 25.9333 20 25 20C24.0667 20 23.3333 19.2667 23.3333 18.3333V15.1667C19.3667 17.5 16.6667 21.7333 16.6667 26.6667H15.8C14.9 26.6667 14.1667 27.4 14.1667 28.3V28.4C14.1667 29.2667 14.9 30 15.8 30Z"
                        fill="#DC3838"
                      />
                      <path
                        d="M30 43.3333C36.2 43.3333 41.3667 39.0667 42.8667 33.3333H17.1333C18.6333 39.0667 23.8 43.3333 30 43.3333Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.submittedAudits}
                  </p>
                  <p className="text-2xl">
                    {' '}
                    Demandes <br /> d&apos;audit soumises
                  </p>
                </div>
              </div>

              {/* Audit In progress */}
              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto pr-1.5"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 13.3333V17.9333C27.2333 16.8333 24.2667 16.2667 21.3 16.2667C15.3333 16.2667 9.36667 18.5333 4.8 23.1L15.9 34.2H19.6V37.9C22.4667 40.7667 26.2 42.2667 29.9667 42.4333V50H20V60C20 63.6667 23 66.6667 26.6667 66.6667H60C65.5333 66.6667 70 62.2 70 56.6667V13.3333H30ZM26.3 34.7V27.5333H18.7L15.2333 24.0667C17.1333 23.3333 19.2 22.9333 21.3 22.9333C25.7667 22.9333 29.9333 24.6667 33.1 27.8L37.8 32.5L37.1333 33.1667C35.4333 34.8667 33.1667 35.8333 30.7333 35.8333C29.1667 35.8333 27.6333 35.4333 26.3 34.7ZM63.3333 56.6667C63.3333 58.5 61.8333 60 60 60C58.1667 60 56.6667 58.5 56.6667 56.6667V50H36.6667V41.3667C38.5667 40.6 40.3333 39.4667 41.8667 37.9333L42.5333 37.2667L51.9667 46.6667H56.6667V41.9667L36.6667 22.0667V20H63.3333V56.6667Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.inProgressAudits}
                  </p>
                  <p className="text-2xl">
                    Demandes <br /> d&apos;audit en cours
                  </p>
                </div>
              </div>

              {/* Audit In progress of validation */}
              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto pr-1.5"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 13.3333V17.9333C27.2333 16.8333 24.2667 16.2667 21.3 16.2667C15.3333 16.2667 9.36667 18.5333 4.8 23.1L15.9 34.2H19.6V37.9C22.4667 40.7667 26.2 42.2667 29.9667 42.4333V50H20V60C20 63.6667 23 66.6667 26.6667 66.6667H60C65.5333 66.6667 70 62.2 70 56.6667V13.3333H30ZM26.3 34.7V27.5333H18.7L15.2333 24.0667C17.1333 23.3333 19.2 22.9333 21.3 22.9333C25.7667 22.9333 29.9333 24.6667 33.1 27.8L37.8 32.5L37.1333 33.1667C35.4333 34.8667 33.1667 35.8333 30.7333 35.8333C29.1667 35.8333 27.6333 35.4333 26.3 34.7ZM63.3333 56.6667C63.3333 58.5 61.8333 60 60 60C58.1667 60 56.6667 58.5 56.6667 56.6667V50H36.6667V41.3667C38.5667 40.6 40.3333 39.4667 41.8667 37.9333L42.5333 37.2667L51.9667 46.6667H56.6667V41.9667L36.6667 22.0667V20H63.3333V56.6667Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.processingValidationAudits}
                  </p>
                  <p className="text-2xl">
                    Demandes <br /> d&apos;audit en cours de validation
                  </p>
                </div>
              </div>
              {/* Audit validate */}
              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto pr-1.5"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 13.3333V17.9333C27.2333 16.8333 24.2667 16.2667 21.3 16.2667C15.3333 16.2667 9.36667 18.5333 4.8 23.1L15.9 34.2H19.6V37.9C22.4667 40.7667 26.2 42.2667 29.9667 42.4333V50H20V60C20 63.6667 23 66.6667 26.6667 66.6667H60C65.5333 66.6667 70 62.2 70 56.6667V13.3333H30ZM26.3 34.7V27.5333H18.7L15.2333 24.0667C17.1333 23.3333 19.2 22.9333 21.3 22.9333C25.7667 22.9333 29.9333 24.6667 33.1 27.8L37.8 32.5L37.1333 33.1667C35.4333 34.8667 33.1667 35.8333 30.7333 35.8333C29.1667 35.8333 27.6333 35.4333 26.3 34.7ZM63.3333 56.6667C63.3333 58.5 61.8333 60 60 60C58.1667 60 56.6667 58.5 56.6667 56.6667V50H36.6667V41.3667C38.5667 40.6 40.3333 39.4667 41.8667 37.9333L42.5333 37.2667L51.9667 46.6667H56.6667V41.9667L36.6667 22.0667V20H63.3333V56.6667Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.validatedAudits}
                  </p>
                  <p className="text-2xl">
                    Demandes <br /> d&apos;audit validées
                  </p>
                </div>
              </div>

              {/* Audit waiting */}
              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto pr-1.5"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 13.3333V17.9333C27.2333 16.8333 24.2667 16.2667 21.3 16.2667C15.3333 16.2667 9.36667 18.5333 4.8 23.1L15.9 34.2H19.6V37.9C22.4667 40.7667 26.2 42.2667 29.9667 42.4333V50H20V60C20 63.6667 23 66.6667 26.6667 66.6667H60C65.5333 66.6667 70 62.2 70 56.6667V13.3333H30ZM26.3 34.7V27.5333H18.7L15.2333 24.0667C17.1333 23.3333 19.2 22.9333 21.3 22.9333C25.7667 22.9333 29.9333 24.6667 33.1 27.8L37.8 32.5L37.1333 33.1667C35.4333 34.8667 33.1667 35.8333 30.7333 35.8333C29.1667 35.8333 27.6333 35.4333 26.3 34.7ZM63.3333 56.6667C63.3333 58.5 61.8333 60 60 60C58.1667 60 56.6667 58.5 56.6667 56.6667V50H36.6667V41.3667C38.5667 40.6 40.3333 39.4667 41.8667 37.9333L42.5333 37.2667L51.9667 46.6667H56.6667V41.9667L36.6667 22.0667V20H63.3333V56.6667Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">{stats?.pendingAudits}</p>
                  <p className="text-2xl">
                    Demandes <br /> d&apos;audit en attente
                  </p>
                </div>
              </div>

              {/* Terminated Audits */}

              <div className="min-h-72 group relative flex h-full flex-col items-center border border-primary p-10 py-16 text-center text-primary transition-all duration-300">
                <div className="m-auto">
                  <span>
                    <svg
                      className="mx-auto pr-1.5"
                      width="80"
                      height="80"
                      viewBox="0 0 80 80"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M30 13.3333V17.9333C27.2333 16.8333 24.2667 16.2667 21.3 16.2667C15.3333 16.2667 9.36667 18.5333 4.8 23.1L15.9 34.2H19.6V37.9C22.4667 40.7667 26.2 42.2667 29.9667 42.4333V50H20V60C20 63.6667 23 66.6667 26.6667 66.6667H60C65.5333 66.6667 70 62.2 70 56.6667V13.3333H30ZM26.3 34.7V27.5333H18.7L15.2333 24.0667C17.1333 23.3333 19.2 22.9333 21.3 22.9333C25.7667 22.9333 29.9333 24.6667 33.1 27.8L37.8 32.5L37.1333 33.1667C35.4333 34.8667 33.1667 35.8333 30.7333 35.8333C29.1667 35.8333 27.6333 35.4333 26.3 34.7ZM63.3333 56.6667C63.3333 58.5 61.8333 60 60 60C58.1667 60 56.6667 58.5 56.6667 56.6667V50H36.6667V41.3667C38.5667 40.6 40.3333 39.4667 41.8667 37.9333L42.5333 37.2667L51.9667 46.6667H56.6667V41.9667L36.6667 22.0667V20H63.3333V56.6667Z"
                        fill="#DC3838"
                      />
                    </svg>
                  </span>
                  <p className="text-4xl font-medium">
                    {stats?.terminatedAudits}
                  </p>
                  <p className="text-2xl">
                    Demandes <br /> d&apos;audit terminées
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Dashboard;
