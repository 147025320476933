import { Text } from '@nextui-org/react';
import React from 'react';

const Conditions = () => {
  return (
    <div className="prose m-auto my-16 max-w-7xl">
      <Text className="prose-headings:text-lg" h1>
        Conditions
      </Text>

      <Text className="prose-body:text-lg" p>
        Les conditions générales ici.
      </Text>
    </div>
  );
};

export default Conditions;
