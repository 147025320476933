/* eslint-disable react/no-unknown-property */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/label-has-associated-control */
import '@uppy/core/dist/style.css';
import '@uppy/dashboard/dist/style.css';

import {
  Button,
  Card,
  Collapse,
  Grid,
  Input,
  Loading,
  Radio,
  Spacer,
  Switch,
  Text,
  Textarea,
} from '@nextui-org/react';
import Uppy from '@uppy/core';
import French from '@uppy/locales/lib/fr_FR';
import { Dashboard } from '@uppy/react';
import StatusBar from '@uppy/status-bar';
import ThumbnailGenerator from '@uppy/thumbnail-generator';
import axios from 'axios';
import _ from 'lodash';
import { nanoid } from 'nanoid';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import IconBuilding from '../components/icons/iconBuilding';
import IconEdit from '../components/icons/IconEdit';
import IconHome from '../components/icons/IconHome';
import IconPosition from '../components/icons/IconLocation';
import IconMail from '../components/icons/IconMail';
import IconPcDisplay from '../components/icons/IconPcDisplay';
import IconUser from '../components/icons/IconUser';
import LinearIndicatorIcon from '../components/icons/LinearIndicatorIcon';
import IconPhone from '../components/icons/PhoneIcon';
import { getAudit, getMesurerAudit } from '../redux/api/audit';
import {
  updateInspectorReport,
  updateMesurerReport,
} from '../redux/api/inspector';
import { expired, loginReset } from '../redux/reducers/userReducer';
import { renderCharacter } from '../utils/common';

const LoadingContainer = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-gray-50">
      <Loading
        loadingCss={{
          $$loadingSize: '100px',
          $$loadingBorder: '10px',
          $$LoadingColors: '#DC3838',
        }}
      />
    </div>
  );
};
const errorBase64 =
  'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz4KPHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMzAwcHgiIHdpZHRoPSIzMDBweCIgdmVyc2lvbj0iMS4wIiB2aWV3Qm94PSItMzAwIC0zMDAgNjAwIDYwMCIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxjaXJjbGUgc3Ryb2tlPSIjQUFBIiBzdHJva2Utd2lkdGg9IjEwIiByPSIyODAiIGZpbGw9IiNGRkYiLz4KPHRleHQgc3R5bGU9ImxldHRlci1zcGFjaW5nOjE7dGV4dC1hbmNob3I6bWlkZGxlO3RleHQtYWxpZ246Y2VudGVyO3N0cm9rZS1vcGFjaXR5Oi41O3N0cm9rZTojMDAwO3N0cm9rZS13aWR0aDoyO2ZpbGw6IzQ0NDtmb250LXNpemU6MzYwcHg7Zm9udC1mYW1pbHk6Qml0c3RyZWFtIFZlcmEgU2FucyxMaWJlcmF0aW9uIFNhbnMsIEFyaWFsLCBzYW5zLXNlcmlmO2xpbmUtaGVpZ2h0OjEyNSU7d3JpdGluZy1tb2RlOmxyLXRiOyIgdHJhbnNmb3JtPSJzY2FsZSguMikiPgo8dHNwYW4geT0iLTQwIiB4PSI4Ij5QQVMgROKAmUlNQUdFPC90c3Bhbj4KPHRzcGFuIHk9IjQwMCIgeD0iOCI+RElTUE9OSUJMRTwvdHNwYW4+CjwvdGV4dD4KPC9zdmc+Cg==';
const UpdateReport = () => {
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const dispatch = useDispatch();
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigator = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [audit, setAudit] = useState(null);
  const [message, setMessage] = useState(null);
  const [list, setList] = useState([]);
  const [loadingOnDownload, setloadingOnDownload] = useState(false);
  const { isMesurer, isInspector } = useSelector((state) => state.user);

  function closeNotif() {
    setTimeout(() => {
      setTimeout(() => {
        setShowNotification(false);
      }, 300);
    }, 100);
  }
  const showNotif = (text, type) => {
    setTimeout(() => {
      setNotification({
        text,
        type,
      });
      setTimeout(() => {
        setShowNotification(true);
      }, 100);
    }, 500);

    setTimeout(() => {
      setNotification({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 6000);
    }, 5000);
  };
  const [inputs, setInputs] = useState({});

  // console.log('inputs: ', inputs);

  const [files, setFiles] = useState([]);
  const [filesHome, setFilesHome] = useState([]);
  const [filesWindows, setFilesWindows] = useState([]);
  const [filesWindowsDoors, setFilesWindowsDoors] = useState([]);
  const [filesDoors, setFilesDoors] = useState([]);
  const [helper, setHelper] = useState({
    applicant: {
      text: '',
      color: '',
    },
    lastname: {
      text: '',
      color: '',
    },
    firstname: {
      text: '',
      color: '',
    },
    siret: {
      text: '',
      color: '',
    },
    address: {
      text: '',
      color: '',
    },
    phone: {
      text: '',
      color: '',
    },
    tva: {
      text: '',
      color: '',
    },
    email: {
      text: '',
      color: '',
    },
    contactDiagnostic: {
      text: '',
      color: '',
    },
    addressDiagnostic: {
      text: '',
      color: '',
    },
    phoneDiagnostic: {
      text: '',
      color: '',
    },
    emailDiagnostic: {
      text: '',
      color: '',
    },
    contactAboutSite: {
      text: '',
      color: '',
    },
    addressAboutSite: {
      text: '',
      color: '',
    },
    codePorte: {
      text: '',
      color: '',
    },
    codePortail: {
      text: '',
      color: '',
    },
    accommodation: {
      text: '',
      color: '',
    },
    totaleSurface: {
      text: '',
      color: '',
    },
    floorNumber: {
      text: '',
      color: '',
    },
    manufactureYear: {
      text: '',
      color: '',
    },
    dateInstallation: {
      text: '',
      color: '',
    },
    workKind: {
      text: '',
      color: '',
    },
    files: {
      text: '',
      type: '',
    },
    windowSurface: {
      text: '',
      type: '',
    },
    windowNumbers: {
      text: '',
      type: '',
    },
    windowWeight: {
      text: '',
      type: '',
    },
    doorSurface: {
      text: '',
      type: '',
    },
    doorNumbers: {
      text: '',
      type: '',
    },
    windowDoorWeight: {
      text: '',
      type: '',
    },
    doorEntranceSurface: {
      text: '',
      type: '',
    },
    doorEntranceNumbers: {
      text: '',
      type: '',
    },
    numberEastSideWall: {
      text: '',
      type: '',
    },
    numberNorthSideWall: {
      text: '',
      type: '',
    },
    numberSouthSideWall: {
      text: '',
      type: '',
    },
    numberWestSideWall: {
      text: '',
      type: '',
    },
    underground: {
      text: '',
      type: '',
    },
    atticUnheated: {
      text: '',
      type: '',
    },
    roof: {
      text: '',
      type: '',
    },
    typeFloor: {
      text: '',
      type: '',
    },
    lowFloor: {
      text: '',
      type: '',
    },
    highFloor: {
      text: '',
      type: '',
    },
    atticFloor: {
      text: '',
      type: '',
    },
    filesHome: {
      text: '',
      type: '',
    },
    filesWindows: {
      text: '',
      type: '',
    },
    filesWindowsDoors: {
      text: '',
      type: '',
    },
    filesDoors: {
      text: '',
      type: '',
    },
    unitToConnect: {
      text: '',
      type: '',
    },
  });
  const resetHelpers = (e) => {
    if (e.target.name === 'applicant')
      setHelper((prev) => {
        return {
          ...prev,
          applicant: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'lastname')
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'firstname')
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'email')
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'phone')
      setHelper((prev) => {
        return {
          ...prev,
          phone: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'address')
      setHelper((prev) => {
        return {
          ...prev,
          address: {
            text: '',
            color: '',
          },
        };
      });
  };

  const uppy = useMemo(() => {
    return new Uppy({
      locale: French,
      debug: false,
      restrictions: {
        maxFileSize: 10000000,
        allowedFileTypes: ['image/png'],
      },
      allowMultipleUploads: true,
      autoProceed: false,
      id: 'files',
    })
      .use(StatusBar, {
        hideUploadButton: true,
        hideAfterFinish: true,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 200,
        // thumbnailHeight: 200 // optional, use either width or height,
        waitForThumbnailsBeforeUpload: true,
      })
      .on('thumbnail:generated', (file) => {
        const newList = files.concat({ file });
        setFiles(newList);
      })
      .on('file-added', (file) => {
        const newList = files.concat({ file });
        setFiles(newList);
      });
  }, []);

  const uppy_files_home = useMemo(() => {
    return new Uppy({
      locale: French,
      debug: false,
      restrictions: {
        maxFileSize: 10000000,
        allowedFileTypes: ['image/png'],
      },
      allowMultipleUploads: true,
      autoProceed: false,
      id: 'files_home',
    })
      .use(StatusBar, {
        hideUploadButton: true,
        hideAfterFinish: true,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 200,
        // thumbnailHeight: 200 // optional, use either width or height,
        waitForThumbnailsBeforeUpload: true,
      })
      .on('thumbnail:generated', (file) => {
        const newList = filesHome.concat({ file });
        setFilesHome(newList);
      })
      .on('file-added', (file) => {
        const newList = filesHome.concat({ file });
        setFilesHome(newList);
      });
  }, []);

  const uppy_files_windows = useMemo(() => {
    return new Uppy({
      locale: French,
      debug: false,
      restrictions: {
        maxFileSize: 10000000,
        allowedFileTypes: ['image/png'],
      },
      allowMultipleUploads: true,
      autoProceed: false,
      id: 'files_windows',
    })
      .use(StatusBar, {
        hideUploadButton: true,
        hideAfterFinish: true,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 200,
        // thumbnailHeight: 200 // optional, use either width or height,
        waitForThumbnailsBeforeUpload: true,
      })
      .on('thumbnail:generated', (file) => {
        const newList = filesWindows.concat({ file });
        setFilesWindows(newList);
      })
      .on('file-added', (file) => {
        const newList = filesWindows.concat({ file });
        setFilesWindows(newList);
      });
  }, []);

  const uppy_files_roof = useMemo(() => {
    return new Uppy({
      locale: French,
      debug: false,
      restrictions: {
        maxFileSize: 10000000,
        allowedFileTypes: ['image/png'],
      },
      allowMultipleUploads: true,
      autoProceed: false,
      id: 'files_windows_doors',
    })
      .use(StatusBar, {
        hideUploadButton: true,
        hideAfterFinish: true,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 200,
        // thumbnailHeight: 200 // optional, use either width or height,
        waitForThumbnailsBeforeUpload: true,
      })
      .on('thumbnail:generated', (file) => {
        const newList = filesWindowsDoors.concat({ file });
        setFilesWindowsDoors(newList);
      })
      .on('file-added', (file) => {
        const newList = filesWindowsDoors.concat({ file });
        setFilesWindowsDoors(newList);
      });
  }, []);

  const uppy_files_doors = useMemo(() => {
    return new Uppy({
      locale: French,
      debug: false,
      restrictions: {
        maxFileSize: 10000000,
        allowedFileTypes: ['image/png'],
      },
      allowMultipleUploads: true,
      autoProceed: false,
      id: 'files_doors',
    })
      .use(StatusBar, {
        hideUploadButton: true,
        hideAfterFinish: true,
      })
      .use(ThumbnailGenerator, {
        thumbnailWidth: 200,
        // thumbnailHeight: 200 // optional, use either width or height,
        waitForThumbnailsBeforeUpload: true,
      })
      .on('thumbnail:generated', (file) => {
        const newList = filesDoors.concat({ file });
        setFilesDoors(newList);
      })
      .on('file-added', (file) => {
        const newList = filesDoors.concat({ file });
        setFilesDoors(newList);
      });
  }, []);

  const uppy_params = {
    hideCancelButton: true,
    hideUploadButton: true,
    proudlyDisplayPoweredByUppy: false,
    note: "Tous les 5 fichiers, jusqu'à 5 MB",
    metaFields: [{ id: 'name', name: 'Nom', placeholder: 'Nom du fichier' }],
  };

  const DEFAULT_LIST_FIELDS = {
    applicant: [
      {
        name: 'Syndicat',
        value: 'syndic',
      },
      {
        name: 'Propriétaire',
        value: 'propriétaire',
      },
      {
        name: 'Locataire',
        value: 'locataire',
      },
      {
        name: 'Autre',
        value: 'autre',
      },
    ],
    contactDetails: [
      {
        icon: <IconUser />,
        type: 'text',
        name: 'lastname',
        label: 'Nom',
      },
      {
        icon: <IconUser />,
        type: 'text',
        name: 'firstname',
        label: 'Prénom(s)',
      },
      {
        icon: <IconEdit />,
        type: 'text',
        name: 'siret',
        label: 'Siret',
      },
      {
        icon: <IconPosition />,
        type: 'text',
        name: 'address',
        label: 'Adresse',
      },
      {
        icon: <IconPhone />,
        type: 'tel',
        name: 'phone',
        label: 'Téléphone',
      },
      {
        icon: <IconEdit />,
        type: 'text',
        name: 'tva',
        label: 'TVA',
      },
      {
        icon: <IconMail />,
        type: 'email',
        name: 'email',
        label: 'Email',
      },
    ],
    contactDiagnostic: [
      {
        icon: <IconUser />,
        type: 'text',
        name: 'contactDiagnostic',
        label: 'Contact',
      },
      {
        icon: <IconPosition />,
        type: 'text',
        name: 'addressDiagnostic',
        label: 'Adresse',
      },
      {
        icon: <IconPhone />,
        type: 'tel',
        name: 'phoneDiagnostic',
        label: 'Téléphone',
      },
      {
        icon: <IconMail />,
        type: 'email',
        name: 'emailDiagnostic',
        label: 'Email',
      },
    ],
    aboutSite: [
      {
        icon: <IconUser />,
        name: 'contactAboutSite',
        type: 'text',
        label: 'Contact',
      },
      {
        icon: <IconPosition />,
        name: 'addressAboutSite',
        type: 'text',
        label: 'Adresse',
      },
      {
        icon: <IconEdit />,
        name: 'codePorte',
        label: 'Code porte',
      },
      {
        icon: <IconEdit />,
        name: 'codePortail',
        type: 'text',
        label: 'Code Portail',
      },
    ],
    typeAccommodation: {
      type: [
        {
          icon: <IconBuilding />,
          value: 'appartement',
          label: 'Appartement',
        },
        {
          icon: <IconHome />,
          value: 'maison',
          label: 'Maison',
        },
        {
          icon: '',
          value: 'autre',
          label: 'Autre',
        },
      ],
      accommodation: [
        {
          icon: <IconEdit />,
          type: 'number',
          name: 'totaleSurface',
          label: 'Surface totale',
        },
        {
          icon: <IconEdit />,
          type: 'number',
          name: 'floorNumber',
          label: "Nombre d'étages",
        },
      ],
    },
    heatingType: {
      type: [
        {
          name: 'Gaz',
          value: 'gaz',
        },
        {
          name: 'Elec',
          value: 'elec',
        },
        {
          name: 'Pompe à chaleur',
          value: 'pompe à chaleur',
        },
        {
          name: 'Fioul',
          value: 'fioul',
        },
        {
          name: 'Bois',
          value: 'bois',
        },
        {
          name: 'Autre',
          value: 'autre',
        },
      ],
      moreDetails: [
        {
          icon: '',
          type: 'date',
          name: 'manufactureYear',
          label: 'Date de fabrication',
        },
      ],
    },
    previousWork: [
      {
        icon: '',
        type: 'text',
        name: 'workKind',
        label: 'Quel type de travaux ?',
      },
    ],
    windows: [
      {
        icon: <IconBuilding />,
        label: 'Surface de vitres',
        groupType: 'classic_input',
        name: '',
        inputs: [
          {
            name: 'windowSurface',
            type: 'number',
            label: 'Renseignez ici la surface des vitres',
          },
          {
            name: 'windowNumbers',
            type: 'number',
            label: 'Nombre de surface de vitres',
          },
        ],
      },
      {
        icon: <IconBuilding />,
        label: 'Type de vitrage',
        groupType: 'radio',
        name: 'glazing',
        inputs: [
          {
            value: 'Simple',
            name: 'Simple',
          },
          {
            value: 'Double',
            name: 'Double',
          },
        ],
      },
      {
        icon: <IconBuilding />,
        label: 'Épaisseur des vitres',
        groupType: 'classic_input',
        name: '',
        inputs: [
          {
            name: 'windowWeight',
            type: 'number',
            label: "Renseignez ici l'épaisseur des vitres",
          },
        ],
      },
      {
        icon: <IconBuilding />,
        label: 'Type de huissierie',
        groupType: 'radio',
        name: 'frame',
        inputs: [
          {
            value: 'métallique',
            name: 'Aluminium',
          },
          {
            value: 'plastique',
            name: 'PVC',
          },
          {
            value: 'Bois',
            name: 'Bois',
          },
          {
            value: 'Autre',
            name: 'Autre',
          },
        ],
      },
    ],
    doors: {
      id: 'doors',
      name: 'Portes fenêtres',
      fields: [
        {
          icon: <IconBuilding />,
          label: 'Surface de portes vitrées',
          groupType: 'classic_input',
          name: '',
          inputs: [
            {
              name: 'doorSurface',
              type: 'number',
              label: 'Renseignez ici la surface des portes vitrées',
            },
            {
              name: 'doorNumbers',
              type: 'number',
              label: 'Nombre de surface des portes vitrées',
            },
          ],
        },
        {
          icon: <IconBuilding />,
          label: 'Type de vitrages',
          groupType: 'radio',
          name: 'doorGlazing',
          inputs: [
            {
              value: 'Simple',
              name: 'Simple',
            },
            {
              value: 'Double',
              name: 'Double',
            },
          ],
        },
        {
          icon: <IconBuilding />,
          label: 'Epaisseur des vitres',
          groupType: 'classic_input',
          inputs: [
            {
              name: 'windowDoorWeight',
              type: 'number',
              label: "Renseignez ici l'épaisseur des vitres",
            },
          ],
        },
        {
          icon: <IconBuilding />,
          label: 'Type de huissierie',
          groupType: 'radio',
          name: 'doorFrame',
          inputs: [
            {
              value: 'métallique',
              name: 'Aluminium',
            },
            {
              value: 'plastique',
              name: 'PVC',
            },
            {
              value: 'Bois',
              name: 'Bois',
            },
            {
              value: 'Autre',
              name: 'Autre',
            },
          ],
        },
      ],
    },
    doorsEntrance: {
      id: 'doorsEntrance',
      name: "Portes d'entrée",
      fields: [
        {
          icon: <IconBuilding />,
          label: "Surface des portes d'entrée",
          groupType: 'classic_input',
          name: '',
          inputs: [
            {
              name: 'doorEntranceSurface',
              type: 'number',
              label: "Renseignez ici la surface des portes d'entrée",
            },
            {
              name: 'doorEntranceNumbers',
              type: 'number',
              label: "Nombre de surface des portes d'entrée",
            },
          ],
        },
      ],
    },
    walls: {
      id: 'walls',
      name: 'Murs',
      fields: [
        {
          icon: <IconBuilding />,
          label: 'Surface mur côté EST',
          groupType: 'classic_input',
          name: 'eastWall',
          numberSideWall: {
            placeholder: 'Renseignez ici le nombre de mur côté EST',
            name: 'numberEastSideWall',
          },
          inputs: [],
        },
        {
          icon: <IconBuilding />,
          label: 'Surface mur côté NORD',
          groupType: 'classic_input',
          name: 'northWall',
          numberSideWall: {
            placeholder: 'Renseignez ici le nombre de mur côté NORD',
            name: 'numberNorthSideWall',
          },
          inputs: [],
        },
        {
          icon: <IconBuilding />,
          label: 'Surface mur côté SUD',
          groupType: 'classic_input',
          name: 'southWall',
          numberSideWall: {
            placeholder: 'Renseignez ici le nombre de mur côté SUD',
            name: 'numberSouthSideWall',
          },
          inputs: [],
        },
        {
          icon: <IconBuilding />,
          label: 'Surface mur côté OUEST',
          groupType: 'classic_input',
          name: 'westWall',
          numberSideWall: {
            placeholder: 'Renseignez ici le nombre de mur côté OUEST',
            name: 'numberWestSideWall',
          },
          inputs: [],
        },
      ],
    },
    area: {
      name: 'Zone non chauffée',
      id: 'area',
      fields: [
        {
          name: 'underground',
          label: 'Sous sol',
          type: 'switch',
          for: '',
        },
        {
          name: 'undergroundSurface',
          label: 'Surface du Sous sol',
          type: 'number',
          for: 'underground',
        },
        {
          name: 'atticUnheated',
          label: 'Comble',
          type: 'switch',
          for: '',
        },
        {
          name: 'atticUnheatedSurface',
          label: 'Surface du comble',
          type: 'number',
          for: 'atticUnheated',
        },
        {
          name: 'roof',
          label: 'Toiture',
          type: 'switch',
          for: '',
        },
        {
          name: 'roofSurface',
          label: 'Surface de la toiture',
          type: 'number',
          for: 'roof',
        },
      ],
    },
    floor: {
      name: 'Isolation',
      id: 'floor',
      fields: [
        {
          name: 'typeFloor',
          label: "Type d'isolation",
          type: 'text',
          for: '',
        },
        {
          name: 'lowFloor',
          label: 'Surface Plancher bas',
          type: 'switch',
          for: '',
        },
        {
          name: 'highFloor',
          label: 'Surface Plancher haut',
          type: 'switch',
          for: '',
        },
        {
          name: 'atticFloor',
          label: 'Surface Comble',
          type: 'switch',
          for: '',
        },
      ],
    },
    importPicture: [
      {
        id: 'preview-home',
        helper: 'files_home',
        name: 'Importez des photos de la maison',
        files: uppy_files_home,
        params: uppy_params,
      },
      {
        id: 'preview-windows',
        helper: 'files_windows',
        name: 'Importez des photos des fenêtres',
        files: uppy_files_windows,
        params: uppy_params,
      },
      {
        id: 'preview-doors',
        helper: 'files_doors',
        name: 'Importez des photos des portes',
        files: uppy_files_doors,
        params: uppy_params,
      },
      {
        id: 'preview-roof',
        helper: 'files_roofs',
        name: 'Importez des photos de la toiture',
        files: uppy_files_roof,
        params: uppy_params,
      },
      {
        id: 'preview-other',
        helper: 'files_others',
        name: "Importez d'autres photos",
        files: uppy,
        params: uppy_params,
      },
    ],
    installationType: {
      id: 'installationType',
      default: [
        [
          {
            value: 'climatiseur',
            name: 'Climatiseur',
          },
          {
            value: 'ballon thermodynamique',
            name: 'Ballon thermodynamique',
          },
        ],
        [
          {
            value: 'chambre froide',
            name: 'Chambre froide',
          },
          {
            value: 'groupe eau glacée',
            name: "Groupe d'eau glacée",
          },
        ],
        [
          {
            value: 'pompe à chaleur',
            name: 'Pompe à chaleur',
          },
          {
            value: 'gaz',
            name: 'Gaz',
          },
        ],
        [
          {
            value: 'chaudière fioul',
            name: 'Chaudière fioul',
          },
          {
            value: 'bois',
            name: 'Bois',
          },
        ],
        [
          {
            value: 'autre',
            name: 'Autre',
          },
        ],
      ],
      moreDetails: [
        {
          icon: '',
          type: 'date',
          name: 'dateInstallation',
          label: "Date d'installation",
        },
      ],
    },
  };
  const [walls, setWalls] = useState(DEFAULT_LIST_FIELDS.walls);
  const [listFields, setListFields] = useState(DEFAULT_LIST_FIELDS);

  const addFields = (numberOfLines, fieldName) => {
    // get walls content where name match fieldName
    const wallContent = walls.fields.find((wall) => wall.name === fieldName);
    const wallContentIndex = walls.fields.findIndex(
      (wall) => wall.name === fieldName
    );

    if (numberOfLines > 0 && wallContent.inputs.length < numberOfLines) {
      const leftLines = numberOfLines - wallContent.inputs.length;
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < leftLines; i++) {
        const idx = nanoid(6);
        wallContent.inputs.push({
          id: idx,
          wall: {
            name: `${idx}_wall`,
            group: fieldName,
            type: 'number',
            label: 'Renseignez la surface',
          },
          type: {
            name: `${idx}_type`,
            group: fieldName,
            type: 'select',
            list: [
              'parping',
              'brique',
              'mur non isolé',
              'béton',
              'bois',
              'autre',
            ],
            label: 'Type de mur',
          },
        });

        setHelper((prev) => {
          return {
            ...prev,
            [`${idx}_wall`]: {
              text: '',
              type: '',
            },
            [`${idx}_type`]: {
              text: '',
              type: '',
            },
          };
        });
      }

      const wallsList = walls;
      // update walls state
      wallsList.fields[wallContentIndex] = wallContent;
      setWalls(wallsList);
    }
  };

  const removeFields = (wall, wallIndex, index) => {
    const inputFields = [...wall.inputs];

    const field = inputFields[index];
    const idx = field.id;

    inputFields.splice(index, 1);

    const wallsList = walls;
    wallsList.fields[wallIndex].inputs = inputFields;
    setWalls((prev) => {
      return {
        ...prev,
        fields: wallsList.fields,
      };
    });

    setTimeout(() => {
      // remove inputs
      const inputKeys = Object.keys(inputs).filter((key) => key.includes(idx));

      // remove inputs keys
      inputKeys.forEach((key) => {
        delete inputs[key];
      });
      // find in helper keys that contain id
      const helperKeys = Object.keys(helper).filter((key) => key.includes(idx));

      // remove helper keys
      helperKeys.forEach((key) => {
        delete helper[key];
      });
    }, 500);
  };

  const handleChange = (e, fieldName) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);

    if (
      e.target.name === 'numberEastSideWall' ||
      e.target.name === 'numberNorthSideWall' ||
      e.target.name === 'numberSouthSideWall' ||
      e.target.name === 'numberWestSideWall'
    ) {
      addFields(e.target.value, fieldName);
    }
  };

  const styles = {
    backgroundColor: '#fff',
    width: '100%',
    height: 'auto',
    textAlign: 'center',
  };

  const renderDate = (datetoTransform) => {
    const date = new Date(datetoTransform);
    let day = date.getDate().toString();
    day = day.length > 1 ? day : `0${day}`;
    let month = (parseInt(date.getMonth().toString(), 10) + 1).toString();
    month = month.length > 1 ? month : `0${month}`;
    return `${date.getFullYear()}-${month}-${day}`;
  };

  const navigate = (route) => {
    navigator(route);
  };

  const loadData = (data) => {
    setAudit(data);

    let object = {
      ...inputs,
      applicant: data?.demandeur,
      lastname: renderCharacter(
        data?.client_info?.original?.data?.users?.prenom
      ),
      firstname: renderCharacter(data?.client_info?.original?.data?.users?.nom),
      address: data?.client_info?.original?.data?.users?.adresse,
      phone: data?.client_info?.original?.data?.users?.telephone,
      email: data?.client_info?.original?.data?.users?.email,
      contactDiagnostic: data?.contact_lieu_audit,
      addressDiagnostic: data?.adresse_lieu_audit,
      phoneDiagnostic: data?.telephone_lieu_audit,
      emailDiagnostic: data?.email_lieu_audit,
      contactAboutSite: data?.contact_ics,
      addressAboutSite: data?.adresse_ics,
      codePorte: data?.code_porte_ics,
      codePortail: data?.code_portail_ics,
      accommodation: data?.type_logement,
      totaleSurface: data?.surface_totale,
      floorNumber: data?.nbre_etage,
      typeHeating: data?.type_chauffage,
      manufactureYear: data?.annee_fabrication
        ? renderDate(data?.annee_fabrication)
        : '',
      workKind: renderCharacter(data?.type_de_travaux_effectuee),
      windowSurface: data?.vitrage_surface_vitre,
      windowNumbers: data?.vitrage_nombre_vitre,
      glazing: data?.vitrage_type_vitrage,
      windowWeight: data?.vitrage_epaisseur_vitre,
      frame: data?.vitrage_type_huisserie,
      doorSurface: data?.porte_fenetres_surface_portes_vitre,
      doorGlazing: data?.porte_fenetres_type_vitrage,
      windowDoorWeight: data?.porte_fenetres_epaisseur_vitre,
      doorFrame: data?.porte_fenetres_type_huisserie,
      doorNumbers: data?.porte_fenetres_nombre_portes_vitre,
      doorEntranceSurface: data?.porte_entree_surface,
      doorEntranceNumbers: data?.porte_entree_surface_nbre_porte,
      typeInstallation: data?.type_installation,
      dateInstallation: data?.date_installation
        ? renderDate(data?.date_installation)
        : '',
      hasRecentWork: data?.confirm_travaux_effectuee === 1,
      underground: data?.zone_non_chauffee_sous_sol,
      undergroundSurface: data?.zone_non_chauffee_sous_sol_surface,
      roof: data?.zone_non_chauffee_toiture,
      roofSurface: data?.zone_non_chauffee_toiture_surface,
      atticUnheated: data?.zone_non_chauffee_comble,
      atticUnheatedSurface: data?.zone_non_chauffee_comble_surface,
      unitToConnect: data?.nbre_apparail_control,
      typeFloor: data?.type_isolation,
      lowFloor: data?.isolation_plancher_bas,
      highFloor: data?.isolation_plancher_haut,
      atticFloor: data?.isolation_comblee,
      isEpiredDate: data?.isEpiredDate,
    };

    setInputs(object);
    setLoading(false);

    setTimeout(() => {
      if (data?.mure_cote_est?.length) {
        const group = 'eastWall';
        let position = 0;

        const wallArray = JSON.parse(data?.mure_cote_est);
        wallArray?.forEach((obj) => {
          // eslint-disable-next-line no-plusplus
          position++;

          // get walls content where name match fieldName
          const wallContent = walls.fields.find((wall) => wall.name === group);
          const wallContentIndex = walls.fields.findIndex(
            (wall) => wall.name === group
          );

          const idx = nanoid(6);
          wallContent.inputs.push({
            id: idx,
            wall: {
              name: `${idx}_wall`,
              group,
              type: 'number',
              label: 'Renseignez la surface',
            },
            type: {
              name: `${idx}_type`,
              group,
              type: 'select',
              list: [
                'parping',
                'brique',
                'mur non isolé',
                'béton',
                'bois',
                'autre',
              ],
              label: 'Type de mur',
            },
          });

          setHelper((prev) => {
            return {
              ...prev,
              [`${idx}_wall`]: {
                text: '',
                type: '',
              },
              [`${idx}_type`]: {
                text: '',
                type: '',
              },
            };
          });

          const wallsList = walls;
          // update walls state
          wallsList.fields[wallContentIndex] = wallContent;
          setWalls(wallsList);

          setTimeout(() => {
            object = { ...object, numberEastSideWall: wallArray.length };

            // add all object key to inputs state
            Object.keys(obj).forEach((key) => {
              if (key === 'type') {
                if (
                  ![
                    'parping',
                    'brique',
                    'mur non isolé',
                    'béton',
                    'bois',
                    'autre',
                  ].includes(obj[key])
                ) {
                  object = {
                    ...object,
                    [`${idx}_type`]: 'autre',
                    [`${idx}_type_other`]: obj[key],
                  };
                } else {
                  object = { ...object, [`${idx}_type`]: obj[key] };
                }
              } else {
                object = { ...object, [`${idx}_wall`]: obj[key] };
              }
            });

            setInputs(object);
          }, 500);
        });
      }
      if (data?.mure_cote_nord?.length) {
        const group = 'northWall';
        let position = 0;

        const wallArray = JSON.parse(data?.mure_cote_nord);
        wallArray?.forEach((obj) => {
          // eslint-disable-next-line no-plusplus
          position++;

          // get walls content where name match fieldName
          const wallContent = walls.fields.find((wall) => wall.name === group);
          const wallContentIndex = walls.fields.findIndex(
            (wall) => wall.name === group
          );

          const idx = nanoid(6);
          wallContent.inputs.push({
            id: idx,
            wall: {
              name: `${idx}_wall`,
              group,
              type: 'number',
              label: 'Renseignez la surface',
            },
            type: {
              name: `${idx}_type`,
              group,
              type: 'select',
              list: [
                'parping',
                'brique',
                'mur non isolé',
                'béton',
                'bois',
                'autre',
              ],
              label: 'Type de mur',
            },
          });

          setHelper((prev) => {
            return {
              ...prev,
              [`${idx}_wall`]: {
                text: '',
                type: '',
              },
              [`${idx}_type`]: {
                text: '',
                type: '',
              },
            };
          });

          const wallsList = walls;
          // update walls state
          wallsList.fields[wallContentIndex] = wallContent;
          setWalls(wallsList);

          setTimeout(() => {
            object = { ...object, numberNorthSideWall: wallArray.length };

            // add all object key to inputs state
            Object.keys(obj).forEach((key) => {
              if (key === 'type') {
                object = { ...object, [`${idx}_type`]: obj[key] };
              } else {
                object = { ...object, [`${idx}_wall`]: obj[key] };
              }
            });

            setInputs(object);
          }, 500);
        });
      }
      if (data?.mure_cote_ouest?.length) {
        const group = 'westWall';
        let position = 0;

        const wallArray = JSON.parse(data?.mure_cote_ouest);
        wallArray?.forEach((obj) => {
          // eslint-disable-next-line no-plusplus
          position++;

          // get walls content where name match fieldName
          const wallContent = walls.fields.find((wall) => wall.name === group);
          const wallContentIndex = walls.fields.findIndex(
            (wall) => wall.name === group
          );

          const idx = nanoid(6);
          wallContent.inputs.push({
            id: idx,
            wall: {
              name: `${idx}_wall`,
              group,
              type: 'number',
              label: 'Renseignez la surface',
            },
            type: {
              name: `${idx}_type`,
              group,
              type: 'select',
              list: [
                'parping',
                'brique',
                'mur non isolé',
                'béton',
                'bois',
                'autre',
              ],
              label: 'Type de mur',
            },
          });

          setHelper((prev) => {
            return {
              ...prev,
              [`${idx}_wall`]: {
                text: '',
                type: '',
              },
              [`${idx}_type`]: {
                text: '',
                type: '',
              },
            };
          });

          const wallsList = walls;
          // update walls state
          wallsList.fields[wallContentIndex] = wallContent;
          setWalls(wallsList);

          setTimeout(() => {
            object = { ...object, numberWestSideWall: wallArray.length };

            // add all object key to inputs state
            Object.keys(obj).forEach((key) => {
              if (key === 'type') {
                object = { ...object, [`${idx}_type`]: obj[key] };
              } else {
                object = { ...object, [`${idx}_wall`]: obj[key] };
              }
            });

            setInputs(object);
          }, 500);
        });
      }
      if (data?.mure_cote_sud?.length) {
        const group = 'southWall';
        let position = 0;

        const wallArray = JSON.parse(data?.mure_cote_sud);
        wallArray?.forEach((obj) => {
          // eslint-disable-next-line no-plusplus
          position++;

          // get walls content where name match fieldName
          const wallContent = walls.fields.find((wall) => wall.name === group);
          const wallContentIndex = walls.fields.findIndex(
            (wall) => wall.name === group
          );

          const idx = nanoid(6);
          wallContent.inputs.push({
            id: idx,
            wall: {
              name: `${idx}_wall`,
              group,
              type: 'number',
              label: 'Renseignez la surface',
            },
            type: {
              name: `${idx}_type`,
              group,
              type: 'select',
              list: [
                'parping',
                'brique',
                'mur non isolé',
                'béton',
                'bois',
                'autre',
              ],
              label: 'Type de mur',
            },
          });

          setHelper((prev) => {
            return {
              ...prev,
              [`${idx}_wall`]: {
                text: '',
                type: '',
              },
              [`${idx}_type`]: {
                text: '',
                type: '',
              },
            };
          });

          const wallsList = walls;
          // update walls state
          wallsList.fields[wallContentIndex] = wallContent;
          setWalls(wallsList);

          setTimeout(() => {
            object = { ...object, numberSouthSideWall: wallArray.length };

            // add all object key to inputs state
            Object.keys(obj).forEach((key) => {
              if (key === 'type') {
                object = { ...object, [`${idx}_type`]: obj[key] };
              } else {
                object = { ...object, [`${idx}_wall`]: obj[key] };
              }
            });

            setInputs(object);
          }, 500);
        });
      }
    }, 300);

    Object.keys(data).forEach(async (key) => {
      if (key === 'maison') {
        // load files in uppy
        let filesURLs = [];

        const urlToFetch = data?.maison;

        if (urlToFetch) {
          await axios
            .get(urlToFetch, {
              responseType: 'json',
            })
            .then((response) => {
              filesURLs = [...response.data];
            })
            .catch(() => {
              showNotif(
                'Une erreur est survenue lors du chargement des fichiers',
                'error'
              );
            });
        }

        if (filesURLs.length) {
          filesURLs.forEach((url) => {
            axios.get(url, { responseType: 'blob' }).then((blob) => {
              uppy_files_home.addFile({
                id: url,
                name: url.split('/').pop(),
                type: 'image/png',
                data: blob.data,
                preview: url,
                source: 'react:Dashboard',
                extension: 'png',
                size: blob.data.size,
                isRemote: false,
              });
            });
          });
        }
      } else if (key === 'porte') {
        // load files in uppy
        let filesURLs = [];

        const urlToFetch = data?.porte;

        if (urlToFetch) {
          await axios
            .get(urlToFetch, {
              responseType: 'json',
            })
            .then((response) => {
              filesURLs = [...response.data];
            })
            .catch(() => {
              showNotif(
                'Une erreur est survenue lors du chargement des fichiers',
                'error'
              );
            });
        }

        if (filesURLs.length) {
          filesURLs.forEach((url) => {
            axios.get(url, { responseType: 'blob' }).then((blob) => {
              uppy_files_doors.addFile({
                id: url,
                name: url.split('/').pop(),
                type: 'image/png',
                data: blob.data,
                preview: url,
                source: 'react:Dashboard',
                extension: 'png',
                size: blob.data.size,
                isRemote: false,
              });
            });
          });
        }
      } else if (key === 'toiture') {
        // load files in uppy
        let filesURLs = [];

        const urlToFetch = data?.toiture;

        if (urlToFetch) {
          await axios
            .get(urlToFetch, {
              responseType: 'json',
            })
            .then((response) => {
              filesURLs = [...response.data];
            })
            .catch(() => {
              showNotif(
                'Une erreur est survenue lors du chargement des fichiers',
                'error'
              );
            });
        }

        if (filesURLs.length) {
          filesURLs.forEach((url) => {
            axios.get(url, { responseType: 'blob' }).then((blob) => {
              uppy_files_roof.addFile({
                id: url,
                name: url.split('/').pop(),
                type: 'image/png',
                data: blob.data,
                preview: url,
                source: 'react:Dashboard',
                extension: 'png',
                size: blob.data.size,
                isRemote: false,
              });
            });
          });
        }
      } else if (key === 'fenetre') {
        // load files in uppy
        let filesURLs = [];

        const urlToFetch = data?.fenetre;

        if (urlToFetch) {
          await axios
            .get(urlToFetch, {
              responseType: 'json',
            })
            .then((response) => {
              filesURLs = [...response.data];
            })
            .catch(() => {
              showNotif(
                'Une erreur est survenue lors du chargement des fichiers',
                'error'
              );
            });
        }

        if (filesURLs.length) {
          filesURLs.forEach((url) => {
            axios.get(url, { responseType: 'blob' }).then((blob) => {
              uppy_files_windows.addFile({
                id: url,
                name: url.split('/').pop(),
                type: 'image/png',
                data: blob.data,
                preview: url,
                source: 'react:Dashboard',
                extension: 'png',
                size: blob.data.size,
                isRemote: false,
              });
            });
          });
        }
      } else if (key === 'autrephoto') {
        // load files in uppy
        let filesURLs = [];

        const urlToFetch = data?.autrephoto;

        if (urlToFetch) {
          await axios
            .get(urlToFetch, {
              responseType: 'json',
            })
            .then((response) => {
              filesURLs = [...response.data];
            })
            .catch(() => {
              showNotif(
                'Une erreur est survenue lors du chargement des fichiers',
                'error'
              );
            });
        }

        if (filesURLs.length) {
          filesURLs.forEach((url) => {
            axios.get(url, { responseType: 'blob' }).then((blob) => {
              uppy.addFile({
                id: url,
                name: url.split('/').pop(),
                type: 'image/png',
                data: blob.data,
                preview: url,
                source: 'react:Dashboard',
                extension: 'png',
                size: blob.data.size,
                isRemote: false,
              });
            });
          });
        }
      }
    });
  };

  const get = () => {
    if (isMesurer) {
      getMesurerAudit(id)
        .then((res) => {
          let data = res.data?.data?.demande_audit_info?.original?.data;
          data = {
            ...data,
            id: res.data?.data?.id,
          };
          data = {
            ...data,
            isEpiredDate: res.data?.data?.is_later,
          };
          loadData(data);
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            showNotif('Une erreur est survenue', 'error');
          }
        });
    } else {
      getAudit(id)
        .then((res) => {
          let data =
            res.data?.data?.demande_audit_info?.original?.data ||
            res.data?.data?.demande_audit?.original?.data;
          data = {
            ...data,
            id: res.data?.data?.id,
          };
          loadData(data);
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            showNotif('Une erreur est survenue', 'error');
          }
        });
    }
  };

  useEffect(() => {
    if (id) get();
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (inputs.contactDiagnostic)
      setHelper((prev) => {
        return {
          ...prev,
          contactDiagnostic: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.contactDiagnostic)
      setHelper((prev) => {
        return {
          ...prev,
          contactDiagnostic: {
            text: 'Veuillez renseigner le téléphone',
            color: 'error',
          },
        };
      });

    if (inputs.phoneDiagnostic)
      setHelper((prev) => {
        return {
          ...prev,
          phoneDiagnostic: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.phoneDiagnostic)
      setHelper((prev) => {
        return {
          ...prev,
          phoneDiagnostic: {
            text: 'Veuillez renseigner le téléphone',
            color: 'error',
          },
        };
      });

    if (inputs?.addressDiagnostic)
      setHelper((prev) => {
        return {
          ...prev,
          addressDiagnostic: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.addressDiagnostic)
      setHelper((prev) => {
        return {
          ...prev,
          addressDiagnostic: {
            text: "Veuillez renseigner l'adresse",
            color: 'error',
          },
        };
      });

    if (inputs.emailDiagnostic)
      setHelper((prev) => {
        return {
          ...prev,
          emailDiagnostic: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.emailDiagnostic)
      setHelper((prev) => {
        return {
          ...prev,
          emailDiagnostic: {
            text: "Veuillez renseigner l'email",
            color: 'error',
          },
        };
      });

    if (inputs.email && !validateEmail(inputs.email))
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: 'Email non valide',
            color: 'error',
          },
        };
      });

    if (inputs?.contactAboutSite)
      setHelper((prev) => {
        return {
          ...prev,
          contactAboutSite: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.contactAboutSite)
      setHelper((prev) => {
        return {
          ...prev,
          contactAboutSite: {
            text: 'Veuillez renseigner le contact',
            color: 'error',
          },
        };
      });

    if (inputs?.addressAboutSite)
      setHelper((prev) => {
        return {
          ...prev,
          addressAboutSite: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.addressAboutSite)
      setHelper((prev) => {
        return {
          ...prev,
          addressAboutSite: {
            text: "Veuillez renseigner l'adresse",
            color: 'error',
          },
        };
      });

    if (inputs?.code_porte_ics)
      setHelper((prev) => {
        return {
          ...prev,
          code_porte_ics: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.code_porte_ics)
      setHelper((prev) => {
        return {
          ...prev,
          code_porte_ics: {
            text: 'Veuillez renseigner le code porte',
            color: 'error',
          },
        };
      });

    if (inputs?.code_portail_ics)
      setHelper((prev) => {
        return {
          ...prev,
          code_portail_ics: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.code_portail_ics)
      setHelper((prev) => {
        return {
          ...prev,
          code_portail_ics: {
            text: 'Veuillez renseigner le code portail',
            color: 'error',
          },
        };
      });

    if (inputs?.applicant)
      setHelper((prev) => {
        return {
          ...prev,
          applicant: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.applicant)
      setHelper((prev) => {
        return {
          ...prev,
          applicant: {
            text: 'Veuillez renseigner le demandeur',
            color: 'error',
          },
        };
      });

    if (inputs?.dateMeeting)
      setHelper((prev) => {
        return {
          ...prev,
          dateMeeting: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.dateMeeting)
      setHelper((prev) => {
        return {
          ...prev,
          dateMeeting: {
            text: 'Veuillez renseigner la date',
            color: 'error',
          },
        };
      });

    if (inputs?.contactAboutSite)
      setHelper((prev) => {
        return {
          ...prev,
          contactAboutSite: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.contactAboutSite)
      setHelper((prev) => {
        return {
          ...prev,
          contactAboutSite: {
            text: 'Veuillez renseigner le contact',
            color: 'error',
          },
        };
      });

    if (inputs?.addressAboutSite)
      setHelper((prev) => {
        return {
          ...prev,
          addressAboutSite: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.addressAboutSite)
      setHelper((prev) => {
        return {
          ...prev,
          addressAboutSite: {
            text: "Veuillez renseigner l'adresse",
            color: 'error',
          },
        };
      });

    if (inputs?.codePorte)
      setHelper((prev) => {
        return {
          ...prev,
          codePorte: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.codePorte)
      setHelper((prev) => {
        return {
          ...prev,
          codePorte: {
            text: 'Veuillez renseigner le code porte',
            color: 'error',
          },
        };
      });

    if (inputs?.codePortail)
      setHelper((prev) => {
        return {
          ...prev,
          codePortail: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.codePortail)
      setHelper((prev) => {
        return {
          ...prev,
          codePortail: {
            text: 'Veuillez renseigner le code portail',
            color: 'error',
          },
        };
      });

    if (inputs?.typeHeating)
      setHelper((prev) => {
        return {
          ...prev,
          typeHeating: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.typeHeating)
      setHelper((prev) => {
        return {
          ...prev,
          typeHeating: {
            text: 'Veuillez renseigner le type de chauffage',
            color: 'error',
          },
        };
      });

    if (inputs?.manufactureYear)
      setHelper((prev) => {
        return {
          ...prev,
          manufactureYear: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.manufactureYear)
      setHelper((prev) => {
        return {
          ...prev,
          manufactureYear: {
            text: "Veuillez renseigner l'année",
            color: 'error',
          },
        };
      });

    if (inputs?.typeInstallation)
      setHelper((prev) => {
        return {
          ...prev,
          typeInstallation: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.typeInstallation)
      setHelper((prev) => {
        return {
          ...prev,
          typeInstallation: {
            text: "Veuillez renseigner le type d'installation",
            color: 'error',
          },
        };
      });

    if (inputs?.manufactureYear)
      setHelper((prev) => {
        return {
          ...prev,
          manufactureYear: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.manufactureYear)
      setHelper((prev) => {
        return {
          ...prev,
          manufactureYear: {
            text: "Veuillez renseigner l'année",
            color: 'error',
          },
        };
      });

    if (inputs?.accommodation)
      setHelper((prev) => {
        return {
          ...prev,
          accommodation: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.accommodation)
      setHelper((prev) => {
        return {
          ...prev,
          accommodation: {
            text: 'Veuillez renseigner le type de logement',
            color: 'error',
          },
        };
      });

    if (inputs?.totaleSurface)
      setHelper((prev) => {
        return {
          ...prev,
          totaleSurface: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.totaleSurface)
      setHelper((prev) => {
        return {
          ...prev,
          totaleSurface: {
            text: 'Veuillez renseigner la surface',
            color: 'error',
          },
        };
      });

    if (inputs?.floorNumber)
      setHelper((prev) => {
        return {
          ...prev,
          floorNumber: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.floorNumber)
      setHelper((prev) => {
        return {
          ...prev,
          floorNumber: {
            text: "Veuillez renseigner le nombre d'étages",
            color: 'error',
          },
        };
      });

    if (inputs?.hasRecentWork) {
      if (inputs?.workKind)
        setHelper((prev) => {
          return {
            ...prev,
            workKind: {
              text: '',
              color: '',
            },
          };
        });

      if (!inputs?.workKind)
        setHelper((prev) => {
          return {
            ...prev,
            workKind: {
              text: 'Veuillez renseigner le type de travaux',
              color: 'error',
            },
          };
        });
    }

    if (inputs?.windowSurface)
      setHelper((prev) => {
        return {
          ...prev,
          windowSurface: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.windowSurface)
      setHelper((prev) => {
        return {
          ...prev,
          windowSurface: {
            text: 'Veuillez renseigner la surface des vitres',
            color: 'error',
          },
        };
      });

    if (inputs?.windowNumbers)
      setHelper((prev) => {
        return {
          ...prev,
          windowNumbers: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.windowNumbers)
      setHelper((prev) => {
        return {
          ...prev,
          windowNumbers: {
            text: 'Veuillez renseigner la surface des vitres',
            color: 'error',
          },
        };
      });

    if (inputs?.glazing)
      setHelper((prev) => {
        return {
          ...prev,
          glazing: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.glazing)
      setHelper((prev) => {
        return {
          ...prev,
          glazing: {
            text: 'Veuillez sélectionner le type de vitrage',
            color: 'error',
          },
        };
      });

    if (inputs?.windowWeight)
      setHelper((prev) => {
        return {
          ...prev,
          windowWeight: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.windowWeight)
      setHelper((prev) => {
        return {
          ...prev,
          windowWeight: {
            text: "Veuillez renseigner l'épaisseur des vitres",
            color: 'error',
          },
        };
      });

    if (inputs?.frame)
      setHelper((prev) => {
        return {
          ...prev,
          frame: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.frame)
      setHelper((prev) => {
        return {
          ...prev,
          frame: {
            text: 'Veuillez sélectionner le type de huissierie',
            color: 'error',
          },
        };
      });

    if (inputs?.doorSurface)
      setHelper((prev) => {
        return {
          ...prev,
          doorSurface: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.doorSurface)
      setHelper((prev) => {
        return {
          ...prev,
          doorSurface: {
            text: 'Veuillez renseigner la surface',
            color: 'error',
          },
        };
      });

    if (inputs?.doorNumbers)
      setHelper((prev) => {
        return {
          ...prev,
          doorNumbers: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.doorNumbers)
      setHelper((prev) => {
        return {
          ...prev,
          doorNumbers: {
            text: 'Veuillez renseigner le nombre de surface',
            color: 'error',
          },
        };
      });

    if (inputs?.doorGlazing)
      setHelper((prev) => {
        return {
          ...prev,
          doorGlazing: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.doorGlazing)
      setHelper((prev) => {
        return {
          ...prev,
          doorGlazing: {
            text: 'Veuillez sélectionner le type de vitrage',
            color: 'error',
          },
        };
      });

    if (inputs?.windowDoorWeight)
      setHelper((prev) => {
        return {
          ...prev,
          windowDoorWeight: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.windowDoorWeight)
      setHelper((prev) => {
        return {
          ...prev,
          windowDoorWeight: {
            text: "Veuillez renseigner l'épaisseur",
            color: 'error',
          },
        };
      });

    if (inputs?.doorFrame)
      setHelper((prev) => {
        return {
          ...prev,
          doorFrame: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.doorFrame)
      setHelper((prev) => {
        return {
          ...prev,
          doorFrame: {
            text: 'Veuillez sélectionner le type de huissierie',
            color: 'error',
          },
        };
      });

    if (inputs?.doorEntranceSurface)
      setHelper((prev) => {
        return {
          ...prev,
          doorEntranceSurface: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.doorEntranceSurface)
      setHelper((prev) => {
        return {
          ...prev,
          doorEntranceSurface: {
            text: 'Veuillez renseigner la surface',
            color: 'error',
          },
        };
      });

    if (inputs?.doorEntranceNumbers)
      setHelper((prev) => {
        return {
          ...prev,
          doorEntranceNumbers: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.doorEntranceNumbers)
      setHelper((prev) => {
        return {
          ...prev,
          doorEntranceNumbers: {
            text: 'Veuillez renseigner le nombre de surface',
            color: 'error',
          },
        };
      });

    if (
      (inputs?.underground && inputs?.undergroundSurface) ||
      (!inputs?.underground && !inputs?.undergroundSurface)
    ) {
      setHelper((prev) => {
        return {
          ...prev,
          undergroundSurface: {
            text: '',
            color: '',
          },
        };
      });
    }

    if (
      (inputs?.atticUnheated && inputs?.atticUnheatedSurface) ||
      (!inputs?.atticUnheated && !inputs?.atticUnheatedSurface)
    ) {
      setHelper((prev) => {
        return {
          ...prev,
          atticUnheatedSurface: {
            text: '',
            color: '',
          },
        };
      });
    }

    if (
      (inputs?.roof && inputs?.roofSurface) ||
      (!inputs?.roof && !inputs?.roofSurface)
    ) {
      setHelper((prev) => {
        return {
          ...prev,
          roofSurface: {
            text: '',
            color: '',
          },
        };
      });
    }

    if (inputs?.typeFloor) {
      setHelper((prev) => {
        return {
          ...prev,
          typeFloor: {
            text: '',
            color: '',
          },
        };
      });
    }

    if (inputs?.lowFloor) {
      setHelper((prev) => {
        return {
          ...prev,
          lowFloor: {
            text: '',
            color: '',
          },
        };
      });
    }

    if (inputs?.highFloor) {
      setHelper((prev) => {
        return {
          ...prev,
          highFloor: {
            text: '',
            color: '',
          },
        };
      });
    }

    if (inputs?.atticFloor) {
      setHelper((prev) => {
        return {
          ...prev,
          atticFloor: {
            text: '',
            color: '',
          },
        };
      });
    }

    if (inputs?.underground && !isInspector) {
      if (!inputs?.undergroundSurface)
        setHelper((prev) => {
          return {
            ...prev,
            undergroundSurface: {
              text: 'Veuillez renseigner la surface du sous sol',
              color: 'error',
            },
          };
        });

      if (!inputs?.atticUnheatedSurface)
        setHelper((prev) => {
          return {
            ...prev,
            atticUnheatedSurface: {
              text: 'Veuillez renseigner la surface du comble',
              color: 'error',
            },
          };
        });

      if (!inputs?.roofSurface)
        setHelper((prev) => {
          return {
            ...prev,
            roofSurface: {
              text: 'Veuillez renseigner la surface de la toiture',
              color: 'error',
            },
          };
        });
    }

    if (!inputs?.typeFloor && !isInspector) {
      setHelper((prev) => {
        return {
          ...prev,
          typeFloor: {
            text: "Veuillez renseigner le type d'isolation",
            color: 'error',
          },
        };
      });
    }

    if (!inputs?.lowFloor && !isInspector) {
      setHelper((prev) => {
        return {
          ...prev,
          lowFloor: {
            text: 'Veuillez sélectionner la surface du plancher bas',
            color: 'error',
          },
        };
      });
    }

    if (!inputs?.highFloor && !isInspector) {
      setHelper((prev) => {
        return {
          ...prev,
          highFloor: {
            text: 'Veuillez sélectionner la surface du plancher haut',
            color: 'error',
          },
        };
      });
    }

    if (!inputs?.atticFloor && !isInspector) {
      setHelper((prev) => {
        return {
          ...prev,
          atticFloor: {
            text: 'Veuillez sélectionner la surface du plancher comble',
            color: 'error',
          },
        };
      });
    }

    setHelper((prev) => {
      return {
        ...prev,
        numberEastSideWall: {
          text: '',
          color: '',
        },
      };
    });
    setHelper((prev) => {
      return {
        ...prev,
        numberNorthSideWall: {
          text: '',
          color: '',
        },
      };
    });
    setHelper((prev) => {
      return {
        ...prev,
        numberSouthSideWall: {
          text: '',
          color: '',
        },
      };
    });
    setHelper((prev) => {
      return {
        ...prev,
        numberWestSideWall: {
          text: '',
          color: '',
        },
      };
    });

    if (!inputs?.numberEastSideWall && !isInspector) {
      setHelper((prev) => {
        return {
          ...prev,
          numberEastSideWall: {
            text: 'Veuillez sélectionner le nombre de mur côté Est',
            color: 'error',
          },
        };
      });
    }

    if (!inputs?.numberWestSideWall && !isInspector) {
      setHelper((prev) => {
        return {
          ...prev,
          numberWestSideWall: {
            text: 'Veuillez sélectionner le nombre de mur côté Ouest',
            color: 'error',
          },
        };
      });
    }

    if (!inputs?.numberNorthSideWall && !isInspector) {
      setHelper((prev) => {
        return {
          ...prev,
          numberNorthSideWall: {
            text: 'Veuillez sélectionner le nombre de mur côté Nord',
            color: 'error',
          },
        };
      });
    }

    if (!inputs?.numberSouthSideWall && !isInspector) {
      setHelper((prev) => {
        return {
          ...prev,
          numberSouthSideWall: {
            text: 'Veuillez sélectionner le nombre de mur côté Sud',
            color: 'error',
          },
        };
      });
    }

    if (
      inputs?.numberEastSideWall > 0 &&
      walls.fields[0].inputs?.length !== Number(inputs?.numberEastSideWall) &&
      !isInspector
    ) {
      setHelper((prev) => {
        return {
          ...prev,
          numberEastSideWall: {
            text: 'Vous devez renseigner la surface et le type de chaque mur',
            color: 'error',
          },
        };
      });
    }

    if (
      inputs?.numberNorthSideWall > 0 &&
      walls.fields[1].inputs?.length !== Number(inputs?.numberEastSideWall) &&
      !isInspector
    ) {
      setHelper((prev) => {
        return {
          ...prev,
          numberNorthSideWall: {
            text: 'Vous devez renseigner la surface et le type de chaque mur',
            color: 'error',
          },
        };
      });
    }

    if (
      inputs?.numberSouthSideWall > 0 &&
      walls.fields[2].inputs?.length !== Number(inputs?.numberSouthSideWall) &&
      !isInspector
    ) {
      setHelper((prev) => {
        return {
          ...prev,
          numberSouthSideWall: {
            text: 'Vous devez renseigner la surface et le type de chaque mur',
            color: 'error',
          },
        };
      });
    }

    if (
      inputs?.numberWestSideWall > 0 &&
      walls.fields[3].inputs?.length !== Number(inputs?.numberWestSideWall) &&
      !isInspector
    ) {
      setHelper((prev) => {
        return {
          ...prev,
          numberWestSideWall: {
            text: 'Vous devez renseigner la surface et le type de chaque mur',
            color: 'error',
          },
        };
      });
    }

    if (
      (!inputs.contactDiagnostic && !isInspector) ||
      (!inputs.addressDiagnostic && !isInspector) ||
      (!inputs.phoneDiagnostic && !isInspector) ||
      (!inputs.emailDiagnostic && !isInspector) ||
      (!validateEmail(inputs.emailDiagnostic) && !isInspector)
    ) {
      document.getElementById('location_info').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (
      (!inputs?.windowSurface && !isInspector) ||
      (!inputs.windowNumbers && !isInspector) ||
      (!inputs.glazing && !isInspector) ||
      (!inputs.windowWeight && !isInspector) ||
      (!inputs.frame && !isInspector)
    ) {
      document.getElementById('glazing').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (
      (!inputs?.doorSurface && !isInspector) ||
      (!inputs.doorNumbers && !isInspector) ||
      (!inputs.doorGlazing && !isInspector) ||
      (!inputs.windowDoorWeight && !isInspector) ||
      (!inputs.doorFrame && !isInspector)
    ) {
      document.getElementById('doors').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (
      (!inputs?.doorEntranceSurface && !isInspector) ||
      (!inputs.doorEntranceNumbers && !isInspector)
    ) {
      document.getElementById('doorsEntrance').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (
      (!inputs?.numberEastSideWall && !isInspector) ||
      (inputs?.numberEastSideWall &&
        !isInspector &&
        walls.fields[2].inputs?.length !==
        Number(inputs?.numberEastSideWall)) ||
      (!inputs?.numberNorthSideWall && !isInspector) ||
      (inputs?.numberNorthSideWall &&
        !isInspector &&
        walls.fields[2].inputs?.length !==
        Number(inputs?.numberNorthSideWall)) ||
      (!inputs?.numberSouthSideWall && !isInspector) ||
      (inputs?.numberSouthSideWall &&
        !isInspector &&
        walls.fields[2].inputs?.length !==
        Number(inputs?.numberSouthSideWall)) ||
      (!inputs?.numberWestSideWall && !isInspector) ||
      (inputs?.numberWestSideWall &&
        !isInspector &&
        walls.fields[2].inputs?.length !== Number(inputs?.numberWestSideWall))
    ) {
      document.getElementById('walls').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (
      (inputs?.underground && !isInspector && !inputs.undergroundSurface) ||
      (inputs.atticUnheated && !isInspector && !inputs.atticUnheatedSurface) ||
      (inputs.roof && !isInspector && !inputs.roofSurface)
    ) {
      document.getElementById('area').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (!inputs.typeFloor && !isInspector) {
      document.getElementById('floor').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (
      inputs?.lowFloor &&
      !inputs.highFloor &&
      !isInspector &&
      !inputs.atticFloor
    ) {
      document.getElementById('floor').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (
      (!inputs.contactAboutSite && !isInspector) ||
      (!inputs.addressAboutSite && !isInspector) ||
      (!inputs.codePorte && !isInspector) ||
      (!inputs.codePortail && !isInspector)
    ) {
      document.getElementById('site_info').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (
      (!inputs?.accommodation && !isInspector) ||
      (!inputs?.totaleSurface && !isInspector) ||
      (!inputs?.floorNumber && !isInspector)
    ) {
      document.getElementById('lodge').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (
      (!inputs?.typeInstallation && !isInspector) ||
      (!inputs?.dateInstallation && !isInspector)
    ) {
      document.getElementById('installation').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (
      (!inputs?.typeHeating && !isInspector) ||
      (!inputs?.manufactureYear && !isInspector)
    ) {
      document.getElementById('heating').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (inputs?.hasRecentWork && !inputs?.workKind && !isInspector) {
      document.getElementById('work').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    // get files from uppy
    const files_home = uppy_files_home.getFiles();
    const files_windows = uppy_files_windows.getFiles();
    const files_doors = uppy_files_doors.getFiles();
    const files_roofs = uppy_files_roof.getFiles();
    const others = uppy.getFiles();

    if (files_home.length > 0) {
      setHelper((prev) => {
        return {
          ...prev,
          files_home: {
            text: '',
            type: '',
          },
        };
      });
    }

    if (files_home.length <= 0) {
      setHelper((prev) => {
        return {
          ...prev,
          files_home: {
            text: 'Ajoutez les photos de la maison',
            type: 'error',
          },
        };
      });
      document.getElementById('preview-home').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (files_windows.length > 0) {
      setHelper((prev) => {
        return {
          ...prev,
          files_windows: {
            text: '',
            type: '',
          },
        };
      });
    }

    if (files_windows.length <= 0 && !isInspector) {
      setHelper((prev) => {
        return {
          ...prev,
          files_windows: {
            text: 'Ajoutez les photos des fenêtres',
            type: 'error',
          },
        };
      });
      document.getElementById('preview-windows').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (files_doors.length > 0) {
      setHelper((prev) => {
        return {
          ...prev,
          files_doors: {
            text: '',
            type: '',
          },
        };
      });
    }

    if (files_doors.length <= 0 && !isInspector) {
      setHelper((prev) => {
        return {
          ...prev,
          files_doors: {
            text: 'Ajoutez les photos des portes',
            type: 'error',
          },
        };
      });
      document.getElementById('preview-doors').scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
      return;
    }

    if (files_roofs.length > 0 && !isInspector) {
      setHelper((prev) => {
        return {
          ...prev,
          files_roofs: {
            text: '',
            type: '',
          },
        };
      });

      if (files_roofs.length <= 0) {
        setHelper((prev) => {
          return {
            ...prev,
            files_roofs: {
              text: 'Ajoutez les photos de la toiture',
              type: 'error',
            },
          };
        });

        document.getElementById('preview-roof').scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
        return;
      }
    }

    setIsLoading(true);
    setMessage(null);

    const data = new FormData();

    // console.log('data: ', data);

    data.append('demandeur', inputs.applicant);
    data.append('contact_lieu_audit', inputs.contactDiagnostic);
    data.append('adresse_lieu_audit', inputs.addressDiagnostic);
    data.append('telephone_lieu_audit', inputs.phoneDiagnostic);
    data.append('email_lieu_audit', inputs.emailDiagnostic);
    // data.append('date_rdvz', inputs.dateMeeting);
    data.append('date_rdvz', inputs.dateInstallation);
    data.append('contact_ics', inputs.contactAboutSite);
    data.append('adresse_ics', inputs.addressAboutSite);
    data.append('code_porte_ics', inputs.codePorte);
    data.append('code_portail_ics', inputs.codePortail);
    data.append('type_logement', inputs.accommodation);
    data.append('surface_totale', inputs.totaleSurface);
    data.append('nbre_etage', inputs.floorNumber);
    data.append(
      'confirm_travaux_effectuee',
      inputs.hasRecentWork === true ? 1 : 0
    );
    data.append('vitrage_surface_vitre', inputs.windowSurface);
    data.append('vitrage_nombre_vitre', inputs.windowNumbers);
    data.append('vitrage_type_vitrage', inputs.glazing);
    data.append('vitrage_epaisseur_vitre', inputs.windowWeight);
    data.append('vitrage_type_huisserie', inputs.frame);
    data.append('porte_fenetres_surface_portes_vitre', inputs.doorSurface);
    data.append('porte_fenetres_type_vitrage', inputs.doorGlazing);
    data.append('porte_fenetres_epaisseur_vitre', inputs.windowDoorWeight);
    data.append('porte_fenetres_type_huisserie', inputs.doorFrame);
    data.append('porte_fenetres_nombre_portes_vitre', inputs.doorNumbers);
    data.append('porte_entree_surface', inputs.doorEntranceSurface);
    data.append('porte_entree_surface_nbre_porte', inputs.doorEntranceNumbers);
    data.append(
      'zone_non_chauffee_sous_sol',
      inputs.underground === true ? 1 : 0
    );
    data.append(
      'zone_non_chauffee_sous_sol_surface',
      inputs.undergroundSurface
    );
    data.append(
      'zone_non_chauffee_comble',
      inputs.atticUnheated === true ? 1 : 0
    );
    data.append(
      'zone_non_chauffee_comble_surface',
      inputs.atticUnheatedSurface
    );
    data.append('zone_non_chauffee_toiture', inputs.roof === true ? 1 : 0);
    data.append('zone_non_chauffee_toiture_surface', inputs.roofSurface);

    data.append('type_chauffage', inputs.typeHeating);
    data.append('annee_fabrication', inputs.manufactureYear);
    data.append('isolation_plancher_bas', inputs.lowFloor === true ? 1 : 0);
    data.append('isolation_plancher_haut', inputs.highFloor === true ? 1 : 0);
    data.append('isolation_comblee', inputs.atticFloor === true ? 1 : 0);

    data.append('type_installation', inputs.typeInstallation);
    data.append('date_installation', inputs.manufactureYear);
    data.append('nbre_apparail_control', inputs.unitToConnect);
    /**
     * TO DO
     * Set inspector note
     */

    data.append('commentaire_demandeur', inputs.commentaireDemandeur);
    data.append('commentaire_infos_client', inputs.commentaireInfoClient);
    data.append(
      'commentaire_coordonnees_lieu_a_diagnostiquer',
      inputs.commentaireInfoDiagnostic
    );
    data.append('commentaire_infos_du_site', inputs.commentaireInfoSite);
    data.append('commentaire_type_logement', inputs.commentaireTypeLogement);
    data.append('commentaire_portes_fenetres', inputs.commentairePorteFenetre);
    data.append('commentaire_portes_entree', inputs.CommentairePorteEntree);
    data.append('commentaire_murs', inputs.commentaireMur);
    data.append(
      'commentaire_zone_non_chauffee',
      inputs.commentaireZoneNonChauffee
    );
    data.append('commentaire_isolation', inputs.commentaireIsolation);
    data.append(
      'commentaire_type_installation',
      inputs.commentaireTypeInstallation
    );
    data.append('commentaire_type_chauffage', inputs.commentaireTypeChauffage);
    data.append(
      'commentaire_nombre_appareils_a_connecter',
      inputs.commentaireappareilsAconnecter
    );
    data.append(
      'commentaire_travaux_effectues',
      inputs.commentaireTravauxEffectues
    );
    data.append('commentaire_photos_maison', inputs.commentaireAutrePhoto);
    data.append('commentaire_photos_fenetres', inputs.commentaireAutrePhoto);
    data.append('commentaire_photos_portes', inputs.commentaireAutrePhoto);
    data.append('commentaire_photos_toiture', inputs.commentaireAutrePhoto);
    data.append('commentaire_photos_autres', inputs.commentaireAutrePhoto);
    // data.append('commentaire_portes_entree', inputs.CommentairedoorsEntrance);
    data.append('note', inputs.inspectorNote);

    // data.append('commentaire_info_vitrage', inputs.commentaireVitrages);
    // data.append('type_installation', inputs.typeInstallation);

    files_home.forEach((file, i) => {
      data.append(`maison[${i}]`, file.data);
    });

    files_doors.forEach((file, i) => {
      data.append(`porte[${i}]`, file.data);
    });

    files_windows.forEach((file, i) => {
      data.append(`fenetre[${i}]`, file.data);
    });

    files_roofs.forEach((file, i) => {
      data.append(`toiture[${i}]`, file.data);
    });

    others.forEach((file, i) => {
      data.append(`autre[${i}]`, file.data);
    });

    data.append('id', id);

    walls.fields.forEach((field, i) => {
      // eslint-disable-next-line no-shadow
      const list = [];

      field.inputs.forEach((input, j) => {
        // retrieve input in inputs and push in list
        const object = {};
        object.surface = inputs[`${input.id}_wall`];
        if (inputs[`${input.id}_type`] === 'autre')
          object.type = inputs[`${input.id}_type_other`];
        else object.type = inputs[`${input.id}_type`];

        list.push(object);

        if (j === field.inputs.length - 1) {
          if (field.name === 'eastWall') {
            data.append('mure_cote_est', JSON.stringify(list));
          } else if (field.name === 'westWall') {
            data.append('mure_cote_ouest', JSON.stringify(list));
          } else if (field.name === 'northWall') {
            data.append('mure_cote_nord', JSON.stringify(list));
          } else if (field.name === 'southWall') {
            data.append('mure_cote_sud', JSON.stringify(list));
          }
        }
      });
    });

    if (isMesurer) {
      updateMesurerReport(data)
        .then(() => {
          setIsLoading(false);
          showNotif(`La demande d'audit a été mis à jour`, 'success');
          navigate('/audits');
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            showNotif('Une erreur est survenue', 'error');
          }
        });
    } else {
      updateInspectorReport(data)
        .then(() => {
          setIsLoading(false);
          showNotif(`La demande d'audit a été mis à jour`, 'success');
          navigate('/audits');
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            showNotif('Une erreur est survenue', 'error');
          }
        });
    }
  };

  const handleCallback = (data) => {
    const tempList = _.merge(list, data);
    setList(tempList);
  };
  const loadContentsToDownload = () => {
    const mainTitle = {
      text: renderCharacter(audit?.num_dmd),
      margin: [0, 0, 0, 10],
      style: 'header',
    };
    const auditName = {
      text: `Type d'audit:   ${renderCharacter(audit?.name_audit)}`,
      margin: [0, 0, 0, 10],
    };
    const applicant = {
      title: {
        text: 'Demandeur',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: renderCharacter(inputs?.applicant),
    };
    const contactDetails = {
      title: {
        text: 'Informations du client',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Nom:   ${renderCharacter(inputs?.lastname)}`,
            },
            {
              width: '50%',
              text: `Prénom:   ${renderCharacter(inputs?.firstname)}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Siret:   ${renderCharacter(inputs?.siret)}`,
            },
            {
              width: '50%',
              text: `Adresse:   ${renderCharacter(inputs?.address)}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Téléphone:   ${renderCharacter(inputs?.phone)}`,
            },
            {
              width: '50%',
              text: `N°TVA:   ${renderCharacter(inputs?.tva)}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        `Email:   ${renderCharacter(inputs?.email)}`,
      ],
    };
    const placeLocation = {
      title: {
        text: 'Coordonnées du lieu à diagnostiquer',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Contact:   ${renderCharacter(inputs?.contactDiagnostic)}`,
            },
            {
              width: '50%',
              text: `Adresse:   ${renderCharacter(inputs?.addressDiagnostic)}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Téléphone:   ${renderCharacter(inputs?.phoneDiagnostic)}
    `,
            },
            {
              width: '50%',
              text: `Email:   ${renderCharacter(inputs?.emailDiagnostic)}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const windows = {
      title: {
        text: 'Vitrages',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Surface de vitres:   ${inputs?.windowSurface}`,
            },
            {
              width: '50%',
              text: `Type de vitrage:   ${renderCharacter(inputs?.glazing)}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Nombre de vitres:   ${inputs?.windowNumbers}`,
            },
            {
              width: '50%',
              text: `Epaisseur des vitres:   ${inputs?.windowWeight}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        `Type de huisserie:   ${renderCharacter(inputs?.frame)}`,
      ],
    };
    const aboutWebSite = {
      title: {
        text: 'Information concernant le site',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Contact:   ${inputs?.contactAboutSite}`,
            },
            {
              width: '50%',
              text: `Adresse:   ${renderCharacter(inputs?.addressAboutSite)}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Code porte:   ${inputs?.codePorte}
      `,
            },
            {
              width: '50%',
              text: `Code portail:   ${inputs?.codePortail}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const typeOfHousing = {
      title: {
        text: 'Type de logement',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `${renderCharacter(inputs?.accommodation)}`,
            },
            {
              width: '50%',
              text: `Surface totale:   ${inputs?.totaleSurface}m³`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: ``,
            },
            {
              width: '50%',
              text: `Nombre d’étages: ${inputs?.floorNumber}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const typeOfInstallation = {
      title: {
        text: "Type d'installation",
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Type : ${renderCharacter(inputs?.typeInstallation)}`,
            },
            {
              width: '50%',
              text: `Date de fabrication: ${inputs?.manufactureYear || ''}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const typeOfHeating = {
      title: {
        text: 'Type de chauffage',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: {
        columns: [
          {
            width: '50%',
            text: `Type : ${renderCharacter(inputs?.typeHeating)}`,
          },
          {
            width: '50%',
            text: `Date de fabrication: ${inputs?.manufactureYear}`,
          },
        ],
        margin: [0, 0, 0, 10],
      },
    };
    const hasAnyWorkDone = {
      title: {
        text: 'Est-ce que des travaux ont été effectués ?',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: inputs?.hasRecentWork === 1 ? 'Oui' : 'Non',
            },
            {
              width: '50%',
              text: `Type de travaux:   ${renderCharacter(inputs?.workKind)}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const windowsDoors = {
      title: {
        text: 'Portes fenêtres',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Surface des portes vitrées:   ${inputs?.doorSurface}`,
            },
            {
              width: '50%',
              text: `Type de vitrage:   ${renderCharacter(
                inputs?.doorGlazing
              )}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Nombre des portes vitrées:   ${inputs?.doorNumbers}`,
            },
            {
              width: '50%',
              text: `Epaisseur des vitres:   ${inputs?.windowDoorWeight}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        `Type de huisserie:   ${renderCharacter(inputs?.doorFrame)}`,
      ],
    };
    const entranceDoors = {
      title: {
        text: "Portes d'entrée",
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Surface des portes d'entrée:   ${inputs?.doorEntranceSurface}`,
            },
            {
              width: '50%',
              text: `Nombre des portes d'entrée:   ${inputs?.doorEntranceNumbers}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const sideWall = {
      title: {
        text: 'Murs',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Surface mur côté EST:   ${inputs?.eastSideWall}`,
            },
            {
              width: '50%',
              text: `Type de mur côté EST:   ${renderCharacter(
                inputs?.typeEastSideWall
              )}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Surface mur côté NORD:   ${inputs?.northSideWall}`,
            },
            {
              width: '50%',
              text: `Type de mur côté NORD:   ${renderCharacter(
                inputs?.typeNorthSideWall
              )}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Surface mur côté SUD:   ${inputs?.southSideWall}`,
            },
            {
              width: '50%',
              text: `Type de mur côté SUD:   ${renderCharacter(
                inputs?.typeSouthSideWall
              )}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Surface mur côté OUEST:   ${inputs?.westSideWall}`,
            },
            {
              width: '50%',
              text: `Type de mur côté OUEST:   ${renderCharacter(
                inputs?.typeWestSideWall
              )}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const unHeated = {
      title: {
        text: 'Zone non chauffée',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Sous sol:   ${inputs?.underground === 1 ? 'Oui' : 'Non'}`,
            },
            {
              width: '50%',
              text: `Sous sol surface:   ${inputs?.undergroundSurface}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Comble :   ${inputs?.atticUnheated === 1 ? 'Oui' : 'Non'}`,
            },
            {
              width: '50%',
              text: `Comble surface:   ${inputs?.atticUnheatedSurface}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Toiture :   ${inputs?.roof === 1 ? 'Oui' : 'Non'}`,
            },
            {
              width: '50%',
              text: `Toiture surface:   ${inputs?.roofSurface}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const devicesConnected = {
      title: {
        text: "Nombre d'appareil à contrôler :",
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: renderCharacter(inputs?.unitToConnect),
    };
    const contents = {
      content: [
        // Main title
        mainTitle,
        // REFERENCE
        auditName,
        //  Applicant
        applicant.title,
        applicant.description,
        // Contact Details
        contactDetails.title,
        contactDetails.description[0],
        contactDetails.description[1],
        contactDetails.description[2],
        contactDetails.description[3],
        // Place location
        placeLocation.title,
        placeLocation.description[0],
        placeLocation.description[1],
        // Windows
        windows.title,
        windows.description[0],
        windows.description[1],
        windows.description[2],
      ],
      styles: {
        header: {
          fontSize: 25,
          bold: true,
          alignment: 'center',
        },
        title: {
          fontSize: 18,
          bold: true,
        },
      },
      images: {},
    };
    const getDataUrl = (imageId) => {
      let result;
      try {
        const listimages = document.querySelectorAll(`#${imageId} img`);
        let identifiant = 0;
        listimages.forEach((element) => {
          const image = `${imageId}-${identifiant}`;
          contents.images[image] = {
            url: element.src,
            headers: {
              myheader: '123',
              myotherheader: 'abc',
            },
          };
          identifiant += 1;
        });
        result = listimages.length;
      } catch (error) {
        result = 0;
      }
      return result;
    };
    const imageToPrint = (imageId) => {
      const description = [];
      const numberImage = getDataUrl(imageId);
      if (numberImage !== 0) {
        for (let index = 0; index < numberImage; index += 1) {
          description.push({
            image: `${imageId}-${index}`,
            width: 200,
            height: 200,
            margin: [0, 0, 0, 30],
          });
        }
      } else {
        description.push({ text: 'Aucune image', margin: [0, 0, 0, 30] });
      }
      return description;
    };
    const pictureHome = {
      title: {
        text: 'Photos de la maison:',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: imageToPrint('preview-home'),
    };
    const pictureWindows = {
      title: {
        text: 'Photos des fenêtres:',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: imageToPrint('preview-windows'),
    };
    const pictureDoors = {
      title: {
        text: 'Photos des portes:',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: imageToPrint('preview-doors'),
    };

    const pictureOthers = {
      title: {
        text: 'Autres photos:',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: imageToPrint('preview-other'),
    };

    contents.content.push(
      // Information about the site
      aboutWebSite.title,
      aboutWebSite.description[0],
      aboutWebSite.description[1]
    );

    contents.content.push(
      // Type of Housing
      typeOfHousing.title,
      typeOfHousing.description[0],
      typeOfHousing.description[1],
      // Window DOORS
      windowsDoors.title,
      windowsDoors.description[0],
      windowsDoors.description[1],
      windowsDoors.description[2],
      // Entrance DOORS
      entranceDoors.title,
      entranceDoors.description[0],
      // Side Wall
      sideWall.title,
      sideWall.description[0],
      sideWall.description[1],
      sideWall.description[2],
      sideWall.description[3],
      // Unheated
      unHeated.title,
      unHeated.description[0],
      unHeated.description[1],
      unHeated.description[2]
    );

    contents.content.push(
      // Type of installation
      typeOfInstallation.title,
      typeOfInstallation.description,
      // Devices connected
      devicesConnected.title,
      devicesConnected.description
    );
    contents.content.push(
      // Type of heating
      typeOfHeating.title,
      typeOfHeating.description
    );
    contents.content.push(
      // Has any work been done?
      hasAnyWorkDone.title,
      hasAnyWorkDone.description[0]
    );
    // Home Picture
    contents.content.push(pictureHome.title);
    pictureHome.description.forEach((element) => {
      contents.content.push(element);
    });
    // Windows Picture
    contents.content.push(pictureWindows.title);
    pictureWindows.description.forEach((element) => {
      contents.content.push(element);
    });
    // Doors Picture
    contents.content.push(pictureDoors.title);
    pictureDoors.description.forEach((element) => {
      contents.content.push(element);
    });
    // Roof Picture
    const pictureRoof = {
      title: {
        text: 'Photos de la toiture:',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: imageToPrint('preview-roof'),
    };
    contents.content.push(pictureRoof.title);
    pictureRoof.description.forEach((element) => {
      contents.content.push(element);
    });

    // Others Picture
    contents.content.push(pictureOthers.title);
    pictureOthers.description.forEach((element) => {
      contents.content.push(element);
    });
    return contents;
  };

  return (
    <>
      {loadingOnDownload && <LoadingContainer />}
      {loading ? (
        <LoadingContainer />
      ) : (
        <form className="px-14" id="audit_form" onSubmit={handleClick}>
          <h1 className="mb-4 text-xl font-bold">
            Numéro de la demande {audit?.num_dmd}
          </h1>
          <h2 className="mb-4 text-sm font-medium">
            Type d&apos;audit:{' '}
            <span className="text-black">
              {audit?.name_audit || audit?.type_audit}
            </span>
          </h2>
          {/* Applicant */}
          <Card>
            <Card.Body>
              <Collapse title="Demandeur" expanded divider={false}>
                <Radio.Group
                  name="applicant"
                  onChange={(value) =>
                    handleChange({
                      target: {
                        name: 'applicant',
                        value,
                      },
                    })
                  }
                  value={inputs?.applicant}
                >
                  <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                    {listFields.applicant.map((field) => (
                      <div className="w-full">
                        <Radio
                          value={field?.value}
                          isDisabled={isInspector || inputs?.isEpiredDate}
                        >
                          {field?.name}
                        </Radio>
                      </div>
                    ))}
                  </div>
                </Radio.Group>
                <br />
                {isInspector && (
                  <div>
                    <Textarea
                      name="commentaireDemandeur"
                      underlined
                      label="Commentaire"
                      fullWidth="true"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      onChange={handleChange}
                    />
                    <br />
                    <div className="file_uploader">
                      <Dashboard
                        style={styles}
                        uppy={commentairePhotoDemandeurUppy}
                      />
                      {commentairePhotoMaisonImages.map((image, index) => (
                        <div key={index}>
                          <img src={image.url} alt={`Image ${index}`} />
                          <button onClick={() => removeCommentairePhotoMaisonImage(index)}>Supprimer</button>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <p
                  className={`text-initial mb-4 text-xs ${helper?.applicant?.color === 'error' && '!text-[#F31260]'
                    }`}
                >
                  {helper?.applicant?.text}
                </p>
              </Collapse>
            </Card.Body>
          </Card>
          {/* Contact Details */}
          <br />
          <Card id="personal_info">
            <Card.Body>
              <Collapse title="Informations du client" expanded divider={false}>
                <div className="w-full p-2">
                  <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                    {listFields.contactDetails.map((field) => (
                      <div className="w-full">
                        <Input
                          contentLeft={field.icon}
                          name={field.name}
                          type={field.type}
                          underlined
                          fullWidth="true"
                          label={field.label}
                          className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                          value={inputs[field.name]}
                          color={helper[field.name].color}
                          helperColor={helper[field.name].color}
                          helperText={helper[field.name].text}
                          onChange={handleChange}
                          shadow={false}
                          animated={false}
                          readOnly
                        />
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
                {isInspector && (
                  <Textarea
                    name="commentaireInfoClient"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          {/* Place location */}
          <br />
          <Card id="location_info">
            <Card.Body>
              <Collapse
                title="Coordonnées du lieu à diagnostiquer"
                expanded
                divider={false}
              >
                <div className="w-full p-2">
                  <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                    {listFields.contactDiagnostic.map((field) => (
                      <div className="w-full">
                        <Input
                          contentLeft={field.icon}
                          name={field.name}
                          type={field.type}
                          underlined
                          fullWidth="true"
                          label={field.label}
                          className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                          value={inputs[field.name]}
                          color={helper[field.name].color}
                          helperColor={helper[field.name].color}
                          helperText={helper[field.name].text}
                          onChange={handleChange}
                          readOnly={isInspector || inputs?.isEpiredDate}
                        />
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
                {isInspector && (
                  <Textarea
                    name="commentaireInfoDiagnostic"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          {/* Windows */}
          <br />
          <Card id="glazing">
            <Card.Body>
              <Collapse title="Vitrages" expanded divider={false}>
                {listFields.windows.map((field) => (
                  <>
                    <Grid.Container
                      alignItems="center"
                      css={{ marginBottom: '10px' }}
                    >
                      <Grid css={{ marginRight: '10px' }}>{field.icon}</Grid>
                      <Grid>{field.label}</Grid>
                    </Grid.Container>
                    {field.groupType === 'radio' ? (
                      <>
                        <Radio.Group
                          name={field.name}
                          onChange={(value) =>
                            handleChange({
                              target: {
                                name: field.name,
                                value,
                              },
                            })
                          }
                          value={inputs[field.name]}
                        >
                          <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                            {field.inputs.map((input) => (
                              <div className="w-full">
                                <Radio
                                  value={input.value}
                                  isDisabled={
                                    isInspector || inputs?.isEpiredDate
                                  }
                                >
                                  {input.name}
                                </Radio>
                              </div>
                            ))}
                          </div>
                        </Radio.Group>
                        <p
                          className={`text-initial mb-4 text-xs ${helper[field.name]?.color === 'error' &&
                            '!text-[#F31260]'
                            }`}
                        >
                          {helper[field.name]?.text}
                        </p>
                      </>
                    ) : (
                      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                        {field.inputs.map((input) => (
                          <div className="w-full">
                            <Input
                              name={input.name}
                              type={input.type}
                              underlined
                              fullWidth="true"
                              label={input.label}
                              className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                              value={inputs[input.name]}
                              color={helper[input.name].color}
                              helperColor={helper[input.name].color}
                              helperText={helper[input.name].text}
                              onChange={handleChange}
                              readOnly={isInspector || inputs?.isEpiredDate}
                              min={0}
                              step={1}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    <br />
                  </>
                ))}
                {isInspector && (
                  <Textarea
                    name="commentaireVitrages"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          {/* Information about the site */}
          <br />
          <Card id="site_info">
            <Card.Body>
              <Collapse
                title="Informations concernant le site"
                expanded
                divider={false}
              >
                <div className="w-full">
                  <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                    {listFields.aboutSite.map((field) => (
                      <div className="w-full">
                        <Input
                          contentLeft={field.icon}
                          name={field.name}
                          type={field.type}
                          underlined
                          fullWidth="true"
                          label={field.label}
                          className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                          value={inputs[field.name]}
                          color={helper[field.name].color}
                          helperColor={helper[field.name].color}
                          helperText={helper[field.name].text}
                          onChange={handleChange}
                          readOnly={isInspector || inputs?.isEpiredDate}
                        />
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
                {isInspector && (
                  <Textarea
                    name="commentaireInfoSite"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          {/* Type of accommodation */}
          <br />
          <Card>
            <Card.Body id="lodge">
              <Collapse title="Type de logement" expanded divider={false}>
                <div className="w-full">
                  <Radio.Group
                    name="accommodation"
                    onChange={(value) =>
                      handleChange({
                        target: {
                          name: 'accommodation',
                          value,
                        },
                      })
                    }
                    value={inputs?.accommodation}
                  >
                    <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                      {listFields.typeAccommodation.type.map((field) => (
                        <div className="w-full">
                          <Grid.Container alignItems="center" gap={1}>
                            <Grid>
                              <Radio
                                value={field?.value}
                                isDisabled={isInspector || inputs?.isEpiredDate}
                              >
                                {field?.label}
                              </Radio>
                            </Grid>
                            <Grid>{field?.icon}</Grid>
                          </Grid.Container>
                        </div>
                      ))}
                    </div>
                  </Radio.Group>
                  <p
                    className={`text-initial mb-4 text-xs ${helper?.accommodation?.color === 'error' &&
                      '!text-[#F31260]'
                      }`}
                  >
                    {helper?.accommodation?.text}
                  </p>
                  <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                    {listFields.typeAccommodation.accommodation.map((field) => (
                      <div className="w-full">
                        <Input
                          contentLeft={field.icon}
                          name={field.name}
                          type={field.type}
                          underlined
                          fullWidth="true"
                          label={field.label}
                          className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                          value={inputs[field.name]}
                          color={helper[field.name].color}
                          helperColor={helper[field.name].color}
                          helperText={helper[field.name].text}
                          onChange={handleChange}
                          readOnly={isInspector || inputs?.isEpiredDate}
                          min={0}
                          status="any"
                        />
                        <br />
                        <br />
                      </div>
                    ))}
                  </div>
                </div>
                {isInspector && (
                  <Textarea
                    name="commentaireTypeLogement"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          <br />
          {/* DOORS ----  */}
          <Card id={listFields.doors.id}>
            <Card.Body>
              <Collapse title={listFields.doors.name} expanded divider={false}>
                {listFields.doors.fields.map((field) => (
                  <>
                    <Grid.Container
                      alignItems="center"
                      css={{ marginBottom: '10px' }}
                    >
                      <Grid css={{ marginRight: '10px' }}>{field.icon}</Grid>
                      <Grid>{field.label}</Grid>
                    </Grid.Container>
                    {field.groupType === 'radio' ? (
                      <>
                        <Radio.Group
                          name={field.name}
                          onChange={(value) =>
                            handleChange({
                              target: {
                                name: field.name,
                                value,
                              },
                            })
                          }
                          value={inputs[field.name]}
                        >
                          <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                            {field.inputs.map((input) => (
                              <div className="w-full">
                                <Radio
                                  value={input.value}
                                  isDisabled={
                                    isInspector || inputs?.isEpiredDate
                                  }
                                >
                                  {input.name}
                                </Radio>
                              </div>
                            ))}
                          </div>
                        </Radio.Group>
                        <p
                          className={`text-initial mb-4 text-xs ${helper[field.name]?.color === 'error' &&
                            '!text-[#F31260]'
                            }`}
                        >
                          {helper[field.name]?.text}
                        </p>
                      </>
                    ) : (
                      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                        {field.inputs.map((input) => (
                          <div className="w-full">
                            <Input
                              name={input.name}
                              type={input.type}
                              underlined
                              fullWidth="true"
                              label={input.label}
                              className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                              value={inputs[input.name]}
                              color={helper[input.name].color}
                              helperColor={helper[input.name].color}
                              helperText={helper[input.name].text}
                              onChange={handleChange}
                              readOnly={isInspector || inputs?.isEpiredDate}
                              min={0}
                              step="any"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    <br />
                  </>
                ))}
                {isInspector && (
                  <Textarea
                    name="commentairePorteFenetre"
                    underlined
                    label="Commentairer"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          <br />
          {/* DOORS ENTRANCE ----  */}
          <Card id={listFields.doorsEntrance.id}>
            <Card.Body>
              <Collapse
                title={listFields.doorsEntrance.name}
                expanded
                divider={false}
              >
                {listFields.doorsEntrance.fields.map((field) => (
                  <>
                    <Grid.Container
                      alignItems="center"
                      css={{ marginBottom: '10px' }}
                    >
                      <Grid css={{ marginRight: '10px' }}>{field.icon}</Grid>
                      <Grid>{field.label}</Grid>
                    </Grid.Container>
                    {field.groupType === 'radio' ? (
                      <>
                        <Radio.Group
                          name={field.name}
                          onChange={(value) =>
                            handleChange({
                              target: {
                                name: field.name,
                                value,
                              },
                            })
                          }
                          value={inputs[field.name]}
                        >
                          <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                            {field.inputs.map((input) => (
                              <div className="w-full">
                                <Radio
                                  value={input.value}
                                  isDisabled={
                                    isInspector || inputs?.isEpiredDate
                                  }
                                >
                                  {input.name}
                                </Radio>
                              </div>
                            ))}
                          </div>
                        </Radio.Group>
                        <p
                          className={`text-initial mb-4 text-xs ${helper[field.name]?.color === 'error' &&
                            '!text-[#F31260]'
                            }`}
                        >
                          {helper[field.name]?.text}
                        </p>
                      </>
                    ) : (
                      <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                        {field.inputs.map((input) => (
                          <div className="w-full">
                            <Input
                              name={input.name}
                              type={input.type}
                              underlined
                              fullWidth="true"
                              label={input.label}
                              className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                              value={inputs[input.name]}
                              color={helper[input.name].color}
                              helperColor={helper[input.name].color}
                              helperText={helper[input.name].text}
                              onChange={handleChange}
                              readOnly={isInspector || inputs?.isEpiredDate}
                              min={0}
                              step="any"
                            />
                          </div>
                        ))}
                      </div>
                    )}
                    <br />
                  </>
                ))}
                {isInspector && (
                  <Textarea
                    name="CommentairePorteEntree"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          <br />
          {/* walls ----  */}
          <Card
            id={walls.id}
            className="door supports-scrollbars:pr-2 overflow-auto scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-gray-200"
          >
            <Card.Body>
              <Collapse title={walls.name} expanded divider={false}>
                {walls.fields.map((field, i) => (
                  <>
                    <Grid.Container
                      alignItems="center"
                      css={{ marginBottom: '10px' }}
                    >
                      <Grid css={{ marginRight: '10px' }}>{field.icon}</Grid>
                      <Grid>{field.label}</Grid>
                    </Grid.Container>
                    <div className="mb-3 grid grid-cols-1 gap-3 md:grid-cols-2">
                      <div>
                        <Input
                          name={`${field.numberSideWall.name}`}
                          type="number"
                          min={0}
                          underlined
                          fullWidth="true"
                          label={field.numberSideWall.placeholder}
                          className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                          value={inputs[field.numberSideWall.name]}
                          color={helper[field.numberSideWall.name].color}
                          helperColor={helper[field.numberSideWall.name].color}
                          helperText={helper[field.numberSideWall.name].text}
                          onChange={(e) => handleChange(e, field.name)}
                          step="any"
                          readOnly={isInspector || inputs?.isEpiredDate}
                        />
                      </div>
                    </div>
                    <div className="grid grid-cols-1 gap-3">
                      {field.inputs.map((input, index) => (
                        <div key={input.id} className="md:flex md:space-x-4">
                          <div className="md:w-1/2">
                            <Input
                              name={input.wall.name}
                              type={input.wall.type}
                              underlined
                              fullWidth="true"
                              label={input.wall.label}
                              className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                              value={inputs[`${input.id}_wall`]}
                              color={helper[`${input.id}_wall`].color}
                              helperColor={helper[`${input.id}_wall`].color}
                              helperText={helper[`${input.id}_wall`].text}
                              onChange={handleChange}
                              step="any"
                              readOnly={isInspector}
                              min={0}
                            />
                          </div>
                          <div className="w-full md:w-1/2">
                            <div className="w-full border-b-2 border-b-[#00000026]">
                              <label
                                htmlFor={inputs[`${input.id}_type`]}
                                className="text-xs font-medium"
                              >
                                {input.type.label}
                              </label>
                              <select
                                className="w-full border-0"
                                name={input.type.name}
                                onChange={(e) =>
                                  setInputs((prev) => {
                                    return {
                                      ...prev,
                                      [`${input.id}_type`]: e.target.value,
                                    };
                                  })
                                }
                                disabled={isInspector}
                              >
                                {input?.type?.list.map((option) => (
                                  <option
                                    value={option}
                                    selected={
                                      inputs[`${input.id}_type`] === option
                                    }
                                  >
                                    {' '}
                                    {renderCharacter(option)}
                                  </option>
                                ))}
                              </select>
                            </div>
                            {inputs[`${input.id}_type`] === 'autre' && (
                              <div className="mt-3">
                                <Input
                                  name={`${input.id}_type_other`}
                                  type="text"
                                  underlined
                                  fullWidth="true"
                                  label="Préciser le type"
                                  className="ring-0 focus:border-none focus:outline-none focus:ring-0 "
                                  value={inputs[`${input.id}_type_other`]}
                                  onChange={handleChange}
                                  step="any"
                                  readOnly={isInspector || inputs?.isEpiredDate}
                                />
                              </div>
                            )}
                          </div>
                          {!isInspector && (
                            <div className="flex items-end">
                              <Button
                                css={{
                                  border: '1px solid',
                                  borderColor: '#DC3838',
                                  color: 'white',
                                  backgroundColor: '#DC3838',
                                }}
                                auto
                                onClick={() => removeFields(field, i, index)}
                              >
                                Retirer
                              </Button>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                    <br />
                  </>
                ))}
                {isInspector && (
                  <Textarea
                    name="commentaireMur"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          <br />
          {/* Area */}
          <Card
            id={listFields.area?.id}
            className={`area-switch
                    ${(inputs?.underground &&
                inputs?.atticUnheated &&
                !inputs?.roof) ||
                (inputs?.underground &&
                  !inputs?.atticUnheated &&
                  inputs?.roof) ||
                (!inputs?.underground &&
                  inputs?.atticUnheated &&
                  inputs?.roof)
                ? 'area-switch-active'
                : ''
              }
               ${!inputs?.underground && !inputs?.atticUnheated && inputs?.roof
                ? 'area-switch-last'
                : ''
              }
               ${inputs?.underground && inputs?.atticUnheated && inputs?.roof
                ? 'area-switch-all'
                : ''
              }
               ${(inputs?.underground &&
                !inputs?.atticUnheated &&
                !inputs?.roof) ||
                (!inputs?.underground &&
                  inputs?.atticUnheated &&
                  !inputs?.roof)
                ? 'area-switch-first'
                : ''
              }`}
          >
            <Card.Body>
              <Collapse title={listFields.area.name} expanded divider={false}>
                <div className="flex flex-wrap">
                  {listFields.area.fields.map((field) => (
                    <div className="mb-4 w-full items-center">
                      {field.type === 'switch' ? (
                        <div className="mb-5 flex items-center space-x-2">
                          <Text>{field.label}</Text>{' '}
                          <Switch
                            name={field.name}
                            onChange={(e) =>
                              handleChange({
                                target: {
                                  name: field.name,
                                  value: e.target.checked,
                                },
                              })
                            }
                            checked={inputs[field.label]}
                            readOnly={isInspector || inputs?.isEpiredDate}
                            disabled={isInspector || inputs?.isEpiredDate}
                          />
                        </div>
                      ) : (
                        ((inputs.underground === true &&
                          field.for === 'underground' &&
                          field.name === 'undergroundSurface') ||
                          (inputs.atticUnheated === true &&
                            field.for === 'atticUnheated' &&
                            field.name === 'atticUnheatedSurface') ||
                          (inputs.roof === true &&
                            field.for === 'roof' &&
                            field.name === 'roofSurface')) && (
                          <div className="w-full md:w-1/2">
                            <Input
                              name={field.name}
                              type={field.type}
                              underlined
                              fullWidth="true"
                              label={field.label}
                              className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                              value={inputs[field.name]}
                              color={helper[field.name]?.color}
                              helperColor={helper[field.name]?.color}
                              helperText={helper[field.name]?.text}
                              onChange={handleChange}
                              readOnly={isInspector || inputs?.isEpiredDate}
                              min={0}
                              step="any"
                            />
                            <br />
                          </div>
                        )
                      )}
                    </div>
                  ))}
                </div>
              </Collapse>
              {isInspector && (
                <Textarea
                  name="commentaireZoneNonChauffee"
                  underlined
                  label="Commentaire"
                  fullWidth="true"
                  className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                  onChange={handleChange}
                />
              )}
            </Card.Body>
          </Card>
          <br />
          {/* Type of floor */}
          <Card id={listFields.floor?.id}>
            <Card.Body>
              <Collapse title={listFields.floor.name} expanded divider={false}>
                <div className="flex flex-wrap">
                  {listFields.floor.fields.map((field) => (
                    <div className="mb-4 w-full items-center">
                      {field.type === 'switch' ? (
                        <div className="mb-5 flex items-center space-x-2">
                          <Text>{field.label}</Text>{' '}
                          <Switch
                            name={field.name}
                            onChange={(e) =>
                              handleChange({
                                target: {
                                  name: field.name,
                                  value: e.target.checked,
                                },
                              })
                            }
                            checked={inputs[field.label]}
                            readOnly={isInspector || inputs?.isEpiredDate}
                            disabled={isInspector || inputs?.isEpiredDate}
                          />
                        </div>
                      ) : (
                        <div className="w-full md:w-1/2">
                          <Input
                            name={field.name}
                            type={field.type}
                            underlined
                            fullWidth="true"
                            label={field.label}
                            className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                            value={inputs[field.name]}
                            color={helper[field.name]?.color}
                            helperColor={helper[field.name]?.color}
                            helperText={helper[field.name]?.text}
                            onChange={handleChange}
                            readOnly={isInspector || inputs?.isEpiredDate}
                          />
                          <br />
                          <br />
                        </div>
                      )}
                    </div>
                  ))}
                </div>
                {isInspector && (
                  <Textarea
                    name="commentaireIsolation"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          <br />
          {/* Type of installation */}
          <Card id="installation">
            <Card.Body>
              <Collapse title="Type d'installation" expanded divider={false}>
                {/* Type of installation */}
                <Radio.Group
                  name="typeInstallation"
                  onChange={(value) =>
                    handleChange({
                      target: {
                        name: 'typeInstallation',
                        value,
                      },
                    })
                  }
                  value={inputs?.typeInstallation}
                >
                  {listFields.installationType.default.map((row) => (
                    <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                      {row.map((field) => (
                        <div className="w-full">
                          <Radio
                            value={field.value}
                            isDisabled={isInspector || inputs?.isEpiredDate}
                          >
                            {field.name}
                          </Radio>
                        </div>
                      ))}
                    </div>
                  ))}
                </Radio.Group>
                <p
                  className={`text-initial mb-4 text-xs ${helper?.typeInstallation?.color === 'error' &&
                    '!text-[#F31260]'
                    }`}
                >
                  {helper?.typeInstallation?.text}
                </p>

                {/* More details */}
                <div className="mt-1.5 w-full max-w-sm">
                  <Card variant="bordered">
                    <Card.Body>
                      {listFields.installationType.moreDetails.map((field) => (
                        <Input
                          contentLeft={field.icon}
                          name={field.name}
                          type={field.type}
                          underlined
                          fullWidth="true"
                          label={field.label}
                          className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                          value={inputs[field.name]}
                          color={helper[field.name].color}
                          helperColor={helper[field.name].color}
                          helperText={helper[field.name].text}
                          onChange={handleChange}
                          readOnly={isInspector || inputs?.isEpiredDate}
                        />
                      ))}
                    </Card.Body>
                  </Card>
                </div>
                {isInspector && (
                  <Textarea
                    name="commentaireTypeInstallation"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          <br />

          {/* Type of heating */}
          <Card id="heating">
            <Card.Body>
              <Collapse title="Type de chauffage" expanded divider={false}>
                {/* Type of heating */}
                <Radio.Group
                  name="typeHeating"
                  onChange={(value) =>
                    handleChange({
                      target: {
                        name: 'typeHeating',
                        value,
                      },
                    })
                  }
                  value={inputs?.typeHeating}
                >
                  <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                    {listFields.heatingType.type.map((field) => (
                      <div className="w-full">
                        <Radio
                          value={field?.value}
                          isDisabled={isInspector || inputs?.isEpiredDate}
                        >
                          {field?.name}
                        </Radio>
                      </div>
                    ))}
                  </div>
                </Radio.Group>
                <p
                  className={`text-initial mb-4 text-xs ${helper?.typeHeating?.color === 'error' && '!text-[#F31260]'
                    }`}
                >
                  {helper?.typeHeating?.text}
                </p>

                {/* More details */}
                <div className="mt-1.5 w-full max-w-sm">
                  <Card variant="bordered">
                    <Card.Body>
                      {listFields.heatingType.moreDetails.map((field) => (
                        <Input
                          contentLeft={field.icon}
                          name={field.name}
                          type={field.type}
                          underlined
                          fullWidth="true"
                          label={field.label}
                          className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                          value={inputs[field.name]}
                          color={helper[field.name].color}
                          helperColor={helper[field.name].color}
                          helperText={helper[field.name].text}
                          onChange={handleChange}
                          readOnly={isInspector || inputs?.isEpiredDate}
                        />
                      ))}
                    </Card.Body>
                  </Card>
                </div>
                {isInspector && (
                  <Textarea
                    name="commentaireTypeChauffage"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          {/* Devices connected ----  */}
          <br />
          <Card>
            <Card.Body>
              <Collapse
                title="Nombre d'appareils à connecter"
                expanded
                divider={false}
              >
                <Input
                  contentLeft={<IconPcDisplay />}
                  name="unitToConnect"
                  type="number"
                  underlined
                  fullWidth="true"
                  label="Renseignez ici le nombre d'appareil à contrôler"
                  className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                  value={inputs.unitToConnect}
                  color={helper.unitToConnect.color}
                  helperColor={helper.unitToConnect.color}
                  helperText={helper.unitToConnect.text}
                  onChange={handleChange}
                  readOnly={isInspector || inputs?.isEpiredDate}
                  min={0}
                  step="any"
                />
                {isInspector && (
                  <Textarea
                    name="commentaireappareilsAconnecter"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>
          {/* Has any work been done? ---- */}
          <br />
          <Card id="work" className={`${inputs?.hasRecentWork && 'work'}`}>
            <Card.Body>
              <Collapse
                title="Est-ce que des travaux ont été effectués ?"
                expanded
                divider={false}
                css={{
                  height: 'auto !important',
                }}
              >
                {/* Has any work been done? */}
                <div className="space-between mb-4">
                  <div>
                    <div className="flex items-center space-x-2">
                      <div css={{ marginRight: '20px', paddingTop: '10px' }}>
                        Oui
                      </div>
                      <Switch
                        name="hasRecentWork"
                        onChange={(e) =>
                          handleChange({
                            target: {
                              name: 'hasRecentWork',
                              value: e.target.checked,
                            },
                          })
                        }
                        checked={inputs?.hasRecentWork}
                        readOnly={isInspector || inputs?.isEpiredDate}
                        disabled={isInspector || inputs?.isEpiredDate}
                      />
                    </div>
                    <p
                      className={`text-initial mb-4 text-xs ${helper?.hasRecentWork?.color === 'error' &&
                        '!text-[#F31260]'
                        }`}
                    >
                      {helper?.hasRecentWork?.text}
                    </p>
                  </div>
                </div>
                {/* More details */}
                {inputs?.hasRecentWork === true && (
                  <div className="grid grid-cols-1 gap-3 md:grid-cols-2">
                    {listFields.previousWork.map((field) => (
                      <div className="w-full">
                        <Card variant="bordered">
                          <Card.Body>
                            <Input
                              contentLeft={field.icon}
                              name={field.name}
                              type={field.type}
                              underlined
                              fullWidth="true"
                              label={field.label}
                              className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                              value={inputs[field.name]}
                              color={helper[field.name].color}
                              helperColor={helper[field.name].color}
                              helperText={helper[field.name].text}
                              onChange={handleChange}
                              readOnly={isInspector || inputs?.isEpiredDate}
                            />
                          </Card.Body>
                        </Card>
                      </div>
                    ))}
                  </div>
                )}
                {isInspector && (
                  <Textarea
                    name="commentaireTravauxEffectues"
                    underlined
                    label="Commentaire"
                    fullWidth="true"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    onChange={handleChange}
                  />
                )}
              </Collapse>
            </Card.Body>
          </Card>

          {listFields.importPicture.map((group) => (
            <>
              <br />
              <Card id={group.id} className="p-upload">
                <Card.Body>
                  <Collapse title={group.name} expanded divider={false}>
                    <div className="file_uploader">
                      <Dashboard
                        style={styles}
                        uppy={group.files}
                        {...group.params}
                      />
                      <p
                        className={`text-initial mb-4 text-xs ${helper[group.helper]?.color === 'error' &&
                          '!text-[#F31260]'
                          }`}
                      >
                        {helper[group.helper]?.text}
                      </p>
                    </div>
                  </Collapse>
                  {isInspector &&
                    group.name === 'Importez des photos de la maison' && (
                      <Textarea
                        name="commentairePhotoMaison"
                        underlined
                        label="Commentaire"
                        fullWidth="true"
                        className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                        onChange={handleChange}
                      />
                    )}
                  {isInspector &&
                    group.name === 'Importez des photos des fenêtres' && (
                      <Textarea
                        name="commentairePhotoFenetre"
                        underlined
                        label="Commentaire"
                        fullWidth="true"
                        className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                        onChange={handleChange}
                      />
                    )}
                  {isInspector &&
                    group.name === 'Importez des photos des portes' && (
                      <Textarea
                        name="commentairePhotoPorte"
                        underlined
                        label="Commentaire"
                        fullWidth="true"
                        className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                        onChange={handleChange}
                      />
                    )}
                  {isInspector &&
                    group.name === 'Importez des photos de la toiture' && (
                      <Textarea
                        name="commentairePhotoPorte"
                        underlined
                        label="Commentaire"
                        fullWidth="true"
                        className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                        onChange={handleChange}
                      />
                    )}
                  {isInspector && group.name === "Importez d'autres photos" && (
                    <Textarea
                      name="commentaireAutrePhoto"
                      underlined
                      label="Commentaire"
                      fullWidth="true"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      onChange={handleChange}
                    />
                  )}
                </Card.Body>
              </Card>
              <br />
            </>
          ))}
          <br />
          {/* Inspector note? ---- */}
          {isInspector && (
            <Card>
              <Card.Body>
                <Collapse title="inspectorNote" expanded divider={false}>
                  <Input
                    contentLeft={<LinearIndicatorIcon />}
                    name="inspectorNote"
                    type="number"
                    underlined
                    fullWidth="true"
                    label="Donnez une note au mesureur"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    value={inputs.inspectorNote}
                    color={helper.unitToConnect.color}
                    helperColor={helper.unitToConnect.color}
                    helperText={helper.unitToConnect.text}
                    onChange={handleChange}
                    min={0}
                    max={100}
                    step={5}
                  />
                </Collapse>
              </Card.Body>
            </Card>
          )}
          {/* Actions Button  */}
          <div className="m-auto my-6 flex max-w-xl justify-between">
            <Button
              css={{
                border: '1px solid',
                borderColor: '#DC3838',
                color: 'white',
                backgroundColor: '#DC3838',
              }}
              auto
              onClick={() => navigator(-1)}
            >
              Annuler
            </Button>
            <Spacer x={0.5} />
            <Button type="submit" color="success" auto>
              {isLoading && (
                <Loading
                  style={{
                    marginRight: '1rem',
                  }}
                  color="currentColor"
                  size="sm"
                />
              )}
              Valider
            </Button>
          </div>
        </form>
      )}
      {notification?.text && (
        <div
          className={`fixed bottom-4 right-4 z-[99999] flex rounded px-3 py-2.5 text-sm text-white transition-all delay-200 duration-300
        ${showNotification
              ? 'translate-y-0 opacity-100'
              : 'translate-y-2 opacity-0'
            } ${notification.type === 'error' ? 'bg-red-600' : 'bg-green-600'}`}
        >
          <span>{notification.text}</span>
          <svg
            onClick={() => {
              closeNotif();
            }}
            className="ml-2 h-5 w-5 cursor-pointer"
            width="24"
            height="24"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default UpdateReport;
