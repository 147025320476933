import PropTypes from 'prop-types';
import React from 'react';

const IndicatorIcon = ({ x, y, width, height }) => {
  return (
    <svg
      x={x}
      y={y}
      width={width}
      height={height}
      id="e5BHyleXmoi1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 28 28"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
    >
      <g>
        <circle
          r="13"
          transform="translate(14 14)"
          opacity="0.16"
          fill="none"
          stroke="#dc3838"
          strokeWidth="2"
        />
        <g transform="matrix(-1 0 0 1 24 5)">
          <g>
            <circle r="7" transform="translate(10 9)" fill="#fff" />
            <circle
              r="5"
              transform="translate(10 9)"
              fill="none"
              stroke="#dc3838"
              strokeWidth="4"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

IndicatorIcon.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default IndicatorIcon;
