import { publicRequest, userRequest } from '../../api';
import {
  appFailure,
  appStart,
  appSuccess,
  openMenu,
  openSidebar,
} from '../reducers/appReducer';
import { statsSuccess } from '../reducers/enterpriseReducer';
import { expired, loginReset } from '../reducers/userReducer';

export const dispatchMenu = (state) => async (dispatch) => {
  try {
    dispatch(openMenu(state));
  } catch (error) {
    dispatch(openMenu(false));
  }
};

export const dispatchAside = (state) => async (dispatch) => {
  try {
    dispatch(openSidebar(state));
  } catch (error) {
    dispatch(openSidebar(false));
  }
};

export const getStats = () => async (dispatch) => {
  dispatch(appStart());
  try {
    const res = await userRequest.get('admin/dashboard/index');
    dispatch(appSuccess(res.data));
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(appFailure(err.response.data));
    }
  }
};

export const getEnterprisesStats = () => async (dispatch) => {
  dispatch(appStart());
  try {
    const res = await userRequest.get(`entreprise/dashboard`);
    dispatch(appSuccess(res.data));
    dispatch(statsSuccess(res.data));
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(appFailure(err.response.data));
    }
  }
};

export const getInspectorsStats = () => async (dispatch) => {
  dispatch(appStart());
  try {
    const res = await userRequest.get(`inspecteur/dashboard`);
    dispatch(appSuccess(res.data));
  } catch (err) {
    dispatch(appFailure(err.response.data));
    if (err.response.status === 401 || err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(appFailure(err.response.data));
    }
  }
};

export const getMesurersStats = () => async (dispatch) => {
  dispatch(appStart());
  try {
    const res = await userRequest.get(`mesureur/dashboard`);
    dispatch(appSuccess(res.data));
  } catch (err) {
    dispatch(appFailure(err.response.data));
    if (err.response.status === 401 || err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(appFailure(err.response.data));
    }
  }
};

export const getTransactionsHistory = async (page, pageSize) => {
  return userRequest.get(
    `admin/historiquepaiement/historiquepaiement?page=${page}&per_page=${pageSize}`
  );
};

export const getEnterpriseTransactionsHistory = async (page, pageSize) => {
  return userRequest.get(
    `entreprise/historiquepaiement?page=${page}&per_page=${pageSize}`
  );
};

export const getPeriodicTransactionsHistory = async (data, page, pageSize) => {
  return userRequest.post(
    `admin/historiquepaiement/filterbyperiode?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getEnterprisePeriodicTransactionsHistory = async (
  data,
  page,
  pageSize
) => {
  return userRequest.post(
    `entreprise/historiquepaiementfilterbyperiode?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getInspectorAuditsStats = async (data) => {
  return userRequest.post(`inspecteur/demandeauditstatistique`, data);
};

export const getMesurerAuditsStats = async (data) => {
  return userRequest.post(`mesureur/demandeauditstatistique`, data);
};

export const getInspectorClientStats = async (data) => {
  return userRequest.post(`inspecteur/clientstatistique`, data);
};

export const getMesurerClientStats = async (data) => {
  return userRequest.post(`mesureur/clientstatistique`, data);
};

export const getInspectorChart = async (data) => {
  return userRequest.post(`inspecteur/demandeauditshowgraphe`, data);
};

export const getMesurerChart = async (data) => {
  return userRequest.post(`mesureur/demandeauditshowgraphe`, data);
};

export const getEnterpriseAuditsStats = async (data) => {
  return userRequest.post(`entreprise/demandeauditstatistique`, data);
};

export const getEnterpriseClientStats = async (data) => {
  return userRequest.post(`entreprise/clientstatistique`, data);
};

export const getEnterpriseChart = async (data) => {
  return userRequest.post(`entreprise/demandeauditshowgraphe`, data);
};

export const getAuditsStats = async (data) => {
  return userRequest.post(`admin/demandeauditstatistique`, data);
};

export const getClientStats = async (data) => {
  return userRequest.post(`admin/clientstatistique`, data);
};

export const getChart = async (data) => {
  return userRequest.post(`admin/demandeauditshowgraphe`, data);
};

export const creditInspectorAccount = async (data) => {
  return userRequest.post(`entreprise/creditercompteinspecteur`, data);
};

export const creditMesurerAccount = async (data) => {
  return userRequest.post(`entreprisemesureur/creditercomptemesureur`, data);
};

export const getCrediting = async () => {
  return userRequest.get(`entreprise/creditationinspecteur`);
};

export const getMesurerCrediting = async () => {
  return userRequest.get(`entreprisemesureur/creditationmesureur`);
};

export const reverseLocation = async (lat, long) => {
  return publicRequest.get(
    `https://api.mapbox.com/geocoding/v5/mapbox.places/${lat},${long}.json?access_token=${process.env.REACT_APP_MAPBOX_MAPS_API_KEY}`
  );
};
