import axios from 'axios';

export const BASE_URL = process.env.REACT_APP_API_URL;
export const TOKEN =
  JSON.parse(localStorage.getItem('persist:root')) !== null &&
  JSON.parse(localStorage.getItem('persist:root')).user &&
  JSON.parse(JSON.parse(localStorage.getItem('persist:root')).user)
    .currentUser &&
  JSON.parse(JSON.parse(localStorage.getItem('persist:root')).user).currentUser
    .access_token
    ? JSON.parse(JSON.parse(localStorage.getItem('persist:root')).user)
        .currentUser.access_token
    : '';

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: {
    Authorization: `Bearer ${TOKEN}`,
  },
});
