/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { INACTIVE, INTERNAL, UNAUTHORIZED } from '../constants';

const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    isEnterprise: false,
    isMesurer: false,
    isAdmin: false,
    isInspector: false,
    isLoading: false,
    error: null,
  },
  reducers: {
    loginStart: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    loginSuccess: (state, action) => {
      state.isEnterprise = action.payload.role[0] === 'entreprise';
      state.isMesurer = action.payload.role[0] === 'mesureur';
      state.isAdmin = action.payload.role[0] === 'admin';
      state.isInspector = action.payload.role[0] === 'inspecteur';
      if (!action.payload.available) {
        state.isLoading = false;
        state.error = INACTIVE;
      }
      state.currentUser = action.payload;
    },
    loginFailure: (state, action) => {
      state.isLoading = false;
      state.error = action.payload.error;
    },
    loginReset: (state) => {
      state.currentUser = state.currentUser?.email
        ? {
            email: state.currentUser.email,
          }
        : null;
    },
    resetUser: (state) => {
      state.isEnterprise = false;
      state.isAdmin = false;
      state.isLoading = false;
      state.error = null;
    },
    expired: (state) => {
      state.isLoading = false;
      state.error = UNAUTHORIZED;
    },
    start: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    success: (state, action) => {
      const data = action.payload;
      state.isLoading = false;
      if (data)
        state.currentUser = {
          ...state.currentUser,
          ...data,
        };
    },
    failure: (state) => {
      state.isLoading = false;
      state.error = INTERNAL;
    },
    reset: (state) => {
      state.error = null;
    },
    userSuccess: (state) => {
      state.isLoading = false;
    },
    confirmEmail: (state, action) => {
      state.currentUser = {
        email: action.payload?.email,
      };
      state.isEnterprise = action.payload?.account_type === 'company';
    },
  },
});

export const {
  expired,
  loginStart,
  loginSuccess,
  loginFailure,
  loginReset,
  start,
  success,
  failure,
  reset,
  resetUser,
  userSuccess,
  confirmEmail,
  resetOth,
} = userSlice.actions;
export default userSlice.reducer;
