/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/fr';
import 'moment-timezone';

import {
  Badge,
  Button,
  Input,
  Loading,
  Modal,
  Row,
  Spacer,
  Text,
  useModal,
} from '@nextui-org/react';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  addAvailability,
  deleteAvailability,
  getAvailabilities,
  updateAvailability,
  updateMeeting,
} from '../redux/api/enterprise';
import { expired, loginReset } from '../redux/reducers/userReducer';
import { renderCharacter, renderDate, renderTime } from '../utils/common';
// use paris
moment.tz.setDefault('Europe/Paris');
momentLocalizer(moment);

const Agenda = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const [events, setEventList] = useState([]);
  const [message, setMessage] = useState(null);
  const { visible, setVisible, bindings } = useModal();
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingChange, setLoadingChange] = useState(false);
  const [meetingInformation, setMeetingInformation] = useState(null);
  const [inputs, setInputs] = useState({});
  const [view, setView] = useState('week');
  const [mode, setMode] = useState(null);

  const getAvailabilitiesList = () => {
    setIsLoading(true);
    getAvailabilities()
      .then((res) => {
        const listMeeting = [];
        res.data.data.forEach((item) => {
          listMeeting.push({
            id: item.id,
            type: item.available ? 'available' : 'busy',
            title: item.available ? 'Disponible' : 'Occupé',
            startDate: moment(item.date_heure).subtract(1, 'hours').toDate(),
            endDate: moment(item.date_heure).subtract(1, 'hours').toDate(),
            allDay: false,
            list: item?.demande_rdvz?.original?.data || [],
            entreprise_info: item?.entreprise_info?.original?.data,
          });
        });
        setEventList(listMeeting);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
        if (err.response?.status === 401 || err.response?.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        }
      });
  };

  useEffect(() => {
    getAvailabilitiesList();
  }, []);

  const navigate = (route) => {
    navigator(route);
  };

  const closeHandler = () => {
    navigate('/meeting');
  };

  function closeNotif() {
    setTimeout(() => {
      setMessage({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 300);
    }, 100);
  }
  const showNotif = (text, type) => {
    setTimeout(() => {
      setNotification({
        text,
        type,
      });
      setTimeout(() => {
        setShowNotification(true);
      }, 100);
    }, 500);

    setTimeout(() => {
      setNotification({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 6000);
    }, 5000);
  };

  const updateThisMeeting = (item) => {
    if (loading || loadingChange) return;

    setLoadingChange(true);
    updateMeeting({
      id: item?.id,
      confirm_rdvz: !item.confirm_rdvz,
    })
      .then(() => {
        setLoadingChange(false);
        setTimeout(() => {
          showNotif('Le rendez-vous a été mis à jour avec succès', 'success');
        }, 300);
        getAvailabilitiesList();
        setVisible(false);
      })
      .catch((err) => {
        setLoadingChange(false);
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          setLoading(false);
          showNotif('Une erreur est survenue', 'error');
        }
      });
  };

  // Setup the localizer by providing the moment (or globalize) Object
  // to the correct localizer.
  const localizer = momentLocalizer(moment);
  moment.locale('fr');

  const formats = {
    eventTimeRangeFormat: ({ start }) => {
      return renderTime(start);
    },
    dayRangeHeaderFormat(_ref3, culture, local) {
      const { start } = _ref3;
      const { end } = _ref3;
      return `${local.format(start, 'D MMM YYYY', culture)} - ${local.format(
        end,
        'D MMM YYYY',
        culture
      )}`;
    },
  };

  const eventStyleGetter = (event) => {
    const backgroundColor = `#${event.hexColor}`;
    const style =
      event.type === 'busy'
        ? {
            backgroundColor,
            opacity: 0.9,
          }
        : {
            backgroundColor: '#00bfa5',
            opacity: 0.9,
          };
    return {
      style,
    };
  };

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleSelectSlot = (slotInfo) => {
    if (loadingChange) return;

    const { start } = slotInfo;

    setVisible(true);
    setMode('create');

    setInputs({
      date: moment(start, 'YYYY-MM-DD', 'fr').format('YYYY-MM-DD'),
      time: moment(start, 'hh:mm', 'fr').format('HH:mm'),
    });

    setMeetingInformation({
      ...slotInfo,
      type: 'available',
      audit_name: 'Disponible',
    });
  };

  const handleDelete = () => {
    if (loading) return;

    setLoading(true);

    showNotif(
      "Votre disponibilité est entrain d'être supprimée.. Patientez un peu",
      'success'
    );

    deleteAvailability(meetingInformation?.id)
      .then(() => {
        setEventList(
          events.filter((event) => event.id !== meetingInformation?.id)
        );
        showNotif(
          'Votre disponibilité honoraire a été supprimée avec succès',
          'success'
        );
        setLoading(false);
        setVisible(false);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          showNotif('Une erreur est survenue', 'error');
        }
      });
  };

  const handleSubmitDate = () => {
    if (loadingChange || (!inputs.date && !inputs.time)) return;

    setLoadingChange(true);

    const date = new Date(inputs.date).setHours(
      inputs?.time?.split(':')[0],
      inputs?.time?.split(':')[1],
      0,
      0
    );

    // check if date is in the past
    if (new Date(date) < new Date()) {
      showNotif('Vous ne pouvez pas utiliser une date passée', 'error');
      setLoadingChange(false);
      return;
    }

    if (!mode) {
      const list = [...events].filter((item) => item.type === 'available');

      const ev = list.find(
        (item) => item.id?.toString() === meetingInformation?.id?.toString()
      );

      const evIdx = list.findIndex(
        (item) => item.id?.toString() === meetingInformation?.id?.toString()
      );

      list.forEach((item) => {
        if (item.startDate === new Date(date)) {
          showNotif('Cette honoraire est déjà enregistrée', 'error');
          // update selected date in the list
        }
      });

      updateAvailability({
        date_heure: moment(date).add(1, 'hours'),
        id: meetingInformation?.id,
      })
        .then(() => {
          showNotif(
            'Votre disponibilité a été modifiée avec succès',
            'success'
          );

          list[evIdx] = {
            ...ev,
            startDate: new Date(date),
            endDate: new Date(date),
          };

          setEventList(list);
          setLoadingChange(false);
          setVisible(false);
          setInputs({});
          closeHandler();
        })
        .catch((err) => {
          setLoadingChange(false);
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            showNotif('Une erreur est survenue', 'error');
          }
        });
    } else {
      showNotif(
        "Votre disponibilité est entrain d'être ajoutée.. Patientez un peu",
        'success'
      );

      addAvailability({
        date_heure: moment(date).add(1, 'hours'),
      })
        .then(() => {
          setEventList([
            ...events,
            {
              id: events.length + 1,
              type: 'available',
              title: 'Disponible',
              startDate: moment(date).toDate(),
              endDate: moment(date).toDate(),
              allDay: false,
            },
          ]);

          showNotif('Votre disponibilité a été ajouté avec succès', 'success');
          setLoadingChange(false);
          setInputs({});
          setVisible(false);
          closeHandler();
        })
        .catch((err) => {
          setLoadingChange(false);
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            showNotif('Une erreur est survenue', 'error');
          }
        });
    }
  };

  console.log('mode: ', mode);
  return (
    <>
      {isLoading && !loading && (
        <>
          <div className="fixed inset-0 z-[99] h-full min-h-full overflow-hidden bg-black bg-opacity-10" />
          <div className="absolute top-[50%] left-[40%] z-[991] h-[100px] w-[100px] lg:left-[48%]">
            <Loading
              loadingCss={{
                $$loadingSize: '100px',
                $$loadingBorder: '10px',
                $$LoadingColors: '#DC3838',
              }}
            />
          </div>
        </>
      )}
      <div className="w-full">
        <div className="w-full px-8">
          <div className="px-8 py-8">
            <Row align="flex-end">
              <Button
                flat
                css={{
                  color: '#DC3838',
                  backgroundColor: '#f8d7d7',
                  width: 'fit-content',
                  padding: '5px 10px',
                }}
                onClick={() => {
                  setVisible(true);
                  setMode('create');
                  setMeetingInformation({
                    id: null,
                    type: 'available',
                    title: 'Disponible',
                    startDate: new Date(),
                    endDate: new Date(),
                    allDay: false,
                  });
                }}
              >
                Ajouter une disponibilité
              </Button>
            </Row>
            <Spacer y={1} />
            <div style={{ height: '700px' }}>
              <Calendar
                localizer={localizer}
                formats={formats}
                events={events}
                min={new Date(2022, 10, 0, 7, 0, 0)}
                max={new Date(2070, 10, 0, 19, 0, 0)}
                defaultView="week"
                views={['month', 'week', 'day']}
                onView={(e) => setView(e)}
                startAccessor="startDate"
                endAccessor="endDate"
                culture="fr"
                messages={{
                  week: 'Semaine',
                  work_week: 'Semaine de travail',
                  day: 'Jour',
                  month: 'Mois',
                  previous: 'Précédent',
                  next: 'Suivant',
                  today: `Aujourd’hui`,
                  time: 'Heure',
                  event: 'Information',
                  noEventsInRange: 'Aucun rendez-vous dans la plage',
                  showMore: (total) => `+${total} plus`,
                }}
                selectable
                onSelectSlot={handleSelectSlot}
                popup
                onSelectEvent={(event) => {
                  setVisible(true);
                  setMode(null);
                  setMeetingInformation({
                    ...event,
                    audit_name: event.title,
                    date_meeting: event?.startDate,
                  });
                  if (event?.type === 'available') {
                    setInputs({
                      date: moment(event.startDate, 'YYYY-MM-DD', 'fr').format(
                        'YYYY-MM-DD'
                      ),
                      time: moment(event.startDate, 'hh:mm', 'fr').format(
                        'HH:mm'
                      ),
                    });
                  }
                }}
                eventPropGetter={eventStyleGetter}
              />
            </div>
          </div>
        </div>
        <Modal
          closeButton
          scroll
          width="600px"
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          onClose={closeHandler}
          {...bindings}
        >
          <Modal.Header justify="start">
            <Text h2 b id="modal-title" size={18}>
              {meetingInformation?.list?.length > 0 &&
                !mode &&
                'Liste des demandes'}
              {mode === 'create' && 'Ajouter une disponibilité'}
              {meetingInformation?.type === 'available' &&
                mode !== 'create' &&
                meetingInformation?.list?.length === 0 &&
                'Disponibilité'}
            </Text>
          </Modal.Header>
          <Modal.Body>
            <div className="w-full justify-between space-y-4">
              {mode !== 'create' &&
                meetingInformation?.list?.map((item, index) => (
                  <div
                    key={item.id}
                    className={`w-full space-y-4 ${
                      meetingInformation?.list?.length !== index + 1 &&
                      'border-b-2 pb-4'
                    } ${
                      meetingInformation?.list?.findIndex(
                        (e) => e.confirm_rdvz
                      ) !== -1 &&
                      !item.confirm_rdvz &&
                      'opacity-50'
                    }`}
                  >
                    <div>
                      <strong>Audit : </strong>
                      {item?.nom_audit}
                    </div>
                    <div>
                      <strong>Client :</strong>{' '}
                      {`${renderCharacter(
                        item?.client_info?.original?.data?.users?.nom
                      )} ${renderCharacter(
                        item?.client_info?.original?.data?.users?.prenom
                      )}`}
                    </div>
                    <div>
                      <strong>Entreprise : </strong>
                      {meetingInformation?.entreprise_info?.nom_entreprise ||
                        'Non disponible'}
                    </div>
                    <div>
                      <strong>Date de Rendez vous:</strong>{' '}
                      {renderDate(meetingInformation?.date_meeting)}
                    </div>
                    <div>
                      <strong>Confirmer :</strong>
                      {'  '}
                      <Badge
                        css={{
                          backgroundColor: meetingInformation?.confirm_rdvz
                            ? '#17C964'
                            : '#F31260',
                        }}
                        className="inspector-badge"
                        enableShadow
                        disableOutline
                      >
                        {item?.confirm_rdvz ? 'Oui' : 'Non'}
                      </Badge>
                    </div>
                    <div className="flex justify-end">
                      {meetingInformation?.list?.length && (
                        <Button
                          auto
                          css={{
                            marginRight: '10px',
                            backgroundColor: item?.confirm_rdvz && '#DC3838',
                          }}
                          disabled={
                            (meetingInformation?.list?.findIndex(
                              (e) => e.confirm_rdvz
                            ) !== -1 &&
                              !item.confirm_rdvz) ||
                            item?.confirm_rdvz
                          }
                          onClick={() => updateThisMeeting(item)}
                        >
                          {loadingChange && (
                            <Loading
                              style={{
                                marginRight: '1rem',
                              }}
                              color="currentColor"
                              size="sm"
                            />
                          )}
                          {item?.confirm_rdvz ? 'Rejeter' : 'Confirmer'}
                        </Button>
                      )}
                    </div>
                  </div>
                ))}

              {((meetingInformation?.type === 'available' &&
                !meetingInformation?.list?.length) ||
                mode === 'create') && (
                <div className="w-full space-y-4">
                  <form onSubmit={handleSubmitDate}>
                    <Row align="center">
                      <Input
                        name="date"
                        type="date"
                        value={inputs.date}
                        css={{
                          width: '100%',
                        }}
                        helperText="Vous pouvez changer la date et l'heure"
                        min={moment(new Date(), 'YYYY-MM-DD', 'fr').format(
                          'YYYY-MM-DD'
                        )}
                        onChange={handleChange}
                        animated={false}
                        required
                      />
                      <Spacer x={0.2} />
                      <Input
                        name="time"
                        type="time"
                        value={inputs.time}
                        css={{
                          width: '100%',
                        }}
                        min={new Date()}
                        onChange={handleChange}
                        animated={false}
                        required
                      />
                    </Row>
                  </form>
                </div>
              )}
              <br />
            </div>
          </Modal.Body>
          <Modal.Footer
            justify="start"
            className="!block w-full justify-between md:!flex"
          >
            <div
              id="message"
              className={`mt-4 w-full flex-1 text-left text-sm md:mb-0 md:mt-2 ${
                message?.type === 'success' ? 'text-green-500' : 'text-red-500'
              } `}
            >
              {message?.text}
            </div>
            <div className="m-auto flex justify-center md:justify-start">
              {(meetingInformation?.type === 'available' ||
                mode === 'create') && (
                <Button
                  auto
                  css={{
                    marginRight: '10px',
                  }}
                  onClick={handleSubmitDate}
                >
                  {loadingChange && (
                    <Loading
                      style={{
                        marginRight: '1rem',
                      }}
                      color="currentColor"
                      size="sm"
                    />
                  )}
                  {mode ? 'Ajouter' : 'Mettre à jour'}
                </Button>
              )}

              {meetingInformation?.type === 'available' &&
                mode !== 'create' && (
                  <Button
                    css={{
                      border: '1px solid',
                      borderColor: '#DC3838',
                      color: 'white',
                      backgroundColor: '#DC3838',
                    }}
                    auto
                    onClick={handleDelete}
                  >
                    {loading && (
                      <Loading
                        style={{
                          marginRight: '1rem',
                        }}
                        color="currentColor"
                        size="sm"
                      />
                    )}
                    Supprimer
                  </Button>
                )}
            </div>
          </Modal.Footer>
        </Modal>
        {notification?.text && (
          <div
            className={`fixed bottom-4 right-4 z-[99999] flex rounded px-3 py-2.5 text-sm text-white transition-all delay-200 duration-300
        ${
          showNotification
            ? 'translate-y-0 opacity-100'
            : 'translate-y-2 opacity-0'
        } ${notification.type === 'error' ? 'bg-red-600' : 'bg-green-600'}`}
          >
            <span>{notification.text}</span>
            <svg
              onClick={() => {
                closeNotif();
              }}
              className="ml-2 h-5 w-5 cursor-pointer"
              width="24"
              height="24"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    </>
  );
};

export default Agenda;
