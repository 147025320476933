/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import * as Ably from 'ably';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import sound from '../assets/sounds/h42vwcd.mp3';
import notificationSound from '../assets/sounds/PM_BlurryDreams_169_254.mp3';
import Aside from '../components/Aside';
import MobileAside from '../components/MobileAside';
import Navbar from '../components/Navbar';
import { get } from '../redux/api/user';
import { updateState } from '../redux/reducers/appReducer';
import {
  expired,
  failure,
  loginReset,
  loginStart,
  userSuccess,
} from '../redux/reducers/userReducer';
import { remove } from '../utils/array-utils';
import Home from './Home';

const Index = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const audio = new Audio(sound);
  const [deviceType, setDeviceType] = useState('Mobile');
  const [realtime, setRealTime] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const isAuth = () => {
    return (
      path === '/login' ||
      path === '/register' ||
      path === '/signin' ||
      path === 'confirm' ||
      path === '/confirmed' ||
      path === '/'
    );
  };

  const navigate = () => {
    if (user?.currentUser?.missing_d) navigator('/settings');
  };

  useEffect(() => {
    dispatch(loginStart());
    get().then(
      (res) => {
        dispatch(userSuccess());
        if (user?.isEnterprise) {
          dispatch(
            updateState({
              ...app.state,
              enterprise: res.data.entreprise?.original?.data,
              user: res.data.user,
            })
          );
        } else if (user?.isAdmin) {
          dispatch(
            updateState({
              ...app.state,
              user: res.data,
            })
          );
        } else if (user?.isMesurer) {
          dispatch(
            updateState({
              ...app.state,
              mesurer: res.data.mesureur.original.data,
              user: res.data.mesureur.original.data,
            })
          );
        } else {
          dispatch(
            updateState({
              ...app.state,
              inspector: res.data.inspecteur,
              user: res.data.inspecteur,
            })
          );
        }
      },
      (err) => {
        if (err.response.status === 401) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          dispatch(failure(err.response.data));
        }
      }
    );
  }, []);

  useEffect(() => {
    navigate();
  }, [location]);

  useEffect(() => {
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator.userAgent
      )
    ) {
      setDeviceType('Mobile');
    } else {
      setDeviceType('Desktop');
    }
  }, []);

  const notify = () => {
    audio.play();
  };
  const notifSounds = () => {
    const soundAudio = new Audio(notificationSound);
    soundAudio.play();
  };

  useEffect(() => {
    setRealTime(new Ably.Realtime(process.env.REACT_APP_ABLY_API_KEY));
  }, []);

  useEffect(() => {
    if (realtime && app.currentState) {
      realtime.channels.get('notifications').subscribe((msg) => {
        let userId = '';

        if (user.isEnterprise) {
          userId = app.currentState?.enterprise?.users_id;
        } else if (user.isAdmin) {
          userId = app.currentState?.user?.users_id;
        } else if (user.isMesurer) {
          userId = app.currentState?.mesurer?.users_id;
        } else {
          userId = app.currentState?.inspector?.users_id;
        }
        if (
          !window.location.pathname?.split('/')[1].includes('chats') &&
          userId !== msg?.data?.client
        ) {
          notify();
          setNotifications((arr) => [...arr, msg]);
          setTimeout(() => {
            // remove first
            setNotifications((arr) => arr.slice(1));
          }, 5000);
          if (msg?.data?.client === app.currentState?.user?.id) {
            setTimeout(() => {
              // remove first
              const newArr = [...notifications];
              newArr.splice(0, 1);
              setNotifications(newArr);
            }, 5000);
          }
        }
      });
    }
    realtime?.channels
      ?.get(`entreprise-${user?.currentUser?.user?.original?.data?.id}`)
      .subscribe((msg) => {
        notifSounds();
        setNotifications((arr) => [...arr, msg?.data]);
        setTimeout(() => {
          // remove first
          setNotifications((arr) => arr.slice(1));
        }, 5000);
      });
  }, [realtime]);

  return (
    <main>
      {!isAuth() && (
        <div className="flex">
          <div>{deviceType === 'Desktop' && <Aside />}</div>
          <div className="w-full">
            <Navbar />
            {deviceType === 'Mobile' && <MobileAside />}

            <Outlet />
            <ul className="notification-box">
              <AnimatePresence initial={false}>
                {notifications.map((notification) => (
                  <motion.li
                    key={notification?.id}
                    positionTransition
                    initial={{ opacity: 0, y: 50, scale: 0.3 }}
                    animate={{ opacity: 1, y: 0, scale: 1 }}
                    exit={{
                      opacity: 0,
                      scale: 0.5,
                      transition: { duration: 0.2 },
                    }}
                    className="notification-popup cursor-pointer rounded-lg bg-white/60 shadow backdrop-blur-xl hover:bg-white/80 hover:shadow-lg"
                  >
                    <div className="flex justify-between space-x-2">
                      <h1
                        className="text-md truncate px-6 pt-6 font-medium text-slate-700"
                        onClick={() => navigator('/chats')}
                      >
                        {notification?.data?.sender}
                      </h1>
                      <svg
                        onClick={() =>
                          setNotifications(remove(notifications, notification))
                        }
                        width="24"
                        height="24"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </div>
                    <div
                      onClick={() => !notification?.text && navigator('/chats')}
                      className="flex items-center justify-between px-6 pb-6"
                    >
                      <p
                        href="#"
                        className={`inline-flex items-center space-x-1 truncate text-sm text-slate-500 hover:text-indigo-600 ${
                          notification?.text && 'w-full'
                        }`}
                      >
                        {notification?.data?.message || notification?.text}
                      </p>
                    </div>
                  </motion.li>
                ))}
              </AnimatePresence>
            </ul>
          </div>
        </div>
      )}
      {path === '/' && <Home />}
    </main>
  );
};

export default Index;
