import { Button, Input, Loading } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import IconMail from '../components/icons/IconMail';
import { request } from '../redux/api/user';
import { confirmEmail, loginReset, reset } from '../redux/reducers/userReducer';

const ResetPassword = () => {
  const navigator = useNavigate();
  const [email, setEmail] = useState('');
  const user = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [helper, setHelper] = useState({
    text: '',
    color: '',
  });
  const [loading, setLoading] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [message, setMessage] = useState({
    type: '',
    text: '',
  });

  useEffect(() => {
    dispatch(loginReset());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();

    setMessage('');
    if (email === '') {
      setHelper({
        text: 'Email requis',
        color: 'error',
      });
    }

    if (email === '') {
      return;
    }

    if (email) {
      setLoading(true);
      request({ email })
        .then(() => {
          setLoading(false);
          if (isSent)
            setMessage({
              type: 'success',
              text: 'Un nouveau mail vous a été envoyé',
            });
          setIsSent(true);
          dispatch(confirmEmail({ email }));
        })
        .catch((err) => {
          setLoading(false);
          if (err?.response?.data?.message === 'user not found') {
            setMessage({
              type: 'error',
              text: 'Cette adresse email ne correspond à aucun compte',
            });
          } else {
            setMessage({
              type: 'error',
              text: "Une erreur s'est produite",
            });
          }
        });
    }
  };

  useEffect(() => {
    setHelper({
      text: '',
      color: 'default',
    });
  }, [email]);

  const navigate = (route) => {
    navigator(route);
  };

  useEffect(() => {
    if (
      (user.currentUser && user.currentUser?.available && !error) ||
      (user.currentUser && user?.isAdmin)
    ) {
      navigate('/dashboard');
      // reload page
      window.location.reload();
    }
  }, [user]);

  return (
    <div className="h-full bg-white">
      <div className="flex h-full min-h-screen justify-center">
        <div
          className="mx-auto flex w-full flex-col items-center bg-bottom bg-no-repeat md:w-1/2"
          style={{
            backgroundImage: 'url("/images/home-light.svg")',
            backgroundSize: '100% auto',
          }}
        >
          <div className="w-full items-center text-primary md:flex md:pr-4">
            <div className="w-1/3 px-4">
              <img
                className="py-8 md:h-44 md:py-0"
                src="/images/logo-large.svg"
                alt=""
              />
            </div>
          </div>
          {!isSent && (
            <div className="w-full px-4 md:px-16">
              <div className="text-start">
                <div className="max-w-xl space-y-2 text-2xl text-gray-500">
                  <p className="">
                    Entrez votre adresse e-mail pour demander une
                  </p>
                  <p className="">réinitialisation de mot de passe</p>
                </div>
              </div>
              <form onSubmit={handleClick}>
                <div className="mt-4 flex h-full w-full max-w-md flex-col items-start justify-center space-y-8">
                  <Input
                    contentLeft={<IconMail />}
                    underlined
                    fullWidth="true"
                    label="Email"
                    type="email"
                    className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                    color={helper.color}
                    helperColor={helper.color}
                    helperText={helper.text}
                    onChange={(e) => setEmail(e.target.value)}
                  />

                  {message && (
                    <div
                      className={`w-full text-center text-sm
              ${
                message?.type === 'error' ? 'text-red-500' : 'text-green-500'
              } `}
                    >
                      {message.text}
                    </div>
                  )}
                  <div className="w-full">
                    <Button
                      type="submit"
                      css={{
                        borderRadius: '0px', // radii.xs
                        border: '1px solid',
                        borderColor: '#DC3838',
                        color: 'white',
                        backgroundColor: '#DC3838',
                        width: '100%',
                      }}
                      size="xl"
                    >
                      {loading && (
                        <Loading
                          style={{
                            marginRight: '1rem',
                          }}
                          color="currentColor"
                          size="sm"
                        />
                      )}
                      DEMANDER UNE REINITIALISATION
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          )}
          {isSent && (
            <div className="w-full px-4 md:px-16">
              <div className="text-start">
                <div className="max-w-xl space-y-2 text-2xl text-gray-500">
                  <h2 className="font-bold">Mail de réinitialisation</h2>
                  <p className="">
                    Veuillez vérifier votre boîte mail et confirmer le mail de
                    réinitialisation que nous venons de vous envoyer au{' '}
                    {user?.currentUser?.email} pour changer votre mot de passe
                  </p>
                </div>
              </div>

              <div className="mt-8 text-lg">
                <p className="">Vous n&apos;avez pas reçu le lien ?</p>
                <Button
                  type="button"
                  onClick={handleClick}
                  css={{
                    color: '#DC3838',
                    paddingLeft: '0px',
                    paddingRight: '0px',
                    justifyContent: 'start',
                  }}
                  light
                  auto
                  animated={false}
                >
                  Renvoyer un mail
                </Button>
                <p className="text-gray-500">
                  {loading && (
                    <Loading
                      style={{
                        marginRight: '1rem',
                      }}
                      color="currentColor"
                      size="sm"
                    />
                  )}
                </p>

                <p className="mt-4 text-gray-500">
                  {message && (
                    <div
                      className={`w-full text-left text-sm
              ${
                message?.type === 'error' ? 'text-red-500' : 'text-green-500'
              } `}
                    >
                      {message.text}
                    </div>
                  )}
                </p>
              </div>
            </div>
          )}
        </div>
        <div
          className="hidden w-1/2 bg-cover lg:flex"
          style={{
            backgroundImage: 'url("/images/cover.png")',
          }}
        />
      </div>
    </div>
  );
};

export default ResetPassword;
