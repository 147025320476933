import React from 'react';

import Menu from './Menu';

const Aside = () => {
  return (
    <aside className="relative z-30 hidden h-full w-64 flex-shrink-0 overflow-y-auto bg-primary lg:block">
      <div className="relative z-[999] min-h-screen py-4 text-white">
        <a className="flex h-32 px-3 text-lg font-bold text-white" href="#null">
          <img
            className="mr-2 h-auto w-full"
            src="/images/logo-white.svg"
            alt=""
          />
        </a>
        <Menu />
      </div>
      <img
        className="absolute bottom-0 text-white"
        src="../images/whitehome.svg"
        alt=""
      />
    </aside>
  );
};

export default Aside;
