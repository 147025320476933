import { Button } from '@nextui-org/react';
import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Home = () => {
  const navigator = useNavigate();
  const user = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.user);

  const navigate = (route) => {
    navigator(route);
  };

  useEffect(() => {
    if (
      (user.currentUser && user.currentUser?.available && !error) ||
      (user.currentUser && user?.isAdmin)
    ) {
      navigate('/dashboard');
      // reload page
      window.location.reload();
    }
  }, [user]);

  return (
    <div className="bg-white">
      <div className="flex min-h-screen justify-center">
        <div
          className="mx-auto flex w-full flex-col items-center bg-bottom bg-no-repeat xl:w-1/2"
          style={{
            backgroundImage: 'url("/images/home.svg")',
            backgroundSize: '100% auto',
          }}
        >
          <div className="w-full items-center text-primary md:flex md:pr-4">
            <div className="w-1/3">
              <img src="/images/logo.svg" alt="" />
            </div>
            <div className="hidden space-x-4 md:flex md:w-2/3">
              <Button
                onClick={() => {
                  navigate('/choose-profile');
                }}
                css={{
                  borderRadius: '0px', // radii.xs
                  border: '1px solid',
                  height: '58px',
                  maxWidth: '100px', // space[12]
                  color: '#DC3838',
                  borderColor: '#DC3838',
                }}
                bordered
                auto
              >
                Créer mon compte
              </Button>
              <Button
                onClick={() => {
                  navigate('/login');
                }}
                css={{
                  borderRadius: '0px', // radii.xs
                  border: '1px solid',
                  height: '58px',
                  borderColor: '#DC3838',
                  color: 'white',
                  backgroundColor: '#DC3838',
                }}
                auto
                size="xl"
              >
                Se connecter
              </Button>
            </div>
          </div>
          <div className="w-full md:px-8">
            <div className="text-start">
              <h2 className="px-4 text-start text-2xl font-bold text-primary md:text-4xl">
                Bienvenue !
              </h2>

              <div className="mt-8 space-y-2 px-4 text-xl text-gray-500 md:text-2xl">
                <p className=" ">
                  Diags Audit, la solution pour vos audits{' '}
                  <br className="hidden md:block" />
                  énergétiques en toute <br className="hidden md:block" />
                  facilité et en toute sécurité.
                </p>
              </div>

              <div className="mx-2 mt-4 flex flex-col space-y-4 md:hidden">
                <Button
                  onClick={() => {
                    navigate('/choose-profile');
                  }}
                  css={{
                    borderRadius: '0px', // radii.xs
                    border: '1px solid',
                    color: '#DC3838',
                    borderColor: '#DC3838',
                  }}
                  bordered
                  auto
                  size="lg"
                >
                  Créer mon compte
                </Button>
                <Button
                  onClick={() => {
                    navigate('/login');
                  }}
                  css={{
                    borderRadius: '0px', // radii.xs
                    border: '1px solid',
                    borderColor: '#DC3838',
                    color: 'white',
                    backgroundColor: '#DC3838',
                  }}
                  size="lg"
                >
                  Se connecter
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div
          className="hidden w-1/2 bg-cover lg:flex"
          style={{
            backgroundImage: 'url("/images/cover.png")',
          }}
        />
      </div>
    </div>
  );
};

export default Home;
