import { Button, Grid, Loading, Row, Text } from '@nextui-org/react';
import { motion } from 'framer-motion';
import { PropTypes } from 'prop-types';
import React, { useState } from 'react';

import {
  restrictEnterprise,
  unlockEnterprise,
  updateMeeting,
} from '../redux/api/enterprise';
import { deleteReport } from '../redux/api/inspector';
import { expired, loginReset } from '../redux/reducers/userReducer';
import useOutsideClick from '../utils/useOutsideClick';

const Popover = ({
  id,
  title,
  actionName,
  description,
  show,
  displayPopover,
  displayNotif,
  refresh,
  dispatch,
}) => {
  const handleClickOutside = () => {
    displayPopover();
  };

  const ref = useOutsideClick(handleClickOutside);
  const [loading, setLoading] = useState(false);

  const restrictThisEnterprise = () => {
    setLoading(true);
    restrictEnterprise({ id })
      .then(() => {
        setLoading(false);
        setTimeout(() => {
          displayNotif("L'entreprise a été bloqué avec succès", 'success');
          displayPopover();
          refresh();
        }, 300);
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          setLoading(false);
          displayNotif('Une erreur est survenue', 'error');
        }
      });
  };
  const unlockThisEnterprise = () => {
    setLoading(true);
    unlockEnterprise({ id })
      .then(() => {
        setLoading(false);
        setTimeout(() => {
          displayNotif("L'entreprise a été débloqué avec succès", 'success');
          displayPopover();
          refresh();
        }, 300);
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          setLoading(false);
          displayNotif('Une erreur est survenue', 'error');
        }
      });
  };
  const deleteThisReport = () => {
    setLoading(true);
    deleteReport(id)
      .then(() => {
        setLoading(false);
        setTimeout(() => {
          refresh();
          displayNotif('Le rapport a été supprimé avec succès', 'success');
          displayPopover();
        }, 300);
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          setLoading(false);
          displayNotif('Une erreur est survenue', 'error');
        }
      });
  };
  const updateThisMeeting = () => {
    setLoading(true);
    updateMeeting({
      id,
      confirm_rdvz: title === 'Annuler',
    })
      .then(() => {
        setLoading(false);
        setTimeout(() => {
          refresh();
          displayNotif(
            'Le rendez-vous a été mis à jour avec succès',
            'success'
          );
          displayPopover();
        }, 300);
      })
      .catch((err) => {
        setLoading(false);
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          setLoading(false);
          displayNotif('Une erreur est survenue', 'error');
        }
      });
  };
  const actionToExcute = (name) => {
    switch (name) {
      case 'deleteThisReport':
        deleteThisReport();
        break;
      case 'unlockThisEnterprise':
        unlockThisEnterprise();
        break;
      case 'updateMeeting':
        updateThisMeeting(title);
        break;
      default:
        restrictThisEnterprise();
        break;
    }
  };

  return (
    <motion.div ref={ref} initial="hidden">
      <Grid.Container
        css={{
          borderRadius: '14px',
          padding: '0.75rem',
          maxWidth: '330px',
          backgroundColor: '#fff',
          boxShadow:
            'rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px',
        }}
      >
        <Row justify="center" align="center">
          <Text b>{title}</Text>
        </Row>
        <Row>
          <Text>{description}</Text>
        </Row>
        <Grid.Container justify="space-between" alignContent="center">
          <Grid>
            <Button size="sm" light onClick={() => displayPopover()}>
              Fermer
            </Button>
          </Grid>
          <Grid>
            <Button
              size="sm"
              onClick={() => actionToExcute(actionName)}
              css={{
                tt: 'capitalize',
                color: 'white',
                backgroundColor: '#DC3838',
              }}
            >
              {loading && (
                <Loading
                  style={{
                    marginRight: '1rem',
                  }}
                  color="currentColor"
                  size="sm"
                />
              )}
              {title}
            </Button>
          </Grid>
        </Grid.Container>
      </Grid.Container>
    </motion.div>
  );
};

Popover.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  actionName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  displayPopover: PropTypes.func.isRequired,
  displayNotif: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

export default Popover;
