import { userRequest } from '../../api';

export const post = async (data) => {
  return userRequest.post(`chat/chat`, data);
};

export const get = async (id) => {
  return userRequest.get(`chat/chat/${id}`);
};

export const update = async (data) => {
  return userRequest.put(`chat/chat`, data);
};

export const getList = () => {
  return userRequest.get(`chat/chat`);
};

export const remove = async (id) => {
  return userRequest.delete(`chat/chat/${id}`);
};
