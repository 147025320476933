import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { resetState } from '../redux/reducers/appReducer';
import { loginReset } from '../redux/reducers/userReducer';

const Menu = () => {
  const dispatch = useDispatch();
  const path = window.location.pathname;
  const { isEnterprise } = useSelector((state) => state.user);
  const { isAdmin } = useSelector((state) => state.user);

  const logout = () => {
    dispatch(resetState());
    dispatch(loginReset());
  };

  return (
    <ul className="px-1">
      {/* Dashboard */}
      <li
        className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
          path === '/dashboard' ? 'bg-white bg-opacity-30' : ''
        }`}
      >
        <Link
          className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
          to="/dashboard"
        >
          <svg
            width="24"
            height="24"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 9.5L12 4L21 9.5"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M19 13V19.4C19 19.7314 18.7314 20 18.4 20H5.6C5.26863 20 5 19.7314 5 19.4V13"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="ml-4 mt-1.5">Tableau de bord</span>
        </Link>
      </li>
      {/* Audits */}
      {isEnterprise && (
        <li
          className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
            path === '/audits' ? 'bg-white bg-opacity-30' : ''
          }`}
        >
          <Link
            className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
            to="/audits"
          >
            <svg
              width="24px"
              height="24px"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="currentColor"
            >
              <path
                d="M19 3H5a2 2 0 00-2 2v14a2 2 0 002 2h14a2 2 0 002-2V5a2 2 0 00-2-2zM7 7h10M7 12h10M7 17h6"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="ml-4 mt-1.5">Audits</span>
          </Link>
        </li>
      )}
      {isEnterprise && (
        <li
          className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
            path === '/pricing' ? 'bg-white bg-opacity-30' : ''
          }`}
        >
          <Link
            className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
            to="/pricing"
          >
            <svg
              width="24"
              height="24"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18.5 4.80423C17.4428 4.28906 16.2552 4 15 4C10.5817 4 7 7.58172 7 12C7 16.4183 10.5817 20 15 20C16.2552 20 17.4428 19.7109 18.5 19.1958"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 10H16"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 14H16"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="ml-4 mt-1.5">Tarifications</span>
          </Link>
        </li>
      )}
      {/* Add inspector */}
      {/* Inspectors */}
      {(isEnterprise || isAdmin) && (
        <li
          className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
            path === '/inspectors' ? 'bg-white bg-opacity-30' : ''
          }`}
        >
          <Link
            className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
            to="/inspectors"
          >
            <svg
              width="24"
              height="24"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5 20V19C5 15.134 8.13401 12 12 12V12C15.866 12 19 15.134 19 19V20"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="ml-4 mt-1.5">Les inspecteurs</span>
          </Link>
        </li>
      )}
      {(isEnterprise || isAdmin) && (
        <li
          className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
            path === '/measurers' ? 'bg-white bg-opacity-30' : ''
          }`}
        >
          <Link
            className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
            to="/measurers"
          >
            <svg
              width="24px"
              height="24px"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="currentColor"
            >
              <path
                d="M2.5 9.5L12 4l9.5 5.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M7 21v-1a5 5 0 015-5v0a5 5 0 015 5v1"
                stroke="currentColor"
                strokeWidth="1.5"
              />
              <path
                d="M12 15a3 3 0 100-6 3 3 0 000 6z"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <span className="ml-4 mt-1.5">Les mesureurs</span>
          </Link>
        </li>
      )}
      {/* Add Entreprise */}
      {/* {isAdmin && (
        <li
          className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
            path === '/enterprises/add' ? 'bg-white bg-opacity-30' : ''
          }`}
        >
          <Link
            className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
            to="/enterprises/add"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 7V3H2V21H22V7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM20 19H12V17H14V15H12V13H14V11H12V9H20V19ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z"
                fill="white"
              />
            </svg>

            <span className="ml-4 mt-1.5">Ajoutez une entreprise</span>
          </Link>
        </li>
      )} */}
      {/* Entreprise */}
      {isAdmin && (
        <li
          className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
            path === '/enterprises' ? 'bg-white bg-opacity-30' : ''
          }`}
        >
          <Link
            className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
            to="/enterprises"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 7V3H2V21H22V7H12ZM6 19H4V17H6V19ZM6 15H4V13H6V15ZM6 11H4V9H6V11ZM6 7H4V5H6V7ZM10 19H8V17H10V19ZM10 15H8V13H10V15ZM10 11H8V9H10V11ZM10 7H8V5H10V7ZM20 19H12V17H14V15H12V13H14V11H12V9H20V19ZM18 11H16V13H18V11ZM18 15H16V17H18V15Z"
                fill="white"
              />
            </svg>

            <span className="ml-4 mt-1.5">Les entreprises</span>
          </Link>
        </li>
      )}
      {/* Customers */}
      {!isAdmin && (
        <li
          className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
            path === '/customers' ? 'bg-white bg-opacity-30' : ''
          }`}
        >
          <Link
            className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
            to="/customers"
          >
            <svg
              width="24"
              height="24"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 20V19C1 15.134 4.13401 12 8 12V12C11.866 12 15 15.134 15 19V20"
                stroke="currentColor"
                strokeLinecap="round"
              />
              <path
                d="M13 14V14C13 11.2386 15.2386 9 18 9V9C20.7614 9 23 11.2386 23 14V14.5"
                stroke="currentColor"
                strokeLinecap="round"
              />
              <path
                d="M8 12C10.2091 12 12 10.2091 12 8C12 5.79086 10.2091 4 8 4C5.79086 4 4 5.79086 4 8C4 10.2091 5.79086 12 8 12Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M18 9C19.6569 9 21 7.65685 21 6C21 4.34315 19.6569 3 18 3C16.3431 3 15 4.34315 15 6C15 7.65685 16.3431 9 18 9Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="ml-4 mt-1.5">Les clients</span>
          </Link>
        </li>
      )}
      {/* Meeting */}
      {isEnterprise && (
        <li
          className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
            path === '/meeting' ? 'bg-white bg-opacity-30' : ''
          }`}
        >
          <Link
            className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
            to="/meeting"
          >
            <svg
              width="24"
              height="24"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="currentColor"
            >
              <path
                d="M15 4V2m0 2v2m0-2h-4.5M3 10v9a2 2 0 002 2h14a2 2 0 002-2v-9H3zM3 10V6a2 2 0 012-2h2M7 2v4M21 10V6a2 2 0 00-2-2h-.5"
                stroke="currentColor"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="ml-4 mt-1.5">Agenda</span>
          </Link>
        </li>
      )}
      {/* Account crediting */}
      {isEnterprise && (
        <li
          className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
            path === '/account/crediting' ? 'bg-white bg-opacity-30' : ''
          }`}
        >
          <Link
            className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
            to="/account/crediting"
          >
            <svg
              width="24"
              height="24"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 15C7.55228 15 8 14.5523 8 14C8 13.4477 7.55228 13 7 13C6.44772 13 6 13.4477 6 14C6 14.5523 6.44772 15 7 15Z"
                fill="currentColor"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2 9V5.6C2 5.26863 2.26863 5 2.6 5H21.4C21.7314 5 22 5.26863 22 5.6V9M2 9V18.4C2 18.7314 2.26863 19 2.6 19H21.4C21.7314 19 22 18.7314 22 18.4V9M2 9H22"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="ml-4 mt-1.5">Paiements Audits</span>
          </Link>
        </li>
      )}
      {/* History transactions */}
      {(isAdmin || isEnterprise) && (
        <li
          className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
            path === '/history/transactions' ? 'bg-white bg-opacity-30' : ''
          }`}
        >
          <Link
            className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
            to="/history/transactions"
          >
            <svg
              width="24"
              height="24"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2 9V5.6C2 5.26863 2.26863 5 2.6 5H21.4C21.7314 5 22 5.26863 22 5.6V9V18.4C22 18.7314 21.7314 19 21.4 19H2.6C2.26863 19 2 18.7314 2 18.4V9ZM2 9H16"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <rect
                x="15"
                y="12"
                width="4"
                height="4"
                rx="0.6"
                fill="currentColor"
              />
            </svg>

            <span className="ml-4 mt-1.5">Historique des transactions</span>
          </Link>
        </li>
      )}
      {/* Payments */}
      {!isEnterprise && (
        <li
          className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
            path === '/payments' ? 'bg-white bg-opacity-30' : ''
          }`}
        >
          <Link
            className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
            to="/payments"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M13 21H4C2.89543 21 2 20.1046 2 19V5C2 3.89543 2.89543 3 4 3H20C21.1046 3 22 3.89543 22 5V15"
                stroke="currentColor"
                strokeLinecap="round"
              />
              <path
                d="M2 7L22 7"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M5 5.01L5.01 4.99889"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M8 5.01L8.01 4.99889"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M11 5.01L11.01 4.99889"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M16 20L18 22L22 18"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>

            <span className="ml-4 mt-1.5">{`${
              !isAdmin ? 'Mes paiements' : 'Mes commissions'
            }`}</span>
          </Link>
        </li>
      )}
      {/* Stats */}
      <li className="relative px-6 py-1 hover:bg-white hover:bg-opacity-30">
        <Link
          className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
          to="/statistiques"
        >
          <svg
            width="24"
            height="24"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M9 21H15M9 21V16M9 21H3.6C3.26863 21 3 20.7314 3 20.4V16.6C3 16.2686 3.26863 16 3.6 16H9M15 21V9M15 21H20.4C20.7314 21 21 20.7314 21 20.4V3.6C21 3.26863 20.7314 3 20.4 3H15.6C15.2686 3 15 3.26863 15 3.6V9M15 9H9.6C9.26863 9 9 9.26863 9 9.6V16"
              stroke="currentColor"
              strokeWidth="1.5"
            />
          </svg>

          <span className="ml-4 mt-1.5">Statistiques</span>
        </Link>
      </li>
      {/* Settings */}
      <li
        className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
          path === '/settings' ? 'bg-white bg-opacity-30' : ''
        }`}
      >
        <Link
          className="inline-flex w-full text-base font-light transition-colors duration-150"
          to="/settings"
        >
          <img className="w-5" src="/images/Settings.svg" alt="" />
          <span className="ml-4 mt-1.5">Paramètres</span>
        </Link>
      </li>
      {/* Chats */}
      <li
        className={`relative px-6 py-1 hover:bg-white hover:bg-opacity-30 ${
          path === '/chats' ? 'bg-white bg-opacity-30' : ''
        }`}
      >
        <Link
          className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
          to="/chats"
        >
          <svg
            width="24"
            height="24"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12 11.5V16.5"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 7.51L12.01 7.49889"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>

          <span className="ml-4 mt-1.5">Support en ligne</span>
        </Link>
      </li>
      <hr className="customeDivider mx-4 my-5" />
      <li className="relative px-6 py-1 hover:bg-white hover:bg-opacity-30">
        <button
          type="button"
          className="inline-flex w-full items-center text-base font-light transition-colors duration-150"
          onClick={logout}
        >
          <svg
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            viewBox="0 0 24 24"
            stroke="currentColor"
            className="h-5 w-5"
            aria-hidden="true"
          >
            <path d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1" />
          </svg>
          <span className="ml-4 mt-1.5">Se déconnecter</span>
        </button>
      </li>
    </ul>
  );
};

export default Menu;
