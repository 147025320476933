import { userRequest } from '../../api';
import {
  customerFailure,
  customerStart,
  customerSuccess,
} from '../reducers/customerReducer';
import { expired, loginReset } from '../reducers/userReducer';

export const getCustomers = (page, pageSize) => async (dispatch) => {
  dispatch(customerStart());
  try {
    const res = await userRequest.get(
      `inspecteur/listeclient?page=${page}&per_page=${pageSize}`
    );
    dispatch(customerSuccess(res.data));
  } catch (err) {
    dispatch(customerFailure(err.response.data));
    if (err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(customerFailure(err.response.data));
    }
  }
};

export const getMesurerCustomers = (page, pageSize) => async (dispatch) => {
  dispatch(customerStart());
  try {
    const res = await userRequest.get(
      `mesureur/listeclient?page=${page}&per_page=${pageSize}`
    );
    dispatch(customerSuccess(res.data));
  } catch (err) {
    dispatch(customerFailure(err.response.data));
    if (err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(customerFailure(err.response.data));
    }
  }
};

export const getEnterpriseCustomers = (page, pageSize) => async (dispatch) => {
  dispatch(customerStart());
  try {
    const res = await userRequest.get(
      `entreprise/client?page=${page}&per_page=${pageSize}`
    );
    dispatch(customerSuccess(res.data));
  } catch (err) {
    dispatch(customerFailure(err.response.data));
    if (err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(customerFailure(err.response.data));
    }
  }
};

export const getAdminCustomers = (id, page, pageSize) => async (dispatch) => {
  dispatch(customerStart());
  try {
    const res = await userRequest.get(
      `admin/entreprise/${id}/client?page=${page}&per_page=${pageSize}`
    );
    dispatch(customerSuccess(res.data));
  } catch (err) {
    dispatch(customerFailure(err.response.data));
    if (err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(customerFailure(err.response.data));
    }
  }
};

export const getAdminEnterpriseCustomers =
  (id, page, pageSize) => async (dispatch) => {
    dispatch(customerStart());
    try {
      const res = await userRequest.get(
        `admin/entreprise/${id}/client?page=${page}&per_page=${pageSize}`
      );
      dispatch(customerSuccess(res.data));
    } catch (err) {
      dispatch(customerFailure(err.response.data));
      if (err.response.status === 403) {
        dispatch(expired());
        dispatch(loginReset());
      } else {
        dispatch(customerFailure(err.response.data));
      }
    }
  };

export const getCustomer = () => async (id) => {
  return userRequest.get(`admin/entreprise/${id}/showclient`);
};

export const getAdminCustomer = () => async (id) => {
  return userRequest.get(`inspecteur/${id}/client/show`);
};

export const getEnterpriseCustomer = () => async (id) => {
  return userRequest.get(`entreprise/client/${id}`);
};

export const addCustomer = async (customer) => {
  return userRequest.post('entreprise/client', customer);
};

export const deleteCustomer = async (id) => {
  return userRequest.delete(`entreprise/client/${id}`);
};

export const update = async (user) => {
  return userRequest.put('entreprise/client', user);
};
