/* eslint-disable no-useless-escape */
import { createTheme, ThemeProvider } from '@mui/material';
import { DataGrid, frFR, GridActionsCellItem } from '@mui/x-data-grid';
import {
  Badge,
  Button,
  Dropdown,
  Grid,
  Input,
  Loading,
  Modal,
  Row,
  Spacer,
  Text,
  useModal,
} from '@nextui-org/react';
import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { TOKEN } from '../api';
import IconMessage from '../components/icons/IconMessage';
import IconSearch from '../components/icons/IconSearch';
import { assignAuditToInspector } from '../redux/api/audit';
import { getRequestAuditAwaiting } from '../redux/api/enterprise';
import { getEnterpriseMeasurers, getMeasurers } from '../redux/api/inspector';
import { get } from '../redux/api/user';
import { updateState } from '../redux/reducers/appReducer';
import { resetList } from '../redux/reducers/inspectorReducer';
import {
  expired,
  failure,
  loginReset,
  userSuccess,
} from '../redux/reducers/userReducer';

const Measurers = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const location = useLocation();
  const id = location.pathname.split('/')[2];
  const { setVisible, bindings } = useModal();
  const [selected, setSelected] = React.useState(new Set(['Filtrer']));
  const [filters] = useState(['Tous', 'Par Ville']);
  const { isEnterprise } = useSelector((state) => state.user);
  const { isLoading, meta } = useSelector((state) => state.measurer);
  const list = useSelector((state) => state.measurer.list);
  const auditList = useSelector((state) => state.audit.list);
  const [audits, setAudits] = useState(auditList);
  const [measurers, setMeasurers] = useState([]);
  const [completeList, setCompleteList] = useState([]);
  const [measurer, setMeasurer] = useState(null);
  const [mode, setMode] = useState('server');
  const [inputs, setInputs] = useState({});
  const [helper, setHelper] = useState({
    audit: {
      text: '',
      color: '',
    },
  });

  console.log('list: ', list);
  console.log('isEnterprise: ', isEnterprise);

  const resetHelpers = (e) => {
    if (e.target.name === 'audit')
      setHelper((prev) => {
        return {
          ...prev,
          audit: {
            text: '',
            color: '',
          },
        };
      });
  };

  const [loading, setLoading] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [message, setMessage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(15);
  const [rowCountState, setRowCountState] = useState(0);

  const [allMeasurer, setAllMeasurer] = useState([]);

  console.log('allMeasurer: ', allMeasurer);

  const selectedValue = useMemo(
    () => Array.from(selected).join(', ').replaceAll('_', ' '),
    [selected]
  );

  function closeNotif() {
    setTimeout(() => {
      setMessage({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 300);
    }, 100);
  }

  function showNotif(text, type) {
    setTimeout(() => {
      setNotification({
        text,
        type,
      });
      setTimeout(() => {
        setShowNotification(true);
      }, 100);
    }, 500);

    setTimeout(() => {
      setNotification({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 6000);
    }, 5000);
  }

  const getAllMeasurer = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}entreprisemesureur/listemesureur`,
        config
      );
      setAllMeasurer(res.data.data);
    } catch (err) {
      console.log(err);
      throw err.message;
    }
  };

  useEffect(() => {
    get().then(
      (res) => {
        dispatch(userSuccess(res.data?.user));
      },
      (err) => {
        if (err.response.status === 401) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          dispatch(failure(err.response.data));
        }
      }
    );
  }, []);

  useEffect(() => {
    return () => {
      dispatch(resetList());
    };
  }, []);

  useEffect(() => {
    setRowCountState(meta?.pagination?.total);
    setCompleteList(
      isEnterprise
        ? list.map((measur) => ({
            id: measur?.id,
            name:
              measur?.users?.nom ||
              measur?.mesureur_info?.original?.data?.users?.original?.data?.nom,
            prenom:
              measur?.users?.prenom ||
              measur?.mesureur_info?.original?.data?.users?.original?.data
                ?.prenom,
            email:
              measur?.users?.email ||
              measur?.mesureur_info?.original?.data?.users?.original?.data
                ?.email,
            telephone: measur?.users?.telephone,
            city:
              measur?.users?.ville_residence ||
              measur?.mesureur_info?.original?.data?.users?.original?.data
                ?.ville_residence,
            userId:
              measur?.users_id ||
              measur?.mesureur_info?.original?.data?.users_id,
          }))
        : allMeasurer.map((measur) => ({
            id: measur?.id,
            name:
              measur?.users?.nom ||
              measur?.mesureur_info?.original?.data?.users?.original?.data?.nom,
            prenom:
              measur?.users?.nom ||
              measur?.mesureur_info?.original?.data?.users?.original?.data
                ?.prenom,
            email:
              measur?.users?.email ||
              measur?.mesureur_info?.original?.data?.users?.original?.data
                ?.email,
            telephone: measur?.users?.telephone,
            city:
              measur?.users?.ville_residence ||
              measur?.mesureur_info?.original?.data?.users?.original?.data
                ?.ville_residence,
            userId:
              measur?.users_id ||
              measur?.mesureur_info?.original?.data?.users_id,
          }))
    );
  }, [list, allMeasurer]);

  useEffect(() => {
    setMeasurers(completeList);
  }, [completeList]);

  useEffect(() => {
    getAllMeasurer();
  }, []);

  const requestMeasurers = () => {
    if (id) {
      dispatch(getEnterpriseMeasurers(id, page + 1, pageSize));
    } else {
      dispatch(getMeasurers(page + 1, pageSize));
    }
  };

  useEffect(() => {
    const filterVal = selected.entries().next().value[0].toLowerCase();

    if (filterVal === 'tous') {
      setMode('server');
      if (page === 0) requestMeasurers();
      else setPage(0);
    }
  }, [selected]);

  const navigate = (route) => {
    navigator(route);
  };

  const closeHandler = () => {
    navigate('/measurers');
  };

  const getThisRequestAuditAwaiting = (searchTerm) => {
    setLoadingData(true);
    getRequestAuditAwaiting({
      num_dmd: searchTerm,
    })
      .then((res) => {
        setLoadingData(false);
        setAudits(
          res.data?.data?.map((audit) => {
            return {
              num_dmd: audit?.num_dmd,
              client_info: audit?.client_info,
            };
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          setLoadingData(false);
          showNotif(
            'Une erreur est survenue lors du chargement des audits à attribuer',
            'error'
          );
        }
      });
  };

  const handleChange = (e) => {
    if (e.target.value === '') {
      setTimeout(() => {
        setAudits(auditList);
      }, 300);
    } else {
      getThisRequestAuditAwaiting(e.target.value.toLowerCase());
    }
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  const handleClick = (e) => {
    e.preventDefault();

    const audit = audits.find((a) => a.num_dmd === inputs.audit);

    if (audit) {
      setLoading(true);
      assignAuditToInspector(audit?.entreprise_id, measurer.id, audit?.id).then(
        () => {
          setLoading(false);
          setVisible(false);
          setMeasurer(null);
          delete inputs?.audit;
          dispatch(getMeasurers(page + 1, pageSize));

          showNotif("L'audit a été attribué au mesureur", 'success');
        },
        (err) => {
          setLoading(false);
          if (err.response.status === 422) {
            showNotif(
              'Vous avez déjà affecté cet audit à un mesureur!',
              'error'
            );
          } else {
            showNotif('Une erreur est survenue', 'error');
          }
        }
      );
    }
  };

  const search = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filterVal = selected.entries().next().value[0].toLowerCase();

    if (searchTerm === '') {
      setMeasurers(completeList);
    } else {
      setMeasurers(
        completeList.filter((item) => {
          return filterVal.toLowerCase() === 'par ville'
            ? item.name?.toLowerCase().includes(searchTerm) ||
                item.email?.toLowerCase().includes(searchTerm) ||
                item.city?.toLowerCase().includes(searchTerm)
            : item.name?.toLowerCase().includes(searchTerm) ||
                item.email?.toLowerCase().includes(searchTerm);
        })
      );
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Nom du mesureur',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">
            {params.row.name} {params.row.prenom}
          </span>
        );
      },
    },
    {
      field: 'telephone',
      headerName: 'Telephone',
      minWidth: 250,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">{params.row.telephone}</span>
        );
      },
    },
    {
      field: 'email',
      headerName: 'Email du mesureur',
      minWidth: 250,
      flex: 1,
    },
    {
      field: 'city',
      headerName: 'Ville',
      width: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">{params.row.city}</span>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      cellClassName: 'actions',
      getActions: (params) => {
        return isEnterprise
          ? [
              <GridActionsCellItem
                icon={undefined}
                label="Voir les audits affectés"
                showInMenu
                onClick={() => {
                  dispatch(
                    updateState({
                      mesurer: { nom: params.row?.name },
                    })
                  );
                  setTimeout(() => {
                    navigate(`/measurer/${params.row?.id}/audits`);
                  }, 1000);
                }}
              />,
              <GridActionsCellItem
                icon={<IconMessage />}
                label="Envoyer un message"
                onClick={() => {
                  navigate(`/chats/measurer/${params.row.userId}/new`);
                }}
              />,
            ]
          : [
              <GridActionsCellItem
                icon={undefined}
                label="Voir les audits affectés"
                showInMenu
                onClick={() => {
                  navigate(
                    `/enterprise/${id}/measurer/${params.row?.id}/audits`
                  );
                }}
              />,
              <GridActionsCellItem
                icon={<IconMessage />}
                label="Envoyer un message"
                onClick={() => {
                  navigate(`/chats/measurer/${params.row.userId}/new`);
                }}
              />,
            ];
      },
    },
  ];

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#dc3838' },
      },
    },
    frFR
  );

  useEffect(() => {
    requestMeasurers();
  }, [page]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.pagination?.total !== undefined
        ? meta?.pagination?.total
        : prevRowCountState
    );
  }, [meta?.pagination?.total, setRowCountState]);

  const selectAudit = (audit) => {
    handleChange({ target: { name: 'audit', value: audit?.num_dmd } });
  };

  return (
    <>
      <div className="w-full">
        <div className="mx-auto w-full px-3 md:px-8">
          <div className="py-8">
            <div>
              <h2 className="block text-xl font-medium text-black md:hidden">
                Liste des inspecteurs
              </h2>
              <div className="mt-2 flex items-center justify-end space-x-4">
                <Grid>
                  <Input
                    onChange={search}
                    labelRight={<IconSearch />}
                    placeholder="Rechercher"
                    bordered
                    type="search"
                  />
                </Grid>
                <Dropdown placement="bottom-right">
                  <Dropdown.Button
                    flat
                    css={{
                      tt: 'capitalize',
                      color: 'white',
                      backgroundColor: '#DC3838',
                    }}
                  >
                    {selectedValue}
                  </Dropdown.Button>
                  <Dropdown.Menu
                    aria-label="Single selection actions"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={selected.keys()}
                    onSelectionChange={setSelected}
                  >
                    {filters?.map((item) => (
                      <Dropdown.Item
                        key={item}
                        css={{
                          '&:hover': {
                            fontWeight: 500,
                          },
                          fontSize: '14px',
                        }}
                        className="first-letter:uppercase"
                      >
                        {item}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="mt-8 w-full"
                style={{
                  height: `${108 + 8 * 52}px`,
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    rows={measurers}
                    disableSelectionOnClick
                    columns={columns}
                    getRowId={(row) => row.id}
                    columnBuffer={15}
                    checkboxSelection
                    rowCount={rowCountState}
                    loading={isLoading}
                    rowsPerPageOptions={[15]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode={mode}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  />
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        closeButton
        scroll
        width="600px"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        onClose={closeHandler}
        {...bindings}
      >
        <form onSubmit={handleClick}>
          <Modal.Header justify="start">
            <Text h2 b id="modal-title" size={18}>
              Affecter un audit
            </Text>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="w-full">
                <div className="flex flex-col items-center">
                  <div className="flex h-64 w-full flex-col items-center">
                    <div className="w-full px-4">
                      <div className="relative flex flex-col items-center">
                        <div className="w-full">
                          <span className="font-semibold">Mesureur</span>:{' '}
                          <span className="first-letter:uppercase">
                            {measurer?.name}
                          </span>
                        </div>
                        <div className="w-full">
                          <div
                            className="my-2 flex rounded bg-white p-1"
                            style={{
                              boxShadow: '0 0 0 2px #DFE3E6',
                            }}
                          >
                            <input
                              name="audit"
                              placeholder="Rechercher un audit à affecter"
                              value={inputs?.audit}
                              className={`w-full appearance-none p-1 px-2 text-gray-800 outline-none
                            ${
                              helper.audit.color &&
                              'border-[#F31260] text-[#F31260]'
                            }`}
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="top-100 max-h-select absolute left-0 z-40 w-full overflow-y-auto rounded bg-white shadow">
                          {loadingData && (
                            <Row justify="center" align="flex-end">
                              <span>Chargement </span>
                              <Badge
                                css={{
                                  backgroundColor: 'transparent',
                                }}
                                className="measurer-badge"
                                variant="points"
                              />
                            </Row>
                          )}
                          {!loadingData && audits?.length <= 0 && (
                            <Row justify="center" align="flex-end">
                              <span>Aucun résultat </span>
                            </Row>
                          )}
                          {audits.map((audit) => (
                            <button
                              type="button"
                              className="group flex w-full cursor-pointer flex-col"
                              onClick={() => selectAudit(audit)}
                            >
                              <div className="relative flex w-full items-center border-x-2 border-transparent p-2 pl-2 hover:border-red-100 hover:bg-primary hover:text-white">
                                <div className="flex w-full items-center">
                                  <div className="mx-2 -mt-1 flex-1 text-left">
                                    <span>{audit?.num_dmd}</span>
                                    <div className="-mt-1 w-full truncate text-xs font-normal normal-case text-gray-500 group-hover:text-white">
                                      {
                                        audit?.client_info?.original?.data
                                          ?.users?.nom
                                      }{' '}
                                      {
                                        audit?.client_info?.original?.data
                                          ?.users?.prenom
                                      }
                                    </div>
                                  </div>
                                  {inputs.audit === audit.num_dmd && (
                                    <div className="pr-1.5">
                                      <svg
                                        width="24"
                                        height="24"
                                        strokeWidth="1.5"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        stroke="currentColor"
                                        xmlns="http://www.w3.org/2000/svg"
                                        className={`group-hover:text-white
                                          ${
                                            inputs.audit === audit.num_dmd
                                              ? 'text-primary'
                                              : 'text-white'
                                          }
                                        `}
                                      >
                                        <path
                                          d="M5 13L9 17L19 7"
                                          stroke="currentColor"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </button>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer
            justify="start"
            className="!block w-full justify-between md:!flex"
          >
            <div
              id="message"
              className={`mt-4 w-full flex-1 text-left text-sm md:mb-0 md:mt-2 ${
                message?.type === 'success' ? 'text-green-500' : 'text-red-500'
              } `}
            >
              {message?.text}
            </div>
            <div className="m-auto flex justify-center md:justify-start">
              <Button
                css={{
                  border: '1px solid',
                  borderColor: '#DC3838',
                  color: 'white',
                  backgroundColor: '#DC3838',
                }}
                auto
                onClick={() => setVisible(false)}
              >
                Annuler
              </Button>
              <Spacer x={0.5} />
              <Button type="submit" color="success" auto>
                {loading && (
                  <Loading
                    style={{
                      marginRight: '1rem',
                    }}
                    color="currentColor"
                    size="sm"
                  />
                )}
                Affecter
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {notification?.text && (
        <div
          className={`fixed bottom-4 right-4 z-[99999] flex rounded px-3 py-2.5 text-sm text-white transition-all delay-200 duration-300
        ${
          showNotification
            ? 'translate-y-0 opacity-100'
            : 'translate-y-2 opacity-0'
        } ${notification.type === 'error' ? 'bg-red-600' : 'bg-green-600'}`}
        >
          <span>{notification.text}</span>
          <svg
            onClick={() => {
              closeNotif();
            }}
            className="ml-2 h-5 w-5 cursor-pointer"
            width="24"
            height="24"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default Measurers;
