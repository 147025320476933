/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { INTERNAL } from '../constants';

const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState: {
    list: [],
    availables: 0,
    unavailables: 0,
    isLoading: false,
    error: null,
    meta: {},
  },
  reducers: {
    enterpriseStart: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    enterpriseSuccess: (state, action) => {
      state.isLoading = false;
      if (action.payload.data) state.list = action.payload.data;
      else state.list = action.payload;
      if (action.payload?.meta) state.meta = action.payload.meta;
    },
    enterpriseFailure: (state) => {
      state.isLoading = false;
      state.error = INTERNAL;
    },
    enterpriseReset: (state) => {
      state.list = [];
      state.isLoading = false;
      state.error = null;
      state.meta = {};
    },
    orderEnterprise: (state, action) => {
      if (action.payload === 'asc') {
        state.list = state.list.sort(
          (a, b) => new Date(a.create_at) - new Date(b.create_at)
        );
      } else {
        state.list = state.list.sort(
          (a, b) => new Date(b.create_at) - new Date(a.create_at)
        );
      }
    },
    meta: (state, action) => {
      state.meta = action.payload;
    },
    statsSuccess: (state, action) => {
      state.total = action.payload.total;
      state.availables = action.payload.availables;
      state.unavailables = action.payload.unavailables;
    },
    resetList: (state) => {
      state.list = [];
    },
    reset: (state) => {
      state.list = [];
      state.isLoading = false;
      state.error = null;
      state.meta = {};
    },
  },
});

export const {
  enterpriseStart,
  enterpriseSuccess,
  enterpriseReset,
  enterpriseFailure,
  orderEnterprise,
  meta,
  statsSuccess,
  resetList,
  reset,
} = enterpriseSlice.actions;
export default enterpriseSlice.reducer;
