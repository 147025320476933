/* eslint-disable prettier/prettier */
import { Button, Input, Loading } from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import IconLeft from '../components/icons/IconLeft';
import IconMail from '../components/icons/IconMail';
import IconPassword from '../components/icons/IconPassword';
import { login } from '../redux/api/user';
import { INACTIVE } from '../redux/constants';
import { loginReset, reset, resetUser } from '../redux/reducers/userReducer';

const Login = () => {
  const navigator = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const user = useSelector((state) => state.user);
  const { error, isLoading } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [helper, setHelper] = useState({
    text: '',
    color: '',
  });
  const [passhelper, setPasshelper] = useState({
    text: '',
    color: '',
  });
  const [message, setMessage] = useState('');

  useEffect(() => {
    dispatch(resetUser());
    dispatch(loginReset());
  }, []);

  useEffect(() => {
    return () => {
      dispatch(reset());
    };
  }, []);

  const handleClick = (e) => {
    e.preventDefault();
    if (email === '') {
      setHelper({
        text: 'Email requis',
        color: 'error',
      });
    }

    if (!password) {
      setPasshelper({
        text: 'Mot de passe requis',
        color: 'error',
      });
    }
    if (email === '' || password === '') {
      return;
    }
    dispatch(loginReset());
    if (email && password) {
      dispatch(login({ email, password }));
    }
  };

  useEffect(() => {
    if (error === 'Unauthorized') setMessage('Email ou mot de passe incorrect');
    else if (error === INACTIVE) setMessage('Vous n\'avez pas encore confirmer votre inscription, veuillez vérifier votre email s\'il vous plaît');
    else setMessage('');
  }, [error]);

  useEffect(() => {
    setHelper({
      text: '',
      color: 'default',
    });
  }, [email]);

  useEffect(() => {
    setPasshelper({
      text: '',
      color: '',
    });
  }, [password]);

  const navigate = (route) => {
    navigator(route);
  };

  useEffect(() => {
    if (user.currentUser?.access_token && user.currentUser?.available && !error || user.currentUser?.access_token && user?.isAdmin) {
      setTimeout(() => {
        window.location.href = '/dashboard';
      }, 50);
    }
  }, [user]);

  const back = () => {
    navigate('/');
  };

  return (
    <div className="h-full bg-white">
      <div className="h-full flex min-h-screen justify-center">
        <div
          className="mx-auto inline-flex flex-col items-center bg-bottom bg-no-repeat lg:w-1/2"
          style={{
            backgroundImage: 'url("/images/home-light.svg")',
            backgroundSize: '100% auto',
          }}
        >
          <div className="w-full items-center text-primary flex md:pr-4">
            <div className="w-1/3 px-4">
              <img
                className="py-8 md:h-44 md:py-0"
                src="/images/logo-large.svg"
                alt=""
              />
            </div>
          </div>
          <div className="w-full px-4 md:px-16">
            <div className="text-start">
              <div className="space-y-2 text-2xl text-gray-500">
                <p className="">Connectez vous à votre compte pour</p>
                <p>explorez vos audits</p>
              </div>
            </div>
            <div className="mt-4 flex h-full w-full md:max-w-md flex-col items-start justify-center space-y-8">
              <form onSubmit={handleClick} className="space-y-6 w-full">
                <Input
                  contentLeft={
                    <IconMail />
                  }
                  underlined
                  fullWidth="true"
                  label="Email"
                  type="email"
                  className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                  color={helper.color}
                  helperColor={helper.color}
                  helperText={helper.text}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <Input.Password
                  contentLeft={
                    <IconPassword />
                  }
                  underlined
                  fullWidth="true"
                  width=""
                  label="Mot de passe"
                  visibleIcon={
                    <svg
                      width="24"
                      height="24"
                      strokeWidth="1.5"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.5 8C7.5 14.5 16.5 14.5 19.5 8"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.8162 11.3175L19.5 15"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M12 12.875V16.5"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.18383 11.3175L4.5 15"
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  }
                  color={passhelper.color}
                  helperColor={passhelper.color}
                  helperText={passhelper.text}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <div className="mt-8 flex h-8 w-full items-center justify-between text-sm text-gray-500">
                  <div className="flex items-center space-x-3">
                    <input
                      className="mr-1 cursor-pointer rounded-sm text-primary hover:ring-0 focus:ring-0"
                      type="checkbox"
                      name="rememberme"
                    />
                    Se souvenir de moi
                  </div>
                  <div>
                    <Button
                      onClick={() => {
                        navigate('/forgot-password');
                      }}
                      css={{
                        color: 'rgb(107 114 128)',
                        paddingLeft: '0px',
                        paddingRight: '0px',
                        justifyContent: 'center',
                      }}
                      light
                      animated={false}
                    >
                      Mot de passe oublié
                    </Button>
                  </div>
                </div>
                {message && (
                  <div className="w-full text-center text-sm text-red-500">
                    {message}
                  </div>
                )}
                <div className="mt-4 flex w-full md:w-auto flex-col-reverse space-y-4 space-y-reverse md:flex-row md:space-x-4">
                  <Button
                    onClick={back}
                    css={{
                      borderRadius: '0px', // radii.xs
                      border: '1px solid',
                      color: '#DC3838',
                      borderColor: '#DC3838',
                    }}
                    bordered
                    size="lg"
                  >
                    <IconLeft />
                    <span>RETOUR</span>
                  </Button>
                  <Button
                    type="submit"
                    css={{
                      borderRadius: '0px', // radii.xs
                      border: '1px solid',
                      borderColor: '#DC3838',
                      color: 'white',
                      backgroundColor: '#DC3838',
                    }}
                    size="lg"
                  >
                    {isLoading && (
                      <Loading
                        style={{
                          marginRight: '1rem',
                        }}
                        color="currentColor"
                        size="sm"
                      />
                    )}
                    SE CONNECTER
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div
          className="hidden w-1/2 bg-cover lg:flex"
          style={{
            backgroundImage: 'url("/images/cover.png")',
          }}
        />
      </div>
    </div>
  );
};

export default Login;
