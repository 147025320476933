import { Button, Row } from '@nextui-org/react';
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { dispatchAside } from '../redux/api/app';
import Menu from './Menu';

const MobileAside = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const { isSidebarOpen } = useSelector((state) => state.app);

  const closeSidebar = () => {
    dispatch(dispatchAside(!isSidebarOpen));
  };

  useEffect(() => {
    return () => {
      dispatch(dispatchAside(false));
    };
  }, []);

  const Backdrop = () => (
    <motion.div
      initial="backdropInitial"
      animate={isSidebarOpen ? 'backdropAnimate' : 'backdropInitial'}
      variants={{
        backdropInitial: {
          opacity: 0,
        },
        backdropAnimate: {
          opacity: 1,
        },
      }}
      className={`fixed inset-0 z-[998] flex items-end bg-black bg-opacity-50 sm:items-center sm:justify-center
  ${!isSidebarOpen && 'hidden'}`}
      onClick={closeSidebar}
    />
  );

  useEffect(() => {
    dispatch(dispatchAside(false));
  }, [location]);

  return (
    <>
      <Backdrop />
      <aside
        className={`fixed inset-y-0 z-[999] w-3/4 flex-shrink-0 overflow-y-auto bg-primary transition-all delay-75 duration-75 ease-in-out lg:hidden
        ${!isSidebarOpen ? '-translate-x-full' : '-translate-x-0'}`}
      >
        <div className="py-4 text-white">
          <Row justify="start">
            <Button
              auto
              css={{
                color: 'white',
              }}
              light
              icon={
                <svg
                  width="24"
                  height="24"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              }
              onClick={closeSidebar}
            />
          </Row>
          <a
            className="flex h-32 px-6 text-lg font-bold text-white"
            href="#null"
          >
            <img
              className="mr-4 h-auto w-full"
              src="/images/logo-white.svg"
              alt=""
            />
          </a>
          <Menu path={path} />
        </div>
      </aside>
    </>
  );
};

export default MobileAside;
