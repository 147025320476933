import { userRequest } from '../../api';
import {
  enterpriseFailure,
  enterpriseStart,
  enterpriseSuccess,
} from '../reducers/enterpriseReducer';
import {
  expired,
  failure,
  loginReset,
  start,
  success,
} from '../reducers/userReducer';

export const addEnterprise = async (enterprise) => {
  return userRequest.post('admin/entreprise', enterprise);
};

export const getEnterprises = (page, pageSize) => async (dispatch) => {
  dispatch(enterpriseStart());
  try {
    const res = await userRequest.get(
      `admin/entreprise?page=${page}&per_page=${pageSize}`
    );
    dispatch(enterpriseSuccess(res.data));
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(enterpriseFailure(err.response.data));
    }
  }
};

export const deleteEnterprise = async (id) => {
  return userRequest.delete(`admin/entreprise/${id}`);
};

export const getAdminEnterprise = async (id) => {
  return userRequest.get(`admin/entreprise/${id}`);
};
export const getAdminEnterpriseAudits = async (id) => {
  return userRequest.get(`/admin/entreprise/${id}/demande-audits`);
};

export const getMeeting = async () => {
  return userRequest.get(`entreprise/demanderendezvous`);
};

export const searchEntreprise = async (data) => {
  return userRequest.post(`admin/searchentreprise`, data);
};

export const updateMeeting = async (data) => {
  return userRequest.put(`entreprise/demanderendezvous`, data);
};

export const getRequestAuditAwaiting = async (data) => {
  return userRequest.post(`entreprise/demandeauditenattente`, data);
};

export const updateEnterprisePayments = async (user) => {
  return userRequest.put('entreprise/usersinfo', user);
};

export const updateEnterprise = async (user) => {
  return userRequest.put('entreprise/updateentreprise', user);
};

export const updateMeasurerProfile = async (user) => {
  return userRequest.put('mesureur/updatemesureur', user);
};

export const updateAvatar = async (id, data) => {
  return userRequest.post(`entreprise/avatarlogo/${id}`, data);
};

export const updateCover = async (id, data) => {
  return userRequest.post(`entreprise/avatarbackground/${id}`, data);
};

export const restrictEnterprise = async (user) => {
  return userRequest.post(`admin/entreprise/bloquer`, user);
};

export const unlockEnterprise = async (user) => {
  return userRequest.post(`admin/entreprise/debloquer`, user);
};

export const getProfile = async () => {
  return userRequest.get(`entreprise/verifyprofil`);
};

export const getAvatar = (id) => async (dispatch) => {
  dispatch(start());
  try {
    const res = await userRequest.get(`avatar/logo/${id}/entreprise`);
    dispatch(success({ avatar: res.data }));
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(failure(err.response.data));
    }
  }
};

export const getCover = (id) => async (dispatch) => {
  dispatch(start());
  try {
    const res = await userRequest.get(`avatar/background/${id}/entreprise`);
    dispatch(success(res.data));
  } catch (err) {
    if (err.response.status === 401 || err.response.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(failure(err.response.data));
    }
  }
};

export const getEnterprisesByType =
  (data, page, pageSize) => async (dispatch) => {
    dispatch(enterpriseStart());
    try {
      const res = await userRequest.post(
        `admin/filterbytypeentreprise?page=${page}&per_page=${pageSize}`,
        data
      );
      dispatch(enterpriseSuccess(res.data));
    } catch (err) {
      if (err.response.status === 401 || err.response.status === 403) {
        dispatch(expired());
        dispatch(loginReset());
      } else {
        dispatch(enterpriseFailure(err.response.data));
      }
    }
  };

export const getMeasurers = async (page, pageSize) => {
  return userRequest.get(
    `entreprisemesureur/listemesureur?page=${page}&per_page=${pageSize}`
  );
};

export const getRequestInspectorAwaiting = async (data) => {
  return userRequest.post(`entreprise/searchinspecteur`, data);
};

export const getRequestMeasurerAwaiting = async (data) => {
  return userRequest.post(`entreprisemesureur/searchmesureur`, data);
};

export const getAvailabilities = async () => {
  return userRequest.get(`entreprise/availabletime`);
};

export const addAvailability = async (data) => {
  return userRequest.post(`entreprise/availabletime`, data);
};

export const updateAvailability = async (data) => {
  return userRequest.put(`entreprise/availabletime`, data);
};

export const deleteAvailability = async (id) => {
  return userRequest.delete(`entreprise/availabletime/${id}`);
};

export const updateMesurerPayments = async (user) => {
  return userRequest.put('mesureur/mesureurinfo', user);
};
