/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unsafe-optional-chaining */
import Picker from '@emoji-mart/react';
import { Button, Input, Textarea } from '@nextui-org/react';
import * as Ably from 'ably';
import { motion } from 'framer-motion';
import { nanoid } from 'nanoid';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import IconSearch from '../components/icons/IconSearch';
import { getList, post, update } from '../redux/api/chat';
import { expired, loginReset } from '../redux/reducers/userReducer';
import { renderCharacter } from '../utils/common';
import { months } from '../utils/constants';
import useOutsideClick from '../utils/useOutsideClick';

const Chat = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname;
  const { type } = useParams();
  const { id } = useParams();
  const [loading, setLoading] = useState(true);
  const [isFetched, setIsFetched] = useState(false);
  const [isloading, setIsLoading] = useState(true);
  const [chats, setChats] = useState([]);
  const [chat, setChat] = useState('');
  const user = useSelector((state) => state.user);
  const app = useSelector((state) => state.app);
  const types = ['enterprise', 'inspector', 'measurer', 'customer'];
  const [channelId, setChannelId] = useState('');
  const [channel, setChannel] = useState('');
  const [realtime, setRealTime] = useState(null);
  const [message, setMessage] = useState('');
  const [list, setList] = useState([]);
  const [subscribed, setSubscribed] = useState(false);
  const [inputRows, setInputRows] = useState(1);
  const [openEmojiPicker, setOpenEmojiPicker] = useState(false);
  const [error, setError] = useState('');
  const [data, setData] = useState('');
  const [rtm, setRtm] = useState(null);
  const [userId, setUserId] = useState(null);

  const variants = {
    visible: {
      opacity: 1,
      scale: 1,
      x: 0,
      y: 0,
    },
    hidden: {
      opacity: 0,
      scale: 0,
      x: -205,
      y: 205,
    },
  };

  const messagesEndRef = useRef(null);
  const messageContainerRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef?.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const isMobile = () => {
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Windows Phone/i.test(
        navigator?.userAgent
      ) || window?.innerWidth < 1024
    );
  };

  useEffect(scrollToBottom, [list]);

  const getChats = () => {
    setLoading(true);
    getList()
      .then((res) => {
        setLoading(false);
        setIsFetched(true);
        setChats(res.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        if (err?.response?.status === 401 || err?.response?.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        }
      });
  };

  const loadUserId = () => {
    if (user?.isEnterprise) {
      setUserId(app?.currentState?.enterprise?.users_id);
    } else if (user?.isAdmin) {
      setUserId(app?.currentState?.user?.users_id);
    } else if (user?.isMesurer) {
      setUserId(app?.currentState?.mesurer?.users_id);
    } else {
      setUserId(app?.currentState?.inspector?.users_id);
    }
  };

  useEffect(() => {
    let currentUserId = '';

    if (user?.isEnterprise) {
      currentUserId = app?.currentState?.enterprise?.users_id;
    } else if (user?.isAdmin) {
      currentUserId = app?.currentState?.user?.id;
    } else if (user?.isMesurer) {
      currentUserId = app?.currentState?.mesurer?.users_id;
    } else {
      currentUserId = app?.currentState?.inspector?.users_id;
    }
    setRealTime(
      new Ably.Realtime({
        key: process.env.REACT_APP_ABLY_API_KEY,
        transportParams: { remainPresentFor: 1000 },
        clientId:
          currentUserId !== undefined ? currentUserId?.toString() : null,
      })
    );
    if (path?.split('/')[2] && types.indexOf(path.split('/')[2]) === -1)
      setChannel(path.split('/')[2]);
    getChats();
  }, []);

  const hasRecentChat = (idUser) => {
    return chats?.findIndex(
      (ch) =>
        ch?.owner?.ownerId.toString() === idUser?.toString() ||
        ch?.client?.clientId?.toString() === idUser?.toString()
    );
  };

  const typeChat = (result) => {
    switch (result) {
      case 'inspector':
        return 'inspecteur';
      case 'measurer':
        return 'mesureur';
      case 'customer':
        return 'client';
      default:
        return 'entreprise';
    }
  };

  useEffect(() => {
    loadUserId();
  }, []);

  useEffect(() => {
    loadUserId();
  }, [app?.currentState]);

  useEffect(() => {
    if (id && isFetched) {
      const chatIdx = hasRecentChat(id);
      if (types?.indexOf(type) !== -1 && chatIdx === -1 && !loading) {
        post({
          type_chat: typeChat(type),
          channel: `diag_audit:${nanoid()}`,
          clientId: id,
        })
          .then((res) => {
            setChat(res?.data?.data);
            setChannelId(res?.data?.data?.channel);
            getChats();
          })
          .catch((err) => {
            if (err?.response?.status === 401 || err.response?.status === 403) {
              dispatch(expired());
              dispatch(loginReset());
            }
          });
      } else if (!loading) {
        setChat(chats[chatIdx]);
        setChannelId(chats[chatIdx]?.channel);
      }
    }
  }, [chats]);

  useEffect(() => {
    if (channelId && realtime) {
      setChannel(realtime?.channels.get(channelId));
      setRtm(realtime?.channels.get('notifications'));
    }
  }, [channelId, chat]);

  useEffect(() => {
    const lastMessage = list[list?.length - 1];
    if (
      lastMessage?.data?.text?.toLowerCase() === data?.data?.text?.toLowerCase()
    ) {
      let temp = list;
      if (temp?.length > 0) {
        delete temp?.pop();

        temp = [...temp, data];
        setList(temp);
      }
    } else {
      setList([...list, data]);
    }
  }, [data]);

  useEffect(() => {
    if (channel) {
      channel?.attach(() => {
        channel?.history({ direction: 'forwards' }, (err, resultPage) => {
          if (!err) {
            setList(resultPage.items);
            messageContainerRef.current.scrollTop =
              messageContainerRef.current.scrollHeight;
            if (resultPage.hasNext()) {
              resultPage.next(function (err, nextPage) {
                return nextPage;
              });
            }
          }
        });
      });

      if (channel && !subscribed) {
        channel?.subscribe((msg) => {
          setData(msg);
        });

        channel?.presence.subscribe((member) => {
          return member;
        });
      }
      setSubscribed(true);
    }
  }, [channel]);

  const renderChannel = (cha) => {
    if (userId?.toString() === cha?.owner?.ownerId?.toString()) {
      if (
        cha?.client?.role.includes('inspecteur') ||
        cha?.client?.role.includes('mesureur') ||
        cha?.client?.role.includes('admin')
      ) {
        return `${
          cha?.client?.info?.original?.data?.users?.original?.data?.nom ||
          cha?.client?.info?.original?.data?.nom ||
          cha?.client?.info?.original?.data?.users?.nom
        } ${
          cha?.client?.info?.original?.data?.users?.original?.data?.prenom ||
          cha?.client?.info?.original?.data?.prenom ||
          cha?.client?.info?.original?.data?.users?.prenom
        }`;
      }
      return cha?.client?.info?.original?.data?.nom_entreprise;
    }
    if (
      cha?.owner?.role.includes('inspecteur') ||
      cha?.owner?.role.includes('mesureur') ||
      cha?.owner?.role.includes('admin')
    ) {
      return `${
        cha?.owner?.info?.original?.data?.users?.original?.data?.nom ||
        cha?.owner?.info?.original?.data?.nom ||
        cha?.owner?.info?.original?.data?.users?.nom
      } ${
        cha?.owner?.info?.original?.data?.users?.original?.data?.prenom ||
        cha?.owner?.info?.original?.data?.prenom ||
        cha?.owner?.info?.original?.data?.users?.prenom
      }`;
    }
    return cha?.owner?.info?.original?.data?.nom_entreprise;
  };

  const renderSenderChannel = (cha) => {
    if (userId?.toString() === cha?.owner?.ownerId.toString()) {
      if (
        cha?.owner?.role.includes('inspecteur') ||
        cha?.owner?.role.includes('mesureur') ||
        cha?.owner?.role.includes('admin')
      ) {
        return `${
          cha?.owner?.info?.original?.data?.users?.original?.data?.nom ||
          cha?.owner?.info?.original?.data?.nom ||
          cha?.owner?.info?.original?.data?.users?.nom
        } ${
          cha?.owner?.info?.original?.data?.users?.original?.data?.prenom ||
          cha?.owner?.info?.original?.data?.prenom ||
          cha?.owner?.info?.original?.data?.users?.prenom
        }`;
      }
      return cha?.owner?.info?.original?.data?.nom_entreprise;
    }
    if (
      cha?.client?.role.includes('inspecteur') ||
      cha?.client?.role.includes('mesureur') ||
      cha?.client?.role.includes('admin')
    ) {
      return `${
        cha?.client?.info?.original?.data?.users?.original?.data?.nom ||
        cha?.client?.info?.original?.data?.nom ||
        cha?.client?.info?.original?.data?.users?.nom
      } ${
        cha?.client?.info?.original?.data?.users?.original?.data?.prenom ||
        cha?.client?.info?.original?.data?.prenom ||
        cha?.client?.info?.original?.data?.users?.prenom
      }`;
    }
    return cha?.client?.info?.original?.data?.nom_entreprise;
  };

  useEffect(() => {
    if (isloading && message) {
      channel.publish(
        'message',
        {
          sender: userId,
          text: message,
        },
        (result) => {
          if (result) {
            setError('message');
          } else {
            setIsLoading('false');
          }
        }
      );
      rtm.publish('message', {
        sender: renderSenderChannel(chat),
        client: userId,
        message,
      });
      update({
        id: chat?.id,
        history: message,
      });
      setMessage('');
    }
  }, [list]);

  const send = () => {
    if (message) {
      setIsLoading(true);
      setList([
        ...list,
        {
          id: nanoid(),
          data: {
            text: message,
            sender: userId,
          },
          timestamp: new Date().getTime(),
          isSent: false,
        },
      ]);
    }
  };

  const sendMessage = (e) => {
    e.preventDefault();
    send();
  };

  const handleKeyDown = (event) => {
    if (event.keyCode === 13 && !event.shiftKey) {
      event.preventDefault();

      send();
      return;
    }
    // shift + enter
    if (event.keyCode === 13 && event.shiftKey) {
      setInputRows(inputRows + 1);
      // smooth scroll body to bottom
      window.setTimeout(() => {
        document.querySelector('body').scrollTo(0, document.body.scrollHeight);
      }, 0);
    }
    // backspace
    if (event.keyCode === 8 && inputRows > 1) {
      // split message by line breaks
      const messageLines = message.split(/\r\n|\r|\n/);
      // check if last line is empty
      if (messageLines[messageLines.length - 1] === '') {
        setInputRows(inputRows - 1);
      }
    }
  };

  const handleClickOutside = () => {
    if (message === '') setInputRows(1);
  };

  const handleClickOutsideEmojiPicker = () => {
    setOpenEmojiPicker(false);
  };

  const ref = useOutsideClick(handleClickOutside);
  const emojiRef = useOutsideClick(handleClickOutsideEmojiPicker);

  const renderDate = (date) => {
    const d = new Date(date);

    // same date and same time
    if (
      d.getDate() === new Date().getDate() &&
      d.getMinutes() === new Date().getMinutes()
    ) {
      return "A l'instant";
    }
    // under a day render hours
    if (d.getDate() === new Date().getDate()) {
      return `${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${
        d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
      }`;
    }
    // last message was yesterday
    if (d.getDate() === new Date().getDate() - 1) {
      return 'Hier';
    }
    // same year render date render JJ/MMMM
    if (d.getFullYear() === new Date().getFullYear()) {
      return `${d.getDate()} ${months[d.getMonth()]}`;
    }
    // render full date
    return `${d.getDate()}/${d.getMonth() + 1}/${d.getFullYear()}`;
  };

  const openChat = (chat) => {
    let userID = null;
    async function getUserId() {
      userID = await chat?.owner?.ownerId;
    }
    getUserId();
    setRealTime(
      new Ably.Realtime({
        key: process.env.REACT_APP_ABLY_API_KEY,
        transportParams: { remainPresentFor: 1000 },
        clientId: userID,
      })
    );

    setChat(chat);
    setChannelId(chat?.channel);
  };

  const renderRole = (role) => {
    if (role === 'client') return 'Client';
    if (role === 'inspecteur') return 'Inspecteur';
    if (role === 'entreprise') return 'Entreprise';
    if (role === 'admin') return 'Admin';
    return '';
  };

  const renderTime = (timestamp) => {
    const d = new Date(timestamp);
    return `${d.getHours() < 10 ? `0${d.getHours()}` : d.getHours()}:${
      d.getMinutes() < 10 ? `0${d.getMinutes()}` : d.getMinutes()
    }`;
  };

  return (
    <div className="mx-auto">
      <div className="min-w-full rounded border lg:grid lg:grid-cols-4">
        <motion.div
          initial="visible"
          animate={
            !isMobile() || (!chat?.id && isMobile()) ? 'visible' : 'hidden'
          }
          variants={{
            hidden: {
              opacity: 0,
              x: -20,
              display: 'none',
            },
            visible: {
              opacity: 1,
              x: 0,
              display: 'block',
            },
          }}
          className="border-r border-gray-200 lg:col-span-1"
        >
          <div className="mx-3 my-3">
            <div className="relative text-gray-600">
              <span className="absolute inset-y-0 left-0 flex items-center pl-2">
                <IconSearch />
              </span>
              <Input
                type="search"
                name="search"
                placeholder="Rechercher"
                fullWidth
                rounded
                className="pl-2"
              />
            </div>
          </div>

          <div>
            <h2 className="my-2 mb-2 ml-2 text-lg text-gray-600">
              Discussions
            </h2>
            <div className="chat-list supports-scrollbars:pr-2 overflow-auto scrollbar-thin scrollbar-track-slate-100 scrollbar-thumb-gray-200">
              <motion.ul
                initial="visible"
                animate={chats.length === 0 && loading ? 'visible' : 'hidden'}
                variants={{
                  hidden: {
                    opacity: 0,
                    scale: 1,
                    display: 'none',
                  },
                  visible: {
                    opacity: 1,
                    scale: 1,
                    display: 'block',
                  },
                }}
              >
                <li className="flex w-full animate-pulse cursor-pointer items-center justify-start border-b border-gray-300 p-3 text-sm transition duration-150 ease-in-out">
                  <div className="h-10 w-11 rounded-full bg-gray-200 object-cover" />
                  <div className="w-full justify-start pb-2 text-left">
                    <div className="mb-2 flex h-5 justify-between">
                      <span className="ml-2 block w-2/4 rounded-lg bg-gray-300" />
                      <span className=" block w-1/6 rounded-lg bg-gray-300" />
                    </div>
                    <span className="ml-2 block h-5 w-1/3 rounded-lg bg-gray-300" />
                  </div>
                </li>
                <li className="flex w-full animate-pulse cursor-pointer items-center justify-start border-b border-gray-300 p-3 text-sm transition duration-150 ease-in-out">
                  <div className="h-10 w-11 rounded-full bg-gray-200 object-cover" />
                  <div className="w-full justify-start pb-2 text-left">
                    <div className="mb-2 flex h-5 justify-between">
                      <span className="ml-2 block w-2/4 rounded-lg bg-gray-300" />
                      <span className=" block w-1/6 rounded-lg bg-gray-300" />
                    </div>
                    <span className="ml-2 block h-5 w-1/3 rounded-lg bg-gray-300" />
                  </div>
                </li>
              </motion.ul>
              <motion.ul
                initial="hidden"
                animate={chats.length > 0 && !loading ? 'visible' : 'hidden'}
                variants={{
                  hidden: {
                    opacity: 0,
                    scale: 0.99,
                  },
                  visible: {
                    opacity: 1,
                    scale: 1,
                    transition: {
                      delay: 0.02,
                      duration: 0.3,
                    },
                  },
                }}
              >
                {chats?.map((cha) => (
                  <li key={cha?.channel}>
                    <button
                      type="button"
                      onClick={() => {
                        openChat(cha);
                      }}
                      className={`flex w-full cursor-pointer items-center justify-start border-b border-gray-300 p-3 text-sm transition duration-150 ease-in-out
                    ${
                      chat?.channel === cha?.channel
                        ? 'bg-gray-100'
                        : 'hover:bg-gray-100'
                    }
                    `}
                    >
                      {user?.curentUser && userId !== cha?.client?.clientId && (
                        <img
                          className="h-10 w-10 rounded-full border border-gray-300 object-cover"
                          src={
                            cha?.client?.info?.original?.data?.logo ||
                            '/profile.svg'
                          }
                          alt="user"
                        />
                      )}
                      {user.curentUser && userId !== cha?.owner?.ownerId && (
                        <img
                          className="h-10 w-10 rounded-full border border-gray-300 object-cover"
                          src={
                            cha?.owner?.info?.original?.data?.logo ||
                            '/profile.svg'
                          }
                          alt="user"
                        />
                      )}
                      <div className="w-full justify-start pb-2 text-left">
                        <div className="flex justify-between">
                          <span
                            className={`ml-2 block truncate font-semibold first-letter:uppercase
                        ${
                          chat?.channel === cha?.channel
                            ? '!text-primary'
                            : 'text-gray-600'
                        }`}
                          >
                            {renderChannel(cha)}
                          </span>
                          <span className="ml-2 block text-sm text-gray-600">
                            {renderDate(cha?.updated_at)}
                          </span>
                        </div>
                        <span className="ml-2 block truncate text-sm text-gray-600">
                          {cha?.history}
                        </span>
                      </div>
                    </button>
                  </li>
                ))}
              </motion.ul>
              {chats.length === 0 && !loading && (
                <div className="justify-center border-t border-gray-300 p-3 text-center text-sm">
                  Aucune discussion
                </div>
              )}
            </div>
          </div>
        </motion.div>
        <motion.div
          initial="hidden"
          animate={
            !isMobile() || (chat?.id && isMobile()) ? 'visible' : 'hidden'
          }
          variants={{
            hidden: {
              opacity: 0,
              x: -20,
              display: 'none',
            },
            visible: {
              opacity: 1,
              x: 0,
              display: 'block',
              delay: {
                when: 'beforeChildren',
                staggerChildren: 0.1,
              },
            },
          }}
          className="lg:col-span-3 lg:block"
        >
          <div className="w-full">
            <div className="relative flex items-center border-b border-gray-300 p-3">
              <span className="ml-2 block font-bold text-gray-600 first-letter:uppercase">
                {userId && chat && renderChannel(chat)}
                <br />
                {userId && chat && (
                  <span className="text-sm font-medium text-gray-500 first-letter:!uppercase">
                    {userId === chat?.owner?.ownerId
                      ? renderCharacter(chat?.client?.role[0])
                      : renderCharacter(chat?.owner?.role[0])}
                  </span>
                )}
              </span>
              {/* <span className="absolute left-10 top-3 h-3 w-3 rounded-full bg-green-600" /> */}
            </div>
            <div
              ref={messageContainerRef}
              className="messages h-screen-chat relative w-full overflow-y-auto p-6"
            >
              <ul className="space-y-8">
                {list.map((item) => (
                  <li
                    key={item.id}
                    className={`flex ${
                      item?.data?.sender === userId
                        ? 'order-2 items-end justify-end'
                        : 'items-start justify-start'
                    }`}
                  >
                    <div className="relative ml-3 flex-col">
                      <div
                        className={`tri-right relative min-w-[5rem] max-w-xl items-end rounded-t-md rounded-bl-md bg-primary px-4 py-2 shadow
                      ${
                        item?.data?.sender === userId
                          ? 'right-in bg-opacity-[35%] text-black'
                          : 'left-in text-white'
                      }
                      `}
                      >
                        <span className="block break-words">
                          {item?.data?.text}
                        </span>
                      </div>
                      <span className="mt-px flex justify-between text-xs text-gray-600">
                        <span>{renderTime(item?.timestamp)}</span>
                        {item?.data?.sender === userId && (
                          <span>
                            {item?.isSent === false ? (
                              <svg
                                className="h-4 w-4"
                                width="24"
                                height="24"
                                strokeWidth="1.5"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M12 6L12 12L18 12"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <path
                                  d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                              </svg>
                            ) : (
                              <svg
                                className="h-5 w-5"
                                width="24"
                                height="24"
                                strokeWidth="1.5"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.5 12.5L5.57574 16.5757C5.81005 16.8101 6.18995 16.8101 6.42426 16.5757L9 14"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M16 7L12 11"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                />
                                <path
                                  d="M7 12L11.5757 16.5757C11.8101 16.8101 12.1899 16.8101 12.4243 16.5757L22 7"
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                />
                              </svg>
                            )}
                          </span>
                        )}
                      </span>
                    </div>
                  </li>
                ))}
                <div ref={messagesEndRef} />
              </ul>
            </div>

            <motion.div
              initial="hidden"
              animate={chat ? 'visible' : 'hidden'}
              variants={{
                hidden: {
                  opacity: 0,
                  y: -20,
                },
                visible: {
                  opacity: 1,
                  y: 0,
                },
              }}
              className="relative flex w-full items-start justify-between border-t border-gray-300 p-3"
            >
              <motion.div
                ref={emojiRef}
                initial="hidden"
                animate={openEmojiPicker ? 'visible' : 'hidden'}
                variants={variants}
                transition={{
                  duration: 0.2,
                }}
                className="absolute bottom-20 left-4 z-50"
              >
                <Picker
                  data={data}
                  theme="light"
                  searchPosition="none"
                  previewPosition="none"
                  locale="fr"
                  emojiButtonColors={['#DC3838']}
                  onEmojiSelect={(emoji) => {
                    setOpenEmojiPicker(false);
                    // append emoji to input
                    setMessage((prev) => prev + emoji.native);
                    // focus input
                    ref.current.focus();
                  }}
                />
              </motion.div>

              <button
                id="emoji-canvas-button"
                type="button"
                className={`mt-2 inline-flex h-10 w-10 items-center justify-center rounded-full text-gray-500 transition duration-500 ease-in-out hover:text-primary focus:outline-none
                ${openEmojiPicker ? 'bg-gray-100 !text-primary' : ''}`}
                onClick={() => {
                  setOpenEmojiPicker(!openEmojiPicker);
                  // unput already focused
                  ref.current.focus();
                }}
              >
                <svg
                  width="24"
                  height="24"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 17.5228 17.5228 22 12 22Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M16.5 14.5C16.5 14.5 15 16.5 12 16.5C9 16.5 7.5 14.5 7.5 14.5"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M15.5 9C15.2239 9 15 8.77614 15 8.5C15 8.22386 15.2239 8 15.5 8C15.7761 8 16 8.22386 16 8.5C16 8.77614 15.7761 9 15.5 9Z"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M8.5 9C8.22386 9 8 8.77614 8 8.5C8 8.22386 8.22386 8 8.5 8C8.77614 8 9 8.22386 9 8.5C9 8.77614 8.77614 9 8.5 9Z"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </button>
              <div className="w-full">
                <form
                  className="relative flex w-full items-center"
                  onSubmit={sendMessage}
                >
                  <Textarea
                    ref={ref}
                    type="text"
                    placeholder="Écrire quelque chose"
                    fullWidth
                    shadow="false"
                    animated="false"
                    rows={inputRows}
                    css={{
                      padding: '10px !important',
                    }}
                    value={message}
                    onChange={(event) => {
                      setMessage(event.target.value);
                      setOpenEmojiPicker(false);
                    }}
                    onKeyDown={handleKeyDown}
                    id="message"
                    name="message"
                  />
                  <div className="block">
                    <Button
                      type="submit"
                      css={{
                        tt: 'capitalize',
                        color: 'white',
                        backgroundColor: '#DC3838',
                      }}
                      auto
                    >
                      <span className="font-bold md:block">Envoyer</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="ml-2 h-6 w-6 rotate-90 transform"
                      >
                        <path d="M10.894 2.553a1 1 0 00-1.788 0l-7 14a1 1 0 001.169 1.409l5-1.429A1 1 0 009 15.571V11a1 1 0 112 0v4.571a1 1 0 00.725.962l5 1.428a1 1 0 001.17-1.408l-7-14z" />
                      </svg>
                    </Button>
                  </div>
                </form>
              </div>
            </motion.div>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Chat;
