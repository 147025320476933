/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { INTERNAL } from '../constants';

const inspectorSlice = createSlice({
  name: 'inspector',
  initialState: {
    list: [],
    total: 0,
    error: null,
    meta: {},
  },
  reducers: {
    inspectorStart: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    inspectorSuccess: (state, action) => {
      state.isLoading = false;
      if (action.payload.data) state.list = action.payload.data;
      else state.list = action.payload;
      if (action.payload?.meta) state.meta = action.payload.meta;
    },
    inspectorFailure: (state) => {
      state.isLoading = false;
      state.error = INTERNAL;
    },
    inspectorReset: (state) => {
      state.list = [];
      state.isLoading = false;
      state.error = null;
    },
    orderInspector: (state, action) => {
      if (action.payload === 'asc') {
        state.list = state.list.sort(
          (a, b) => new Date(a.create_at) - new Date(b.create_at)
        );
      } else {
        state.list = state.list.sort(
          (a, b) => new Date(b.create_at) - new Date(a.create_at)
        );
      }
    },
    meta: (state, action) => {
      state.meta = action.payload;
    },
    resetList: (state) => {
      state.list = [];
    },
    reset: (state) => {
      state.list = [];
      state.isLoading = false;
      state.error = null;
    },
  },
});

export const {
  inspectorStart,
  inspectorSuccess,
  inspectorReset,
  inspectorFailure,
  orderInspector,
  resetList,
  reset,
} = inspectorSlice.actions;
export default inspectorSlice.reducer;
