/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import { INTERNAL } from '../constants';

const auditSlice = createSlice({
  name: 'audit',
  initialState: {
    list: [],
    isLoading: false,
    error: null,
    meta: {},
  },
  reducers: {
    auditStart: (state) => {
      state.error = null;
      state.isLoading = true;
    },
    auditOnSuccess: (state) => {
      state.isLoading = false;
    },
    auditSuccess: (state, action) => {
      state.isLoading = false;
      if (action.payload.data) state.list = action.payload.data;
      else state.list = action.payload;
      if (action.payload?.meta) state.meta = action.payload.meta;
    },
    auditFailure: (state) => {
      state.isLoading = false;
      state.error = INTERNAL;
    },
    auditReset: (state) => {
      state.list = [];
      state.isLoading = false;
      state.error = null;
    },
    orderAudit: (state, action) => {
      if (action.payload === 'asc') {
        state.list = state.list.sort(
          (a, b) => new Date(a.create_at) - new Date(b.create_at)
        );
      } else {
        state.list = state.list.sort(
          (a, b) => new Date(b.create_at) - new Date(a.create_at)
        );
      }
    },
    meta: (state, action) => {
      state.meta = action.payload;
    },
  },
});

export const {
  auditStart,
  auditSuccess,
  auditOnSuccess,
  auditReset,
  auditFailure,
  orderAudit,
  meta,
} = auditSlice.actions;
export default auditSlice.reducer;
