/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
import { createTheme, ThemeProvider } from '@mui/material';
import { DataGrid, frFR, GridActionsCellItem } from '@mui/x-data-grid';
import { Dropdown, Grid, Input } from '@nextui-org/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import ExportToExcel from '../components/ExportToExcel';
import IconMessage from '../components/icons/IconMessage';
import IconSearch from '../components/icons/IconSearch';
import {
  getAdminEnterpriseCustomers,
  getCustomers,
  getEnterpriseCustomers,
  getMesurerCustomers,
} from '../redux/api/customer';
import { resetList } from '../redux/reducers/customerReducer';
import { renderCharacter } from '../utils/common';

const Customers = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigator = useNavigate();
  const navigate = (route) => {
    navigator(route);
  };
  const id = location.pathname.split('/')[3];
  const { isEnterprise, isAdmin, isMesurer } = useSelector(
    (state) => state.user
  );
  const { error, isLoading, meta } = useSelector((state) => state.customer);
  const [customers, setCustomers] = useState([]);
  const [exportCustomers, setExportCustomers] = useState([]);
  const [cities, setCities] = useState([]);
  const [mode, setMode] = useState('server');
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [rowCountState, setRowCountState] = useState(0);
  const [completeList, setCompleteList] = useState([]);
  const list = useSelector((state) => state.customer.list);

  console.log('list: ', list);

  const [selectedCity, setSelectedCity] = React.useState(
    new Set(['Par ville'])
  );
  const [selected, setSelected] = React.useState(new Set(['Filtrer']));
  const [filters, setFilters] = useState(['Tous', 'Par Ville']);

  const selectedValue = useMemo(
    () => Array.from(selected).join(', ').replaceAll('_', ' '),
    [selected]
  );

  const selectedCityValue = useMemo(
    () => Array.from(selectedCity).join(', ').replaceAll('_', ' '),
    [selectedCity]
  );

  useEffect(() => {
    setRowCountState(meta?.pagination?.total);
    setCompleteList(
      list.map((inspect) => ({
        id: inspect?.id,
        name: renderCharacter(inspect?.users?.nom),
        firstname: renderCharacter(inspect?.users?.prenom),
        phone: inspect?.users?.telephone,
        city: renderCharacter(inspect?.users?.ville_residence),
        paymentMethod: inspect?.payment_method,
        userId: inspect?.users_id,
      }))
    );
  }, [list]);

  useEffect(() => {
    setCustomers(completeList);
  }, [completeList]);

  useEffect(() => {
    setExportCustomers(
      customers.map((item) => ({
        id: item?.id,
        Nom: renderCharacter(item?.name),
        Prénom: renderCharacter(item?.firstname),
        Téléphone: item?.phone,
        Ville: renderCharacter(item?.city),
        'Moyen de paiement': item?.paymentMethod,
        'Identifiant utilisateur': item?.userId,
      }))
    );
  }, [customers]);

  const requestCustomers = () => {
    if (id && isAdmin) {
      dispatch(getAdminEnterpriseCustomers(id, page + 1, pageSize));
    } else if (isEnterprise) {
      dispatch(getEnterpriseCustomers(page + 1, pageSize));
    } else if (isMesurer) {
      dispatch(getMesurerCustomers(page + 1, pageSize));
    } else {
      dispatch(getCustomers(page + 1, pageSize));
    }
  };

  useEffect(() => {
    return () => {
      dispatch(resetList());
    };
  }, []);

  useEffect(() => {
    const filterVal = selected.entries().next().value[0].toLowerCase();

    if (filterVal === 'tous') {
      setMode('server');
      if (page === 0) requestCustomers();
      else setPage(0);
    }
  }, [selected]);

  const search = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    const filterVal = selected.entries().next().value[0].toLowerCase();
    if (searchTerm === '') {
      setCustomers(completeList);
    } else {
      setCustomers(
        completeList.filter((item) => {
          return filterVal.toLowerCase() === 'par ville'
            ? item.city?.toLowerCase().includes(searchTerm)
            : item.name?.toLowerCase().includes(searchTerm) ||
                item.firstname?.toLowerCase().includes(searchTerm) ||
                item.email?.toLowerCase().includes(searchTerm) ||
                item.city?.toLowerCase().includes(searchTerm) ||
                item.paymentMethod?.toLowerCase().includes(searchTerm);
        })
      );
    }
  };

  const columns = [
    {
      field: 'name',
      headerName: 'Nom du client',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'firstname',
      headerName: 'Prénom',
      minWidth: 150,
      flex: 1,
    },
    {
      field: 'phone',
      headerName: 'Téléphone',
      minWidth: 180,
      flex: 1,
    },
    {
      field: 'city',
      headerName: 'Ville',
      minWidth: 200,
      flex: 1,
    },
    {
      field: 'paymentMethod',
      headerName: 'Méthode de paiement',
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        return (
          <span>
            {params.row.paymentMethod !== null
              ? params.row.paymentMethod
              : 'Aucun'}
          </span>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      minWidth: 150,
      flex: 1,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<IconMessage />}
            label="Envoyer un message"
            onClick={() => {
              navigate(`/chats/customer/${params.row.userId}/new`);
            }}
          />,
        ];
      },
    },
  ];

  useEffect(() => {
    requestCustomers();
  }, [page]);

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.pagination?.total !== undefined
        ? meta?.pagination?.total
        : prevRowCountState
    );
  }, [meta?.pagination?.total, setRowCountState]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#dc3838' },
      },
    },
    frFR
  );

  return (
    <div className="w-full">
      <div className="mx-auto w-full px-8">
        <div className="py-8">
          <div>
            <h2 className="block text-xl font-medium text-black md:hidden">
              Liste des clients
            </h2>
            <div className="mt-2 flex flex-wrap items-center justify-end space-x-4">
              <Grid
                css={{
                  marginBottom: '20px',
                }}
              >
                <Input
                  onChange={search}
                  labelRight={<IconSearch />}
                  placeholder="Rechercher"
                  bordered
                  type="search"
                />
              </Grid>
              <Grid
                css={{
                  marginBottom: '20px',
                }}
              >
                <ExportToExcel
                  data={exportCustomers}
                  fileName="Liste des clients"
                />
              </Grid>
              <Grid
                css={{
                  marginBottom: '20px',
                }}
              >
                <Dropdown placement="bottom-right">
                  <Dropdown.Button
                    flat
                    css={{
                      color: 'white',
                      backgroundColor: '#DC3838',
                    }}
                  >
                    {selectedValue}
                  </Dropdown.Button>
                  <Dropdown.Menu
                    aria-label="Single selection actions"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={selected.keys()}
                    onSelectionChange={setSelected}
                  >
                    {filters?.map((city) => (
                      <Dropdown.Item
                        key={city}
                        css={{
                          '&:hover': {
                            fontWeight: 500,
                          },
                          fontSize: '14px',
                        }}
                        className="first-letter:capitalize"
                      >
                        {city}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </Grid>
            </div>
            <div
              className="mt-8 w-full"
              style={{
                height: `${108 + 8 * 52}px`,
              }}
            >
              <ThemeProvider theme={theme}>
                <DataGrid
                  rows={customers}
                  disableSelectionOnClick
                  columns={columns}
                  getRowId={(row) => row.id}
                  columnBuffer={15}
                  checkboxSelection
                  rowCount={rowCountState}
                  loading={isLoading}
                  rowsPerPageOptions={[15]}
                  pagination
                  page={page}
                  pageSize={pageSize}
                  paginationMode={mode}
                  onPageChange={(newPage) => setPage(newPage)}
                  onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                />
              </ThemeProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Customers;
