/* eslint-disable react/no-array-index-key */
import { Badge, Loading } from '@nextui-org/react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';

import IconDownload from '../components/icons/IconDownload';
import IGallery from '../components/iGallery';
import { reverseLocation } from '../redux/api/app';
import {
  getAudit,
  getEnterpriseAudit,
  getEnterpriseInspectorAudit,
  getEnterpriseMeasurerAudit,
  getMesurerAudit,
} from '../redux/api/audit';
import { applyStatusColor, renderCharacter, renderDate } from '../utils/common';

const LoadingContainer = () => {
  return (
    <div className="flex h-screen w-full items-center justify-center bg-gray-50">
      <Loading
        loadingCss={{
          $$loadingSize: '100px',
          $$loadingBorder: '10px',
          $$LoadingColors: '#DC3838',
        }}
      />
    </div>
  );
};
const Audit = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);
  const user = location.pathname.split('/')[1];
  const { id } = useParams();
  const [audit, setAudit] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingOnDownload, setloadingOnDownload] = useState(false);
  const { isEnterprise, isMesurer } = useSelector((state) => state.user);

  const getAddress = (response) => {
    if (
      response?.client_info?.original?.data?.users?.lat &&
      response?.client_info?.original?.data?.users?.long
    ) {
      reverseLocation(
        response?.client_info?.original?.data?.users?.long,
        response?.client_info?.original?.data?.users?.lat
      ).then((resp) => {
        setAudit((prev) => ({
          ...prev,
          client_info: {
            ...prev.client_info,
            original: {
              ...prev.client_info.original,
              data: {
                ...prev.client_info.original.data,
                users: {
                  ...prev.client_info.original.data.users,
                  adresse: resp?.data?.features[0]?.place_name,
                },
              },
            },
          },
        }));
      });
    } else if (
      response?.demande_audit_info?.original?.data?.client_info?.original?.data
        ?.users?.lat &&
      response?.demande_audit_info?.original?.data?.client_info?.original?.data
        ?.users?.long
    ) {
      reverseLocation(
        response?.demande_audit_info?.original?.data?.client_info?.original
          ?.data?.users?.long,
        response?.demande_audit_info?.original?.data?.client_info?.original
          ?.data?.users?.lat
      ).then((resp) => {
        setAudit((prev) => ({
          ...prev,
          demande_audit_info: {
            ...prev.demande_audit_info,
            original: {
              ...prev.demande_audit_info.original,
              data: {
                ...prev.demande_audit_info.original.data,
                client_info: {
                  ...prev.demande_audit_info.original.data.client_info,
                  original: {
                    ...prev.demande_audit_info.original.data.client_info
                      .original,
                    data: {
                      ...prev.demande_audit_info.original.data.client_info
                        .original.data,
                      users: {
                        ...prev.demande_audit_info.original.data.client_info
                          .original.data.users,
                        adresse: resp?.data?.features[0]?.place_name,
                      },
                    },
                  },
                },
              },
            },
          },
        }));
      });
    }
  };

  const get = () => {
    if (isEnterprise) {
      if (user === 'inspector') {
        getEnterpriseInspectorAudit(id).then((res) => {
          let data =
            res.data?.data?.demande_audit?.original?.data || res.data?.data;
          data = {
            ...data,
            status:
              res.data?.data?.status ||
              data?.demande_audit_info?.original?.data?.status,
          };
          setAudit(data);
          getAddress(data);
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
      } else if (user === 'measurer') {
        getEnterpriseMeasurerAudit(id).then((res) => {
          let data =
            res.data?.data?.demande_audit?.original?.data || res.data?.data;
          data = {
            ...data,
            status:
              res.data?.data?.status ||
              data?.demande_audit_info?.original?.data?.status,
          };
          setAudit(data);
          getAddress(data);
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
      } else {
        getEnterpriseAudit(id).then((res) => {
          let data =
            res.data?.data?.demande_audit?.original?.data || res.data?.data;
          data = {
            ...data,
            status:
              res.data?.data?.status ||
              data?.demande_audit_info?.original?.data?.status,
          };
          setAudit(data);
          getAddress(data);
          setTimeout(() => {
            setLoading(false);
          }, 300);
        });
      }
    } else if (isMesurer) {
      getMesurerAudit(id).then((res) => {
        let data =
          res.data?.data?.demande_audit?.original?.data || res.data?.data;
        data = {
          ...data,
          status:
            res.data?.data?.status ||
            data?.demande_audit_info?.original?.data?.status,
        };
        setAudit(data);
        getAddress(data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
    } else {
      getAudit(id).then((res) => {
        let data =
          res.data?.data?.demande_audit?.original?.data || res.data?.data;
        data = {
          ...data,
          status:
            res.data?.data?.status ||
            data?.demande_audit_info?.original?.data?.status,
        };
        setAudit(data);
        getAddress(data);
        setTimeout(() => {
          setLoading(false);
        }, 300);
      });
    }
  };

  useEffect(() => {
    if (id) get();
  }, [id]);

  function closeNotif() {
    setTimeout(() => {
      setTimeout(() => {
        setShowNotification(false);
      }, 300);
    }, 100);
  }

  const showNotif = (text, type) => {
    setTimeout(() => {
      setNotification({
        text,
        type,
      });
      setTimeout(() => {
        setShowNotification(true);
      }, 100);
    }, 500);

    setTimeout(() => {
      setNotification({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 6000);
    }, 5000);
  };

  const renderMeeting = (date) => {
    if (date) {
      // 12 March 2020 at 12:00
      return `${Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      }).format(new Date(date))} à ${Intl.DateTimeFormat('fr-FR', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(date))}`;
    }
    return null;
  };
  const loadContentsToDownload = () => {
    const mainTitle = {
      text:
        audit?.num_dmd || audit?.demande_audit_info?.original?.data?.num_dmd,
      margin: [0, 0, 0, 10],
      style: 'header',
    };
    const author = {
      text: `${renderCharacter(
        audit?.client_info?.original?.data?.users?.nom
      )} ${renderCharacter(audit?.client_info?.original?.data?.users?.prenom)}`,
      margin: [0, 0, 0, 10],
    };
    const auditName = {
      text: renderCharacter(audit?.name_audit),
      margin: [0, 0, 0, 10],
    };
    const applicant = {
      title: {
        text: 'Demandeur',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: renderCharacter(audit?.demandeur),
    };
    const contactDetails = {
      title: {
        text: 'Informations du client',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Nom:   ${renderCharacter(
                audit?.client_info?.original?.data?.users?.nom ||
                  audit?.demande_audit_info?.original?.data?.client_info
                    ?.original?.data?.users?.nom
              )}`,
            },
            {
              width: '50%',
              text: `Prénom:   ${renderCharacter(
                audit?.client_info?.original?.data?.users?.prenom ||
                  audit?.demande_audit_info?.original?.data?.client_info
                    ?.original?.data?.users?.prenom
              )}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Siret: ${renderCharacter(
                audit?.client_info?.original?.data?.users?.siret ||
                  audit?.demande_audit_info?.original?.data?.client_info
                    ?.original?.data?.users?.siret
              )}`,
            },
            {
              width: '50%',
              text: `Adresse:   ${
                audit?.client_info?.original?.data?.users?.adresse ||
                audit?.demande_audit_info?.original?.data?.client_info?.original
                  ?.data?.users?.adresse
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Téléphone:   ${
                audit?.client_info?.original?.data?.users?.telephone ||
                audit?.demande_audit_info?.original?.data?.client_info?.original
                  ?.data?.users?.telephone
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        `Email:   ${
          audit?.client_info?.original?.data?.users?.email ||
          audit?.demande_audit_info?.original?.data?.client_info?.original?.data
            ?.users?.email
        }`,
      ],
    };
    const placeLocation = {
      title: {
        text: 'Coordonnées du lieu à diagnostiquer',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Contact:   ${
                audit?.contact_ics ||
                audit?.demande_audit_info?.original?.data?.contact_ics
              }}`,
            },
            {
              width: '50%',
              text: `Adresse:   ${renderCharacter(
                audit?.adresse_lieu_audit ||
                  audit?.demande_audit_info?.original?.data?.adresse_lieu_audit
              )}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Téléphone:   ${
                audit?.contact_lieu_audit ||
                audit?.demande_audit_info?.original?.data?.contact_lieu_audit
              }
`,
            },
            {
              width: '50%',
              text: `Email:   ${
                audit?.email_lieu_audit ||
                audit?.demande_audit_info?.original?.data?.email_lieu_audit
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const appointmentOnline = {
      title: {
        text: 'Prise de rendez-vous en ligne',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: renderMeeting(
        audit?.rendez_vous_info?.original?.data?.available_time_info?.original
          ?.data?.date_heure
      ),
    };
    const aboutWebSite = {
      title: {
        text: 'Information concernant le site',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Contact:   ${
                audit?.contact_ics ||
                audit?.demande_audit_info?.original?.data?.contact_ics
              }
`,
            },
            {
              width: '50%',
              text: `Adresse:   ${
                audit?.adresse_ics ||
                audit?.demande_audit_info?.original?.data?.adresse_ics
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Code porte:   ${
                audit?.code_porte_ics ||
                audit?.demande_audit_info?.original?.data?.code_porte_ics
              }
  `,
            },
            {
              width: '50%',
              text: `Code portail:   ${
                audit?.code_portail_ics ||
                audit?.demande_audit_info?.original?.data?.code_portail_ics
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const typeOfHousing = {
      title: {
        text: 'Type de logement',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `${renderCharacter(
                audit?.type_logement ||
                  audit?.demande_audit_info?.original?.data?.type_logement
              )}`,
            },
            {
              width: '50%',
              text: `Surface totale:   ${
                audit?.surface_totale ||
                audit?.demande_audit_info?.original?.data?.surface_totale
              }m³`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: ``,
            },
            {
              width: '50%',
              text: `Nombre d'étages:   ${
                audit?.nbre_etage ||
                audit?.demande_audit_info?.original?.data?.nbre_etage
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const typeOfInstallation = {
      title: {
        text: 'Type de chauffage',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Type :   ${renderCharacter(
                audit?.type_chauffage ||
                  audit?.demande_audit_info?.original?.data?.type_chauffage
              )}`,
            },
            {
              width: '50%',
              text: `Année de fabrication:   ${renderDate(
                audit?.annee_fabrication ||
                  audit?.demande_audit_info?.original?.data?.annee_fabrication
              )}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };

    const hasAnyWorkDone = {
      title: {
        text: 'Est-ce que des travaux ont été effectués ?',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '25%',
              text:
                audit?.confirm_travaux_effectuee === 1 ||
                audit?.demande_audit_info?.original?.data
                  ?.confirm_travaux_effectuee === 1
                  ? 'Oui'
                  : 'Non',
            },
            {
              width: '50%',
              text: `Type de travaux:   ${renderCharacter(
                audit?.type_de_travaux_effectuee ||
                  audit?.demande_audit_info?.original?.data
                    ?.type_de_travaux_effectuee
              )}`,
            },
            {
              width: '25%',
              text: `Année:`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const windows = {
      title: {
        text: 'Vitrages',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Surface de vitres:   ${
                audit?.vitrage_surface_vitre ||
                audit?.demande_audit_info?.original?.data
                  ?.vitrage_surface_vitre ||
                ''
              }`,
            },
            {
              width: '50%',
              text: `Type de vitrage:   ${renderCharacter(
                audit?.vitrage_type_vitrage ||
                  audit?.demande_audit_info?.original?.data
                    ?.vitrage_type_vitrage
              )}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Nombre de vitres:   ${
                audit?.vitrage_nombre_vitre ||
                audit?.demande_audit_info?.original?.data
                  ?.vitrage_nombre_vitre ||
                ''
              }`,
            },
            {
              width: '50%',
              text: `Epaisseur des vitres:   ${
                audit?.vitrage_epaisseur_vitre ||
                audit?.demande_audit_info?.original?.data
                  ?.vitrage_epaisseur_vitre ||
                ''
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        `Type de huisserie:   ${renderCharacter(
          audit?.vitrage_type_huisserie ||
            audit?.demande_audit_info?.original?.data?.vitrage_type_huisserie
        )}`,
      ],
    };
    const windowsDoors = {
      title: {
        text: 'Portes fenêtres',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Surface des portes vitrées:   ${
                audit?.porte_fenetres_surface_portes_vitre ||
                audit?.demande_audit_info?.original?.data
                  ?.porte_fenetres_surface_portes_vitre ||
                ''
              }`,
            },
            {
              width: '50%',
              text: `Type de vitrage:   ${renderCharacter(
                audit?.porte_fenetres_type_vitrage ||
                  audit?.demande_audit_info?.original?.data
                    ?.porte_fenetres_type_vitrage
              )}`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Nombre des portes vitrées:   ${
                audit?.porte_fenetres_nombre_portes_vitre ||
                audit?.demande_audit_info?.original?.data
                  ?.porte_fenetres_nombre_portes_vitre ||
                ''
              }`,
            },
            {
              width: '50%',
              text: `Epaisseur des vitres:   ${
                audit?.porte_fenetres_epaisseur_vitre ||
                audit?.demande_audit_info?.original?.data
                  ?.porte_fenetres_epaisseur_vitre ||
                ''
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        `Type de huisserie:   ${renderCharacter(
          audit?.porte_fenetres_type_huisserie ||
            audit?.demande_audit_info?.original?.data
              ?.porte_fenetres_type_huisserie
        )}`,
      ],
    };
    const entranceDoors = {
      title: {
        text: "Portes d'entrée",
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Surface des portes d'entrée:   ${
                audit?.porte_entree_surface ||
                audit?.demande_audit_info?.original?.data
                  ?.porte_entree_surface ||
                ''
              }`,
            },
            {
              width: '50%',
              text: `Type de porte:   `,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        `Nombre des portes d'entrée:   ${
          audit?.porte_entree_surface_nbre_porte ||
          audit?.demande_audit_info?.original?.data
            ?.porte_entree_surface_nbre_porte ||
          ''
        }`,
      ],
    };
    const Isolation = {
      title: {
        text: 'Isolation',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Isolation plancher haut:   ${
                audit?.isolation_plancher_haut ||
                audit?.demande_audit_info?.original?.data
                  ?.isolation_plancher_haut ||
                ''
              }`,
            },
            {
              width: '50%',
              text: `Isolation plancher bas:   ${
                audit?.isolation_plancher_bas ||
                audit?.demande_audit_info?.original?.data
                  ?.isolation_plancher_bas ||
                ''
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        `Isolation Comble:   ${
          audit?.isolation_comblee ||
          audit?.demande_audit_info?.original?.data?.isolation_comblee
        }`,
      ],
    };
    const surfaceDescription = (name, listSideWall) => {
      const DESCRIPTION = [[`Surface ${name}`, 'Type']];
      listSideWall.forEach((element) => {
        DESCRIPTION.push([`${element.surface} m²`, element.type]);
      });
      return DESCRIPTION;
    };
    const LIST_SIDEWALL = [
      {
        name: 'EST',
        listElement:
          JSON.parse(
            audit?.mure_cote_est ||
              audit?.demande_audit_info?.original?.data?.mure_cote_est
          ) || [],
      },
      {
        name: 'OUEST',
        listElement:
          JSON.parse(
            audit?.mure_cote_ouest ||
              audit?.demande_audit_info?.original?.data?.mure_cote_ouest
          ) || [],
      },
      {
        name: 'SUD',
        listElement:
          JSON.parse(
            audit?.mure_cote_sud ||
              audit?.demande_audit_info?.original?.data?.mure_cote_sud
          ) || [],
      },
      {
        name: 'NORD',
        listElement:
          JSON.parse(
            audit?.mure_cote_nord ||
              audit?.demande_audit_info?.original?.data?.mure_cote_nord
          ) || [],
      },
    ];
    const sideWall = {
      title: {
        text: 'Murs',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: LIST_SIDEWALL.map((side) => {
        return {
          table: {
            widths: ['*', '*'],
            body: surfaceDescription(side.name, side.listElement),
          },
          margin: [0, 0, 0, 20],
        };
      }),
    };
    const unHeated = {
      title: {
        text: 'Zone non chauffée',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: [
        {
          columns: [
            {
              width: '50%',
              text: `Sous sol:   ${
                audit?.zone_non_chauffee_sous_sol === 0 ||
                audit?.demande_audit_info?.original?.data
                  ?.zone_non_chauffee_sous_sol === 0
                  ? 'Oui'
                  : 'Non'
              }`,
            },
            {
              width: '50%',
              text: `Sous sol surface:   ${
                audit?.zone_non_chauffee_sous_sol_surface ||
                audit?.demande_audit_info?.original?.data
                  ?.zone_non_chauffee_sous_sol_surface ||
                ''
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Comble :   ${
                audit?.zone_non_chauffee_comble_surface === 0 ||
                audit?.demande_audit_info?.original?.data
                  ?.zone_non_chauffee_comble_surface === 0
                  ? 'Oui'
                  : 'Non'
              }`,
            },
            {
              width: '50%',
              text: `Comble surface:   ${
                audit?.zone_non_chauffee_comble_surface ||
                audit?.demande_audit_info?.original?.data
                  ?.zone_non_chauffee_comble_surface ||
                ''
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
        {
          columns: [
            {
              width: '50%',
              text: `Toiture :   ${
                audit?.zone_non_chauffee_toiture === 0 ||
                audit?.demande_audit_info?.original?.data
                  ?.zone_non_chauffee_toiture === 0
                  ? 'Oui'
                  : 'Non'
              }`,
            },
            {
              width: '50%',
              text: `Toiture surface:   ${
                audit?.zone_non_chauffee_toiture_surface ||
                audit?.demande_audit_info?.original?.data
                  ?.zone_non_chauffee_toiture_surface ||
                ''
              }`,
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ],
    };
    const devicesConnected = {
      title: {
        text: "Nombre d'appareil à contrôler :",
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: audit?.nbre_apparail_control,
    };
    const contents = {
      content: [
        // Main title
        mainTitle,
        // REFERENCE
        author,
        auditName,
        //  Applicant
        applicant.title,
        applicant.description,
        // Contact Details
        contactDetails.title,
        contactDetails.description[0],
        contactDetails.description[1],
        contactDetails.description[2],
        contactDetails.description[3],
        // Place location
        placeLocation.title,
        placeLocation.description[0],
        placeLocation.description[1],
        // Windows
        windows.title,
        windows.description[0],
        windows.description[1],
        windows.description[2],
      ],
      styles: {
        header: {
          fontSize: 25,
          bold: true,
          alignment: 'center',
        },
        title: {
          fontSize: 18,
          bold: true,
        },
      },
    };
    contents.content.push(
      // Make an appointment online and Information about the site
      // Make an appointment online
      appointmentOnline.title,
      appointmentOnline.description,
      // Information about the site
      aboutWebSite.title,
      aboutWebSite.description[0],
      aboutWebSite.description[1]
    );
    contents.content.push(
      // Type of Housing
      typeOfHousing.title,
      typeOfHousing.description[0],
      typeOfHousing.description[1],
      // Window DOORS
      windowsDoors.title,
      windowsDoors.description[0],
      windowsDoors.description[1],
      windowsDoors.description[2],
      // Entrance DOORS
      entranceDoors.title,
      entranceDoors.description[0],
      entranceDoors.description[1],
      Isolation.title,
      Isolation.description[0],
      Isolation.description[1],
      // Side Wall
      sideWall.title,
      sideWall.description[0],
      sideWall.description[1],
      sideWall.description[2],
      sideWall.description[3],
      // Unheated
      unHeated.title,
      unHeated.description[0],
      unHeated.description[1],
      unHeated.description[2]
    );
    contents.content.push(
      // Type of installation
      typeOfInstallation.title,
      typeOfInstallation.description,
      // Devices connected
      devicesConnected.title,
      devicesConnected.description
    );
    contents.content.push(
      // Has any work been done?
      hasAnyWorkDone.title,
      hasAnyWorkDone.description[0],
      hasAnyWorkDone.description[1]
    );
    // Picture
    const getDataUrl = (imageId) => {
      let result;
      try {
        const listimages = document.querySelectorAll(`#${imageId} img`);
        let identifiant = 0;
        listimages.forEach((element) => {
          const image = `${imageId}-${identifiant}`;
          contents.images[image] = {
            url: element.src,
            headers: {
              myheader: '123',
              myotherheader: 'abc',
            },
          };
          identifiant += 1;
        });
        result = listimages.length;
      } catch (error) {
        result = 0;
      }
      return result;
    };
    const imageToPrint = (imageId) => {
      const description = [];
      const numberImage = getDataUrl(imageId);
      if (numberImage !== 0) {
        for (let index = 0; index < numberImage; index += 1) {
          description.push({
            image: `${imageId}-${index}`,
            width: 200,
            height: 200,
            margin: [0, 0, 0, 30],
          });
        }
      } else {
        description.push({ text: 'Aucune image', margin: [0, 0, 0, 30] });
      }
      return description;
    };
    const pictureHome = {
      title: {
        text: 'Photos de la maison:',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: imageToPrint('preview-home'),
    };
    const pictureWindows = {
      title: {
        text: 'Photos des fenêtres:',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: imageToPrint('preview-windows'),
    };
    const pictureDoors = {
      title: {
        text: 'Photos des portes:',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: imageToPrint('preview-doors'),
    };

    const pictureOthers = {
      title: {
        text: 'Autres photos:',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: imageToPrint('preview-other'),
    };
    // Home Picture
    contents.content.push(pictureHome.title);
    pictureHome.description.forEach((element) => {
      contents.content.push(element);
    });
    // Windows Picture
    contents.content.push(pictureWindows.title);
    pictureWindows.description.forEach((element) => {
      contents.content.push(element);
    });
    // Doors Picture
    contents.content.push(pictureDoors.title);
    pictureDoors.description.forEach((element) => {
      contents.content.push(element);
    });
    // Roof Picture
    const pictureRoof = {
      title: {
        text: 'Photos de la toiture:',
        margin: [0, 30, 0, 10],
        style: 'title',
      },
      description: imageToPrint('preview-roof'),
    };
    contents.content.push(pictureRoof.title);
    pictureRoof.description.forEach((element) => {
      contents.content.push(element);
    });
    // Others Picture
    contents.content.push(pictureOthers.title);
    pictureOthers.description.forEach((element) => {
      contents.content.push(element);
    });
    return contents;
  };
  const downloadDocument = () => {
    setloadingOnDownload(true);
    setTimeout(() => {
      setloadingOnDownload(false);
      // pdfMake.createPdf(loadContentsToDownload()).open();
      pdfMake
        .createPdf(loadContentsToDownload())
        .download(
          audit?.num_dmd || audit?.demande_audit_info?.original?.data?.num_dmd
        );
    }, 3000);
  };

  return (
    <div className="w-full">
      {loadingOnDownload && <LoadingContainer />}
      {loading ? (
        <LoadingContainer />
      ) : (
        <div className="mx-auto w-full md:px-8">
          <div className="py-8">
            <div>
              <div className="flex w-full flex-wrap items-center justify-between space-x-2">
                <h2 className="block text-5xl font-semibold text-black">
                  {audit?.num_dmd ||
                    audit?.demande_audit_info?.original?.data?.num_dmd}
                </h2>
                <Badge
                  css={{
                    backgroundColor: applyStatusColor(
                      audit?.status === 'a_corriger'
                        ? 'A corrigé'
                        : audit?.status
                    ),
                  }}
                  size="lg"
                >
                  {audit?.status}
                </Badge>
              </div>
              <h4 className="mt-4 block text-xl font-normal">
                {renderCharacter(
                  audit?.client_info?.original?.data?.users?.nom ||
                    audit?.demande_audit_info?.original?.data?.client_info
                      ?.original?.data?.users?.nom
                )}{' '}
                {audit?.client_info?.original?.data?.users?.prenom ||
                  audit?.demande_audit_info?.original?.data?.client_info
                    ?.original?.data?.users?.prenom}
              </h4>
              <span className="mt-4 block text-xl font-normal">
                {audit?.name_audit ||
                  audit?.demande_audit_info?.original?.data?.name_audit}
              </span>
              <button
                type="button"
                className="float-right mt-4 flex w-auto cursor-pointer items-center justify-end text-primary"
                onClick={downloadDocument}
              >
                <span className="rounded-md border border-primary p-2">
                  <IconDownload />
                </span>
                <span className="ml-2.5">Télécharger</span>
              </button>
              <br />
              <br />
              <div
                className="my-8 flex w-full flex-wrap space-x-6 overflow-auto bg-black bg-opacity-10 py-16 pl-16"
                style={{
                  height: `${108 + 10 * 52.05}px`,
                }}
              >
                <div className="mx-auto w-full min-w-min max-w-2xl space-y-16 bg-white p-6">
                  {/* Applicant */}
                  <div>
                    <h3 className="text-2xl">Demandeur</h3>
                    <p className="p-4 text-black">
                      {renderCharacter(
                        audit?.demandeur ||
                          audit?.demande_audit_info?.original?.data?.demandeur
                      )}
                    </p>
                  </div>
                  {/* Contact Details */}
                  <div>
                    <h3 className="text-2xl">Informations du client</h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Nom:{' '}
                          {renderCharacter(
                            audit?.client_info?.original?.data?.users?.nom ||
                              audit?.demande_audit_info?.original?.data
                                ?.client_info?.original?.data?.users?.nom
                          )}
                        </p>
                        <p className="p-4 text-black">
                          Siret:{' '}
                          {audit?.client_info?.original?.data?.users?.siret ||
                            audit?.demande_audit_info?.original?.data
                              ?.client_info?.original?.data?.users?.siret}
                        </p>
                        <p className="p-4 text-black">
                          Téléphone:{' '}
                          {audit?.client_info?.original?.data?.users
                            ?.telephone ||
                            audit?.demande_audit_info?.original?.data
                              ?.client_info?.original?.data?.users?.telephone}
                        </p>
                        <p className="p-4 text-black">
                          Email:{' '}
                          {audit?.client_info?.original?.data?.users?.email ||
                            audit?.demande_audit_info?.original?.data
                              ?.client_info?.original?.data?.users?.email}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Prénom:{' '}
                          {renderCharacter(
                            audit?.client_info?.original?.data?.users?.prenom ||
                              audit?.demande_audit_info?.original?.data
                                ?.client_info?.original?.data?.users?.prenom
                          )}
                        </p>
                        <p className="p-4 text-black">
                          Adresse:{' '}
                          {audit?.client_info?.original?.data?.users?.adresse ||
                            audit?.demande_audit_info?.original?.data
                              ?.client_info?.original?.data?.users?.adresse}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.infos_client?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Place location */}
                  <div>
                    <h3 className="text-2xl">
                      Coordonnées du lieu à diagnostiquer
                    </h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Contact:{' '}
                          {audit?.contact_ics ||
                            audit?.demande_audit_info?.original?.data
                              ?.contact_ics}
                        </p>
                        <p className="p-4 text-black">
                          Téléphone:{' '}
                          {audit?.contact_lieu_audit ||
                            audit?.demande_audit_info?.original?.data
                              ?.contact_lieu_audit}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Adresse:{' '}
                          {renderCharacter(
                            audit?.adresse_lieu_audit ||
                              audit?.demande_audit_info?.original?.data
                                ?.adresse_lieu_audit
                          )}
                        </p>
                        <p className="p-4 text-black">
                          Email:{' '}
                          {audit?.email_lieu_audit ||
                            audit?.demande_audit_info?.original?.data
                              ?.email_lieu_audit}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.coordonnees_lieu_a_diagnostiquer?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Windows */}
                  <div>
                    <h3 className="text-2xl">Vitrages</h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Surface de vitres:{' '}
                          {audit?.vitrage_surface_vitre ||
                            audit?.demande_audit_info?.original?.data
                              ?.vitrage_surface_vitre}
                        </p>
                        <p className="p-4 text-black">
                          Nombre de vitres:{' '}
                          {audit?.vitrage_nombre_vitre ||
                            audit?.demande_audit_info?.original?.data
                              ?.vitrage_nombre_vitre}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Type de vitrage:{' '}
                          {audit?.vitrage_type_vitrage ||
                            audit?.demande_audit_info?.original?.data
                              ?.vitrage_type_vitrage}
                        </p>
                        <p className="p-4 text-black">
                          Epaisseur des vitres:{' '}
                          {audit?.vitrage_epaisseur_vitre ||
                            audit?.demande_audit_info?.original?.data
                              ?.vitrage_epaisseur_vitre}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Type de huisserie:{' '}
                          {audit?.vitrage_type_huisserie ||
                            audit?.demande_audit_info?.original?.data
                              ?.vitrage_type_huisserie}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.infos_du_site?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Make an appointment online and Information about the site */}
                  {/* Make an appointment online */}
                  <div>
                    <h3 className="text-2xl">Prise de rendez-vous en ligne</h3>
                    <p className="p-4 text-black">
                      {renderMeeting(
                        audit?.rendez_vous_info?.original?.data
                          ?.available_time_info?.original?.data?.date_heure ||
                          audit?.demande_audit_info?.original?.data
                            ?.rendez_vous_info?.original?.data
                            ?.available_time_info?.original?.data?.date_heure
                      )}
                    </p>
                  </div>
                  {/* Information about the site */}
                  <div>
                    <h3 className="text-2xl">Information concernant le site</h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Contact:{' '}
                          {audit?.contact_ics ||
                            audit?.demande_audit_info?.original?.data
                              ?.contact_ics}
                        </p>
                        <p className="p-4 text-black">
                          Code porte:{' '}
                          {audit?.code_porte_ics ||
                            audit?.demande_audit_info?.original?.data
                              ?.code_porte_ics}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Adresse:{' '}
                          {audit?.adresse_ics ||
                            audit?.demande_audit_info?.original?.data
                              ?.adresse_ics}
                        </p>
                        <p className="p-4 text-black">
                          Code portail:{' '}
                          {audit?.code_portail_ics ||
                            audit?.demande_audit_info?.original?.data
                              ?.code_portail_ics}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.infos_du_site?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Type of installation */}
                  <div>
                    <h3 className="text-2xl">Type de logement</h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          {renderCharacter(
                            audit?.type_logement ||
                              audit?.demande_audit_info?.original?.data
                                ?.type_logement
                          )}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Surface totale:{' '}
                          {audit?.surface_totale ||
                            audit?.demande_audit_info?.original?.data
                              ?.surface_totale}{' '}
                          m²
                        </p>
                        <p className="p-4 text-black">
                          Nombre d’étages:{' '}
                          {audit?.nbre_etage ||
                            audit?.demande_audit_info?.original?.data
                              ?.nbre_etage}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.type_logement?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* DOORS  */}
                  {/* Window DOORS */}
                  <div>
                    <h3 className="text-2xl">Portes fenêtres</h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Surface des portes vitrées :{' '}
                          {audit?.porte_fenetres_surface_portes_vitre ||
                            audit?.demande_audit_info?.original?.data
                              ?.porte_fenetres_surface_portes_vitre}
                        </p>
                        <p className="p-4 text-black">
                          Nombre des portes vitrées :{' '}
                          {audit?.porte_fenetres_nombre_portes_vitre ||
                            audit?.demande_audit_info?.original?.data
                              ?.porte_fenetres_nombre_portes_vitre}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Type de vitrage:{' '}
                          {audit?.porte_fenetres_type_vitrage ||
                            audit?.demande_audit_info?.original?.data
                              ?.porte_fenetres_type_vitrage}
                        </p>
                        <p className="p-4 text-black">
                          Epaisseur des vitres:{' '}
                          {audit?.porte_fenetres_epaisseur_vitre ||
                            audit?.demande_audit_info?.original?.data
                              ?.porte_fenetres_epaisseur_vitre}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Type de huisserie:{' '}
                          {audit?.porte_fenetres_type_huisserie ||
                            audit?.demande_audit_info?.original?.data
                              ?.porte_fenetres_type_huisserie}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.portes_fenetres?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Entrance DOORS */}
                  <div>
                    <h3 className="text-2xl">Portes d&#39;entrée</h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Surface des portes d&#39;entrée :{' '}
                          {audit?.porte_entree_surface ||
                            audit?.demande_audit_info?.original?.data
                              ?.porte_entree_surface}
                        </p>
                        <p className="p-4 text-black">
                          Nombre des portes d&#39;entrée :{' '}
                          {audit?.porte_entree_surface_nbre_porte ||
                            audit?.demande_audit_info?.original?.data
                              ?.porte_entree_surface_nbre_porte}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">Type de porte:</p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.portes_entree?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Floor */}
                  <div>
                    <h3 className="text-2xl">Isolation</h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Isolation plancher haut:{' '}
                          {audit?.isolation_plancher_haut ||
                            audit?.demande_audit_info?.original?.data
                              ?.isolation_plancher_haut}
                        </p>
                        <p className="p-4 text-black">
                          Isolation plancher bas:{' '}
                          {audit?.isolation_plancher_bas ||
                            audit?.demande_audit_info?.original?.data
                              ?.isolation_plancher_bas}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Isolation comble:{' '}
                          {audit?.isolation_comblee ||
                            audit?.demande_audit_info?.original?.data
                              ?.isolation_comblee}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.isolation?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Side Wall */}
                  <div>
                    <h3 className="text-2xl">Murs</h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Surface et Type mur côté EST: <br />
                          {JSON.parse(
                            audit?.mure_cote_est ||
                              audit?.demande_audit_info?.original?.data
                                ?.mure_cote_est ||
                              '[]'
                          )?.map((mure_cote_est) => (
                            <ul className="flex space-x-10">
                              <li>{mure_cote_est.surface} m²</li>
                              <li>{mure_cote_est.type}</li>
                            </ul>
                          ))}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Surface et Type mur côté OUEST: <br />
                          {JSON.parse(
                            audit?.mure_cote_ouest ||
                              audit?.demande_audit_info?.original?.data
                                ?.mure_cote_ouest ||
                              '[]'
                          )?.map((mure_cote_ouest) => (
                            <ul className="flex space-x-10">
                              <li>{mure_cote_ouest.surface} m²</li>
                              <li>{mure_cote_ouest.type}</li>
                            </ul>
                          ))}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Surface et Type mur côté SUD: <br />
                          {JSON.parse(
                            audit?.mure_cote_sud ||
                              audit?.demande_audit_info?.original?.data
                                ?.mure_cote_sud ||
                              '[]'
                          )?.map((mure_cote_sud) => (
                            <ul className="flex space-x-10">
                              <li>{mure_cote_sud.surface} m²</li>
                              <li>{mure_cote_sud.type}</li>
                            </ul>
                          ))}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Surface et Type mur côté NORD: <br />
                          {JSON.parse(
                            audit?.mure_cote_nord ||
                              audit?.demande_audit_info?.original?.data
                                ?.mure_cote_nord ||
                              '[]'
                          )?.map((mure_cote_nord) => (
                            <ul className="flex space-x-10">
                              <li>{mure_cote_nord.surface} m²</li>
                              <li>{mure_cote_nord.type}</li>
                            </ul>
                          ))}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.murs?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Unheated */}
                  <div>
                    <h3 className="text-2xl">Zone non chauffée</h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Sous sol :{' '}
                          {audit?.zone_non_chauffee_sous_sol === 0 ||
                          audit?.demande_audit_info?.original?.data
                            ?.zone_non_chauffee_sous_sol === 0
                            ? 'Oui'
                            : 'Non'}
                        </p>
                        <p className="p-4 text-black">
                          Comble :{' '}
                          {audit?.zone_non_chauffee_comble_surface === 0 ||
                          audit?.demande_audit_info?.original?.data
                            ?.zone_non_chauffee_comble_surface === 0
                            ? 'Oui'
                            : 'Non'}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Sous sol surface :{' '}
                          {audit?.zone_non_chauffee_sous_sol_surface ||
                            audit?.demande_audit_info?.original?.data
                              ?.zone_non_chauffee_sous_sol_surface}
                        </p>
                        <p className="p-4 text-black">
                          Comble surface :{' '}
                          {audit?.zone_non_chauffee_comble_surface ||
                            audit?.demande_audit_info?.original?.data
                              ?.zone_non_chauffee_comble_surface}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Toiture :{' '}
                          {audit?.zone_non_chauffee_toiture === 0 ||
                          audit?.demande_audit_info?.original?.data
                            ?.zone_non_chauffee_toiture === 0
                            ? 'Oui'
                            : 'Non'}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Toiture surface :{' '}
                          {audit?.zone_non_chauffee_toiture_surface ||
                            audit?.demande_audit_info?.original?.data
                              ?.zone_non_chauffee_toiture_surface}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.zone_non_chauffee?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Type of installation AND Devices connected */}
                  {/* Type of installation */}
                  <div>
                    <h3 className="text-2xl">Type d&#39;installation</h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Type :{' '}
                          {renderCharacter(
                            audit?.type_installation ||
                              audit?.demande_audit_info?.original?.data
                                ?.type_installation
                          )}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Année de fabrication:{' '}
                          {renderDate(
                            audit?.date_installation ||
                              audit?.demande_audit_info?.original?.data
                                ?.date_installation
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.type_installation?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Devices connected  */}
                  <div>
                    <h3 className="text-2xl">
                      Nombre d&#39;appareil à contrôler :{' '}
                      {audit?.nbre_apparail_control ||
                        audit?.demande_audit_info?.original?.data
                          ?.nbre_apparail_control}
                    </h3>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.nombre_appareils_a_connecter?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Type of heating */}
                  <div>
                    <h3 className="text-2xl">Type de chauffage</h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Type :{' '}
                          {renderCharacter(
                            audit?.type_chauffage ||
                              audit?.demande_audit_info?.original?.data
                                ?.type_chauffage
                          )}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Année de fabrication:{' '}
                          {renderDate(
                            audit?.annee_fabrication ||
                              audit?.demande_audit_info?.original?.data
                                ?.annee_fabrication
                          )}
                        </p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.type_chauffage?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  {/* Has any work been done? */}
                  <div>
                    <h3 className="text-2xl">
                      Est-ce que des travaux ont été effectués ?
                    </h3>
                    <div className="flex flex-row">
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          {audit?.confirm_travaux_effectuee === 1 ||
                          audit?.demande_audit_info?.original?.data
                            ?.confirm_travaux_effectuee === 1
                            ? 'Oui'
                            : 'Non'}
                        </p>
                      </div>
                      <div className="flex-1 flex-col">
                        <p className="p-4 text-black">
                          Type de travaux :{' '}
                          {renderCharacter(
                            audit?.type_de_travaux_effectuee ||
                              audit?.demande_audit_info?.original?.data
                                ?.type_de_travaux_effectuee
                          )}
                        </p>
                        <p className="p-4 text-black">Année :</p>
                      </div>
                    </div>
                    <div className="flex-1 flex-col">
                      {audit?.inspecteur_comments?.data?.travaux_effectues?.map(
                        (item, index) => {
                          return (
                            <p className="comment-text-color p-4" key={index}>
                              {renderCharacter(item)}
                            </p>
                          );
                        }
                      )}
                    </div>
                  </div>
                  <div className="preview-files flex flex-col space-y-16">
                    {/* HOME picture  */}
                    <div id="preview-home">
                      <h3 className="mb-4 text-2xl">Photos de la maison</h3>
                      <IGallery
                        name="preview-home"
                        src={
                          audit?.maison ||
                          audit?.demande_audit_info?.original?.data?.maison
                        }
                        dispatch={dispatch}
                        showNotif={showNotif}
                        callback={(name) => name}
                      />
                      <div className="flex-1 flex-col">
                        {audit?.inspecteur_comments?.data?.photos_maison?.map(
                          (item, index) => {
                            return (
                              <p className="comment-text-color p-4" key={index}>
                                {renderCharacter(item)}
                              </p>
                            );
                          }
                        )}
                      </div>
                    </div>
                    {/* Windows picture  */}
                    <div className="w-full" id="preview-windows">
                      <h3 className="mb-4 text-2xl">Photos des fenêtres</h3>
                      <IGallery
                        name="preview-windows"
                        src={
                          audit?.fenetre ||
                          audit?.demande_audit_info?.original?.data
                            ?.photos_fenetres
                        }
                        dispatch={dispatch}
                        showNotif={showNotif}
                        callback={(name) => name}
                      />
                      <div className="flex-1 flex-col">
                        {audit?.inspecteur_comments?.data?.travaux_effectues?.map(
                          (item, index) => {
                            return (
                              <p className="comment-text-color p-4" key={index}>
                                {renderCharacter(item)}
                              </p>
                            );
                          }
                        )}
                      </div>
                    </div>
                    {/* doors pictures  */}
                    <div id="preview-doors">
                      <h3 className="mb-4 text-2xl">Photos des portes</h3>
                      <IGallery
                        name="preview-doors"
                        src={
                          audit?.porte ||
                          audit?.demande_audit_info?.original?.data?.porte
                        }
                        dispatch={dispatch}
                        showNotif={showNotif}
                        callback={(name) => name}
                      />
                      <div className="flex-1 flex-col">
                        {audit?.inspecteur_comments?.data?.photos_portes?.map(
                          (item, index) => {
                            return (
                              <p className="comment-text-color p-4" key={index}>
                                {renderCharacter(item)}
                              </p>
                            );
                          }
                        )}
                      </div>
                    </div>
                    {/* roof picture  */}
                    <div id="preview-roof">
                      <h3 className="mb-4 text-2xl">Photos de la toiture</h3>
                      <IGallery
                        name="preview-roof"
                        src={
                          audit?.toiture ||
                          audit?.demande_audit_info?.original?.data?.toiture
                        }
                        dispatch={dispatch}
                        showNotif={showNotif}
                        callback={(name) => name}
                      />
                      <div className="flex-1 flex-col">
                        {audit?.inspecteur_comments?.data?.photos_toiture?.map(
                          (item, index) => {
                            return (
                              <p className="comment-text-color p-4" key={index}>
                                {renderCharacter(item)}
                              </p>
                            );
                          }
                        )}
                      </div>
                    </div>
                    {/* <div id="preview-roof">
                      <h3 className="mb-4 text-2xl">Photos de la toiture</h3>
                      <IGallery
                        name="preview-roof"
                        src={audit?.toiture}
                        dispatch={dispatch}
                        showNotif={showNotif}
                        callback={(name) => name}
                      />
                      <div className="flex-1 flex-col">
                        <p className="p-4 comment-text-color">
                          {renderCharacter(
                            audit?.inspecteur_comments?.data?.photos_toiture
                          )}
                        </p>
                      </div>
                    </div> */}
                    {/* Others pictures  */}
                    <div id="preview-other">
                      <h3 className="mb-4 text-2xl">Autres photos</h3>
                      <IGallery
                        name="preview-other"
                        src={audit?.autrephoto}
                        dispatch={dispatch}
                        showNotif={showNotif}
                        callback={(name) => name}
                      />
                      <div className="flex-1 flex-col">
                        {audit?.inspecteur_comments?.data?.photos_autres?.map(
                          (item, index) => {
                            return (
                              <p className="comment-text-color p-4" key={index}>
                                {renderCharacter(item)}
                              </p>
                            );
                          }
                        )}
                      </div>
                    </div>

                    {/* Comments */}
                    {audit?.commentaire && (
                      <div id="preview-comments">
                        <h3 className="mb-4 text-2xl">Commentaire</h3>
                        <div className="flex flex-row">
                          <div className="flex-1 flex-col">
                            <p className="p-4 text-black">
                              {renderCharacter(audit?.commentaire)}
                            </p>
                          </div>
                        </div>
                      </div>
                    )}
                    {audit?.inspecteur_comments?.data?.note !== null &&
                      audit?.inspecteur_comments?.data?.note !== undefined &&
                      audit?.inspecteur_comments?.data?.note !== '' && (
                        <div id="preview-comments">
                          <h3 className="mb-4 text-2xl">
                            Note de l&#39;inspecteur
                          </h3>
                          <div className="flex flex-row">
                            <div className="flex-1 flex-col">
                              <p className="comment-text-color fs-1 p-4">
                                {`${audit?.inspecteur_comments?.data?.note} %`}
                              </p>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
              </div>
              {notification?.text && (
                <div
                  className={`fixed bottom-4 right-4 z-[99999] flex rounded px-3 py-2.5 text-sm text-white transition-all delay-200 duration-300
                  ${
                    showNotification
                      ? 'translate-y-0 opacity-100'
                      : 'translate-y-2 opacity-0'
                  } ${
                    notification.type === 'error'
                      ? 'bg-red-600'
                      : 'bg-green-600'
                  }`}
                >
                  <span>{notification.text}</span>
                  <svg
                    onClick={() => {
                      closeNotif();
                    }}
                    className="ml-2 h-5 w-5 cursor-pointer"
                    width="24"
                    height="24"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Audit;
