
import axios from 'axios';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Gallery } from 'react-grid-gallery';
import Lightbox from 'yet-another-react-lightbox';
import "yet-another-react-lightbox/styles.css";

import { TOKEN } from '../api';
import { expired, loginReset } from '../redux/reducers/userReducer';

const IGallery = ({ name, src, dispatch, showNotif, callback }) => {
  const [images, setImages] = useState([]);
  const [message, setMessage] = useState({});
  const [currentImage, setCurrentImage] = useState(null);
  const [caption, setCaption] = useState('Photos');
  const [nextImage, setNextImage] = useState(null);
  const [prevImage, setPrevImage] = useState(null);
  const [prevIndex, setPrevIndex] = useState(null);
  const [nextIndex, setNextIndex] = useState(null);

  const [index, setIndex] = useState(-1);

  const handleClick = (idx, item) => setIndex(idx);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  useEffect(() => {
    if (index === -1) {
      setCurrentImage(null);
      setNextImage(null);
      setPrevImage(null);
      setPrevIndex(null);
      setNextIndex(null);
    } else {
      setCurrentImage(images[index]);
      setNextImage(images[(index + 1) % images.length]);
      setPrevImage(images[(index + images.length - 1) % images.length]);
      setPrevIndex((index + images.length - 1) % images.length);
      setNextIndex((index + 1) % images.length);
    }
  }, [index]);

  useEffect(() => {
    callback([name]);
  }, [message]);

  useEffect(() => {
    axios
      .get(src, {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      })
      .then((res) => {
        if (res?.data?.length === 0) {
          const section = document.getElementById('previewWork-upload');
          // replace id

          setMessage({
            type: 'success',
            text: 'Aucune image téléchargé',
          });
          return;
        }
        setImages(
          res.data.map((image) => ({
            src: image,
            nano: image,
            height: 250,
            width: 250,
          }))
        );
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          showNotif('Une erreur est survenue', 'error');
        }
        showNotif('Une erreur est survenue', 'error');
      });
  }, [src]);

  const renderCaption = (title) => {
    switch (title) {
      case 'preview-home':
        setCaption('Photos de la maison');
        break;
      case 'preview-windows':
        setCaption('Photos des fenêtres');
        break;
      case 'preview-doors':
        setCaption('Photos des portes');
        break;
      case 'preview-roof':
        setCaption('Photos de la toiture');
        break;
      case 'preview-other':
        setCaption('Autres photos');
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    renderCaption(name);
  }, [name]);

  return (
    <>
      {!message?.type && images.length > 0 && (
        <Gallery
          images={images}
          onClick={handleClick}
          enableImageSelection={false}
        />
      )}
      {!!currentImage && (
        /* @ts-ignore */
        <Lightbox
          mainSrc={currentImage.src}
          imageTitle={caption}
          mainSrcThumbnail={currentImage.nano}
          nextSrc={nextImage.src}
          nextSrcThumbnail={nextImage.src}
          prevSrc={prevImage.src}
          prevSrcThumbnail={prevImage.nano}
          onCloseRequest={handleClose}
          onMovePrevRequest={handleMovePrev}
          onMoveNextRequest={handleMoveNext}
        />
      )}
      {message?.type && <div className="text-center">{message.text}</div>}
    </>
  );
};

IGallery.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  showNotif: PropTypes.func.isRequired,
  callback: PropTypes.func.isRequired,
};

export default IGallery;
