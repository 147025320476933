import { createTheme, ThemeProvider } from '@mui/material';
import { DataGrid, frFR, GridActionsCellItem } from '@mui/x-data-grid';
import {
  Button,
  Dropdown,
  Grid,
  Input,
  Loading,
  Modal,
  Spacer,
  Text,
  useModal,
} from '@nextui-org/react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import IconSearch from '../components/icons/IconSearch';
import {
  creditInspectorAccount,
  creditMesurerAccount,
  getCrediting,
  getMesurerCrediting,
} from '../redux/api/app';
import { expired, loginReset } from '../redux/reducers/userReducer';
import { renderCharacter } from '../utils/common';

const AccountCrediting = () => {
  const navigator = useNavigate();
  const dispatch = useDispatch();
  const { error, isLoading } = useSelector((state) => state.audit);
  const [list, setList] = useState([]);
  const [completeList, setCompleteList] = useState([]);
  const [message, setMessage] = useState(null);
  const { visible, setVisible, bindings } = useModal();
  const [rowCountState, setRowCountState] = useState(0);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(15);
  const [mode, setMode] = useState('server');
  const [loading, setLoading] = useState(false);
  const [inspectorToCredit, setinspectorToCredit] = useState(0);

  const dataSheet = ['Inspecteur', 'Mesureur'];

  const [selectedDataSheet, setSelectedDataSheet] = useState(
    new Set(['Inspecteur'])
  );

  const selectedDataSheetValue = useMemo(
    () => Array.from(selectedDataSheet).join(', ').replaceAll('_', ' '),
    [selectedDataSheet]
  );

  const getCreditation = () => {
    setLoading(true);
    const onSuccessCrediting = (res) => {
      setLoading(false);
      setPage(0);
      setList(
        res.data?.data?.map((credit) => {
          return {
            id: credit?.demande_audit_id,
            request_number: credit?.num_dmd,
            request_id: credit?.demande_audit_id,
            inspector_id: credit?.inspecteur_id,
            measurer_id: credit?.mesureur_id,
            status: credit?.status,
            payment: credit?.status_paiement,
            amount: credit?.montant,
            name: renderCharacter(
              `${credit?.inspecteur_nom || credit?.mesureur_nom} ${
                credit?.inspecteur_prenom || credit?.mesureur_prenom
              }`
            ),
          };
        })
      );
      setMode('client');
    };
    const onErrorCrediting = (err) => {
      setLoading(false);
      if (err.response?.status === 401 || err.response?.status === 403) {
        dispatch(expired());
        dispatch(loginReset());
      }
    };

    if (selectedDataSheetValue === dataSheet[0]) {
      getCrediting()
        .then((res) => {
          onSuccessCrediting(res);
        })
        .catch((err) => {
          onErrorCrediting(err);
        });
    } else {
      getMesurerCrediting()
        .then((res) => {
          onSuccessCrediting(res);
        })
        .catch((err) => {
          onErrorCrediting(err);
        });
    }
  };

  useEffect(() => {
    getCreditation();
  }, [selectedDataSheet]);

  useEffect(() => {
    setCompleteList(
      list.map((c) => ({
        id: c?.demande_audit_id,
        request_number: c?.num_dmd,
        request_id: c?.demande_audit_id,
        inspector_id: c?.inspecteur_id,
        measurer_id: c?.mesureur_id,
        status: c?.status,
        payment: c?.status_paiement,
        amount: c?.montant,
        name: renderCharacter(
          `${c?.inspecteur_nom || c?.mesureur_nom} ${
            c?.inspecteur_prenom || c?.mesureur_prenom
          }`
        ),
      }))
    );
  }, [list]);

  const navigate = (route) => {
    navigator(route);
  };

  const closeHandler = () => {
    navigate('/inspectors');
  };

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#dc3838' },
      },
    },
    frFR
  );

  function closeNotif() {
    setTimeout(() => {
      setMessage({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 300);
    }, 100);
  }
  function showNotif(text, type) {
    setTimeout(() => {
      setNotification({
        text,
        type,
      });
      setTimeout(() => {
        setShowNotification(true);
      }, 100);
    }, 500);

    setTimeout(() => {
      setNotification({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 6000);
    }, 5000);
  }

  const creditThisAccount = () => {
    setLoading(true);
    const onSuccessInCrediting = (res) => {
      setLoading(false);
      setVisible(false);
      getCreditation();
      setTimeout(() => {
        showNotif(res?.data?.message, 'success');
      }, 300);
    };
    const onErrorInCrediting = (err) => {
      setVisible(false);
      if (err.response.status === 401 || err.response.status === 403) {
        dispatch(expired());
        dispatch(loginReset());
      } else {
        setLoading(false);
        showNotif('Une erreur est survenue', 'error');
      }
    };
    if (selectedDataSheetValue === dataSheet[0]) {
      creditInspectorAccount({
        demande_audit_id: inspectorToCredit.request_id,
        inspecteur_id: inspectorToCredit.inspector_id,
        montant: inspectorToCredit.amount,
      })
        .then((res) => {
          onSuccessInCrediting(res);
        })
        .catch((err) => {
          onErrorInCrediting(err);
        });
    } else {
      creditMesurerAccount({
        demande_audit_id: inspectorToCredit.request_id,
        mesureur_id: inspectorToCredit.measurer_id,
        montant: inspectorToCredit.amount,
      })
        .then((res) => {
          onSuccessInCrediting(res);
        })
        .catch((err) => {
          onErrorInCrediting(err);
        });
    }
  };

  const columns = [
    {
      field: 'request_number',
      headerName: 'Num Demande',
      width: 250,
    },
    /* {
      field: 'status',
      headerName: 'Statut',
      width: 220,
    }, */
    {
      field: 'name',
      headerName:
        selectedDataSheetValue === dataSheet[0]
          ? "Nom et Prénom de l'inspecteur"
          : 'Nom et Prénom du mesureur',
      width: 220,
      flex: 1,
    },
    {
      field: 'payment',
      headerName: 'Etat de Paiement',
      width: 220,
      renderCell: (params) => {
        return (
          <div
            className={`rounded-full
            ${
              params.row?.payment ? 'bg-green-400' : 'bg-gray-400'
            } py-1 px-3 text-sm text-white`}
          >
            <span>{params.row?.payment ? 'Payé' : 'Non payé'}</span>
          </div>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 200,
      cellClassName: 'actions',
      getActions: (params) => {
        if (!params.row?.payment) {
          return [
            <GridActionsCellItem
              icon={undefined}
              label="Créditer"
              showInMenu
              onClick={() => {
                setVisible(true);
                setinspectorToCredit({
                  inspector_name: params?.row?.name,
                  request_number: params?.row?.request_number,
                  request_id: params?.row?.request_id,
                  inspector_id: params?.row?.inspector_id,
                  measurer_id: params?.row?.measurer_id,
                  amount: params?.row?.amount,
                });
              }}
            />,
          ];
        }
        return [];
      },
    },
  ];

  const search = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm === '') {
      setList(completeList);
    } else {
      setList(
        completeList.filter((item) => {
          return (
            item.request_number?.toLowerCase().includes(searchTerm) ||
            item.status?.toLowerCase().includes(searchTerm) ||
            item.payment?.toLowerCase().includes(searchTerm) ||
            item.name?.toLowerCase().includes(searchTerm)
          );
        })
      );
    }
  };

  return (
    <>
      {isLoading && !loading && (
        <>
          <div className="fixed inset-0 z-[99] h-full min-h-full overflow-hidden bg-black bg-opacity-10" />
          <div className="absolute top-[50%] left-[48%] z-[991] h-[100px] w-[100px] animate-spin rounded-full border-[10px] border-t-[10px] border-secondary border-t-primary" />
        </>
      )}
      <div className="w-full">
        <div className="mx-auto w-full px-8">
          <div className="py-8">
            <div>
              <div className="mt-2 flex items-end justify-end space-x-4">
                <Grid>
                  <Input
                    onChange={search}
                    labelRight={<IconSearch />}
                    placeholder="Rechercher"
                    bordered
                    type="search"
                  />
                </Grid>
                <Dropdown placement="bottom-right">
                  <Dropdown.Button
                    flat
                    css={{
                      color: '#DC3838',
                      backgroundColor: '#f8d7d7',
                    }}
                  >
                    {selectedDataSheetValue}
                  </Dropdown.Button>
                  <Dropdown.Menu
                    aria-label="Single selection actions"
                    disallowEmptySelection
                    selectionMode="single"
                    selectedKeys={selectedDataSheet.keys()}
                    onSelectionChange={setSelectedDataSheet}
                  >
                    {dataSheet?.map((p) => (
                      <Dropdown.Item
                        key={p}
                        css={{
                          '&:hover': {
                            fontWeight: 500,
                          },
                          fontSize: '14px',
                        }}
                        className="first-letter:capitalize"
                      >
                        {p}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div
                className="mt-8 w-full"
                style={{
                  height: `${108 + 8 * 52.05}px`,
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    rows={list}
                    disableSelectionOnClick
                    columns={columns}
                    getRowId={(row) => row.id}
                    columnBuffer={15}
                    checkboxSelection
                    rowCount={rowCountState}
                    loading={loading}
                    rowsPerPageOptions={[15]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode={mode}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  />
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
        <Modal
          closeButton
          scroll
          width="600px"
          aria-labelledby="modal-title"
          aria-describedby="modal-description"
          onClose={closeHandler}
          {...bindings}
        >
          <Modal.Header justify="start">
            <Text h2 b id="modal-title" size={18}>
              Créditer
            </Text>
          </Modal.Header>
          <Modal.Body>
            <div className="w-full justify-between space-y-4">
              <div className="w-full space-y-4">
                <div>
                  <strong>Numéro de la demande: </strong>
                  {inspectorToCredit.request_number}
                </div>
                <div>
                  <strong>
                    {selectedDataSheetValue === 'Inspecteur'
                      ? 'Inspecteur'
                      : 'Mesureur'}
                    :
                  </strong>{' '}
                  {inspectorToCredit.inspector_name}
                </div>
                <div>
                  <strong>Montant:</strong> {inspectorToCredit.amount}€
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer
            justify="start"
            className="!block w-full justify-between md:!flex"
          >
            <div
              id="message"
              className={`mt-4 w-full flex-1 text-left text-sm md:mb-0 md:mt-2 ${
                message?.type === 'success' ? 'text-green-500' : 'text-red-500'
              } `}
            >
              {message?.text}
            </div>
            <div className="m-auto flex justify-center md:justify-start">
              <Button
                css={{
                  border: '1px solid',
                  borderColor: '#DC3838',
                  color: 'white',
                  backgroundColor: '#DC3838',
                }}
                auto
                onClick={() => setVisible(false)}
              >
                Annuler
              </Button>
              <Spacer x={0.5} />
              <Button color="success" auto onClick={creditThisAccount}>
                {loading && (
                  <Loading
                    style={{
                      marginRight: '1rem',
                    }}
                    color="currentColor"
                    size="sm"
                  />
                )}
                Créditer
              </Button>
            </div>
          </Modal.Footer>
        </Modal>
        {notification?.text && (
          <div
            className={`fixed bottom-4 right-4 z-[99999] flex rounded px-3 py-2.5 text-sm text-white transition-all delay-200 duration-300
        ${
          showNotification
            ? 'translate-y-0 opacity-100'
            : 'translate-y-2 opacity-0'
        } ${notification.type === 'error' ? 'bg-red-600' : 'bg-green-600'}`}
          >
            <span>{notification.text}</span>
            <svg
              onClick={() => {
                closeNotif();
              }}
              className="ml-2 h-5 w-5 cursor-pointer"
              width="24"
              height="24"
              strokeWidth="1.5"
              viewBox="0 0 24 24"
              stroke="currentColor"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        )}
      </div>
    </>
  );
};

export default AccountCrediting;
