/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-useless-return */

import { Text } from '@nextui-org/react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { useDispatch, useSelector } from 'react-redux';

import IndicatorIcon from '../components/icons/IndicatorIcon';
import LinearIndicatorIcon from '../components/icons/LinearIndicatorIcon';
import {
  getAdminAuditStatisque,
  getEnterpriseAuditStatisque,
  getInspectorAuditStatisque,
  getMeasurerAuditStatisque,
} from '../redux/api/audit';
import { searchEntreprise } from '../redux/api/enterprise';
import { expired, loginReset } from '../redux/reducers/userReducer';

const CustomizedDot = (props) => {
  const { cx, cy } = props;
  return <IndicatorIcon x={cx - 20} y={cy - 20} width={40} height={40} />;
};

CustomizedDot.propTypes = {
  cx: PropTypes.number,
  cy: PropTypes.number,
};

const Statistiques = () => {
  const user = useSelector((state) => state.user);
  const [loadingData, setLoadingData] = useState(false);
  const dispatch = useDispatch();
  const [selectedEnterprise] = useState(null);
  const [inputs, setInputs] = useState({});

  const [enterprises, setEnterprises] = useState([]);
  const [auditStatistique, setAuditsStatisque] = useState({});

  const [options] = useState({
    xaxis: {
      categories: [
        'Janvier',
        'Fevrier',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Decembre',
      ],
    },
  });
  const [helper, setHelper] = useState({
    enterprise: {
      text: '',
      color: '',
    },
  });

  const [open, setOpen] = useState(false);

  const resetHelpers = (e) => {
    if (e.target.name === 'enterprise')
      setHelper((prev) => {
        return {
          ...prev,
          enterprise: {
            text: '',
            color: '',
          },
        };
      });
  };

  const getError = (err) => {
    if (err.response?.status === 401 || err.response?.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    }
  };

  const getThisRequestAuditAwaiting = (searchTerm) => {
    setLoadingData(true);
    searchEntreprise({
      nom_entreprise: searchTerm,
    })
      .then((res) => {
        setLoadingData(false);
        setEnterprises(
          res.data?.data?.map((enterpriseInformation) => {
            return {
              name: enterpriseInformation?.nom_entreprise,
              type: enterpriseInformation?.type_entreprise,
            };
          })
        );
      })
      .catch((err) => {
        if (err.response.status === 401 || err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          setLoadingData(false);
        }
      });
  };

  const handleChange = (e) => {
    if (e.target.value === '') {
      setEnterprises([]);
      setTimeout(() => {}, 300);
    } else if (
      !selectedEnterprise ||
      e.target.value.toLowerCase() !== selectedEnterprise?.name?.toLowerCase()
    ) {
      setOpen(true);
      getThisRequestAuditAwaiting(e?.target?.value?.toLowerCase());
    }
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  useEffect(() => {
    if (user.isAdmin) {
      handleChange({
        target: { name: 'enterprise', value: selectedEnterprise?.name },
      });
    }
  }, [selectedEnterprise]);

  const getEnterprisesAuditStatisque = () => {
    getEnterpriseAuditStatisque()
      .then((res) => {
        setAuditsStatisque(res.data.data.values);
      })
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          dispatch(expired());
          dispatch(loginReset());
        }
      });
  };

  const getMeasurerAuditStatisques = () => {
    getMeasurerAuditStatisque()
      .then((res) => {
        setAuditsStatisque(res.data.data.values);
      })
      .catch((err) => {
        if (err.response.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        }
      });
  };

  const getInspectorAuditStatisques = () => {
    getInspectorAuditStatisque()
      .then((res) => {
        setAuditsStatisque(res.data.data.values);
      })
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          dispatch(expired());
          dispatch(loginReset());
        }
      });
  };

  const getAdminAuditStatisques = () => {
    getAdminAuditStatisque()
      .then((res) => {
        setAuditsStatisque(res.data.data.values);
      })
      .catch((err) => {
        if (err.response.status === 403 || err.response.status === 401) {
          dispatch(expired());
          dispatch(loginReset());
        }
      });
  };

  useEffect(() => {
    if (user.currentUser.role[0] === 'entreprise') {
      getEnterprisesAuditStatisque();
    } else if (user.currentUser.role[0] === 'mesureur') {
      getMeasurerAuditStatisques();
    } else if (user.currentUser.role[0] === 'admin') {
      getAdminAuditStatisques();
    } else {
      getInspectorAuditStatisques();
    }
  }, []);

  return (
    <div className="w-full">
      <div className="mx-auto w-full md:px-8">
        <div className="py-1">
          <div className="w-full md:flex md:space-x-8">
            <div className="bg-card h-72 w-full rounded-lg sm:h-80">
              <div className="flex h-full flex-col p-4">
                <div className="">
                  <div className="flex items-center">
                    <Text className="font-bold">Type d&lsquo;audit</Text>
                    <div className="flex-grow" />
                    <LinearIndicatorIcon />
                    <div className="ml-2">Par Mois</div>
                  </div>
                </div>
                <div className="d-flex justify-content-center w-full flex-grow">
                  <Chart
                    options={options}
                    series={auditStatistique}
                    type="radar"
                    width={500}
                    height={500}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Statistiques;
