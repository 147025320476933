/* eslint-disable no-console */
/* eslint-disable no-useless-escape */
/* eslint-disable unused-imports/no-unused-vars */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import { createTheme, ThemeProvider } from '@mui/material';
import { DataGrid, frFR, GridActionsCellItem } from '@mui/x-data-grid';
import {
  Badge,
  Button,
  Dropdown,
  Grid,
  Input,
  Loading,
  Modal,
  Row,
  Spacer,
  Text,
  Textarea,
  useModal,
} from '@nextui-org/react';
import axios from 'axios';
import FlatList from 'flatlist-react';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { TOKEN } from '../api';
import IconDownload from '../components/icons/IconDownload';
import IconSearch from '../components/icons/IconSearch';
import {
  affectAuditToMesurer,
  assignAuditToInspector,
  getAdminInspectorAudits,
  getAudits,
  getAuditsStatus,
  getEnterpriseAudits,
  getEnterpriseAuditsStatus,
  getEnterpriseInspecteursStatus,
  getEnterpriseInspectorAudits,
  getEnterpriseInspectorPeriodicAudits,
  getEnterpriseInspectorsAudits,
  getEnterpriseMeasurerPeriodicAudits,
  getEnterpriseMeasurersStatus,
  getEnterprisePeriodicAudits,
  getMeasurePeriodicAudits,
  getMeasurerAudits,
  getMeasurerAuditsStatus,
  getMeasurersAudits,
  getPeriodicAudits,
  rejectAuditToMeasurer,
  rejectInspectorAudit,
  rejectMeasurerAudit,
  removeAuditToInspector,
  updateAuditStatus,
  validateAudit,
  validateInspectorAudit,
} from '../redux/api/audit';
import {
  getRequestInspectorAwaiting,
  getRequestMeasurerAwaiting,
} from '../redux/api/enterprise';
import {
  auditFailure,
  auditOnSuccess,
  auditReset,
  auditStart,
} from '../redux/reducers/auditReducer';
import { expired, loginReset } from '../redux/reducers/userReducer';
import { applyStatusColor, renderDate } from '../utils/common';

const measurerStatus = ['Tous', 'Affectée', 'Réalisée', 'Validée', 'Rejetée'];

const Audits = () => {
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const location = useLocation();
  const path = location.pathname;
  const { id, userId } = useParams();
  const { visible, setVisible, bindings } = useModal();
  const { isAdmin, isEnterprise, isMesurer, currentUser } = useSelector(
    (state) => state.user
  );

  const action = location.pathname.split('/')[2];
  const [selected, setSelected] = useState(new Set(['Filtrer par période']));
  const [selectedStatus, setSelectedStatus] = useState(
    new Set(['Filtrer par statut'])
  );
  const [selectedDataSheet, setSelectedDataSheet] = useState(
    new Set(['Negociation prix demande audit'])
  );
  const [dataSheet, setDataSheet] = useState([
    'Negociation prix demande audit',
    'Demandes Client',
    'Mesureur',
    'Inspecteur',
  ]);
  const [filters, setFilters] = useState([
    'Tous',
    'Il y a une semaine',
    'Il y a deux semaines',
    'Il y a un mois',
    'Il y a six mois',
    'Définir une autre période',
  ]);
  const [status, setStatus] = useState([
    'Tous',
    'Affectée',
    'Réalisée',
    'Validée',
    'Rejetée',
  ]);
  const { error, isLoading, meta } = useSelector((state) => state.audit);

  const list = useSelector((state) => state.audit.list);

  console.log('list :', list);

  const [audits, setAudits] = useState([]);
  const [completeList, setCompleteList] = useState([]);
  const [mode, setMode] = useState('server');
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [inspectors, setInspectors] = useState([]);
  const [measurers, setMeasurers] = useState([]);
  const [auditNegociation, setAuditNegociation] = useState([]);
  const [auditNegociationCompleteList, setAuditNegociationCompleteList] =
    useState([]);
  const [isNegociationModalOpen, setIsNegociationModalOpen] = useState(false);
  const [auditNegociationId, setAuditNegociationId] = useState(null);
  const [auditAffectedToMeasurer, setSetAuditAffectedToMeasurer] = useState([]);
  const [auditRejectedReason, setAuditRejectedReason] = useState('');
  const [requestErrorManagement, setRequestErrorManagement] = useState(null);
  const [isRequestLoading, setIsRequestLoading] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);
  const [audit, setAudit] = useState(null);
  const [auditToAsignId, setAuditToAsignId] = useState(null);

  const [inputs, setInputs] = useState({});
  const [helper, setHelper] = useState({
    siret: {
      text: '',
      color: '',
    },
    enterprise: {
      text: '',
      color: '',
    },
    lastname: {
      text: '',
      color: '',
    },
    firstname: {
      text: '',
      color: '',
    },
    email: {
      text: '',
      color: '',
    },
    phone: {
      text: '',
      color: '',
    },
    city: {
      text: '',
      color: '',
    },
    address: {
      text: '',
      color: '',
    },
    zipcode: {
      text: '',
      color: '',
    },
    password: {
      text: '',
      color: '',
    },
    newpassword: {
      text: '',
      color: '',
    },
    user: {
      text: '',
      color: '',
    },
    comment: {
      text: '',
      color: '',
    },
  });

  const resetHelpers = (e) => {
    if (e.target.name === 'siret')
      setHelper((prev) => {
        return {
          ...prev,
          siret: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'enterprise')
      setHelper((prev) => {
        return {
          ...prev,
          enterprise: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'lastname')
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'firstname')
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'email')
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'phone')
      setHelper((prev) => {
        return {
          ...prev,
          phone: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'zipcode')
      setHelper((prev) => {
        return {
          ...prev,
          zipcode: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'address')
      setHelper((prev) => {
        return {
          ...prev,
          address: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'password')
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'newpassword')
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'user')
      setHelper((prev) => {
        return {
          ...prev,
          user: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'comment')
      setHelper((prev) => {
        return {
          ...prev,
          comment: {
            text: '',
            color: '',
          },
        };
      });
  };

  const [loading, setLoading] = useState(false);
  const [loadingList, setLoadingList] = useState(false);
  const [message, setMessage] = useState(null);
  const [page, setPage] = useState(0);

  const [pageSize, setPageSize] = useState(15);
  const [rowCountState, setRowCountState] = useState(0);

  const selectedValue = useMemo(
    () => Array.from(selected).join(', ').replaceAll('_', ' '),
    [selected]
  );

  const selectedStatusValue = useMemo(
    () => Array.from(selectedStatus).join(', ').replaceAll('_', ' '),
    [selectedStatus]
  );

  const selectedDataSheetValue = useMemo(
    () => Array.from(selectedDataSheet).join(', ').replaceAll('_', ' '),
    [selectedDataSheet]
  );
  const [modalAction, setModalAction] = useState(null);
  const [openList, setOpenList] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  function closeNotif() {
    setTimeout(() => {
      setMessage({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 300);
    }, 100);
  }

  function showNotif(text, type) {
    setTimeout(() => {
      setNotification({
        text,
        type,
      });
      setTimeout(() => {
        setShowNotification(true);
      }, 100);
    }, 500);

    setTimeout(() => {
      setNotification({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 6000);
    }, 5000);
  }

  const requestAudits = () => {
    if (
      (path.startsWith('/enterprise/') && id && userId) ||
      (path.startsWith('/inspector/') && id)
    ) {
      if (isAdmin) {
        dispatch(getAdminInspectorAudits(id, userId, page + 1, pageSize));
        return;
      }
      dispatch(getEnterpriseInspectorAudits(id, page + 1, pageSize));
      return;
    }
    if (isEnterprise) {
      switch (selectedDataSheetValue) {
        case dataSheet[3]:
          dispatch(getEnterpriseInspectorsAudits(page + 1, pageSize));
          setStatus(['Tous', 'Affectée', 'Réalisée', 'Validée', 'Rejetée']);
          setSelectedStatus(new Set(['Filtrer par statut']));
          setSelected(new Set(['Filtrer par période']));
          break;
        case dataSheet[2]:
          dispatch(getMeasurersAudits(page + 1, pageSize));
          setStatus(measurerStatus);
          setSelectedStatus(new Set(['Filtrer par statut']));
          setSelected(new Set(['Filtrer par période']));
          break;
        default:
          dispatch(getEnterpriseAudits(page + 1, pageSize));
          setStatus([
            'Tous',
            'En attente',
            'En cours de réalisation',
            'En cours de validation',
            'Soumise',
            'Affectée',
            'Réalisée',
            'Validée',
            'Terminée',
            'Rejetée',
          ]);
          setSelectedStatus(new Set(['Filtrer par statut']));
          setSelected(new Set(['Filtrer par période']));
          break;
      }
    } else if (isMesurer) {
      dispatch(getMeasurerAudits(page + 1, pageSize));
      setStatus(measurerStatus);
      setSelectedStatus(new Set(['Filtrer par statut']));
      setSelected(new Set(['Filtrer par période']));
    } else if (
      !isEnterprise &&
      !isAdmin &&
      currentUser?.role[0] === 'inspecteur'
    ) {
      dispatch(getAudits(page + 1, pageSize));
    }
  };

  useEffect(() => {
    requestAudits();
  }, [page]);

  useEffect(() => {
    if (action === 'add') {
      setVisible(true);
      return;
    }
    setVisible(false);
  }, [action]);

  useEffect(() => {
    return () => {
      dispatch(auditReset());
    };
  }, []);

  useEffect(() => {
    setRowCountState(meta?.pagination?.total);
    setCompleteList(
      list.map((audi) => ({
        id: audi?.id,
        audit_id: !isMesurer ? audi?.demande_audit_id || audi?.id : audi?.id,
        name: !isAdmin
          ? audi?.demande_audit_info?.original?.data?.num_dmd ||
          audi?.demande_audit?.original?.data?.num_dmd ||
          audi?.num_dmd
          : audi?.demande_audit?.original?.data?.entreprise_info?.original?.data
            ?.nom_entreprise || audi?.num_dmd,
        inspector: `${audi?.inspecteur?.original?.data?.users?.nom || ''} ${audi?.inspecteur?.original?.data?.users?.prenom || ''
          }`,
        auditUserId: audi?.id,
        measurer: `${audi?.mesureur_info?.original?.data?.users?.original?.data?.nom ||
          audi?.mesureur_info?.original?.data?.users?.nom ||
          ''
          } ${audi?.mesureur_info?.original?.data?.users?.original?.data?.prenom ||
          audi?.mesureur_info?.original?.data?.users?.prenom ||
          ''
          }`,
        client: `${audi?.demande_audit?.original?.data?.client_info?.original?.data
          ?.users?.nom ||
          audi?.demande_audit_info?.original?.data?.client_info?.original?.data
            ?.users?.nom ||
          audi?.client_info?.original?.data?.users?.nom ||
          ''
          } ${audi?.demande_audit_info?.original?.data?.client_info?.original?.data
            ?.users?.prenom ||
          audi?.demande_audit?.original?.data?.client_info?.original?.data
            ?.users?.prenom ||
          audi?.client_info?.original?.data?.users?.prenom ||
          ''
          }`,
        enterprise:
          `${audi?.demande_audit_info?.original?.data?.entreprise_info?.original?.data?.nom_entreprise}` ||
          audi?.demande_audit?.original?.data?.entreprise_info?.original?.data
            ?.nom_entreprise,
        date: audi?.created_at,
        audit_type:
          audi?.demande_audit?.original?.data?.name_audit || audi.name_audit,
        status:
          audi?.status ||
          audi?.demande_audit?.original?.data?.status ||
          audi?.demande_audit_info?.original?.data?.status,
      }))
    );
  }, [list]);

  const getCustomPeriodicAudits = () => {
    dispatch(auditStart());

    getPeriodicAudits(
      {
        param: 'custom',
        debut: startDate,
        fin: endDate,
      },
      page + 1,
      pageSize
    )
      .then((res) => {
        dispatch(auditOnSuccess());
        setPage(0);
        setAudits(
          res.data?.data?.map((a) => {
            return {
              id: a?.id,
              audit_id: !isMesurer
                ? a?.demande_audit_id || a?.audit_id || a?.id
                : a?.id,
              name: !isAdmin
                ? a?.demande_audit?.original?.data?.num_dmd ||
                a?.num_dmd ||
                a?.demande_audit_info?.original?.data?.num_dmd
                : a?.entreprise_info?.original?.data?.nom_entreprise,
              inspectorId: a?.id,

              inspector: `${a?.inspecteur?.original?.data?.users?.nom || ''} ${a?.inspecteur?.original?.data?.users?.prenom || ''
                }`,
              measurer: `${a?.mesureur_info?.original?.data?.users?.original?.data?.nom ||
                a?.mesureur_info?.original?.data?.users?.nom ||
                ''
                } ${a?.mesureur_info?.original?.data?.users?.original?.data
                  ?.prenom ||
                a?.mesureur_info?.original?.data?.users?.prenom ||
                ''
                }`,
              auditUserId: a?.id,
              client: `${a?.demande_audit?.original?.data?.client_info?.original?.data
                ?.users?.nom ||
                a?.demande_audit_info?.original?.data?.client_info?.original
                  ?.data?.users?.nom ||
                a?.client_info?.original?.data?.users?.nom ||
                ''
                } ${a?.demande_audit_info?.original?.data?.client_info?.original
                  ?.data?.users?.prenom ||
                a?.demande_audit?.original?.data?.client_info?.original?.data
                  ?.users?.prenom ||
                a?.client_info?.original?.data?.users?.prenom ||
                ''
                }`,
              date: a?.created_at,
              status:
                a?.status ||
                a?.demande_audit?.original?.data?.status ||
                a?.demande_audit_info?.original?.data?.status,
            };
          })
        );
        setRowCountState(res.data?.data?.length);
        setMode('client');
      })
      .catch((err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          dispatch(expired());
          dispatch(loginReset());
        }
        dispatch(auditFailure(err.response.data));
      });
  };

  const errorReaction = (err) => {
    if (err.response?.status === 401 || err.response?.status === 403) {
      dispatch(expired());
      dispatch(loginReset());
    }
    dispatch(auditFailure(err.response.data));
  };

  const onSuccessFilterByPeriod = (res) => {
    dispatch(auditOnSuccess());
    setPage(0);
    setAudits(
      res.data?.data?.map((a) => {
        return {
          id: a.id,
          audit_id: !isMesurer
            ? a?.demande_audit_id || a?.audit_id || a?.id
            : a?.id,
          name: !isAdmin
            ? a?.demande_audit?.original?.data?.num_dmd ||
            a?.num_dmd ||
            a?.demande_audit_info?.original?.data?.num_dmd
            : a?.entreprise_info?.original?.data?.nom_entreprise,
          inspector: `${a?.inspecteur?.original?.data?.users?.nom || ''} ${a?.inspecteur?.original?.data?.users?.prenom || ''
            }`,
          auditUserId: a?.id,
          measurer: `${a?.mesureur_info?.original?.data?.users?.original?.data?.nom ||
            a?.mesureur_info?.original?.data?.users?.nom ||
            ''
            } ${a?.mesureur_info?.original?.data?.users?.original?.data?.prenom ||
            a?.mesureur_info?.original?.data?.users?.prenom ||
            ''
            }`,
          client: `${a?.demande_audit?.original?.data?.client_info?.original?.data?.users
            ?.nom ||
            a?.client_info?.original?.data?.users?.nom ||
            ''
            } ${a?.demande_audit?.original?.data?.client_info?.original?.data?.users
              ?.prenom ||
            a?.client_info?.original?.data?.users?.prenom ||
            ''
            }`,
          date: a?.created_at,
          status:
            a?.status ||
            a?.demande_audit?.original?.data?.status ||
            a?.demande_audit_info?.original?.data?.status,
        };
      })
    );
    setRowCountState(res.data?.data?.length);
    setMode('client');
  };

  const getPeriodicAuditsList = (period) => {
    dispatch(auditStart());

    if (isEnterprise) {
      switch (selectedDataSheetValue) {
        case dataSheet[3]:
          getEnterpriseInspectorPeriodicAudits(
            {
              param: period,
            },
            page + 1,
            pageSize
          )
            .then((res) => {
              onSuccessFilterByPeriod(res);
            })
            .catch((err) => {
              errorReaction(err);
            });
          break;
        case dataSheet[2]:
          getEnterpriseMeasurerPeriodicAudits(
            {
              param: period,
            },
            page + 1,
            pageSize
          )
            .then((res) => {
              onSuccessFilterByPeriod(res);
            })
            .catch((err) => {
              setLoading(false);
              if (
                err.response?.status === 401 ||
                err.response?.status === 403
              ) {
                dispatch(expired());
                dispatch(loginReset());
              }
            });
          break;
        default:
          getEnterprisePeriodicAudits(
            {
              param: period,
            },
            page + 1,
            pageSize
          )
            .then((res) => {
              onSuccessFilterByPeriod(res);
            })
            .catch((err) => {
              setLoading(false);
              if (
                err.response?.status === 401 ||
                err.response?.status === 403
              ) {
                dispatch(expired());
                dispatch(loginReset());
              }
            });
          break;
      }
    } else if (
      !isEnterprise &&
      !isAdmin &&
      currentUser?.role[0] === 'inspecteur'
    ) {
      getPeriodicAudits(
        {
          param: period,
        },
        page + 1,
        pageSize
      )
        .then((res) => {
          dispatch(auditOnSuccess());
          setPage(0);
          setAudits(
            res.data?.data?.map((a) => {
              console.log('a :', a);
              return {
                id: a?.id,
                audit_id: !isMesurer
                  ? a?.demande_audit_id || a?.audit_id || a?.id
                  : a?.id,
                name: !isAdmin
                  ? a?.demande_audit?.original?.data?.num_dmd ||
                  a?.num_dmd ||
                  a?.demande_audit_info?.original?.data?.num_dmd
                  : a?.entreprise_info?.original?.data?.nom_entreprise,
                inspector: `${a?.inspecteur?.original?.data?.users?.nom || ''
                  } ${a?.inspecteur?.original?.data?.users?.prenom || ''}`,
                auditUserId: a?.id,
                measurer: `${a?.mesureur_info?.original?.data?.users?.original?.data
                  ?.nom ||
                  a?.mesureur_info?.original?.data?.users?.nom ||
                  ''
                  } ${a?.mesureur_info?.original?.data?.users?.original?.data
                    ?.prenom ||
                  a?.mesureur_info?.original?.data?.users?.prenom ||
                  ''
                  }`,
                client: `${a?.demande_audit?.original?.data?.client_info?.original?.data
                  ?.users?.nom ||
                  a?.client_info?.original?.data?.users?.nom ||
                  ''
                  } ${a?.demande_audit?.original?.data?.client_info?.original?.data
                    ?.users?.prenom ||
                  a?.client_info?.original?.data?.users?.prenom ||
                  ''
                  }`,
                date: a?.created_at,
                status:
                  a?.status ||
                  a?.demande_audit?.original?.data?.status ||
                  a?.demande_audit_info?.original?.data?.status,
              };
            })
          );
          setRowCountState(res.data?.data?.length);
          setMode('client');
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          }
          dispatch(auditFailure(err.response.data));
        });
    } else {
      getMeasurePeriodicAudits(
        {
          param: period,
        },
        page + 1,
        pageSize
      )
        .then((res) => {
          setLoading(false);
          setPage(0);
          setAudits(
            res.data?.data?.map((a) => {
              console.log('a :', a);
              return {
                id: a?.id,
                audit_id: a?.id,
                name: a?.demande_audit_info?.original?.data?.num_dmd,
                auditUserId: a?.id,
                enterprise: `${a?.demande_audit_info?.original?.data?.entreprise_info?.original?.data?.nom_entreprise}`,
                client: `${a?.demande_audit_info?.original?.data?.client_info?.original?.data?.users?.nom} ${a?.demande_audit_info?.original?.data?.client_info?.original?.data?.users?.prenom}`,
                date: a?.created_at,
                status:
                  a?.status ||
                  a?.demande_audit?.original?.data?.status ||
                  a?.demande_audit_info?.original?.data?.status,
              };
            })
          );
          setRowCountState(res.data?.data?.length);
          setMode('client');
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          }
          dispatch(auditFailure(err.response.data));
        });
    }
  };

  const onSuccessFilterByStatus = (res) => {
    dispatch(auditOnSuccess());
    setPage(0);
    setAudits(
      res.data?.data?.map((a) => {
        console.log('a :', a);
        return {
          id: a.id,
          audit_id: isMesurer
            ? a?.demande_audit_id || a?.audit_id || a?.id
            : a?.id,
          name: !isAdmin
            ? a?.demande_audit?.original?.data?.num_dmd ||
            a?.num_dmd ||
            a?.demande_audit_info?.original?.data?.num_dmd
            : a?.entreprise_info?.original?.data?.nom_entreprise,
          inspector: `${a?.inspecteur?.original?.data?.users?.nom || ''} ${a?.inspecteur?.original?.data?.users?.prenom || ''
            }`,
          auditUserId: a?.id,
          measurer: `${a?.mesureur_info?.original?.data?.users?.original?.data?.nom ||
            a?.mesureur_info?.original?.data?.users?.nom ||
            ''
            } ${a?.mesureur_info?.original?.data?.users?.original?.data?.prenom ||
            a?.mesureur_info?.original?.data?.users?.prenom ||
            ''
            }`,
          client: `${a?.demande_audit?.original?.data?.client_info?.original?.data?.users
            ?.nom ||
            a?.client_info?.original?.data?.users?.nom ||
            ''
            } ${a?.demande_audit?.original?.data?.client_info?.original?.data?.users
              ?.prenom ||
            a?.client_info?.original?.data?.users?.prenom ||
            ''
            }`,
          date: a?.created_at,
          status:
            a?.status ||
            a?.demande_audit?.original?.data?.status ||
            a?.demande_audit_info?.original?.data?.status,
        };
      })
    );
    setRowCountState(res.data?.data?.length);
    setMode('client');
  };

  const onAuditStatusSuccess = (res) => {
    dispatch(auditOnSuccess());
    setPage(0);
    setAudits(
      res.data?.data?.map((a) => {
        console.log('a :', a);
        return {
          id: a.id,
          audit_id: !isMesurer
            ? a?.demande_audit_id || a?.audit_id || a?.id
            : a?.id,
          name: !isAdmin
            ? a?.demande_audit?.original?.data?.num_dmd ||
            a?.num_dmd ||
            a?.demande_audit_info?.original?.data?.num_dmd
            : a?.entreprise_info?.original?.data?.nom_entreprise,
          inspector: `${a?.inspecteur?.original?.data?.users?.nom || ''} ${a?.inspecteur?.original?.data?.users?.prenom || ''
            }`,
          auditUserId: a?.id,
          measurer: `${a?.mesureur_info?.original?.data?.users?.original?.data?.nom ||
            a?.mesureur_info?.original?.data?.users?.nom ||
            ''
            } ${a?.mesureur_info?.original?.data?.users?.original?.data?.prenom ||
            a?.mesureur_info?.original?.data?.users?.prenom ||
            ''
            }`,
          client: `${a?.demande_audit?.original?.data?.client_info?.original?.data?.users
            ?.nom || a?.client_info?.original?.data?.users?.nom
            } ${a?.demande_audit?.original?.data?.client_info?.original?.data?.users
              ?.prenom || a?.client_info?.original?.data?.users?.prenom
            }`,
          date: a?.created_at,
          status:
            a?.status ||
            a?.demande_audit?.original?.data?.status ||
            a?.demande_audit_info?.original?.data?.status,
        };
      })
    );
    setRowCountState(res.data?.data?.length);
    setMode('client');
  };

  const getAuditsByStatus = (stat) => {
    dispatch(auditStart());

    if (isEnterprise) {
      switch (selectedDataSheetValue) {
        case dataSheet[3]:
          getEnterpriseInspecteursStatus(
            {
              status: stat,
            },
            page + 1,
            pageSize
          )
            .then((res) => {
              onAuditStatusSuccess(res);
            })
            .catch((err) => {
              dispatch(auditOnSuccess());
              if (
                err.response?.status === 401 ||
                err.response?.status === 403
              ) {
                dispatch(expired());
                dispatch(loginReset());
              }
            });
          break;
        case dataSheet[2]:
          getEnterpriseMeasurersStatus(
            {
              status: stat,
            },
            page + 1,
            pageSize
          )
            .then((res) => {
              onAuditStatusSuccess(res);
            })
            .catch((err) => {
              if (
                err.response?.status === 401 ||
                err.response?.status === 403
              ) {
                dispatch(expired());
                dispatch(loginReset());
              }
              dispatch(auditFailure(err.response.data));
            });
          break;
        default:
          getEnterpriseAuditsStatus(
            {
              status: stat,
            },
            page + 1,
            pageSize
          )
            .then((res) => {
              onAuditStatusSuccess(res);
            })
            .catch((err) => {
              if (
                err.response?.status === 401 ||
                err.response?.status === 403
              ) {
                dispatch(expired());
                dispatch(loginReset());
              }
              dispatch(auditFailure(err.response.data));
            });
          break;
      }
    } else if (
      !isEnterprise &&
      !isAdmin &&
      currentUser?.role[0] === 'inspecteur'
    ) {
      if (selectedDataSheetValue === dataSheet[3]) {
        getAuditsStatus(
          {
            status: stat,
          },
          page + 1,
          pageSize
        )
          .then((res) => {
            onSuccessFilterByStatus(res);
          })
          .catch((err) => {
            errorReaction(err);
          });
      }
    } else {
      getMeasurerAuditsStatus(
        {
          status: stat,
        },
        page + 1,
        pageSize
      )
        .then((res) => {
          onSuccessFilterByStatus(res);
        })
        .catch((err) => {
          errorReaction(err);
        });
    }
  };

  useEffect(() => {
    setAudits(completeList);
  }, [completeList]);

  const navigate = (route) => {
    navigator(route);
  };

  const closeHandler = () => {
    setTimeout(() => {
      setAudit(null);
      resetHelpers({
        target: {
          name: 'user',
        },
      });
      delete inputs?.user;
      setSelectedUser(null);
    }, 50);

    if (
      ((isEnterprise || isAdmin) &&
        path.startsWith('/enterprise/') &&
        id &&
        userId) ||
      (path.startsWith('/inspector/') && id)
    ) {
      if (isAdmin) {
        navigate(`/enterprise/${id}/inspector/${userId}/audits`);
      } else {
        navigate(`/inspector/${id}/audits`);
      }
    }
  };

  useEffect(() => {
    const filterVal = selected.entries().next().value[0].toLowerCase();

    if (filterVal !== 'filtrer par période') {
      switch (filterVal) {
        case 'tous':
          setMode('server');
          if (page === 0) requestAudits();
          else setPage(0);
          break;
        case 'il y a une semaine':
          getPeriodicAuditsList('1week');
          break;
        case 'il y a deux semaines':
          getPeriodicAuditsList('2weeks');
          break;
        case 'il y a un mois':
          getPeriodicAuditsList('1month');
          break;
        case 'il y a six mois':
          getPeriodicAuditsList('6month');
          break;
        default:
          setStartDate(null);
          setEndDate(null);
          break;
      }
    }
  }, [selected]);

  useEffect(() => {
    const filterVal = selectedStatus.entries().next().value[0].toLowerCase();

    if (filterVal !== 'filtrer par statut') {
      if (filterVal === 'tous') {
        setMode('server');
        if (page === 0) requestAudits();
        else setPage(0);
      } else {
        getAuditsByStatus(filterVal);
      }
    }
  }, [selectedStatus]);

  const getThisRequestUserAwaiting = (searchTerm) => {
    setLoadingList(true);

    if (modalAction === 'assign') {
      getRequestInspectorAwaiting({
        name: searchTerm,
      })
        .then((res) => {
          setLoadingList(false);
          setOpenList(true);
          setInspectors(res.data?.data);
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            setLoadingList(false);
            showNotif(
              'Une erreur est survenue lors du chargement de la liste des inspecteurs',
              'error'
            );
          }
        });
    } else {
      getRequestMeasurerAwaiting({
        name: searchTerm,
      })
        .then((res) => {
          setLoadingList(false);
          setOpenList(true);
          setMeasurers(res.data?.data);
        })
        .catch((err) => {
          if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            setLoadingList(false);
            showNotif(
              'Une erreur est survenue lors du chargement de la liste des mesureurs',
              'error'
            );
          }
        });
    }
  };

  useEffect(() => {
    if (!visible) closeHandler();
  }, [visible]);

  const handleChange = (e) => {
    getThisRequestUserAwaiting(e.target.value.toLowerCase());
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  const rejectAudit = (idx) => {
    dispatch(auditStart());
    showNotif('Rejet en cours...', 'info');

    setLoading(true);
    if (selectedDataSheetValue === dataSheet[3]) {
      validateInspectorAudit(idx, inputs?.comment || '', 'rejetée').then(
        () => {
          dispatch(auditOnSuccess());
          setLoading(false);
          setVisible(false);
          setAudit(null);
          requestAudits();
          showNotif("L'audit a été rejetée", 'success');
        },
        (err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(auditFailure());
            dispatch(expired());
            dispatch(loginReset());
          } else {
            dispatch(auditFailure());
            showNotif('Une erreur est survenue', 'error');
          }
        }
      );
    } else {
      validateAudit(idx, inputs?.comment || '', 'rejetée').then(
        () => {
          dispatch(auditOnSuccess());
          setLoading(false);
          setVisible(false);
          setAudit(null);
          requestAudits();
          showNotif("L'audit a été rejetée", 'success');
        },
        (err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(auditFailure());
            dispatch(expired());
            dispatch(loginReset());
          } else {
            dispatch(auditFailure());
            showNotif('Une erreur est survenue', 'error');
          }
        }
      );
    }
  };

  const reject = (idx) => {
    dispatch(auditStart());
    showNotif('Demande en cours...', 'info');
    if (isMesurer) {
      rejectMeasurerAudit(idx)
        .then(() => {
          dispatch(auditOnSuccess());
          showNotif("La demande d'audit a été révoquée", 'success');
          requestAudits();
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(auditFailure());
            dispatch(expired());
            dispatch(loginReset());
          } else {
            dispatch(auditFailure());
            showNotif('Une erreur est survenue', 'error');
          }
        });
    } else {
      rejectInspectorAudit(idx)
        .then(() => {
          dispatch(auditOnSuccess());
          showNotif("La demande d'audit a été révoquée", 'success');
          requestAudits();
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(auditFailure());
            dispatch(expired());
            dispatch(loginReset());
          } else {
            dispatch(auditFailure());
            showNotif('Une erreur est survenue', 'error');
          }
        });
    }
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (selectedUser) {
      setLoading(true);
      if (modalAction === 'assign') {
        assignAuditToInspector(
          selectedUser?.id,
          audit?.audit_id || auditToAsignId,
          inputs?.comment || ''
        ).then(
          () => {
            setLoading(false);
            setVisible(false);
            setAudit(null);
            delete inputs?.user;
            requestAudits();

            showNotif("L'audit a été bien attribuée", 'success');
          },
          (err) => {
            setLoading(false);
            if (err.response.status === 422) {
              showNotif(
                'Vous avez déjà affecté cet audit à un inspecteur!',
                'error'
              );
            } else {
              showNotif('Une erreur est survenue', 'error');
            }
          }
        );
      } else {
        affectAuditToMesurer(
          selectedUser?.id,
          audit?.id,
          inputs?.comment || ''
        ).then(
          () => {
            setLoading(false);
            setVisible(false);
            setAudit(null);
            delete inputs?.user;
            requestAudits();

            showNotif("L'audit a été assignée", 'success');
          },
          (err) => {
            setLoading(false);
            if (err.response.status === 422) {
              showNotif(
                'Vous avez déjà assigner cet audit à un mesureur!',
                'error'
              );
            } else {
              showNotif('Une erreur est survenue', 'error');
            }
          }
        );
      }
    } else if (modalAction === 'reject') {
      if (!inputs?.comment) {
        showNotif('Veuillez ajouter un commentaire', 'error');
        setLoading(false);
        return;
      }
      rejectAudit(audit?.id);
    }
    const onValidationSuccess = () => {
      setLoading(false);
      setVisible(false);
      setAudit(null);
      requestAudits();
      showNotif("L'audit a été validée", 'success');
    };
    const onValidationError = (err) => {
      setLoading(false);
      if (err.response.status === 422) {
        showNotif('Vous avez déjà validé cet audit!', 'error');
      } else {
        showNotif('Une erreur est survenue', 'error');
      }
    };
    if (modalAction === 'validate') {
      setLoading(true);
      if (selectedDataSheetValue === dataSheet[3]) {
        validateInspectorAudit(
          audit?.id,
          inputs?.comment || '',
          'validée'
        ).then(
          () => {
            onValidationSuccess();
          },
          (err) => {
            onValidationError(err);
          }
        );
      } else {
        validateAudit(audit?.id, inputs?.comment || '', 'validée').then(
          () => {
            onValidationSuccess();
          },
          (err) => {
            onValidationSuccess(err);
          }
        );
      }
    }
  };

  const cancelAudit = (idx) => {
    dispatch(auditStart());
    showNotif('Révocation en cours...', 'info');

    if (selectedDataSheetValue === dataSheet[3]) {
      removeAuditToInspector(idx)
        .then(() => {
          dispatch(auditOnSuccess());
          showNotif("L'audit a été révoquée", 'success');
          requestAudits();
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(auditFailure());
            dispatch(expired());
            dispatch(loginReset());
          } else {
            dispatch(auditFailure());
            showNotif('Une erreur est survenue', 'error');
          }
        });
    } else {
      rejectAuditToMeasurer(idx)
        .then(() => {
          dispatch(auditOnSuccess());
          showNotif("L'audit a été révoquée", 'success');
          requestAudits();
        })
        .catch((err) => {
          if (err.response?.status === 401 || err.response?.status === 403) {
            dispatch(auditFailure());
            dispatch(expired());
            dispatch(loginReset());
          } else {
            dispatch(auditFailure());
            showNotif('Une erreur est survenue', 'error');
          }
        });
    }
  };

  const updateAudit = (idx, stat) => {
    dispatch(auditStart());
    showNotif('Demande en cours...', 'info');

    updateAuditStatus(idx, stat).then(
      () => {
        dispatch(auditOnSuccess());
        showNotif('Changement effectuée avec succès', 'success');
        requestAudits();
      },
      (err) => {
        if (err.response?.status === 401 || err.response?.status === 403) {
          dispatch(auditFailure());
          dispatch(expired());
          dispatch(loginReset());
        } else {
          dispatch(auditFailure());
          showNotif('Une erreur est survenue', 'error');
        }
      }
    );
  };

  const search = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm === '') {
      setAudits(completeList);
    } else {
      setAudits(
        completeList.filter((item) => {
          return (
            item.name?.toLowerCase().includes(searchTerm) ||
            item.email?.toLowerCase().includes(searchTerm) ||
            item.city?.toLowerCase().includes(searchTerm)
          );
        })
      );
    }
  };
  const searchNegociation = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    if (searchTerm === '') {
      setAuditNegociation(auditNegociationCompleteList);
    } else {
      setAuditNegociation(
        auditNegociationCompleteList.filter((item) => {
          return (
            item?.nom_client?.toLowerCase().includes(searchTerm) ||
            item?.email_client?.toLowerCase().includes(searchTerm) ||
            item?.status?.toLowerCase().includes(searchTerm)
          );
        })
      );
    }
  };
  const getAllAuditNegociation = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}entreprise/negociation/index`,
        config
      );
      setAuditNegociation(res?.data?.data);
      setAuditNegociationCompleteList(res?.data?.data);
      setRowCountState(res?.data?.data?.length);
    } catch (err) {
      /**
       * TODO: handle error
       */
      throw err.message;
    }
  };

  const getAllAuditAffectedToMeasurer = async () => {
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${TOKEN}`,
        },
      };
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}entreprisemesureur/index`,
        config
      );
      setSetAuditAffectedToMeasurer(res?.data?.data);
    } catch (err) {
      /**
       * TODO: handle error
       */
      throw err.message;
    }
  };

  useEffect(() => {
    if (isEnterprise) {
      getAllAuditNegociation();
      getAllAuditAffectedToMeasurer();
    }
  }, []);

  const handleConfirmeNegociationClick = async () => {
    try {
      const confirmeNegociationData = {
        id: auditNegociationId,
        status: 'validée',
      };
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}entreprise/negociation/change-status`,
        confirmeNegociationData,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );
      if (response.status === 200) {
        showNotif('La négociation a été confirmée avec succès', 'success');
        getAllAuditNegociation();
      }
    } catch (e) {
      showNotif('Une erreur est survenue', 'error');
    }
  };

  const handleRejectNegociationClick = async () => {
    try {
      setIsRequestLoading(true);
      const confirmeNegociationData = {
        id: auditNegociationId,
        status: 'rejetée',
        commentaire: auditRejectedReason,
      };

      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}entreprise/negociation/change-status`,
        confirmeNegociationData,
        {
          headers: {
            Authorization: `Bearer ${TOKEN}`,
          },
        }
      );

      if (response.status === 200) {
        showNotif("L'offre a été rejeter avec succes", 'success');
        getAllAuditNegociation();
        setIsNegociationModalOpen(false);
      }
    } catch (e) {
      setIsRequestLoading(false);
      showNotif('Une erreur est survenue', 'error');
    } finally {
      setIsRequestLoading(false);
    }
  };

  const columns = [
    {
      field: 'name',
      hide:
        isEnterprise &&
        selectedDataSheetValue === 'Negociation prix demande audit' &&
        !path.startsWith('/inspector') &&
        path.endsWith('/audits'),
      headerName: `${!isAdmin || path.startsWith('enterprise') ||
        path.endsWith('audits') ? 'Num. de Demande' : "Nom de l'entreprise"}`,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        console.log("params: ", params.row)
        return (
          <span className="first-letter:uppercase">
            {selectedDataSheetValue === 'Mesureur' &&
              path.startsWith('/measurer')
              ? params?.row?.demande_audit_info?.original?.data?.num_dmd
              : params?.row?.name}
            {isAdmin && params?.row?.name || params?.row?.demande_audit_info?.original?.data?.num_dmd}
          </span>
        );
      },
    },
    {
      field: 'date',
      headerName: `${isEnterprise &&
        selectedDataSheetValue === 'Negociation prix demande audit' &&
        !path.startsWith('/inspector') &&
        path.endsWith('/audits')
        ? 'Date'
        : 'Date de la demande'
        }`,
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {(isEnterprise &&
              selectedDataSheetValue === 'Negociation prix demande audit' &&
              !path.startsWith('/inspector') &&
              path.endsWith('/audits')) ||
              (selectedDataSheetValue === 'Mesureur' &&
                path.startsWith('/measurer'))
              ? renderDate(params.row.created_at)
              : renderDate(params.row.date)}
          </div>
        );
      },
    },
    {
      field: 'client',
      headerName: `${(isEnterprise &&
        selectedDataSheetValue === 'Negociation prix demande audit' &&
        !path.startsWith('/inspector') &&
        path.endsWith('/audits')) ||
        (path.startsWith('/measurer') && path.endsWith('/audits'))
        ? 'Client'
        : 'Nom du client'
        }`,
      minWidth: 150,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">
            {selectedDataSheetValue === 'Mesureur' &&
              path.startsWith('/measurer')
              ? `${params?.row?.demande_audit_info?.original?.data?.client_info?.original?.data?.users?.nom} ${params?.row?.demande_audit_info?.original?.data?.client_info?.original?.data?.users?.prenom}`
              : params?.row?.client}
          </span>
        );
      },
    },
    {
      field: 'inspector',
      headerName: 'Inspecteur',
      hide:
        isMesurer ||
        currentUser?.role[0] === 'inspecteur' ||
        selectedDataSheetValue !== 'Inspecteur' ||
        (isEnterprise &&
          selectedDataSheetValue === 'Negociation prix demande audit') ||
        (path.startsWith('/inspector') &&
          path.endsWith('/audits') &&
          !isAdmin &&
          !isEnterprise &&
          (id || userId)),
      minWidth: 225,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">{params.row.inspector}</span>
        );
      },
    },
    {
      field: 'priceRange',
      headerName: 'Interval de prix (eur)',
      hide:
        currentUser?.role[0] === 'inspecteur' ||
        isMesurer ||
        (isEnterprise &&
          selectedDataSheetValue !== 'Negociation prix demande audit') ||
        (path.startsWith('/inspector') && path.endsWith('/audits')) ||
        (path.startsWith('/measurer') && path.endsWith('/audits')) ||
        (path.startsWith('/inspector') && path.endsWith('/audits')) ||
        (isAdmin && path.startsWith('/enterprise') && path.endsWith('/audits')),
      minWidth: 200,
      renderCell: (params) => {
        return (
          <div>
            {params.row.montant_min_formated} -{' '}
            {params.row.montant_max_formated}
          </div>
        );
      },
    },
    {
      field: 'prixPropose',
      headerName: 'Prix proposé (eur)',
      hide:
        currentUser?.role[0] === 'inspecteur' ||
        isMesurer ||
        (isEnterprise &&
          selectedDataSheetValue !== 'Negociation prix demande audit') ||
        (path.startsWith('/inspector') && path.endsWith('/audits')) ||
        (path.startsWith('/measurer') && path.endsWith('/audits')) ||
        (isAdmin && path.startsWith('/enterprise') && path.endsWith('/audits')),
      minWidth: 200,
      renderCell: (params) => {
        return <div>{params.row.neg_fee_formated}</div>;
      },
    },
    {
      field: 'measurer',
      headerName: 'Mesureur',
      hide:
        isMesurer ||
        currentUser?.role[0] === 'inspecteur' ||
        selectedDataSheetValue !== 'Mesureur' ||
        (isEnterprise &&
          selectedDataSheetValue === 'Negociation prix demande audit') ||
        (!isAdmin && !isEnterprise && (id || userId)),
      minWidth: 225,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">
            {selectedDataSheetValue === 'Mesureur' &&
              path.startsWith('/measurer')
              ? `${params?.row?.mesureur_info?.original?.data?.users?.nom} ${params?.row?.mesureur_info?.original?.data?.users?.prenom}`
              : params.row.measurer}
          </span>
        );
      },
    },
    {
      field: 'enterprise',
      headerName: 'Entreprise',
      hide:
        !isMesurer ||
        (isEnterprise &&
          selectedDataSheetValue === 'Negociation prix demande audit'),
      minWidth: 225,
      flex: 1,
      renderCell: (params) => {
        return (
          <span className="first-letter:uppercase">
            {params.row.enterprise}
          </span>
        );
      },
    },
    {
      field: 'typeAudit',
      headerName: 'Type d’audit',
      hide:
        currentUser?.role[0] === 'inspecteur' ||
        isMesurer ||
        (isEnterprise &&
          selectedDataSheetValue !== 'Negociation prix demande audit') ||
        (path.startsWith('/inspector') && path.endsWith('/audits')) ||
        (path.startsWith('/measurer') && path.endsWith('/audits')),
      minWidth: 200,
      renderCell: (params) => {
        return <div>{params.row.audit_type}</div>;
      },
    },
    {
      field: 'status',
      headerName: 'Etat',
      width: 200,
      renderCell: (params) => {
        // console.log("params.row.status: ", params.row)
        // console.log("params.row.status: ", params.row.status)
        return (
          <div className="flex w-10/12 items-center justify-between">
            <Badge
              css={{
                backgroundColor: applyStatusColor(params.row.status),
              }}
            >
              {params.row?.status === 'a_corriger'
                ? 'A corrigé'
                : params.row?.status}
            </Badge>
            {isEnterprise &&
              selectedDataSheetValue !== 'Negociation prix demande audit' && (
                <button type="button" className="ml-4">
                  <span
                    className={`flex items-center rounded-sm bg-primary bg-opacity-[35%] p-1 text-sm text-red-600
              ${params.row?.status?.toLowerCase() !== 'finished' && 'hidden'}
          `}
                  >
                    <IconDownload />
                    Télécharger
                  </span>
                </button>
              )}
          </div>
        );
      },
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 100,
      cellClassName: 'actions',
      getActions: (params) => {
        if (isEnterprise) {
          if (
            params.row?.status?.toLowerCase() === 'soumise' &&
            selectedDataSheetValue === 'Demandes Client'
          ) {
            return [
              <GridActionsCellItem
                icon={undefined}
                label="Voir la demande"
                showInMenu
                onClick={() => {
                  navigate(`/audit/${params.row.audit_id}`);
                }}
              />,
              <GridActionsCellItem
                icon={undefined}
                label="Affecter à un mesureur"
                showInMenu
                onClick={() => {
                  setVisible(true);
                  setModalAction('affect');
                  setAudit(params.row);
                }}
              />,
            ];
          }
          if (
            params.row?.status?.toLowerCase() === 'réalisée' &&
            selectedDataSheetValue === 'Demandes Client'
          ) {
            return [
              <GridActionsCellItem
                icon={undefined}
                label="Voir la demande"
                showInMenu
                onClick={() => {
                  navigate(`/audit/${params.row.audit_id}`);
                }}
              />,
              <GridActionsCellItem
                icon={undefined}
                label="Mettre en cours de validation"
                showInMenu
                onClick={() => {
                  updateAudit(params.row.id, 'en cours de validation');
                }}
              />,
            ];
          }
          if (
            params.row?.status?.toLowerCase() === 'validée' &&
            selectedDataSheetValue === 'Demandes Client'
          ) {
            return [
              <GridActionsCellItem
                icon={undefined}
                label="Voir la demande"
                showInMenu
                onClick={() => {
                  navigate(`/audit/${params.row.audit_id}`);
                }}
              />,
              <GridActionsCellItem
                icon={undefined}
                label="Marquer comme terminer"
                showInMenu
                onClick={() => {
                  updateAudit(params.row.id, 'terminée');
                }}
              />,
            ];
          }
          if (
            params.row?.status?.toLowerCase() === 'validée' &&
            selectedDataSheetValue === 'Mesureur'
          ) {
            return [
              <GridActionsCellItem
                icon={undefined}
                label="Voir la demande"
                showInMenu
                onClick={() => {
                  switch (selectedDataSheetValue) {
                    case dataSheet[3]:
                      navigate(`/inspector/audit/${params.row.auditUserId}`);
                      break;
                    case dataSheet[2]:
                      navigate(
                        `/measurer/audit/${params.row.auditUserId || params.row.id
                        }`
                      );
                      break;
                    default:
                      navigate(
                        `/audit/${params.row.audit_id || params.row.id}`
                      );
                      break;
                  }
                }}
              />,
              <GridActionsCellItem
                icon={undefined}
                label="Attribuer à un inspecteur"
                showInMenu
                onClick={() => {
                  setVisible(true);
                  setModalAction('assign');
                  setAudit(params.row);
                  setAuditToAsignId(params.row.demande_audit_id);
                }}
              />,
            ];
          }
          if (
            params.row?.status?.toLowerCase() === 'réalisée' &&
            isEnterprise &&
            selectedDataSheetValue !== 'Negociation prix demande audit'
          ) {
            if (selectedDataSheetValue !== 'Demandes Client') {
              return [
                <GridActionsCellItem
                  icon={undefined}
                  label="Voir la demande"
                  showInMenu
                  onClick={() => {
                    switch (selectedDataSheetValue) {
                      case dataSheet[3]:
                        navigate(
                          `/inspector/audit/${params.row.auditUserId || params.row.id
                          }`
                        );
                        break;
                      case dataSheet[2]:
                        navigate(
                          `/measurer/audit/${params.row.auditUserId || params.row.id
                          }`
                        );
                        break;
                      default:
                        navigate(
                          `/audit/${params.row.audit_id || params.row.id}`
                        );
                        break;
                    }
                  }}
                />,
                <GridActionsCellItem
                  icon={undefined}
                  label="Rejeter"
                  showInMenu
                  onClick={() => {
                    setVisible(true);
                    setModalAction('reject');
                    setAudit(params.row);
                  }}
                />,
                <GridActionsCellItem
                  icon={undefined}
                  label="Valider"
                  showInMenu
                  onClick={() => {
                    setVisible(true);
                    setModalAction('validate');
                    setAudit(params.row);
                  }}
                />,
              ];
            }
            return [
              <GridActionsCellItem
                icon={undefined}
                label="Voir la demande"
                showInMenu
                onClick={() => {
                  switch (selectedDataSheetValue) {
                    case dataSheet[3]:
                      navigate(
                        `/inspector/audit/${params.row.auditUserId || params.row.id
                        }`
                      );
                      break;
                    case dataSheet[2]:
                      navigate(
                        `/measurer/audit/${params.row.auditUserId || params.row.id
                        }`
                      );
                      break;
                    default:
                      navigate(
                        `/audit/${params.row.audit_id || params.row.id}`
                      );
                      break;
                  }
                }}
              />,
              <GridActionsCellItem
                icon={undefined}
                label="Valider"
                showInMenu
                onClick={() => {
                  setVisible(true);
                  setModalAction('validate');
                  setAudit(params.row);
                }}
              />,
            ];
          }
          if (
            params.row?.status?.toLowerCase() === 'affectée' &&
            isEnterprise &&
            selectedDataSheetValue !== 'Negociation prix demande audit'
          ) {
            return [
              <GridActionsCellItem
                icon={undefined}
                label="Voir la demande"
                showInMenu
                onClick={() => {
                  switch (selectedDataSheetValue) {
                    case dataSheet[3]:
                      navigate(
                        `/inspector/audit/${params.row.auditUserId || params.row.id
                        }`
                      );
                      break;
                    case dataSheet[2]:
                      navigate(
                        `/measurer/audit/${params.row.auditUserId || params.row.id
                        }`
                      );
                      break;
                    default:
                      navigate(
                        `/audit/${params.row.audit_id || params.row.id}`
                      );
                      break;
                  }
                }}
              />,
              <GridActionsCellItem
                icon={undefined}
                label="Révoquer"
                showInMenu
                onClick={() => {
                  cancelAudit(params.row.id);
                }}
              />,
            ];
          }
          if (
            (selectedDataSheetValue === 'Negociation prix demande audit' &&
              !path.startsWith('/inspector') &&
              !path.endsWith('/audits')) ||
            (path.endsWith('/audits') &&
              selectedDataSheetValue === 'Negociation prix demande audit' &&
              params.row.status !== 'validée' &&
              params.row.status !== 'cloturée')
          ) {
            return [
              <GridActionsCellItem
                icon={undefined}
                label="Accepter l'offre"
                showInMenu
                onClick={() => {
                  setAuditNegociationId(params.row.id);
                  switch (params.row.status) {
                    case 'validée':
                      showNotif(
                        'Cette négociation a déjà été validée',
                        'error'
                      );
                      break;
                    case 'cloturée':
                      showNotif(
                        'Cette négociation a déjà été cloturée',
                        'error'
                      );
                      break;
                    default:
                      handleConfirmeNegociationClick();
                      break;
                  }
                }}
              />,
              <GridActionsCellItem
                icon={undefined}
                label="Rejeter l'offre"
                showInMenu
                onClick={() => {
                  setAuditNegociationId(params.row.id);
                  switch (params.row.status) {
                    case 'validée':
                      showNotif(
                        'Cette négociation a déjà été validée',
                        'error'
                      );
                      break;
                    case 'rejetée':
                      showNotif(
                        'Cette négociation a déjà été rejettée',
                        'error'
                      );
                      break;
                    case 'cloturée':
                      showNotif(
                        'Cette négociation a déjà été cloturée',
                        'error'
                      );
                      break;
                    default:
                      setIsNegociationModalOpen(true);
                  }
                }}
              />,
            ];
          }
          if (
            (path.endsWith('/audits') &&
              selectedDataSheetValue === 'Negociation prix demande audit' &&
              selectedDataSheetValue !== 'Inpsector' &&
              params.row.status === 'validée') ||
            params.row.status === 'cloturée'
          ) {
            return [];
          }
          if (
            (params.row?.status?.toLowerCase() === 'rejetée' &&
              isEnterprise &&
              selectedDataSheetValue !== 'Negociation prix demande audit' &&
              params.row.status !== 'validée') ||
            (path.startsWith('/inspector') &&
              path.endsWith('/audits') &&
              params.row.status !== 'validée')
          ) {
            return [
              <GridActionsCellItem
                icon={undefined}
                label="Voir la demande"
                showInMenu
                onClick={() => {
                  switch (selectedDataSheetValue) {
                    case dataSheet[3]:
                      navigate(
                        `/inspector/audit/${params.row.auditUserId || params.row.id
                        }`
                      );
                      break;
                    case dataSheet[2]:
                      navigate(
                        `/measurer/audit/${params.row.auditUserId || params.row.id
                        }`
                      );
                      break;
                    default:
                      navigate(
                        `/audit/${params.row.audit_id || params.row.id}`
                      );
                      break;
                  }
                }}
              />,
            ];
          }
        }
        if (isAdmin) {
          return [
            <GridActionsCellItem
              icon={undefined}
              label="Voir la demande"
              showInMenu
              onClick={() => {
                switch (selectedDataSheetValue) {
                  case dataSheet[3]:
                    navigate(
                      `/inspector/audit/${params.row.auditUserId || params.row.id
                      }`
                    );
                    break;
                  case dataSheet[2]:
                    navigate(
                      `/measurer/audit/${params.row.auditUserId || params.row.id
                      }`
                    );
                    break;
                  default:
                    navigate(`/audit/${params.row.audit_id || params.row.id}`);
                    break;
                }
              }}
            />,
          ];
        }
        if (!isAdmin && !isEnterprise) {
          if (params.row?.status?.toLowerCase() === 'affectée') {
            return [
              <GridActionsCellItem
                icon={undefined}
                label="Voir la demande"
                showInMenu
                onClick={() => {
                  if (isMesurer) {
                    navigate(
                      `/measurer/audit/${params.row.auditUserId || params.row.id
                      }`
                    );
                  } else {
                    navigate(
                      `/inspector/audit/${params.row.auditUserId || params.row.id
                      }`
                    );
                  }
                }}
              />,
              <GridActionsCellItem
                icon={undefined}
                label="Modifier la demande"
                showInMenu
                onClick={() => {
                  navigate(`/audit/${params.row.auditUserId}/edit`);
                }}
              />,
              <GridActionsCellItem
                icon={undefined}
                label="Révoquer"
                showInMenu
                onClick={() => {
                  reject(params.row.id);
                }}
              />,
            ];
          }
          if (params.row?.status?.toLowerCase() === 'rejetée') {
            return [
              <GridActionsCellItem
                icon={undefined}
                label="Voir la demande"
                showInMenu
                onClick={() => {
                  if (isMesurer) {
                    navigate(
                      `/measurer/audit/${params.row.auditUserId || params.row.id
                      }`
                    );
                  } else {
                    navigate(
                      `/inspector/audit/${params.row.auditUserId || params.row.id
                      }`
                    );
                  }
                }}
              />,
              <GridActionsCellItem
                icon={undefined}
                label="Modifier la demande"
                showInMenu
                onClick={() => {
                  navigate(`/audit/${params.row.auditUserId}/edit`);
                }}
              />,
            ];
          }
          if (params.row?.status?.toLowerCase() === 'a_corriger') {
            return [
              <GridActionsCellItem
                icon={undefined}
                label="Voir la demande"
                showInMenu
                onClick={() => {
                  if (isMesurer) {
                    navigate(
                      `/measurer/audit/${params.row.auditUserId || params.row.id
                      }`
                    );
                  } else {
                    navigate(
                      `/inspector/audit/${params.row.auditUserId || params.row.id
                      }`
                    );
                  }
                }}
              />,
              <GridActionsCellItem
                icon={undefined}
                label="Modifier la demande"
                showInMenu
                onClick={() => {
                  navigate(`/audit/${params.row.auditUserId}/edit`);
                }}
              />,
            ];
          }
        }
        return [
          <GridActionsCellItem
            icon={undefined}
            label="Voir la demande"
            showInMenu
            onClick={() => {
              if (isMesurer) {
                navigate(
                  `/measurer/audit/${params.row.auditUserId || params.row.id}`
                );
              } else if (isEnterprise || isAdmin) {
                switch (selectedDataSheetValue) {
                  case dataSheet[3]:
                    navigate(
                      `/inspector/audit/${params.row.auditUserId || params.row.id
                      }`
                    );
                    break;
                  case dataSheet[2]:
                    navigate(
                      `/measurer/audit/${params.row.auditUserId || params.row.id
                      }`
                    );
                    break;
                  default:
                    navigate(`/audit/${params.row.audit_id || params.row.id}`);
                    break;
                }
              } else {
                navigate(
                  `/inspector/audit/${params.row.auditUserId || params.row.id}`
                );
              }
            }}
          />,
        ];
      },
    },
  ];
  const handleFilter = (e) => {
    e.preventDefault();
    if (startDate && endDate) getCustomPeriodicAudits();
  };
  const selectUser = (i) => {
    handleChange({
      target: {
        name: 'user',
        value: `${i?.nom} ${i?.prenom}`,
      },
    });
    setOpenList(false);
    setSelectedUser(i);
  };

  useEffect(() => {
    setRowCountState((prevRowCountState) =>
      meta?.pagination?.total !== undefined
        ? meta?.pagination?.total
        : prevRowCountState
    );
  }, [meta?.pagination?.total, setRowCountState]);

  const theme = createTheme(
    {
      palette: {
        primary: { main: '#dc3838' },
      },
    },
    frFR
  );

  useEffect(() => {
    if (path.startsWith('/measurer') && path.endsWith('/audits')) {
      setSelectedDataSheet([dataSheet[2]]);
    }

    requestAudits();
  }, [selectedDataSheetValue]);

  const showBlank = () => {
    if (
      ((modalAction === 'assign' && !inspectors.length) ||
        (modalAction === 'affect' && !measurers.length)) &&
      loadingList
    ) {
      return (
        <Row justify="center" align="flex-end">
          <span>Chargement </span>
          <Badge
            css={{
              backgroundColor: 'transparent',
            }}
            className="inspector-badge"
            variant="points"
          />
        </Row>
      );
    }
    if (
      ((modalAction === 'assign' && inspectors.length === 0) ||
        (modalAction === 'affect' && measurers.length === 0)) &&
      !loadingList &&
      openList &&
      !selectedUser &&
      inputs.user
    ) {
      return (
        <Row justify="center" align="flex-end">
          <span>Aucun résultat </span>
        </Row>
      );
    }
    return null;
  };

  const renderInspector = (inspect) => {
    return (
      <button
        type="button"
        className="group flex w-full cursor-pointer flex-col"
        onClick={() => selectUser(inspect)}
      >
        <div className="relative flex w-full items-center border-x-2 border-transparent p-2 pl-2 hover:border-red-100 hover:bg-primary hover:text-white">
          <div className="flex w-full items-center">
            <div className="mx-2 -mt-1 flex-1 text-left">
              {inspect?.nom} {inspect?.prenom}
            </div>
            {inputs.user === `${inspect?.nom} ${inspect?.prenom}` && (
              <div className="pr-1.5">
                <svg
                  width="24"
                  height="24"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`group-hover:text-white
          ${inputs.user === `${inspect?.nom} ${inspect?.prenom}`
                      ? 'text-primary'
                      : 'text-white'
                    }
        `}
                >
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </button>
    );
  };

  const renderMeasurer = (mesur) => {
    return (
      <button
        type="button"
        className="group flex w-full cursor-pointer flex-col"
        onClick={() => selectUser(mesur)}
      >
        <div className="relative flex w-full items-center border-x-2 border-transparent p-2 pl-2 hover:border-red-100 hover:bg-primary hover:text-white">
          <div className="flex w-full items-center">
            <div className="mx-2 -mt-1 flex-1 text-left">
              {mesur?.nom} {mesur?.prenom}
            </div>
            {inputs.user === `${mesur?.nom} ${mesur?.prenom}` && (
              <div className="pr-1.5">
                <svg
                  width="24"
                  height="24"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  xmlns="http://www.w3.org/2000/svg"
                  className={`group-hover:text-white
            ${inputs.user === `${mesur?.nom} ${mesur?.prenom}`
                      ? 'text-primary'
                      : 'text-white'
                    }
          `}
                >
                  <path
                    d="M5 13L9 17L19 7"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      </button>
    );
  };

  // const notifSounds = () => {
  //   const audio = new Audio(sound);
  //   audio.play();
  // }

  // useEffect(() => {
  //   const channel = ably.channels.get(
  //     `entreprise-${currentUser?.user?.original?.data?.id}`
  //   );
  //   channel.subscribe((message) => {
  //     showNotif(message?.data?.text, 'notification');
  //     notifSounds();
  //   });

  //   return () => {
  //     channel.unsubscribe();
  //   };
  // }, [currentUser.user.original.data.id]);

  return (
    <>
      <div className="w-full">
        <div
          className={`mx-auto w-full ${(isEnterprise || isAdmin) && 'px-8'}`}
        >
          <div className="py-8">
            <div>
              <h2 className="block text-xl font-medium text-black md:hidden">
                Liste des audits
              </h2>
              {(isEnterprise || isAdmin) && !id && !userId && (
                <div className="mx-auto mt-2 flex w-full max-w-xs items-center justify-end sm:max-w-full sm:space-x-4">
                  <div className="flex flex-1 items-center sm:flex-none">
                    Tableau
                  </div>

                  <div className="relative ml-2">
                    <Dropdown placement="bottom-right">
                      <Dropdown.Button
                        flat
                        css={{
                          color: '#DC3838',
                          backgroundColor: '#f8d7d7',
                        }}
                      >
                        {selectedDataSheetValue}
                      </Dropdown.Button>
                      <Dropdown.Menu
                        aria-label="Single selection actions"
                        disallowEmptySelection
                        selectionMode="single"
                        selectedKeys={selectedDataSheet.keys()}
                        onSelectionChange={setSelectedDataSheet}
                      >
                        {dataSheet?.map((p) => (
                          <Dropdown.Item
                            key={p}
                            css={{
                              '&:hover': {
                                fontWeight: 500,
                              },
                              fontSize: '14px',
                            }}
                            className="first-letter:capitalize"
                          >
                            {p}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              )}
              <div className="mx-auto mt-2 max-w-xs items-end justify-end sm:flex sm:max-w-full sm:space-x-4">
                <div className="mb-5 xl:mb-0">
                  <Input
                    css={{ width: '100%' }}
                    onChange={
                      selectedDataSheetValue ===
                        'Negociation prix demande audit'
                        ? searchNegociation
                        : search
                    }
                    labelRight={<IconSearch />}
                    placeholder="Rechercher"
                    bordered
                    type="search"
                  />
                </div>
                <form
                  className="items-end justify-end md:flex md:space-x-4"
                  onSubmit={handleFilter}
                >
                  {selected.entries().next().value[0].toLowerCase() ===
                    'définir une autre période' && (
                      <Grid>
                        <Input
                          onChange={(e) => setStartDate(e.target.value)}
                          width="186px"
                          label="Du"
                          type="date"
                        />
                      </Grid>
                    )}
                  {selected.entries().next().value[0].toLowerCase() ===
                    'définir une autre période' && (
                      <Grid>
                        <Input
                          onChange={(e) => setEndDate(e.target.value)}
                          width="186px"
                          label="Au"
                          type="date"
                        />
                      </Grid>
                    )}
                  {selected.entries().next().value[0].toLowerCase() ===
                    'définir une autre période' && (
                      <div className="mr-4">
                        <Button
                          type="submit"
                          css={{
                            border: '1px solid',
                            borderColor: '#DC3838',
                            color: 'white',
                            backgroundColor: '#DC3838',
                          }}
                          auto
                        >
                          Filtrer
                        </Button>
                      </div>
                    )}
                </form>
                {selectedDataSheetValue !== dataSheet[0] && (
                  <>
                    <div className="mb-5 xl:mb-0">
                      <Dropdown placement="bottom-right">
                        <Dropdown.Button
                          flat
                          css={{
                            color: 'white',
                            backgroundColor: '#DC3838',
                            width: '100%',
                          }}
                        >
                          {selectedValue}
                        </Dropdown.Button>
                        <Dropdown.Menu
                          aria-label="Single selection actions"
                          disallowEmptySelection
                          selectionMode="single"
                          selectedKeys={selected.keys()}
                          onSelectionChange={setSelected}
                        >
                          {filters?.map((p) => (
                            <Dropdown.Item
                              key={p}
                              css={{
                                '&:hover': {
                                  fontWeight: 500,
                                },
                                fontSize: '14px',
                              }}
                              className="first-letter:capitalize"
                            >
                              {p}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                    <div className="mb-5 xl:mb-0">
                      <Dropdown placement="bottom-right">
                        <Dropdown.Button
                          flat
                          css={{
                            color: 'white',
                            backgroundColor: '#DC3838',
                            width: '100%',
                          }}
                        >
                          {selectedStatusValue}
                        </Dropdown.Button>
                        <Dropdown.Menu
                          aria-label="Single selection actions"
                          disallowEmptySelection
                          selectionMode="single"
                          selectedKeys={selectedStatus.keys()}
                          onSelectionChange={setSelectedStatus}
                        >
                          {status?.map((stat) => (
                            <Dropdown.Item
                              key={stat}
                              css={{
                                '&:hover': {
                                  fontWeight: 500,
                                },
                                fontSize: '14px',
                              }}
                              className="first-letter:capitalize"
                            >
                              {stat}
                            </Dropdown.Item>
                          ))}
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </>
                )}
              </div>
              <div
                className="mt-8 w-full"
                style={{
                  height: `${108 + 8 * 52.05}px`,
                }}
              >
                <ThemeProvider theme={theme}>
                  <DataGrid
                    rows={
                      isEnterprise &&
                        selectedDataSheetValue ===
                        'Negociation prix demande audit' &&
                        !path.startsWith('/inspector')
                        ? auditNegociation
                        : selectedDataSheetValue === 'Mesureur' &&
                          path.startsWith('/measurer')
                          ? auditAffectedToMeasurer
                          : audits
                    }
                    disableSelectionOnClick
                    columns={columns}
                    getRowId={(row) => row.id}
                    columnBuffer={15}
                    checkboxSelection
                    rowCount={rowCountState}
                    loading={isLoading && !loading}
                    rowsPerPageOptions={[15]}
                    pagination
                    page={page}
                    pageSize={pageSize}
                    paginationMode={mode}
                    onPageChange={(newPage) => setPage(newPage)}
                    onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                  />
                </ThemeProvider>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isNegociationModalOpen && (
        <div className="modal-overlay">
          <div className="modal">
            <button
              type="button"
              className="modal-close"
              onClick={() => {
                setRequestErrorManagement('');
                setIsNegociationModalOpen(false);
              }}
            >
              &times;
            </button>
            <div className="modal-content">
              <p className="fs-4 text-light">
                Ete vous sure de vouloir rejeter cette offre ?
              </p>
              {requestErrorManagement && (
                <span className="text-center text-red-500">
                  {requestErrorManagement}
                </span>
              )}
              <form>
                <textarea
                  value={auditRejectedReason}
                  onChange={(e) => setAuditRejectedReason(e.target.value)}
                  placeholder="Entrez la raison du rejet..."
                  rows={4}
                  cols={45}
                />
              </form>
              <div className="modal-buttons flex gap-4">
                <button
                  type="button"
                  className="rounded bg-green-500 py-2 px-4 font-bold text-white hover:bg-green-700"
                  onClick={() => {
                    setRequestErrorManagement('');
                    setIsNegociationModalOpen(false);
                  }}
                >
                  Annuler
                </button>
                <button
                  type="button"
                  className="rounded bg-red-500 py-2 px-4 font-bold text-white hover:bg-red-700"
                  onClick={() =>
                    auditRejectedReason !== ''
                      ? handleRejectNegociationClick()
                      : setRequestErrorManagement(
                        'Veuillez entrer la raison du rejet'
                      )
                  }
                >
                  <span className="loader-container">
                    {isRequestLoading && (
                      <Loading
                        style={{
                          marginRight: '1rem',
                        }}
                        color="currentColor"
                        size="sm"
                      />
                    )}
                    Confirmer{' '}
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <Modal
        closeButton
        scroll
        width="700px"
        css={{
          height: '34rem',
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        onClose={closeHandler}
        {...bindings}
      >
        <form onSubmit={handleClick}>
          <Modal.Header justify="start">
            <Text h2 b id="modal-title" size={18}>
              {modalAction === 'assign' && 'Attribuer à un inspecteur'}
              {modalAction === 'affect' && 'Affecter à un mesureur'}
              {modalAction === 'validate' && 'Valider la demande'}
              {modalAction === 'reject' && 'Rejeter la demande'}
            </Text>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="w-full">
                <div className="flex flex-col items-center">
                  <div className="flex h-64 w-full flex-col items-center">
                    <div className="w-full px-4">
                      <div className="relative flex flex-col items-center">
                        <div className="w-full">
                          <span className="font-semibold">
                            Demande d&apos;Audit
                          </span>
                          :{' '}
                          <span className="first-letter:uppercase">
                            {audit?.name}
                          </span>
                        </div>
                        {modalAction !== 'validate' &&
                          modalAction !== 'reject' && (
                            <div className="relative w-full">
                              <div
                                className="my-2 flex rounded-[0.75rem] bg-white p-1"
                                style={{
                                  boxShadow: '0 0 0 2px #DFE3E6',
                                }}
                              >
                                <input
                                  name="user"
                                  placeholder={`Rechercher un ${modalAction === 'assign'
                                    ? 'inspecteur'
                                    : 'mesureur'
                                    }`}
                                  value={inputs?.user}
                                  className={`w-full appearance-none p-1 px-2 text-gray-800 outline-none
                          ${helper.user.color &&
                                    'border-[#F31260] text-[#F31260]'
                                    }`}
                                  onChange={handleChange}
                                />
                              </div>
                              {openList && (
                                <div className="top-100 max-h-select absolute left-0 z-40 w-full overflow-y-auto rounded bg-white shadow">
                                  <FlatList
                                    list={
                                      modalAction === 'assign'
                                        ? inspectors
                                        : measurers
                                    }
                                    renderItem={
                                      modalAction === 'assign'
                                        ? renderInspector
                                        : renderMeasurer
                                    }
                                    renderWhenEmpty={showBlank}
                                    hasMoreItems={loadingList}
                                  />
                                </div>
                              )}
                            </div>
                          )}
                        <Textarea
                          name="comment"
                          css={{
                            width: '100%',
                            appearance: 'none',
                            boxShadow: '0 0 0 2px #DFE3E6',
                            color: 'rgb(31 41 55 / 1)',
                            outline: '2px solid transparent',
                            outlineOffset: '2px',
                            lineHeight: '1.25rem',
                            marginTop: '0.5rem',
                            marginBottom: '0.5rem',
                          }}
                          placeholder="Commentaire (optionnel)"
                          type="text"
                          rows={3}
                          bordered
                          animated={false}
                          onChange={(e) => {
                            setInputs({ ...inputs, comment: e.target.value });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer
            justify="start"
            className="!block w-full justify-between md:!flex"
          >
            <div
              id="message"
              className={`mt-4 w-full flex-1 text-left text-sm md:mb-0 md:mt-2 ${message?.type === 'success' ? 'text-green-500' : 'text-red-500'
                } `}
            >
              {message?.text}
            </div>
            <div className="m-auto flex justify-center md:justify-start">
              <Button
                css={{
                  border: '1px solid',
                  borderColor: '#DC3838',
                  color: 'white',
                  backgroundColor: '#DC3838',
                }}
                auto
                onClick={() => {
                  setVisible(false);
                  setTimeout(() => {
                    setAudit(null);
                    resetHelpers({
                      target: {
                        name: 'user',
                      },
                    });
                    delete inputs?.user;
                    setSelectedUser(null);
                  }, 50);
                }}
              >
                Annuler
              </Button>
              <Spacer x={0.5} />
              <Button type="submit" color="success" auto>
                {loading && (
                  <Loading
                    style={{
                      marginRight: '1rem',
                    }}
                    color="currentColor"
                    size="sm"
                  />
                )}
                {modalAction === 'assign' && 'Attribuer'}
                {modalAction === 'affect' && 'Affecter'}
                {modalAction === 'validate' && 'Valider'}
                {modalAction === 'reject' && 'Rejeter'}
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>

      {notification?.text && (
        <div
          className={`fixed bottom-4 right-4 z-[99999] flex rounded px-3 py-2.5 text-sm text-white transition-all delay-200 duration-300
        ${showNotification
              ? 'translate-y-0 opacity-100'
              : 'translate-y-2 opacity-0'
            }  
        ${notification.type === 'info' && '!bg-blue-600'}
        ${notification.type === 'error' ? 'bg-red-600' : 'bg-green-600'}
        `}
        >
          <span>{notification.text}</span>
          <svg
            onClick={() => {
              closeNotif();
            }}
            className="ml-2 h-5 w-5 cursor-pointer"
            width="24"
            height="24"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default Audits;
