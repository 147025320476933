import { configureStore } from '@reduxjs/toolkit';
import {applyMiddleware } from "redux"
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {thunk} from 'redux-thunk';
import rootReducer from './reducers';

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
};

// middleware
const middleware = [thunk];

const persistedReducer = persistReducer(persistConfig, rootReducer);

// creating store
export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ['persist/PERSIST'],
      }
    }).concat(thunk)
  },
  // middleware: [applyMiddleware(thunk)]
});

export const persistor = persistStore(store);
