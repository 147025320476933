import * as FileSaver from 'file-saver';
import PropTypes from 'prop-types';
import React from 'react';
import * as XLSX from 'xlsx';

const ExportToExcel = ({ data, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToCSV = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const dataToExport = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(dataToExport, fileName + fileExtension);
  };

  return (
    <button
      type="button"
      className="flex w-full cursor-pointer items-center justify-end text-primary"
      onClick={() => exportToCSV()}
    >
      <span className="mr-2.5">Exporter</span>
      <span className="rounded-md border border-primary p-2">
        <svg
          width="24"
          height="24"
          strokeWidth="1.5"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 20L18 20"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M12 4V16M12 16L15.5 12.5M12 16L8.5 12.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </span>
    </button>
  );
};

ExportToExcel.propTypes = {
  data: PropTypes.any.isRequired,
  fileName: PropTypes.string.isRequired,
};

export default ExportToExcel;
