/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
/* eslint-disable unused-imports/no-unused-vars */
import {
  Button,
  Input,
  Loading,
  Modal,
  Spacer,
  Text,
  Textarea,
  useModal,
} from '@nextui-org/react';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import IconHidden from '../components/icons/IconHidden';
import IconPassword from '../components/icons/IconPassword';
import IconVisible from '../components/icons/IconVisible';
import Map from '../components/Map';
import {
  getProfile,
  updateEnterprise,
  updateEnterprisePayments,
  updateMeasurerProfile,
  updateMesurerPayments,
} from '../redux/api/enterprise';
import {
  gotEnterprisePayment,
  gotMeasurerPayment,
  gotPayment,
  updateInspectorPayments,
} from '../redux/api/inspector';
import { get, updateAdmin, updateUser } from '../redux/api/user';
import { updateState } from '../redux/reducers/appReducer';
import {
  expired,
  failure,
  loginReset,
  loginStart,
  loginSuccess,
  userSuccess,
} from '../redux/reducers/userReducer';

const Settings = () => {
  const navigator = useNavigate();
  const [userId, setUserId] = useState(null);
  const [enterprise, setEnterprise] = useState(null);
  const [inspector, setInspector] = useState(null);
  const [selected, setSelected] = useState('profile');
  const dispatch = useDispatch();
  const { visible, setVisible, bindings } = useModal();
  const [action, setAction] = useState(null);
  const user = useSelector((state) => state.user);
  const { isLoading, isAdmin } = useSelector((state) => state.user);
  const [wallet, setWallet] = useState(0);
  const [inputs, setInputs] = useState({});
  const [helper, setHelper] = useState({
    lastname: {
      text: '',
      color: '',
    },
    firstname: {
      text: '',
      color: '',
    },
    email: {
      text: '',
      color: '',
    },
    phone: {
      text: '',
      color: '',
    },
    city: {
      text: '',
      color: '',
    },
    address: {
      text: '',
      color: '',
    },
    zipcode: {
      text: '',
      color: '',
    },
    password: {
      text: '',
      color: '',
    },
    newpassword: {
      text: '',
      color: '',
    },
    account: {
      text: '',
      color: '',
    },
    name: {
      text: '',
      color: '',
    },
    siret: {
      text: '',
      color: '',
    },
    description: {
      text: '',
      color: '',
    },
  });

  const resetHelpers = (e) => {
    if (e.target.name === 'lastname')
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'firstname')
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'email')
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'phone')
      setHelper((prev) => {
        return {
          ...prev,
          phone: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'zipcode')
      setHelper((prev) => {
        return {
          ...prev,
          zipcode: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'address')
      setHelper((prev) => {
        return {
          ...prev,
          address: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'password')
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: '',
            color: '',
          },
        };
      });
    if (e.target.name === 'newpassword')
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: '',
            color: '',
          },
        };
      });
  };

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(null);
  const [showNotification, setShowNotification] = useState(false);
  const [notification, setNotification] = useState(null);

  function closeNotif() {
    setTimeout(() => {
      setMessage({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 300);
    }, 100);
  }

  function showNotif(text, type) {
    setTimeout(() => {
      setNotification({
        text,
        type,
      });
      setTimeout(() => {
        setShowNotification(true);
      }, 100);
    }, 500);

    setTimeout(() => {
      setNotification({
        text: '',
        type: '',
      });
      setTimeout(() => {
        setShowNotification(false);
      }, 6000);
    }, 5000);
  }

  function showProcessNotif(text, type) {
    setTimeout(() => {
      setNotification({
        text,
        type,
      });
      setTimeout(() => {
        setShowNotification(true);
      }, 100);
    }, 500);
  }

  function closeProcessNotif() {
    setTimeout(() => {
      setShowNotification(false);
    }, 300);
  }

  const handleChange = (e) => {
    setInputs((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
    resetHelpers(e);
  };

  const closeHandler = () => {
    setVisible(false);
  };

  useEffect(() => {
    dispatch(loginStart());
    get().then(
      (res) => {
        dispatch(userSuccess());

        setUserId(res.data?.user.id);
        setInputs({
          lastname: res.data?.user?.nom,
          firstname: res.data?.user?.prenom,
          email: res.data?.user?.email,
          phone: res.data?.user?.telephone,
          city: res.data?.user?.ville_residence,
          location: {
            lat: res.data?.user?.lat,
            lng: res.data?.user?.long,
          },
          zipcode: res.data?.user?.code_postal,
        });
        if (res.data) {
          if (user.isEnterprise) {
            setEnterprise(res.data?.entreprise?.original?.data);
          } else {
            setInspector(
              res.data?.inspecteur || res.data?.mesureur?.original?.data
            );
          }
          handleChange({
            target: {
              name: 'name',
              value: res.data?.entreprise?.original?.data?.nom_entreprise,
            },
          });
          handleChange({
            target: {
              name: 'siret',
              value: res.data?.entreprise?.original?.data?.siret,
            },
          });
          handleChange({
            target: {
              name: 'account',
              value:
                res.data?.entreprise?.original?.data?.stripe_marchand_id ||
                res.data?.inspecteur?.stripe_marchand_id ||
                res.data?.mesureur?.stripe_marchand_id,
            },
          });
          handleChange({
            target: {
              name: 'description',
              value: res.data?.entreprise?.original?.data?.services,
            },
          });
          setWallet(
            res.data?.entreprise?.original?.data?.montant ??
              res.data?.inspecteur?.montant ??
              res.data?.mesureur?.montant ??
              0
          );
        }
      },
      (err) => {
        if (err.response.status === 401) {
          dispatch(expired());
          dispatch(loginReset());
        } else {
          dispatch(failure(err.response.data));
        }
      }
    );
  }, []);

  const validateEmail = (email) => {
    const re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  const navigate = (route) => {
    navigator(route);
  };

  const handleClick = (e) => {
    e.preventDefault();

    if (inputs.lastname)
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.lastname)
      setHelper((prev) => {
        return {
          ...prev,
          lastname: {
            text: 'Veuillez renseigner votre nom',
            color: 'error',
          },
        };
      });

    if (inputs.firstname)
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.firstname)
      setHelper((prev) => {
        return {
          ...prev,
          firstname: {
            text: 'Veuillez renseigner votre prénom',
            color: 'error',
          },
        };
      });

    if (inputs.phone)
      setHelper((prev) => {
        return {
          ...prev,
          phone: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.phone)
      setHelper((prev) => {
        return {
          ...prev,
          phone: {
            text: 'Veuillez renseigner votre téléphone',
            color: 'error',
          },
        };
      });

    if (inputs.city)
      setHelper((prev) => {
        return {
          ...prev,
          city: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.city)
      setHelper((prev) => {
        return {
          ...prev,
          city: {
            text: 'Veuillez renseigner votre ville',
            color: 'error',
          },
        };
      });

    if (inputs.zipcode)
      setHelper((prev) => {
        return {
          ...prev,
          zipcode: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.zipcode)
      setHelper((prev) => {
        return {
          ...prev,
          zipcode: {
            text: 'Veuillez renseigner votre code postal',
            color: 'error',
          },
        };
      });

    if (inputs?.location)
      setHelper((prev) => {
        return {
          ...prev,
          address: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs?.location)
      setHelper((prev) => {
        return {
          ...prev,
          address: {
            text: 'Veuillez renseigner votre adresse',
            color: 'error',
          },
        };
      });

    if (inputs.email)
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.email)
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: 'Email requis',
            color: 'error',
          },
        };
      });

    if (inputs.email && !validateEmail(inputs.email))
      setHelper((prev) => {
        return {
          ...prev,
          email: {
            text: 'Email non valide',
            color: 'error',
          },
        };
      });

    if (inputs.password)
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.password && inputs.newpassword)
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: 'Nouveau Mot de passe requis',
            color: 'error',
          },
        };
      });

    if (inputs.password && inputs.password.length < 5)
      setHelper((prev) => {
        return {
          ...prev,
          password: {
            text: 'Le nouveau mot de passe doit contenir au mois 5 caractères',
            color: 'error',
          },
        };
      });

    if (inputs.newpassword || !inputs.password)
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: '',
            color: '',
          },
        };
      });

    if (inputs.password && !inputs.newpassword)
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: 'Confirmer le nouveau mot de passe',
            color: 'error',
          },
        };
      });

    if (
      inputs.password &&
      inputs.newpassword &&
      inputs.password !== inputs.newpassword
    )
      setHelper((prev) => {
        return {
          ...prev,
          newpassword: {
            text: 'Les mots de passe ne correspondent pas',
            color: 'error',
          },
        };
      });

    if (user?.currentUser?.missing_d) {
      if (inputs.name)
        setHelper((prev) => {
          return {
            ...prev,
            name: {
              text: '',
              color: '',
            },
          };
        });

      if (!inputs.name)
        setHelper((prev) => {
          return {
            ...prev,
            name: {
              text: 'Vous devez renseigner le nom de votre entreprise',
              color: 'error',
            },
          };
        });

      if (inputs.siret)
        setHelper((prev) => {
          return {
            ...prev,
            siret: {
              text: '',
              color: '',
            },
          };
        });

      if (!inputs.siret)
        setHelper((prev) => {
          return {
            ...prev,
            siret: {
              text: 'Vous devez renseigner le siret',
              color: 'error',
            },
          };
        });

      if (inputs.description)
        setHelper((prev) => {
          return {
            ...prev,
            description: {
              text: '',
              color: '',
            },
          };
        });

      if (!inputs.description)
        setHelper((prev) => {
          return {
            ...prev,
            description: {
              text: 'Vous devez renseigner les services',
              color: 'error',
            },
          };
        });
    }

    if (
      ((!inputs.lastname ||
        !inputs.firstname ||
        !inputs.phone ||
        !inputs.city ||
        !inputs.zipcode ||
        !inputs.location ||
        !inputs.email ||
        (!inputs.password && inputs.newpassword) ||
        (inputs.password && !inputs.newpassword) ||
        (inputs.password && inputs.password.length < 5) ||
        (inputs.password &&
          inputs.newpassword &&
          inputs.password !== inputs.newpassword) ||
        !validateEmail(inputs.email)) &&
        !user?.currentUser?.missing_d) ||
      ((!inputs.lastname ||
        !inputs.firstname ||
        !inputs.phone ||
        !inputs.city ||
        !inputs.zipcode ||
        !inputs.location ||
        !inputs.email ||
        (!inputs.password && inputs.newpassword) ||
        (inputs.password && !inputs.newpassword) ||
        (inputs.password && inputs.password.length < 5) ||
        (inputs.password &&
          inputs.newpassword &&
          inputs.password !== inputs.newpassword) ||
        !validateEmail(inputs.email) ||
        !inputs.name ||
        !inputs.siret ||
        !inputs.description) &&
        user?.currentUser?.missing_d)
    ) {
      return;
    }

    setLoading(true);
    setMessage(null);

    if (user.isAdmin) {
      let data = {
        id: userId,
        email: inputs.email,
        prenom: inputs.firstname,
        nom: inputs.lastname,
        telephone: inputs.phone,
        lat: inputs.location.lat,
        long: inputs.location.lng,
        code_postal: inputs.zipcode,
        ville_residence: inputs.city,
        type_users: 'admin',
      };

      if (inputs?.password)
        data = {
          ...data,
          password: inputs.password,
        };

      updateAdmin(data)
        .then(() => {
          setLoading(false);
          setMessage({
            type: 'success',
            text: 'Votre profil a été mis à jour',
          });
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data.message === 'The given data was invalid.')
            setMessage({
              type: 'error',
              text: "L'adresse email a été déjà utilisée",
            });
          else if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            dispatch(failure(err.response.data));
            setMessage({
              type: 'error',
              text: 'Une erreur est survenue',
            });
          }
        });
    } else if (user.isEnterprise) {
      let data = {
        id: userId,
        email: inputs.email,
        prenom: inputs.firstname,
        nom: inputs.lastname,
        telephone: inputs.phone,
        lat: inputs.location.lat,
        long: inputs.location.lng,
        code_postal: inputs.zipcode,
        ville_residence: inputs.city,
        type_users: 'entreprise',
      };

      const data2 = {
        siret: inputs.siret,
        nom_entreprise: inputs.name,
        services: inputs.description,
      };

      if (inputs?.password)
        data = {
          ...data,
          password: inputs.password,
        };

      updateEnterprise(data)
        .then(() => {
          updateEnterprisePayments(data2)
            .then(() => {
              getProfile().then((p) => {
                if (p.data.message === false)
                  dispatch(
                    loginSuccess({
                      ...user?.currentUser,
                      missing_d: p.data.message,
                    })
                  );
                get().then((res) => {
                  dispatch(
                    updateState({
                      enterprise: res.data.entreprise?.original?.data,
                    })
                  );
                });
              });
              setLoading(false);
              setMessage({
                type: 'success',
                text: 'Vos informations ont été mises à jour',
              });
            })
            .catch((err) => {
              setLoading(false);
              if (err.response.status === 401) {
                dispatch(expired());
                dispatch(loginReset());
              } else {
                dispatch(failure(err.response.data));
                showNotif('Une erreur est survenue', 'error');
              }
            });
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data.message === 'The given data was invalid.')
            setMessage({
              type: 'error',
              text: "L'adresse email a été déjà utilisée",
            });
          else if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            dispatch(failure(err.response.data));
            setMessage({
              type: 'error',
              text: 'Une erreur est survenue',
            });
          }
        });
    } else if (user.isMesurer) {
      let data = {
        id: userId,
        email: inputs.email,
        prenom: inputs.firstname,
        nom: inputs.lastname,
        telephone: inputs.phone,
        code_postal: inputs.zipcode,
        ville_residence: inputs.city,
        adresse: inputs.address,
      };

      if (inputs?.password)
        data = {
          ...data,
          password: inputs.password,
        };

      updateMeasurerProfile(data)
        .then(() => {
          setLoading(false);
          setMessage({
            type: 'success',
            text: 'Vos informations ont été mises à jour',
          });
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data.message === 'The given data was invalid.')
            setMessage({
              type: 'error',
              text: "L'adresse email a été déjà utilisée",
            });
          else if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            dispatch(failure(err.response.data));
            setMessage({
              type: 'error',
              text: 'Une erreur est survenue',
            });
          }
        });
    } else {
      let data = {
        id: userId,
        email: inputs.email,
        prenom: inputs.firstname,
        nom: inputs.lastname,
        telephone: inputs.phone,
        lat: inputs.location.lat,
        long: inputs.location.lng,
        code_postal: inputs.zipcode,
        ville_residence: inputs.city,
        type_users: 'client',
      };

      if (inputs?.password)
        data = {
          ...data,
          password: inputs.password,
        };

      updateUser(data)
        .then(() => {
          setLoading(false);
          setMessage({
            type: 'success',
            text: 'Votre profil a été mis à jour',
          });
        })
        .catch((err) => {
          setLoading(false);
          if (err.response.data.message === 'The given data was invalid.')
            setMessage({
              type: 'error',
              text: "L'adresse email a été déjà utilisée",
            });
          else if (err.response.status === 401 || err.response.status === 403) {
            dispatch(expired());
            dispatch(loginReset());
          } else {
            dispatch(failure(err.response.data));
            setMessage({
              type: 'error',
              text: 'Une erreur est survenue',
            });
          }
        });
    }

    setTimeout(() => {
      navigate('/dashboard');
    }, 3000);
  };

  const submitPayment = (e) => {
    e.preventDefault();

    if (inputs.account)
      setHelper((prev) => {
        return {
          ...prev,
          account: {
            text: '',
            color: '',
          },
        };
      });

    if (!inputs.account)
      setHelper((prev) => {
        return {
          ...prev,
          account: {
            text:
              action === 'wiretransfer'
                ? 'Veuillez saisir votre RIB'
                : 'Veuillez saisir votre ID compte marchand',
            color: 'error',
          },
        };
      });

    if (!inputs.account) return;

    if (action === 'stripe') {
      setLoading(true);

      const paymentData = {
        stripe_marchand_id: inputs.account,
        status: true,
      };

      if (user.isEnterprise) {
        updateEnterprisePayments(paymentData)
          .then(() => {
            getProfile().then((p) => {
              if (p.data.message === false)
                dispatch(
                  loginSuccess({
                    ...user?.currentUser,
                    missing_d: p.data.message,
                  })
                );
              get().then((res) => {
                dispatch(
                  updateState({
                    enterprise: res.data.entreprise?.original?.data,
                  })
                );
              });
            });
            setLoading(false);
            if (enterprise?.stripe_marchand_id)
              showNotif('Votre Id marchand a été mis à jour', 'success');
            else showNotif('Votre Id marchand a été ajouté', 'success');
            closeHandler();
          })
          .catch((err) => {
            setLoading(false);
            if (err.response.status === 401) {
              dispatch(expired());
              dispatch(loginReset());
            } else {
              dispatch(failure(err.response.data));
              showNotif('Une erreur est survenue', 'error');
            }
          });
      } else if (user.isMesurer) {
        updateMesurerPayments(paymentData)
          .then(() => {
            get().then((res) => {
              dispatch(
                updateState({
                  mesurer: res.data.mesurer,
                })
              );
            });
            setInspector((prev) => {
              return {
                ...prev,
                stripe_marchand_id: inputs.account,
              };
            });
            setLoading(false);
            if (inspector?.stripe_marchand_id)
              showNotif('Votre Id marchand a été mis à jour', 'success');
            else showNotif('Votre Id marchand a été ajouté', 'success');
            closeHandler();
          })
          .catch((err) => {
            setLoading(false);
            if (err.response.status === 401) {
              dispatch(expired());
              dispatch(loginReset());
            } else {
              dispatch(failure(err.response.data));
              showNotif('Une erreur est survenue', 'error');
            }
          });
      } else {
        updateInspectorPayments(paymentData)
          .then(() => {
            get().then((res) => {
              dispatch(
                updateState({
                  inspector: res.data.inspecteur,
                })
              );
            });
            setInspector((prev) => {
              return {
                ...prev,
                stripe_marchand_id: inputs.account,
              };
            });
            setLoading(false);
            if (inspector?.stripe_marchand_id)
              showNotif('Votre Id marchand a été mis à jour', 'success');
            else showNotif('Votre Id marchand a été ajouté', 'success');
            closeHandler();
          })
          .catch((err) => {
            setLoading(false);
            if (err.response.status === 401) {
              dispatch(expired());
              dispatch(loginReset());
            } else {
              dispatch(failure(err.response.data));
              showNotif('Une erreur est survenue', 'error');
            }
          });
      }
    }
  };

  const handleWidthraw = () => {
    if (!loading) {
      setLoading(true);

      if (user.isMesurer) {
        gotMeasurerPayment().then(
          (res) => {
            setLoading(false);
            showNotif(res.data.message, 'success');
          },
          (err) => {
            setLoading(false);
            if (err.response.status === 401) {
              dispatch(expired());
              dispatch(loginReset());
            } else if (err.response.status === 403) {
              showNotif(err.response.data.message, 'error');
            } else {
              showNotif('Une erreur est survenue', 'error');
            }
          }
        );
      } else if (user.isEnterprise) {
        gotEnterprisePayment().then(
          (res) => {
            setLoading(false);
            showNotif(res.data.message, 'success');
          },
          (err) => {
            setLoading(false);
            if (err.response.status === 401) {
              dispatch(expired());
              dispatch(loginReset());
            } else if (err.response.status === 403) {
              showNotif(err.response.data.message, 'error');
            } else {
              showNotif('Une erreur est survenue', 'error');
            }
          }
        );
      } else {
        gotPayment().then(
          (res) => {
            setLoading(false);
            showNotif(res.data.message, 'success');
          },
          (err) => {
            setLoading(false);
            if (err.response.status === 401) {
              dispatch(expired());
              dispatch(loginReset());
            } else if (err.response.status === 403) {
              showNotif(err.response.data.message, 'error');
            } else {
              showNotif('Une erreur est survenue', 'error');
            }
          }
        );
      }
    }
  };

  const changeStatus = (e) => {
    setInputs((prev) => {
      return { ...prev, isActive: e.target.checked };
    });
  };

  const addPayment = () => {
    setSelected('paymentInfo');
  };

  return (
    <>
      {isLoading && !loading && (
        <>
          <div className="fixed inset-0 z-[99] h-full min-h-full overflow-hidden bg-black bg-opacity-10" />
          <div className="absolute top-[50%] left-[40%] z-[991] h-[100px] w-[100px] lg:left-[48%]">
            <Loading
              loadingCss={{
                $$loadingSize: '100px',
                $$loadingBorder: '10px',
                $$LoadingColors: '#DC3838',
              }}
            />
          </div>
        </>
      )}
      <div className="w-full">
        <div className="mx-auto w-full max-w-6xl px-8">
          {!user.isAdmin && (
            <div className="mx-auto flex w-full flex-col place-content-center sm:flex-row">
              <button
                type="button"
                onClick={() => {
                  setSelected('profile');
                }}
                className={`flex w-full items-center justify-start border border-primary border-opacity-10 bg-primary bg-opacity-10 py-[10px] px-[12px] text-center text-sm font-medium capitalize text-white transition duration-150 ease-in-out focus:bg-primary sm:inline-flex sm:w-1/2
                sm:py-3 sm:px-6 sm:text-base
              ${
                selected === 'profile'
                  ? 'border-primary !bg-primary text-white'
                  : 'text-primary'
              }`}
              >
                <svg
                  className="mr-4 w-5"
                  width="24"
                  height="24"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7 18V17C7 14.2386 9.23858 12 12 12V12C14.7614 12 17 14.2386 17 17V18"
                    stroke="currentColor"
                    strokeLinecap="round"
                  />
                  <path
                    d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M21 3.6V20.4C21 20.7314 20.7314 21 20.4 21H3.6C3.26863 21 3 20.7314 3 20.4V3.6C3 3.26863 3.26863 3 3.6 3H20.4C20.7314 3 21 3.26863 21 3.6Z"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>{' '}
                Profil
              </button>
              <button
                type="button"
                onClick={() => {
                  setSelected('paymentInfo');
                }}
                className={`inline-flex w-full items-center justify-start border border-primary border-opacity-20 bg-primary bg-opacity-20 py-[10px] px-[12px] text-center text-sm font-medium capitalize text-white transition duration-150 ease-in-out focus:bg-primary sm:w-1/2
                sm:py-3 sm:px-6 sm:text-base
              ${
                selected === 'paymentInfo'
                  ? 'border-primary !bg-primary text-white'
                  : 'text-primary'
              }`}
              >
                <svg
                  className="mr-4 w-5"
                  width="24"
                  height="24"
                  strokeWidth="1.5"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2 9V5.6C2 5.26863 2.26863 5 2.6 5H21.4C21.7314 5 22 5.26863 22 5.6V9V18.4C22 18.7314 21.7314 19 21.4 19H2.6C2.26863 19 2 18.7314 2 18.4V9ZM2 9H16"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <rect
                    x="15"
                    y="12"
                    width="4"
                    height="4"
                    rx="0.6"
                    fill="currentColor"
                  />
                </svg>{' '}
                Information paiement
              </button>
            </div>
          )}
          {selected === 'profile' && (
            <div
              className={`relative w-full rounded-b-3xl border border-t-0 border-gray-300 border-opacity-20
              ${!user.isEnterprise ? 'h-44 !border-0' : 'h-28 sm:h-auto'}
          `}
            >
              {/* {!user?.currentUser?.missing_d && !user.isAdmin && (
                <div className="!absolute right-4 top-8 flex items-center justify-center space-x-2 py-2 px-2 sm:top-2">
                  <span>Disponible</span>
                  <Switch
                    checked
                    size="xl"
                    css={{
                      color: user?.isEnterprise ? 'white' : 'initial',
                    }}
                    className={`${user?.isEnterprise ? 'switch-checked' : ''}`}
                    onChange={changeStatus}
                    iconOn={
                      <svg
                        width="24"
                        height="24"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 14C13.1046 14 14 13.1046 14 12C14 10.8954 13.1046 10 12 10C10.8954 10 10 10.8954 10 12C10 13.1046 10.8954 14 12 14Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M21 12C19.1114 14.991 15.7183 18 12 18C8.2817 18 4.88856 14.991 3 12C5.29855 9.15825 7.99163 6 12 6C16.0084 6 18.7015 9.1582 21 12Z"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                    iconOff={
                      <svg
                        width="24"
                        height="24"
                        strokeWidth="1.5"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M3 3L21 21"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M10.5 10.6771C10.1888 11.0296 10 11.4928 10 12C10 13.1045 10.8954 14 12 14C12.5072 14 12.9703 13.8112 13.3229 13.5"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M7.36185 7.5611C5.68002 8.73968 4.27894 10.4188 3 12C4.88856 14.991 8.2817 18 12 18C13.5499 18 15.0434 17.4772 16.3949 16.6508"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M12 6C16.0084 6 18.7015 9.1582 21 12C20.6815 12.5043 20.3203 13.0092 19.922 13.5"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    }
                  />
                </div>
              )} */}

              {!user.isEnterprise && (
                <div
                  className={`h-full w-full items-center px-8 md:flex
              ${
                selected === 'profile' && !isAdmin
                  ? 'justify-center'
                  : 'justify-start'
              } `}
                >
                  <h1
                    className={`my-4 text-2xl font-bold text-black md:my-0
                ${selected === 'profile' ? 'text-center' : 'text-left'} `}
                  >
                    {selected === 'profile' && <span>Mon profile</span>}
                    {selected === 'paymentInfo' && (
                      <span>Mes informations de paiements</span>
                    )}
                  </h1>
                </div>
              )}
            </div>
          )}
        </div>

        {user.isEnterprise && selected === 'paymentInfo' && (
          <>
            <br />
            <br />
          </>
        )}

        <div
          className={`mx-auto mb-16 w-full max-w-6xl ${
            selected === 'profile' ? 'px-14' : 'px-8'
          }`}
        >
          {selected === 'profile' &&
            user?.isEnterprise &&
            user?.currentUser?.missing_d &&
            !inputs?.name &&
            !inputs?.siret &&
            !isLoading && (
              <h4 className="my-4 font-medium text-red-600">
                Vous devez compléter les informations de votre entreprise pour
                avoir accès à la plateforme
              </h4>
            )}
          {selected === 'profile' &&
            user?.isEnterprise &&
            user?.currentUser?.missing_d &&
            inputs?.name &&
            inputs?.siret &&
            !inputs?.account &&
            !isLoading && (
              <h4 className="my-4 font-medium text-red-600">
                Vous devez compléter les informations de paiement de votre
                entreprise.{' '}
                <button
                  type="button"
                  onClick={addPayment}
                  className="text-primary underline"
                >
                  Ajouter un moyen de paiement
                </button>
              </h4>
            )}
          {selected === 'profile' && (
            <form onSubmit={handleClick}>
              <div className="mt-4 flex h-full w-full max-w-3xl flex-col items-start justify-center space-y-8">
                {user?.isEnterprise && (
                  <div className="w-full">
                    <h3 className="mb-3 text-left text-xl font-medium">
                      Identité de l&apos;entreprise
                    </h3>
                    <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
                      <div className="w-full">
                        <Input
                          name="name"
                          underlined
                          fullWidth="true"
                          label="Nom de l'entreprise"
                          className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                          value={inputs.name}
                          color={helper.name.color}
                          helperColor={helper.name.color}
                          helperText={helper.name.text}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="w-full">
                        <Input
                          name="siret"
                          underlined
                          fullWidth="true"
                          label="SIRET"
                          className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                          value={inputs.siret}
                          color={helper.siret.color}
                          helperColor={helper.siret.color}
                          helperText={helper.siret.text}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className="mt-4 w-full">
                      <Textarea
                        name="description"
                        underlined
                        fullWidth="true"
                        label="Services"
                        rows="2"
                        className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                        value={inputs.description}
                        color={helper.description.color}
                        helperColor={helper.description.color}
                        helperText={helper.description.text}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                )}
                {user?.isEnterprise && (
                  <h3 className="mb-3 text-left text-xl font-medium">
                    Mes informations personnelles
                  </h3>
                )}
                <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <Input
                      name="lastname"
                      underlined
                      fullWidth="true"
                      label="Nom"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      value={inputs.lastname}
                      color={helper.lastname.color}
                      helperColor={helper.lastname.color}
                      helperText={helper.lastname.text}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      name="firstname"
                      underlined
                      fullWidth="true"
                      label="Prénom(s)"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      value={inputs.firstname}
                      color={helper.firstname.color}
                      helperColor={helper.firstname.color}
                      helperText={helper.firstname.text}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <Input
                      contentLeft={
                        <svg
                          width="24"
                          height="24"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 9L12 12.5L17 9"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M2 17V7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17Z"
                            stroke="currentColor"
                            strokeWidth="1.5"
                          />
                        </svg>
                      }
                      name="email"
                      type="email"
                      underlined
                      fullWidth="true"
                      label="Email"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      value={inputs.email}
                      color={helper.email.color}
                      helperColor={helper.email.color}
                      helperText={helper.email.text}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      contentLeft={
                        <svg
                          width="24"
                          height="24"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M18.1182 14.702L14 15.5C11.2183 14.1038 9.5 12.5 8.5 10L9.26995 5.8699L7.81452 2L4.0636 2C2.93605 2 2.04814 2.93178 2.21654 4.04668C2.63695 6.83 3.87653 11.8765 7.5 15.5C11.3052 19.3052 16.7857 20.9564 19.802 21.6127C20.9668 21.8662 22 20.9575 22 19.7655L22 16.1812L18.1182 14.702Z"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      name="phone"
                      underlined
                      fullWidth="true"
                      label="Téléphone"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      value={inputs.phone}
                      color={helper.phone.color}
                      helperColor={helper.phone.color}
                      helperText={helper.phone.text}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <Input
                      contentLeft={
                        <svg
                          width="24"
                          height="24"
                          strokeWidth="1.5"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7 9.01L7.01 8.99889"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11 9.01L11.01 8.99889"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 13.01L7.01 12.9989"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11 13.01L11.01 12.9989"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M7 17.01L7.01 16.9989"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M11 17.01L11.01 16.9989"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                          <path
                            d="M15 21H3.6C3.26863 21 3 20.7314 3 20.4V5.6C3 5.26863 3.26863 5 3.6 5H9V3.6C9 3.26863 9.26863 3 9.6 3H14.4C14.7314 3 15 3.26863 15 3.6V9M15 21H20.4C20.7314 21 21 20.7314 21 20.4V9.6C21 9.26863 20.7314 9 20.4 9H15M15 21V17M15 9V13M15 13H17M15 13V17M15 17H17"
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                      }
                      name="city"
                      underlined
                      fullWidth="true"
                      label="Ville"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      value={inputs.city}
                      color={helper.city.color}
                      helperColor={helper.city.color}
                      helperText={helper.city.text}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <Input
                      name="zipcode"
                      underlined
                      fullWidth="true"
                      label="Code postal"
                      className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                      value={inputs.zipcode}
                      color={helper.zipcode.color}
                      helperColor={helper.zipcode.color}
                      helperText={helper.zipcode.text}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="w-full">
                  <Map
                    input={
                      <Input
                        name="address"
                        underlined
                        fullWidth="true"
                        label="Adresse"
                        placeholder="Rue, numéro"
                        className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                        color={helper.address.color}
                        helperColor={helper.address.color}
                        helperText={helper.address.text}
                      />
                    }
                    onOpen={inputs?.location}
                    onClose={(place) => {
                      handleChange({
                        target: { name: 'location', value: place },
                      });
                    }}
                  />
                </div>

                <h3 className="mb-3 text-left text-xl font-medium">
                  Changer mon mot de passe
                </h3>
                <div className="w-full justify-between space-y-4 md:flex md:space-y-0 md:space-x-4">
                  <div className="w-full">
                    <Input.Password
                      contentLeft={<IconPassword />}
                      name="password"
                      underlined
                      fullWidth="true"
                      width=""
                      label="Nouveau mot de passe"
                      visibleIcon={
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-lock"
                          width="44"
                          height="24"
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#2c3e50"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <rect x="5" y="11" width="14" height="10" rx="2" />
                          <circle cx="12" cy="16" r="1" />
                          <path d="M8 11v-4a4 4 0 0 1 8 0v4" />
                        </svg>
                      }
                      color={helper.password.color}
                      helperColor={helper.password.color}
                      helperText={helper.password.text}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="w-full">
                    <Input.Password
                      name="newpassword"
                      underlined
                      fullWidth="true"
                      width=""
                      label="Confirmer le nouveau votre mot de passe"
                      visibleIcon={<IconHidden />}
                      hiddenIcon={<IconVisible />}
                      color={helper.newpassword.color}
                      helperColor={helper.newpassword.color}
                      helperText={helper.newpassword.text}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                {message && (
                  <div
                    className={`w-full text-center text-sm ${
                      message?.type === 'success'
                        ? 'text-green-500'
                        : 'text-red-500'
                    } `}
                  >
                    {message?.text}
                  </div>
                )}
                <div className="flex w-full">
                  <Button
                    type="submit"
                    css={{
                      borderRadius: '0px', // radii.xs
                      border: '1px solid',
                      borderColor: '#DC3838',
                      color: 'white',
                      backgroundColor: '#DC3838',
                      width: '100%',
                      minWidth: '0',
                    }}
                    size="xl"
                  >
                    {loading && (
                      <Loading
                        style={{
                          marginRight: '1rem',
                        }}
                        color="currentColor"
                        size="sm"
                      />
                    )}
                    Mettre à jour
                  </Button>
                </div>
              </div>
            </form>
          )}
          {selected === 'paymentInfo' && !isAdmin && (
            <div className="mt-4 w-full md:flex-row">
              <div className="w-full justify-between rounded-md bg-[rgb(247,248,250)] p-4 md:flex">
                <h2 className="flex items-center text-left text-lg font-semibold text-primary">
                  <svg
                    className="w-8 md:ml-3 md:w-14"
                    width="24px"
                    strokeWidth="1.5"
                    height="24px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    color="currentColor"
                  >
                    <path
                      d="M19 20H5a2 2 0 01-2-2V9a2 2 0 012-2h14a2 2 0 012 2v9a2 2 0 01-2 2z"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M16.5 14a.5.5 0 110-1 .5.5 0 010 1z"
                      fill="currentColor"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M18 7V5.603a2 2 0 00-2.515-1.932l-11 2.933A2 2 0 003 8.537V9"
                      stroke="currentColor"
                      strokeWidth="1.5"
                    />
                  </svg>
                  <span className="md:ml-3">Mon portefeuille : </span>
                  <span className="ml-1 font-normal">{wallet} €</span>
                </h2>
                <h2 className="flex items-center text-left text-lg font-semibold text-primary">
                  <svg
                    className="invisible w-8 md:hidden"
                    width="24px"
                    height="24px"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    color="#000000"
                  >
                    <path
                      d="M10 14H9M10 6H9"
                      stroke="#000000"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M5 10V2.6a.6.6 0 01.6-.6h12.8a.6.6 0 01.6.6V10M5 10v11.4a.6.6 0 00.6.6h12.8a.6.6 0 00.6-.6V10M5 10h14"
                      stroke="#000000"
                      strokeWidth="1.5"
                    />
                  </svg>
                  <span className="md:ml-3">ID Marchant: </span>
                  <span className="ml-1 font-normal">
                    {enterprise?.stripe_marchand_id ??
                      inspector?.stripe_marchand_id ??
                      'Aucun'}
                  </span>
                </h2>
              </div>

              <div className="mt-4 flex w-full">
                <Button
                  css={{
                    borderRadius: '0px', // radii.xs
                    border: '1px solid',
                    borderColor: '#DC3838',
                    color: 'white',
                    backgroundColor: '#DC3838',
                    fontWeight: 600,
                  }}
                  auto
                  onClick={handleWidthraw}
                >
                  {loading && (
                    <Loading
                      style={{
                        marginRight: '1rem',
                      }}
                      color="currentColor"
                      size="xs"
                    />
                  )}
                  RETIRER{' '}
                  <svg
                    className="ml-1"
                    width="24px"
                    height="24px"
                    strokeWidth="1.5"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    color="currentColor"
                  >
                    <path
                      d="M12.25 5.5V18m0 0l-6-6m6 6l6-6"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </Button>
              </div>

              <div className="mx-auto my-16 w-full max-w-2xl space-y-14 bg-[#F7F8FA] p-4 py-14 md:px-16">
                <h2 className="my-2 flex justify-center text-center text-lg font-bold text-[#BAB1C5]">
                  {user?.currentUser?.missing_d === true &&
                  enterprise?.stripe_marchand_id
                    ? 'Choisissez un autre moyen de paiement'
                    : 'Choisissez un moyen de paiement'}
                </h2>
                <button
                  type="button"
                  className="flex w-full cursor-pointer items-center justify-between border border-gray-300 p-4 text-left text-lg font-semibold text-primary"
                  onClick={() => {
                    setAction('stripe');
                    setVisible(true);
                    handleChange({
                      target: {
                        name: 'account',
                        value:
                          enterprise?.stripe_marchand_id ??
                          inspector?.stripe_marchand_id,
                      },
                    });
                  }}
                >
                  <img
                    className="w-auto"
                    src="/images/stripe.svg"
                    alt="stripe"
                  />
                  <img className="w-auto" src="/images/open.svg" alt="open" />
                </button>

                {/* <button
                  type="button"
                  className="flex w-full cursor-pointer items-center justify-between border border-gray-300 p-4 text-left text-lg font-semibold text-primary"
                  onClick={() => {
                    setAction('paypal');
                    setVisible(true);
                  }}
                >
                  <img
                    className="w-auto"
                    src="/images/paypal.svg"
                    alt="paypal"
                  />
                  <img className="w-auto" src="/images/open.svg" alt="open" />
                </button>

                <button
                  type="button"
                  className="flex w-full cursor-pointer items-center justify-between border border-gray-300 p-4 text-left text-lg font-semibold text-primary"
                  onClick={() => {
                    setAction('wiretransfer');
                    setVisible(true);
                  }}
                >
                  <span className="font-bold text-[#BAB1C5]">
                    Virement bancaire
                  </span>
                  <img className="w-auto" src="/images/open.svg" alt="open" />
                </button> */}
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal
        closeButton
        scroll
        width="600px"
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        onClose={closeHandler}
        {...bindings}
      >
        <form onSubmit={submitPayment}>
          <Modal.Header justify="start">
            <Text h2 b id="modal-title" size={18}>
              {action === 'stripe' && 'Stripe'}
              {/* {action === 'paypal' && 'Paypal'}
            {action === 'wiretransfer' && 'Virement bancaire'} */}
            </Text>
          </Modal.Header>
          <Modal.Body>
            <div>
              <div className="w-full">
                <Input
                  name="account"
                  bordered
                  fullWidth="true"
                  label={
                    action === 'wiretransfer'
                      ? 'Entrez votre RIB'
                      : 'Entrez votre ID'
                  }
                  placeholder="xxxxxxxxxxxxxxxxxxxxxx"
                  className="ring-0 focus:border-none focus:outline-none focus:ring-0"
                  value={inputs.account}
                  color={helper.account.color}
                  helperColor={helper.account.color}
                  helperText={helper.account.text}
                  onChange={handleChange}
                />
              </div>
              <Spacer y={2} />
            </div>
          </Modal.Body>
          <Modal.Footer
            justify="end"
            className="!block w-full justify-end md:!flex"
          >
            <div className="m-auto flex justify-end">
              <Button
                css={{
                  border: '1px solid',
                  borderColor: '#C9C9C9',
                  color: 'black',
                  backgroundColor: 'transparent',
                }}
                auto
                onClick={() => setVisible(false)}
              >
                Annuler
              </Button>
              <Spacer x={0.5} />
              <Button
                type="submit"
                css={{
                  border: '1px solid',
                  borderColor: '#DC3838',
                  color: 'white',
                  backgroundColor: '#DC3838',
                }}
                auto
              >
                {loading && (
                  <Loading
                    style={{
                      marginRight: '1rem',
                    }}
                    color="currentColor"
                    size="sm"
                  />
                )}
                Valider
              </Button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      {notification?.text && (
        <div
          className={`fixed bottom-4 right-4 z-[99999] flex rounded px-3 py-2.5 text-sm text-white transition-all delay-200 duration-300
        ${
          showNotification
            ? 'translate-y-0 opacity-100'
            : 'translate-y-2 opacity-0'
        } ${notification.type === 'error' ? 'bg-red-600' : 'bg-green-600'}
        ${notification.type === 'info' && 'bg-red-600'}`}
        >
          <span>{notification.text}</span>
          <svg
            onClick={() => {
              closeNotif();
            }}
            className="ml-2 h-5 w-5 cursor-pointer"
            width="24"
            height="24"
            strokeWidth="1.5"
            viewBox="0 0 24 24"
            stroke="currentColor"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6.75827 17.2426L12.0009 12M17.2435 6.75736L12.0009 12M12.0009 12L6.75827 6.75736M12.0009 12L17.2435 17.2426"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      )}
    </>
  );
};

export default Settings;
