import { userRequest } from '../../api';
import {
  inspectorFailure,
  inspectorStart,
  inspectorSuccess,
} from '../reducers/inspectorReducer';
import {
  measurerFailure,
  measurerStart,
  measurerSuccess,
} from '../reducers/measurerReducer';
import { expired, loginReset } from '../reducers/userReducer';

export const getInspectors = (page, pageSize) => async (dispatch) => {
  dispatch(inspectorStart());
  try {
    const res = await userRequest.get(
      `entreprise/inspecteur?page=${page}&per_page=${pageSize}`
    );
    dispatch(inspectorSuccess(res.data));
  } catch (err) {
    dispatch(inspectorFailure(err.response.data));
    if (err.response.status === 401) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(inspectorFailure(err.response.data));
    }
  }
};

export const getAvailableInspectors = async (page, pageSize) => {
  return userRequest.get(
    `entreprise/inspecteur?page=${page}&per_page=${pageSize}`
  );
};

export const getEnterpriseInspectors =
  (id, page, pageSize) => async (dispatch) => {
    dispatch(inspectorStart());
    try {
      const res = await userRequest.get(
        `admin/entreprise/${id}/inspecteur?page=${page}&per_page=${pageSize}`
      );
      dispatch(inspectorSuccess(res.data));
    } catch (err) {
      dispatch(inspectorFailure(err.response.data));
      if (err.response.status === 403) {
        dispatch(expired());
        dispatch(loginReset());
      } else {
        dispatch(inspectorFailure(err.response.data));
      }
    }
  };

export const getMeasurers = (page, pageSize) => async (dispatch) => {
  dispatch(measurerStart());
  try {
    const res = await userRequest.get(
      `entreprisemesureur/mesureurentreprise?page=${page}&per_page=${pageSize}`
    );
    dispatch(measurerSuccess(res.data));
  } catch (err) {
    dispatch(measurerFailure(err.response.data));
    if (err.response.status === 401) {
      dispatch(expired());
      dispatch(loginReset());
    } else {
      dispatch(measurerFailure(err.response.data));
    }
  }
};

export const getAvailableMeasurers = async (page, pageSize) => {
  return userRequest.get(
    `entreprisemesureur/mesureurentreprise?page=${page}&per_page=${pageSize}`
  );
};

export const getEnterpriseMeasurers =
  (id, page, pageSize) => async (dispatch) => {
    dispatch(measurerStart());
    try {
      const res = await userRequest.get(
        `admin/entreprise/${id}/mesureur?page=${page}&per_page=${pageSize}`
      );
      dispatch(measurerSuccess(res.data));
    } catch (err) {
      dispatch(measurerFailure(err.response.data));
      if (err.response.status === 403) {
        dispatch(expired());
        dispatch(loginReset());
      } else {
        dispatch(measurerFailure(err.response.data));
      }
    }
  };

export const addInspector = async (inspector) => {
  return userRequest.post('entreprise/inspecteur', inspector);
};

export const deleteInspector = async (id) => {
  return userRequest.delete(`entreprise/inspecteur/${id}`);
};

export const update = async (user) => {
  return userRequest.put('entreprise/inspecteur', user);
};

export const getReports = async () => {
  return userRequest.get('inspecteur/rapport');
};

export const getHistory = async (page, pageSize) => {
  return userRequest.get(
    `inspecteur/historiquepaiement?page=${page}&per_page=${pageSize}`
  );
};

export const getHistoryMeasurer = async (page, pageSize) => {
  return userRequest.get(
    `mesureur/historiquepaiement?page=${page}&per_page=${pageSize}`
  );
};
export const getAdminCommission = async (page, pageSize) => {
  return userRequest.get(`admin/commissions?page=${page}&per_page=${pageSize}`);
};

export const getEnterpriseReports = async () => {
  return userRequest.get('entreprise/rapport');
};

export const updateInspectorPayments = async (user) => {
  return userRequest.put('inspecteur/inspecteurinfo', user);
};

export const gotEnterprisePayment = async () => {
  return userRequest.post('entreprise/retrievefund');
};

export const gotPayment = async () => {
  return userRequest.post('inspecteur/retrievefund');
};

export const gotMeasurerPayment = async () => {
  return userRequest.post('mesureur/retrievefund');
};

export const getPeriodicMeasurer = async (data, page, pageSize) => {
  return userRequest.post(
    `mesureur/historiquepaiementfilterbyperiode?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getPeriodicHistory = async (data, page, pageSize) => {
  return userRequest.post(
    `inspecteur/historiquepaiementfilterbyperiode?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getPeriodicReports = async (data, page, pageSize) => {
  return userRequest.post(
    `inspecteur/rapportfilterbyperiode?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getEnterprisePeriodicReports = async (data, page, pageSize) => {
  return userRequest.post(
    `entreprise/rapportfilterbyperiode?page=${page}&per_page=${pageSize}`,
    data
  );
};

export const getReport = async (id) => {
  return userRequest.get(`inspecteur/rapport/${id}`);
};

export const getEnterpriseReport = async (id) => {
  return userRequest.get(`entreprise/rapport/${id}`);
};

export const addReport = async (report) => {
  return userRequest.post('inspecteur/rapport', report);
};

export const updateMesurerReport = async (data) => {
  return userRequest.post(`mesureur/updatedemandeaudit`, data);
};

export const updateInspectorReport = async (data) => {
  return userRequest.post(`inspecteur/updatedemandeaudit`, data);
};

export const deleteReport = async (id) => {
  return userRequest.delete(`inspecteur/rapport/${id}`);
};
