export const add = (arr, item) => {
  const newArr = [...arr];
  newArr.push(item);
  return newArr;
};

export const remove = (arr, item) => {
  const newArr = [...arr];
  newArr.splice(
    newArr.findIndex(
      (e) => e.message?.toLowerCase() === item?.message?.toLowerCase()
    ),
    1
  );
  return newArr;
};
