export const renderDate = (date) => {
  return date
    ? Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }).format(new Date(date))
    : '';
};

export const renderDateOnly = (date) => {
  return date
    ? Intl.DateTimeFormat('fr-FR', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }).format(new Date(date))
    : '';
};

export const renderTime = (date) => {
  return date
    ? Intl.DateTimeFormat('fr-FR', {
        hour: 'numeric',
        minute: 'numeric',
      }).format(new Date(date))
    : '';
};

export const renderCharacter = (string) => {
  if (string) {
    return String(string?.slice(0, 1)).toUpperCase() + String(string?.slice(1));
  }
  return '';
};

export const applyStatusColor = (stat) => {
  switch (stat) {
    case 'en attente':
      return '#F31260';
    case 'annulé':
      return '#889096';
    case 'réalisé':
      return '#17C964';
    case 'en cours de réalisation':
      return '#0072F5';
    case 'validée':
      return '#64E986';
    case 'réalisée':
      return '#17C9f3';
    case 'en cours de validation':
      return '#06B7DF';
    case 'en attente de réalisation':
      return '#06B7b9';
    case 'soumise':
      return '#208a9e';
    case 'terminée':
      return '#6AFB92';
    case 'rejetée':
      return '#F75D59';
    case 'en cours':
      return '#FFA500';
    case 'affectée':
      return '#0099FF';
    case 'cloturée':
      return '#C19A6B';
    case 'a_corriger':
      return '#4a919e';
    default:
      return 'transparent';
  }
};
